import { ADD_NEW_NOTE, FETCH_NOTES_BY_TYPE, FETCH_NOTES } from '../actions/types';

const defaultState = {
	data: [],
};

export default function (state = defaultState, action) {
	switch (action.type) {
		case ADD_NEW_NOTE:
			return (
				{
					...state,
					data: [action.payload, ...state.data],
				} || false
			);
		case FETCH_NOTES_BY_TYPE:
			return state;
		case FETCH_NOTES:
			return { data: action.payload || false };
		default:
			return state;
	}
}
