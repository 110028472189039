import React from 'react';
import { useSelector } from 'react-redux';
import { objectEqual } from '../../utils';
// import { longColor, shortColor, backgroundColor } from '../config/genericConfig';
import { DrawPositionBook } from '../../../libs/react-stockcharts/src/lib/DrawPositionBook';

// To use redux hooks, you export a function component, not a react component.
export const PositionBookPriceCoordinateStore = (props) => {
	/**
	 * Get our store and the data that we need.
	 */
	const orderBook = useSelector((state) => state.oanda.orderBook, objectEqual);
	const positionBook = useSelector((state) => state.oanda.positionBook, objectEqual);

	/**
	 * Make sure we have data or return false.
	 * We seperate the below for legability.
	 */
	if (!positionBook || positionBook === {} || positionBook.buckets == null) return '';
	if (props.bookType === 'order' && (!orderBook || orderBook === {} || orderBook.buckets == null)) return '';

	/**
	 * Send it to the indicator
	 */
	return (
		<DrawPositionBook
			data={props.bookType === 'order' ? orderBook.buckets : positionBook.buckets}
			className="react-stockcharts-enable-interaction"
			key={Math.random().toString(36).substr(2, 7)}
			{...props}
		/>
	);
};
