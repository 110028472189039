import React from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux'; // useDispatch()
import { DailyEarnings } from './components/dailyearnings/DailyEarnings';
import { BalanceOverTime } from './components/balanceovertime/BalanceOverTime';
import { MarginUsedWidget } from './components/marginused/MarginUsedWidget';
import { WeeklyFaceOffWidget } from './components/weeklyfaceoff/WeeklyFaceOffWidget';
import { TradingPanelContainer } from './components/tradingpanel/TradingPanelContainer';
import { TradeNumberWidget } from './components/tradenumber/TradeNumberWidget';
import { InstrumentBreakDown } from './components/instrumentbreakdown/InstrumentBreakDown';
import { ChartComponent } from '../../components/StockChart/default';
import s from './Dashboard.module.scss';
import { chartColors } from '../../components/StockChart/config/genericConfig';

export const Dashboard = () => {
	/**
	 * Fetch any state that we need to render the dashboard
	 */
	const chartSize = useSelector((state) => {
		return state.oanda.chartSettings.dashboardSize || 8;
	});

	return (
		<div className={s.root}>
			<Row>
				<Col lg={chartSize} className="mb-5">
					<ChartComponent height={660} backgroundColor={chartColors.chartBackground} toolbarBackgroundColor={chartColors.toolbarBackground} />
				</Col>
				<Col lg={4}>
					<DailyEarnings />
				</Col>
				<Col lg={4} xs={12}>
					<WeeklyFaceOffWidget />
				</Col>
				<Col lg={4} xs={12}>
					<MarginUsedWidget />
				</Col>
				<Col lg={4} xs={12}>
					<TradeNumberWidget />
				</Col>
				<Col xl={12} lg={12} xs={12}>
					<BalanceOverTime />
				</Col>
				<Col xl={12} lg={12} xs={12}>
					<TradingPanelContainer />
				</Col>
				<Col xl={12} lg={12} xs={12}>
					<InstrumentBreakDown />
				</Col>
			</Row>
			<Row>
				<Col lg={12} xs={12}></Col>
			</Row>
		</div>
	);
};
