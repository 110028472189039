import { format } from 'd3-format';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import displayValuesFor from './displayValuesFor';
import GenericChartComponent from '../GenericChartComponent';
import { functor } from '../utils';
import ToolTipText from './ToolTipText';
import ToolTipTSpanLabel from './ToolTipTSpanLabel';
import { rawFloatFormat, rawCommaFormat } from '../../../../../components/utils';

class COTTooltip extends Component {
	constructor(props) {
		super(props);
		this.renderSVG = this.renderSVG.bind(this);
	}
	/**
	 * Create a generic yAccessor
	 *
	 * @param {obj} d
	 * @param {str} type
	 * 	- netDealer, netInstitutional, netLeveraged, netOther
	 *     or any value in cotData
	 */
	yAccessor(d, type) {
		if (this.props.usePercent) type = `${type}Pct`;
		return d && d.cotData && d.cotData[type] ? parseFloat(d.cotData[type]) : 0;
	}

	renderSVG(moreProps) {
		const {
			onClick,
			fontFamily,
			fontSize,
			className,
			labelFill,
			textFill,
			displayValuesFor,
			origin: originProp,
			stroke,
			usePercent,
		} = this.props;

		const {
			chartConfig: { width, height },
		} = moreProps;

		const currentItem = displayValuesFor(this.props, moreProps);

		// const cot = isDefined(currentItem) && yAccessor(currentItem);
		// const value = (cot && displayFormat(cot)) || 'n/a';

		const origin = functor(originProp);
		const [x, y] = origin(width, height);

		/**
		 * We collect our values here so we can determine which
		 * direction each is (long or short)
		 */
		const netLeveraged = usePercent
			? rawFloatFormat(this.yAccessor(currentItem, 'netLeveraged'), 2, 3, ',', '.')
			: this.yAccessor(currentItem, 'netLeveraged');
		const netDealer = usePercent
			? rawFloatFormat(this.yAccessor(currentItem, 'netDealer'), 2, 3, ',', '.')
			: this.yAccessor(currentItem, 'netDealer');
		const netInstitutional = usePercent
			? rawFloatFormat(this.yAccessor(currentItem, 'netInstitutional'), 2, 3, ',', '.')
			: this.yAccessor(currentItem, 'netInstitutional');
		const netOther = usePercent
			? rawFloatFormat(this.yAccessor(currentItem, 'netOther'), 2, 3, ',', '.')
			: this.yAccessor(currentItem, 'netOther');

		return (
			<g className={className} transform={`translate(${x}, ${y})`} onClick={onClick}>
				<line x1={0} y1={-10} x2={0} y2={1} stroke={stroke.netLeveraged} strokeWidth="4px" />
				<ToolTipText x={6} y={0} fontFamily={fontFamily} fontSize={fontSize}>
					<ToolTipTSpanLabel fill={labelFill}>Leveraged: </ToolTipTSpanLabel>
					<tspan fill={textFill}>
						{rawCommaFormat(Math.abs(netLeveraged))}
						{usePercent ? '%' : ''} {netLeveraged > 0 ? 'Long' : 'Short'}
					</tspan>
				</ToolTipText>

				<line x1={0} y1={4} x2={0} y2={15} stroke={stroke.netDealer} strokeWidth="4px" />
				<ToolTipText x={6} y={14} fontFamily={fontFamily} fontSize={fontSize}>
					<ToolTipTSpanLabel fill={labelFill}>Dealer: </ToolTipTSpanLabel>
					<tspan fill={textFill}>
						{rawCommaFormat(Math.abs(netDealer))}
						{usePercent ? '%' : ''} {netDealer > 0 ? 'Long' : 'Short'}
					</tspan>
				</ToolTipText>

				<line x1={0} y1={19} x2={0} y2={30} stroke={stroke.netInstitutional} strokeWidth="4px" />
				<ToolTipText x={6} y={29} fontFamily={fontFamily} fontSize={fontSize}>
					<ToolTipTSpanLabel fill={labelFill}>Institutional: </ToolTipTSpanLabel>
					<tspan fill={textFill}>
						{rawCommaFormat(Math.abs(netInstitutional))}
						{usePercent ? '%' : ''} {netInstitutional > 0 ? 'Long' : 'Short'}
					</tspan>
				</ToolTipText>

				<line x1={0} y1={34} x2={0} y2={45} stroke={stroke.netOther} strokeWidth="4px" />
				<ToolTipText x={6} y={44} fontFamily={fontFamily} fontSize={fontSize}>
					<ToolTipTSpanLabel fill={labelFill}>Other: </ToolTipTSpanLabel>
					<tspan fill={textFill}>
						{rawCommaFormat(Math.abs(netOther))}
						{usePercent ? '%' : ''} {netOther > 0 ? 'Long' : 'Short'}
					</tspan>
				</ToolTipText>
			</g>
		);
	}
	render() {
		return <GenericChartComponent clip={false} svgDraw={this.renderSVG} drawOn={['mousemove']} />;
	}
}

COTTooltip.propTypes = {
	origin: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
	usePercent: PropTypes.bool,
	className: PropTypes.string,
	fontFamily: PropTypes.string,
	fontSize: PropTypes.number,
	onClick: PropTypes.func,
	yAccessor: PropTypes.func,
	displayFormat: PropTypes.func,
	displayValuesFor: PropTypes.func,
	textFill: PropTypes.string,
	labelFill: PropTypes.string,
};

COTTooltip.defaultProps = {
	usePercent: true,
	displayFormat: format('.2f'),
	displayValuesFor: displayValuesFor,
	origin: [0, 0],
	className: 'react-stockcharts-tooltip',
};

export default COTTooltip;
