import React, { useState } from 'react';
import { useSelector } from 'react-redux'; // useDispatch()
import Widget from '../../../../components/Widget/Widget';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { floatToNumberFormat, objectEqual } from '../../../../components/utils';
import Loader from '../../../../components/Loader/Loader';
import { timeFrameMap } from '../../oandaData';

// To use redux hooks, you export a function component, not a react component.
export const TradeNumberWidget = () => {
	/**
	 * We use an arrow function whitch passes state to a
	 * callback function that returns the state we need.
	 */
	const profit = useSelector((state) => {
		if (state.oanda.accountID != null && state.oanda.profit[state.oanda.accountID] != null) return state.oanda.profit[state.oanda.accountID];
	}, objectEqual);

	/**
	 * Local state for changing what we show
	 */
	const [activeTimePeriod, setActiveTimePeriod] = useState('today');

	/**
	 * If undefined, return nothing.
	 */
	if (profit == null || profit?.[activeTimePeriod]?.instruments == null)
		return (
			<Widget title={<h6> TRADE NUMBER </h6>}>
				<Loader />
			</Widget>
		);

	/**
	 * Loop through our array above and return a clickable dropdown
	 */
	const renderDropDownClick = () => {
		let dropDownItems = [];
		for (const period in timeFrameMap)
			dropDownItems.push(
				<DropdownItem key={period} onClick={() => setActiveTimePeriod(period)}>
					{timeFrameMap[period]}
				</DropdownItem>
			);

		return dropDownItems;
	};

	/**
	 * Find the instrument with the most trades
	 */
	let mostTradesInstrument = null;

	/**
	 * Loop through it
	 */
	if (Object.keys(profit[activeTimePeriod].instruments).length > 0) {
		for (const instrument in profit[activeTimePeriod].instruments) {
			if (mostTradesInstrument == null) mostTradesInstrument = { ...profit[activeTimePeriod].instruments[instrument], instrument };
			else
				mostTradesInstrument =
					profit[activeTimePeriod].instruments[instrument].tradeCount > mostTradesInstrument.tradeCount
						? { ...profit[activeTimePeriod].instruments[instrument], instrument }
						: mostTradesInstrument;
		}
	} else {
		mostTradesInstrument = {
			tradeCount: 0,
			instrument: 'N/A',
		};
	}

	return (
		<Widget
			className="mb-0"
			bodyClass={`mt p-0`}
			title={
				<div className="d-flex justify-content-between flex-wrap">
					<h4 className="d-flex align-items-center pb-1 bigStatTitle">
						<span className={`circle bg-success mr-sm`} style={{ fontSize: '6px' }} />
						Trade <span className="fw-normal ml-xs">Numbers</span>
					</h4>
					<UncontrolledButtonDropdown>
						<DropdownToggle caret color="subtle-blue" className="dropdown-toggle-split btn-sm">
							{timeFrameMap[activeTimePeriod]}&nbsp;&nbsp;
						</DropdownToggle>
						<DropdownMenu>{renderDropDownClick()}</DropdownMenu>
					</UncontrolledButtonDropdown>
				</div>
			}
		>
			<h4 className="fw-semi-bold mb-lg px-4">
				{floatToNumberFormat(profit[activeTimePeriod].tradeCount)} <small>{timeFrameMap[activeTimePeriod]}</small>
			</h4>
			<div className={`d-flex borderTop`}>
				<div className={`w-50 borderRight py-3 pr-2 pl-4`}>
					<div className="d-flex align-items-start h3">
						<h6>{floatToNumberFormat(profit[activeTimePeriod].closedTradeCount)}</h6>
						<i className={`la la-arrow-right ml-sm text-success rotate-315`} />
					</div>
					<p className="text-muted mb-0 mr">
						<small>Closed Trades</small>
					</p>
				</div>
				<div className="w-50 py-3 pl-2">
					<div className="d-flex align-items-start h3">
						<h6 className="pr-3">{floatToNumberFormat(mostTradesInstrument.tradeCount)}</h6>
						{mostTradesInstrument.instrument}
					</div>
					<p className="text-muted mb-0 mr">
						<small>Most Traded</small>
					</p>
				</div>
			</div>
		</Widget>
	);
};
