const hostApi = process.env.NODE_ENV === 'development' ? 'http://localhost' : 'https://adenom.io';
const portApi = process.env.NODE_ENV === 'development' ? 5001 : 443;
const baseURLApi = `${hostApi}${portApi ? `:${portApi}` : ``}`;
const redirectUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5001' : 'https://adenom.io';

export default {
	redirectUrl,
	hostApi,
	portApi,
	baseURLApi,
	remote: 'https://adenom.io',
	isBackend: process.env.REACT_APP_BACKEND,
	auth: {
		email: '',
		password: '',
	},
};
