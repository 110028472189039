import {
	CHANGE_SIDEBAR_VISIBILITY,
	CHANGE_SIDEBAR_POSITION,
	OPEN_SIDEBAR,
	CLOSE_SIDEBAR,
	CHANGE_ACTIVE_SIDEBAR_ITEM,
	CHANGE_MODAL_INSTRUMENT_OPEN,
	CHANGE_MODAL_CHARTTYPE_OPEN,
	CHANGE_MODAL_GRANULARITY_OPEN,
	CHANGE_MODAL_INDICATOR_OPEN,
	CHANGE_MODAL_ACCOUNTSEARCH_OPEN,
	CHANGE_MODAL_ORDERS_OPEN,
	CHANGE_MODAL_TPSL_OPEN,
	CHANGE_MODAL_LIMIT_OPEN,
	CHANGE_MODAL_INDICATOR_EDIT,
} from '../actions/types';

const initialState = {
	sidebarOpened: false,
	activeItem: window.location.pathname,
	sidebarPosition: 'left',
	sidebarVisibility: 'hide',
	modals: {
		instrumentSearchOpen: false,
		chartTypeSearchOpen: false,
		granularitySearchOpen: false,
		indicatorSearchOpen: false,
		indicatorEditOpen: false, // Set to false but will be an ID
		accountSearchOpen: false,
		ordersOpen: false,
		tpslOpen: 0,
		limitOpen: 0,
	},
};

export default function runtime(state = initialState, action) {
	switch (action.type) {
		case OPEN_SIDEBAR:
			return Object.assign({}, state, {
				sidebarOpened: true,
			});
		case CLOSE_SIDEBAR:
			return Object.assign({}, state, {
				sidebarOpened: false,
			});
		case CHANGE_SIDEBAR_POSITION:
			return Object.assign({}, state, {
				sidebarPosition: action.payload,
			});
		case CHANGE_SIDEBAR_VISIBILITY:
			return Object.assign({}, state, {
				sidebarVisibility: action.payload,
			});
		case CHANGE_ACTIVE_SIDEBAR_ITEM:
			return {
				...state,
				activeItem: action.activeItem,
			};
		case CHANGE_MODAL_INSTRUMENT_OPEN:
			return { ...state, modals: { ...state.modals, instrumentSearchOpen: action.payload } };
		case CHANGE_MODAL_CHARTTYPE_OPEN:
			return { ...state, modals: { ...state.modals, chartTypeSearchOpen: action.payload } };
		case CHANGE_MODAL_GRANULARITY_OPEN:
			return { ...state, modals: { ...state.modals, granularitySearchOpen: action.payload } };
		case CHANGE_MODAL_INDICATOR_OPEN:
			return { ...state, modals: { ...state.modals, indicatorSearchOpen: action.payload } };
		case CHANGE_MODAL_INDICATOR_EDIT:
			return { ...state, modals: { ...state.modals, indicatorEditOpen: action.payload } };
		case CHANGE_MODAL_ACCOUNTSEARCH_OPEN:
			return { ...state, modals: { ...state.modals, accountSearchOpen: action.payload } };
		case CHANGE_MODAL_ORDERS_OPEN:
			return { ...state, modals: { ...state.modals, ordersOpen: action.payload } };
		case CHANGE_MODAL_TPSL_OPEN:
			return { ...state, modals: { ...state.modals, tpslOpen: action.payload } };
		case CHANGE_MODAL_LIMIT_OPEN:
			return { ...state, modals: { ...state.modals, limitOpen: action.payload } };
		default:
			return state;
	}
}
