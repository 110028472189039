import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LineSeries from './LineSeries';
import StraightLine from './StraightLine';
import BarSeries from './BarSeries';

class GenericSeries extends Component {
	// constructor(props) {
	// 	super(props);
	// }

	render() {
		const {
			className,
			stroke,
			lineSeriesCount,
			fetchName,
			yAccessorDivergence,
			yAccessor,
			width,
			widthRatio,
			divergenceStroke,
			fill,
			opacity,
			clip,
			noZeroLine,
			lineAt,
		} = this.props;
		const { zeroLineStroke, zeroLineOpacity } = this.props;

		/**
		 * Create a holder
		 */
		let seriesHolder = [];
		let lineHolder = []; // Lines as in the straight lines (IE: at 0, from lineAt) NOT lineseries.

		/**
		 * If the yAccessor is a function, use that.
		 *
		 * If the yAccessor is an array (of functions), loop
		 * through each of those.
		 *
		 * If nothing else, loop through generically.
		 */
		if (typeof yAccessor === 'function') {
			seriesHolder.push(
				<LineSeries
					className={className}
					yAccessor={(d) => yAccessor(d, this.props)}
					stroke={stroke[0][0]}
					strokeWidth={stroke[0][1]}
					key={Math.random().toString(36).substr(2, 7)}
				/>
			);
		} else if (Array.isArray(yAccessor)) {
			/**
			 * yAccessor is an array of functions
			 */
			for (let i = 0; i < yAccessor.length; i++) {
				seriesHolder.push(
					<LineSeries
						className={className}
						yAccessor={(d) => yAccessor[i](d, this.props)}
						stroke={stroke[i][0]}
						strokeWidth={stroke[i][1]}
						key={Math.random().toString(36).substr(2, 7)}
					/>
				);
			}
		} else {
			/**
			 * Loop through all of the data and create a lineseries
			 * for it.
			 */
			for (let i = 0; i < lineSeriesCount; i++) {
				seriesHolder.push(
					<LineSeries
						className={className}
						yAccessor={(d) => (d[fetchName] ? d[fetchName].allData[i].value : 0)}
						stroke={stroke[i][0]}
						strokeWidth={stroke[i][1]}
						key={Math.random().toString(36).substr(2, 7)}
					/>
				);
			}
		}

		/**
		 * If the user has included a divergence, show it
		 * here.
		 */
		if (typeof yAccessorDivergence === 'function') {
			seriesHolder.push(
				<BarSeries
					baseAt={(xScale, yScale) => yScale(0)}
					className="macd-divergence"
					width={width}
					widthRatio={widthRatio}
					stroke={divergenceStroke}
					fill={(d) => (yAccessorDivergence(d, this.props) > 0 ? fill.divergencePositive : fill.divergenceNegative)}
					opacity={opacity}
					clip={clip}
					yAccessor={(d) => yAccessorDivergence(d, this.props)}
					key={Math.random().toString(36).substr(2, 7)}
				/>
			);
		}

		/**
		 * Create our lines
		 */
		if (Array.isArray(lineAt)) {
			for (let l = 0; l < lineAt.length; l++) {
				lineHolder.push(
					<StraightLine
						stroke={zeroLineStroke}
						opacity={zeroLineOpacity}
						yValue={lineAt[l]}
						key={Math.random().toString(36).substr(2, 7)}
					/>
				);
			}
		} else if (lineAt !== 0)
			lineHolder.push(
				<StraightLine stroke={zeroLineStroke} opacity={zeroLineOpacity} yValue={lineAt} key={Math.random().toString(36).substr(2, 7)} />
			);

		/**
		 * Do our zero line, or not.
		 */
		if (!noZeroLine)
			lineHolder.push(
				<StraightLine stroke={zeroLineStroke} opacity={zeroLineOpacity} yValue={0} key={Math.random().toString(36).substr(2, 7)} />
			);

		return (
			<g className={className}>
				{lineHolder}
				{/*  */}
				{seriesHolder}
			</g>
		);
	}
}

GenericSeries.propTypes = {
	className: PropTypes.string,
	yAccessor: PropTypes.func,
	yAccessorDivergence: PropTypes.func,
	divergenceStroke: PropTypes.bool,
	usePercent: PropTypes.bool,
	zeroLineStroke: PropTypes.string,
	zeroLineOpacity: PropTypes.number,
	fill: PropTypes.shape({
		divergencePositive: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
		divergenceNegative: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
	}).isRequired,
};

GenericSeries.defaultProps = {
	className: 'react-stockcharts-generic-series',
	zeroLineStroke: '#000000',
	zeroLineOpacity: 0.3,
	usePercent: true,
	fill: {
		divergencePositive: 'green',
		divergenceNegative: 'red',
	},
	stroke: [
		['#1870DC', 1], // Color, width
		['#B8C2CC', 1],
		['#8795A1', 1],
		['#B8C2CC', 1],
		['#58D777', 1],
		['#f0af03', 1],
		['#ef5350', 1],
		['#474D84', 1],
		['#14d3d3', 1],
	],
	clip: true,
	opacity: 0.9,
	divergenceStroke: false,
	widthRatio: 0.5,
	width: BarSeries.defaultProps.width,
	noZeroLine: false,
	lineAt: 0,
};

export default GenericSeries;
