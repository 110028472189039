import React from 'react';
import { useSelector } from 'react-redux';
import { ToolTipText, ToolTipTSpanLabel } from '../../../libs/react-stockcharts/src/lib/tooltip';
import { floatPrecisionFix, objectEqual } from '../../utils';
import { chartColors } from '../config/genericConfig';

// To use redux hooks, you export a function component, not a react component.
export const LongShortPercentText = React.memo(
	({
		origin = [0, 0],
		labelFill = '#4682B4',
		valueFill = '#000000',
		className = 'react-stockcharts-tooltip',
		fontSize = 11,
		fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
		rectHeight = 2,
		longStroke = chartColors.white,
		longFill = chartColors.greenTradingVue,
		longStrokeOpacity = 0.1,
		longFillOpacity = 0.9,
		longStrokeWidth = 1,
		shortStroke = chartColors.white,
		shortFill = chartColors.redTradingView,
		shortStrokeOpacity = 0.1,
		shortFillOpacity = 0.9,
		shortStrokeWidth = 1,
	}) => {
		/**
		 * Get our store and the data that we need.
		 */
		const positionBook = useSelector((state) => state.oanda.positionBook, objectEqual);

		/**
		 * Make sure we have data or return false.
		 * We seperate the below for legability.
		 */
		if (!positionBook || positionBook === {} || positionBook.netLong == null) return '';

		/**
		 * Get our props and origin
		 */
		const [x, y] = origin;

		/**
		 * Send it to the indicator
		 */
		return (
			<g className={className} transform={`translate(${x}, ${y})`}>
				<ToolTipText x={0} y={0} fontFamily={fontFamily} fontSize={fontSize}>
					<ToolTipTSpanLabel fill={labelFill}>Long </ToolTipTSpanLabel>
					<tspan fill={valueFill}>{`${floatPrecisionFix(positionBook.netLong, 1)}%`} </tspan>
					{'    '}
					<ToolTipTSpanLabel fill={labelFill}>Short </ToolTipTSpanLabel>
					<tspan fill={valueFill}>{`${floatPrecisionFix(positionBook.netShort, 1)}%`}</tspan>
				</ToolTipText>
				<rect
					x={10}
					y={5}
					width={positionBook.netLong}
					height={rectHeight}
					key={Math.random().toString(36).substr(2, 7)}
					stroke={longStroke}
					strokeWidth={longStrokeWidth}
					strokeOpacity={longStrokeOpacity}
					fill={longFill}
					fillOpacity={longFillOpacity}
				/>

				<rect
					x={10 + positionBook.netLong + 1}
					y={5}
					width={positionBook.netShort}
					height={rectHeight}
					key={Math.random().toString(36).substr(2, 7)}
					stroke={shortStroke}
					strokeWidth={shortStrokeWidth}
					strokeOpacity={shortStrokeOpacity}
					fill={shortFill}
				/>
			</g>
		);
	}
);
