import React, { useEffect, useState } from 'react';
import s from '../dashboard/Dashboard.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	fetchTransactionBrowseData,
	fetchTransactionBrowseCount,
	changeTransactionConfig,
	removeTransactionBrowseData,
} from '../../actions/backtest';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { floatToCurrencyFormat, objectEqual, NoDataIndication } from '../../components/utils';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import Moment from 'react-moment';

// To use redux hooks, you export a function component, not a react component.
export const BrowseTransactions = (props) => {
	/**
	 * Call the state variables and dispatch into
	 * existance.
	 */
	const { pageIndex, pageSize, sortBy, filters, total, lastID } = useSelector((state) => state.backtest.browse.transactionConfig);
	const transactionsData = useSelector((state) => {
		return state.backtest.browse.transactionsData;
	}, objectEqual);
	const dispatch = useDispatch();

	/**
	 * Local state for the tabs
	 */
	const [activeTab, setActiveTab] = useState('1');

	/**
	 * Similar to componentDidMount and componentDidUpdate:
	 * The optional second param ([]) in useEffect tells it to only
	 * run if whatever data in that array has changed. If left blank (just []),
	 * it will only run once.
	 */
	useEffect(() => {
		/**
		 * Dispatch it!
		 */
		dispatch(fetchTransactionBrowseCount({ filters }));
		dispatch(fetchTransactionBrowseData({ pageIndex, pageSize, sortBy, filters, lastID }));
	}, [pageIndex, pageSize, sortBy, filters, dispatch, lastID, transactionsData]);

	/**
	 * These are the table headers which will be used by
	 * reactTable
	 */
	const columns = [
		{
			text: 'ID',
			dataField: '_id',
			formatter: (cell) => {
				return (
					<Link to={`/browse/transactions/${cell}`} className="text-white">
						{cell}
					</Link>
				);
			},
		},
		{
			text: 'Account ID',
			dataField: 'accountID',
			filter: textFilter({ delay: 2000, defaultValue: filters && filters.accountID ? filters.accountID.filterVal : '' }),
		},
		{
			text: 'Order ID',
			dataField: 'orderID',
			filter: textFilter({
				delay: 2000,
				defaultValue: filters && filters.orderID ? filters.orderID.filterVal : '',
			}),
		},
		{
			text: 'Symbol',
			dataField: 'instrument',
			filter: textFilter({ delay: 2000, defaultValue: filters && filters.instrument ? filters.instrument.filterVal : '' }),
		},
		{
			text: 'Type',
			dataField: 'type',
			filter: textFilter({ delay: 2000, defaultValue: filters && filters.type ? filters.type.filterVal : '' }),
		},
		{
			text: 'Profit',
			dataField: 'pl',
			formatter: (cell) => {
				const colorClass = parseFloat(cell) >= 0 ? 'text-success' : 'text-danger';
				if (cell) return <p className={`m-0 fw-semi-bold ${colorClass}`}>{floatToCurrencyFormat(cell)}</p>;
				else return '';
			},
		},
		{
			text: 'Financing',
			dataField: 'financing',
			formatter: (cell) => {
				if (cell) return <p className="m-0 fw-semi-bold text-danger">-{floatToCurrencyFormat(cell)}</p>;
				else return '';
			},
		},
		{
			text: 'Units',
			dataField: 'units',
			filter: textFilter({ delay: 2000, defaultValue: filters && filters.units ? filters.units.filterVal : '' }),
		},
		{
			text: 'Price',
			dataField: 'price',
		},
		{
			text: 'time',
			dataField: 'time',
			formatter: (cell) => {
				return <Moment format="YYYY-MM-DD HH:MM:SS">{cell}</Moment>;
			},
		},
	];

	const defaultSorted = [
		{
			dataField: '_id',
			order: 'desc',
		},
	];

	/**
	 * The function that calls the server data
	 *
	 * @param {} param0
	 */
	const RemoteAll = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
		<div>
			<BootstrapTable
				remote
				keyField="_id"
				data={data}
				columns={columns}
				defaultSorted={defaultSorted}
				filter={filterFactory()}
				pagination={paginationFactory({ page, sizePerPage, totalSize })}
				onTableChange={onTableChange}
				noDataIndication={() => <NoDataIndication />}
				striped
				borderless
				classes="text-white table-sm"
				filterPosition="top"
			/>
		</div>
	);

	const handleTableChange = (type, options) => {
		// Nothing to do here.
		if (type === 'filter' && JSON.stringify(filters) === JSON.stringify(options.filters)) return;

		/**
		 * Change the options
		 */
		const newtransactionConfig = {
			pageIndex: options.page,
			pageSize: options.sizePerPage,
			sortBy: options.sortField,
			filters: options.filters,
			lastID: null, // We don't use this.
			// lastID: page > pageIndex ? data[data.length - 1]['_id'] : null,
		};

		/**
		 * This should trigger an update via redux.
		 */
		dispatch(removeTransactionBrowseData());
		dispatch(changeTransactionConfig(newtransactionConfig));
	};

	/**
	 * Only render if we actually have data worth showing.
	 */
	return (
		<div className={s.root}>
			<Row>
				<Col xs="12" className="mb-5">
					<Nav tabs>
						<NavItem className={s.customNavItem}>
							<NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
								<span>Transactions</span>
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent className="mb-lg" activeTab={activeTab}>
						<TabPane tabId="1">
							<RemoteAll data={transactionsData} page={pageIndex} sizePerPage={pageSize} totalSize={total} onTableChange={handleTableChange} />
						</TabPane>
					</TabContent>
				</Col>
			</Row>
		</div>
	);
};
