import React, { useState, createRef, useRef } from 'react';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BaseModal } from './BaseModal';
import oanda from '../../images/oanda.svg';
import { changeModalInstrumentOpen } from '../../actions/navigation';
import { moveFocus, searchKeyDown } from '../utils';

// To use redux hooks, you export a function component, not a react component.
export const InstrumentSearch = (props) => {
	/**
	 * Use props to pass some items so we can
	 * have versatile results
	 */
	const { chartInstrument, instruments, instrumentChange } = props;

	/**
	 * Fetch our redux states
	 */
	const isOpen = useSelector((state) => state.navigation.modals.instrumentSearchOpen);
	const dispatch = useDispatch();

	/**
	 * Our state
	 */
	const [searchTerm, setSearchTerm] = useState(null);
	const refSearch = useRef(null);
	let focusRefs = []; // For refs

	/**
	 * This is just a search result holder
	 */
	let filteredInstruments =
		searchTerm === null
			? instruments
			: instruments.filter(
					(ins) => ins.name.search(new RegExp(searchTerm, 'i')) !== -1 || ins.displayName.search(new RegExp(searchTerm, 'i')) !== -1
			  );

	/**
	 * The onClose function
	 */
	const onClose = () => {
		setSearchTerm(null);
		dispatch(changeModalInstrumentOpen(false));
	};

	/**
	 * Handle when the user clicks on a new instrument.
	 *
	 * @param {str} instrument
	 */
	const handleInstrumentClick = (instrument) => {
		instrumentChange(instrument);
		onClose();
	};

	/**
	 * Cycle through the instrument options and map them
	 * to a new array as an option.
	 */
	const renderInstrumentOptions = () => {
		if (!filteredInstruments || !Array.isArray(filteredInstruments)) return '';

		return filteredInstruments
			.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
			.map((instrument) => {
				const newRef = createRef();
				focusRefs.push(newRef);

				return (
					<div
						className="listContainer"
						key={instrument.name}
						onClick={() => handleInstrumentClick(instrument.name)}
						ref={newRef}
						tabIndex={-1}
						onKeyDown={(e) => moveFocus(e, refSearch)}
					>
						<div className="itemRow">
							<div className="itemInfoCell cell">
								<div className="expandHandleWrap">
									{chartInstrument === instrument.name ? (
										<span className="expandHandle">
											<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
												<path stroke="currentColor" d="M8 5l3.5 3.5L8 12"></path>
											</svg>
										</span>
									) : (
										''
									)}
								</div>
								<div className="description">
									<div className="symbolTitleNoFlow">
										<span>{instrument.displayName}</span>
									</div>
								</div>
							</div>
							<div className="symbolDescription cell">{instrument.name}</div>
							<div className="exchangeCell cell">
								<div className="exchangeDescription">
									<div className="marketType">forex</div>
									<div className="exchangeName">OANDA</div>
								</div>
								<div className="flag">
									<img alt="Oanda" src={oanda} className="icon" />
								</div>
							</div>
						</div>
					</div>
				);
			});
	};

	return (
		<BaseModal isOpen={isOpen} onClose={onClose} title="Symbol Search">
			<InputGroup className="modalSearchInputGroup">
				<InputGroupAddon addonType="prepend">
					<span className="input-group-text transparentBG">
						<i className="fa fa-search" />
					</span>
				</InputGroupAddon>
				<Input
					type="text"
					className="modalSearch"
					placeholder="Search..."
					autoFocus={true}
					onChange={(e) => setSearchTerm(e.target.value)}
					innerRef={refSearch} // This is a prop of the input which converts into ref
					onKeyDown={(e) => searchKeyDown(e, focusRefs)}
				/>
			</InputGroup>
			<div className="modalSearchResultsList">{renderInstrumentOptions()}</div>
		</BaseModal>
	);
};
