/**
 * this comes from https://github.com/rrag/react-stockcharts/issues/707
 */
import React, { useState } from 'react';
// import { xScale } from './GenericComponent';
import GenericChartComponent from './GenericChartComponent';
import { findDataBetweenTime, rawFloatFormat } from '../../../../components/utils';
import { chartColors } from '../../../../components/StockChart/config/genericConfig';
import { BasicToolTip } from './tooltip/BasicToolTip';
import { getAxisCanvas } from './GenericComponent';
// import { hexToRGBA } from './utils';

export const DrawPositions = ({
	circleStroke1 = chartColors.circleStroke1, // RGBA/Opacity in SCSS
	circleFill1 = chartColors.circleFill1, // RGBA/Opacity in SCSS
	circleFillOpacity1 = 1,
	circleStrokeWidth1 = 1,
	cirlceRadius1 = 4,
	circleStroke2 = chartColors.circleStroke2, // RGBA/Opacity in SCSS
	circleFill2 = chartColors.circleFill2, // RGBA/Opacity in SCSS
	circleFillOpacity2 = 1,
	circleStrokeWidth2 = 1,
	cirlceRadius2 = 4,
	lineStroke = chartColors.connectorLine, // opacity set in css
	lineStrokeWidth = 1,
	className = 'react-stockcharts-drawpositions react-stockcharts-hover-tooltip',
}) => {
	/**
	 * Local state for the tabs
	 */
	const [showHover, setShowHover] = useState('');

	/**
	 * Render the component in SVG.
	 *
	 * @param {obj} moreProps
	 */
	const drawOnCanvas = (ctx, moreProps) => {
		const {
			xScale,
			xAccessor,
			plotData,
			chartConfig: { yScale },
		} = moreProps;

		if (!plotData || plotData.length === 0 || !yScale) {
			return null;
		}

		for (let index = 0; index < plotData.length; index++) {
			let position = plotData[index];
			if (position.entry && position.entry.posInt !== 0) {
				// && position.entry.exit != null && position.entry.exit.time != null
				let drawLine = true;

				/**
				 * Find the exit bar and if nothing is found,
				 * exit out.
				 */
				let exitBar = position.entry.exitSignal
					? findDataBetweenTime(plotData, Date.parse(position.entry.exitSignal.time))
					: plotData[plotData.length - 1];
				if (exitBar == null) exitBar = plotData[plotData.length - 1];

				/**
				 * Don't draw a line if we have no exit yet
				 */
				if (position.entry.exitSignal == null || position.entry.exitSignal.price == null) drawLine = false;

				/**
				 * Since this was too long for a ternary, we make it a
				 * variable an if here.
				 */
				let exitPrice = exitBar.close;
				if (position.entry.exitSignal && position.entry.exitSignal && position.entry.exitSignal.price)
					exitPrice = position.entry.exitSignal.price;

				/**
				 * Get our cooridnates. This is done using the xAccessor
				 * and passing in the entire bar data which contains the IDX
				 * (index) values.
				 */
				const y1 = yScale(position.entry.price);
				const x1 = xScale(xAccessor(position)); //xScale(new Date(openTime));
				const y2 = yScale(exitPrice);
				const x2 = xScale(xAccessor(exitBar)); //xScale(new Date(closeTime));

				/**
				 * No invalid data.
				 */
				if (isNaN(y2) || isNaN(x2)) return null;

				if (drawLine) {
					ctx.strokeStyle = lineStroke;
					ctx.lineWidth = lineStrokeWidth;
					ctx.beginPath();
					ctx.setLineDash([5, 5]);
					ctx.moveTo(x1, y1);
					ctx.lineTo(x2, y2);
					ctx.closePath();
					ctx.stroke();
				}

				ctx.fillStyle = circleFill1;
				ctx.lineWidth = circleStrokeWidth1;
				ctx.strokeStyle = circleStroke1;
				ctx.beginPath();
				ctx.setLineDash([]);
				ctx.arc(x1, y1, cirlceRadius1, 0, 2 * Math.PI, false);
				ctx.closePath();
				ctx.fill();
				ctx.stroke();

				if (drawLine) {
					ctx.fillStyle = circleFill2;
					ctx.lineWidth = circleStrokeWidth2;
					ctx.strokeStyle = circleStroke2;
					ctx.beginPath();
					ctx.setLineDash([]);
					ctx.arc(x2, y2, cirlceRadius2, 0, 2 * Math.PI, false);
					ctx.closePath();
					ctx.fill();
					ctx.stroke();
				}
			} // If position entery if check
		} // End for
	};

	/* eslint-disable no-unused-vars */
	/**
	 * Render the component in SVG.
	 *
	 * @param {obj} moreProps
	 */
	const renderSVGold = (moreProps) => {
		const {
			xScale,
			xAccessor,
			plotData,
			chartConfig: { yScale },
		} = moreProps;

		if (!plotData || plotData.length === 0 || !yScale) {
			return null;
		}

		return plotData.map((position, index) => {
			if (position.entry && position.entry.posInt !== 0) {
				// && position.entry.exit != null && position.entry.exit.time != null
				let drawLine = true;
				let positionID = position.idx.index;

				/**
				 * Find the exit bar and if nothing is found,
				 * exit out.
				 */
				let exitBar = position.entry.exitSignal
					? findDataBetweenTime(plotData, Date.parse(position.entry.exitSignal.time))
					: plotData[plotData.length - 1];
				if (exitBar == null) exitBar = plotData[plotData.length - 1];

				/**
				 * Don't draw a line if we have no exit yet
				 */
				if (position.entry.exitSignal == null || position.entry.exitSignal.price == null) drawLine = false;

				/**
				 * Since this was too long for a ternary, we make it a
				 * variable an if here.
				 */
				let exitPrice = exitBar.close;
				if (position.entry.exitSignal && position.entry.exitSignal && position.entry.exitSignal.price)
					exitPrice = position.entry.exitSignal.price;

				/**
				 * Short or long?
				 */
				const directionText = position.entry.posInt === 1 ? 'Long' : 'Short';

				/**
				 * Get our cooridnates. This is done using the xAccessor
				 * and passing in the entire bar data which contains the IDX
				 * (index) values.
				 */
				const y1 = yScale(position.entry.price);
				const x1 = xScale(xAccessor(position)); //xScale(new Date(openTime));
				const y2 = yScale(exitPrice);
				const x2 = xScale(xAccessor(exitBar)); //xScale(new Date(closeTime));
				const halfX = (x1 + x2) / 2;
				const halfY = (y1 + y2) / 2;

				/**
				 * No invalid data.
				 */
				if (isNaN(y2) || isNaN(x2)) return null;

				return (
					<g className={className} key={index}>
						{drawLine ? (
							<g className="react-stockcharts-drawpositions-line">
								<line
									x1={x1}
									y1={y1}
									x2={x2}
									y2={y2}
									stroke={lineStroke}
									strokeWidth={lineStrokeWidth}
									strokeDasharray={[5, 5]}
									onMouseEnter={() => setShowHover(`line_${positionID}`)}
									onMouseLeave={() => setShowHover('')}
								/>

								{showHover === `line_${positionID}` ? (
									<BasicToolTip
										content={`${directionText} $${rawFloatFormat(position.entry.profit.amount, 2)} (${
											position.entry.profit.pips
										} pips) @ $${exitPrice}`}
										x={halfX}
										y={halfY}
										width={205}
										opacity={1}
									/>
								) : (
									''
								)}
							</g>
						) : (
							''
						)}
						<g className="react-stockcharts-drawpositions-circle1-holder">
							{showHover === `circle1_${positionID}` ? (
								<BasicToolTip
									content={`${directionText} ${rawFloatFormat(position.entry.quantity)} @ $${position.entry.price}`}
									x={x1 + 10}
									y={y1 + 10}
									width={205}
									opacity={1}
								/>
							) : (
								''
							)}
							<circle
								className="react-stockcharts-drawpositions-circle1"
								cx={x1}
								cy={y1}
								stroke={circleStroke1}
								strokeWidth={circleStrokeWidth1}
								fillOpacity={circleFillOpacity1}
								r={cirlceRadius1}
								fill={circleFill1}
								onMouseEnter={() => setShowHover(`circle1_${positionID}`)}
								onMouseLeave={() => setShowHover('')}
							>
								{/* <title>{`${directionText} ${rawFloatFormat(position.entry.quantity)} @ $${position.entry.price}`}</title> */}
							</circle>
						</g>
						{drawLine ? (
							<g className="react-stockcharts-drawpositions-circle2-holder">
								{showHover === `circle2_${positionID}` ? (
									<BasicToolTip
										content={`${directionText} $${rawFloatFormat(position.entry.profit.amount, 2)} (${
											position.entry.profit.pips
										} pips) @ $${exitPrice}`}
										x={x2 + 10}
										y={y2 + 10}
										width={205}
										opacity={1}
									/>
								) : (
									''
								)}
								<circle
									className="react-stockcharts-drawpositions-circle2"
									cx={x2}
									cy={y2}
									stroke={circleStroke2}
									strokeWidth={circleStrokeWidth2}
									fillOpacity={circleFillOpacity2}
									r={cirlceRadius2}
									fill={circleFill2}
									onMouseEnter={() => setShowHover(`circle2_${positionID}`)}
									onMouseLeave={() => setShowHover('')}
								>
									{/* <title>
										{`${directionText} $${rawFloatFormat(position.entry.profit.profit)} (${
											position.entry.profit.pips
										} pips) @ $${position.exit.price}`}
									</title> */}
								</circle>
							</g>
						) : (
							''
						)}
					</g>
				);
			}

			return null;
		});
	};
	/* eslint-enable no-unused-vars */

	return (
		<GenericChartComponent
			canvasToDraw={getAxisCanvas}
			canvasDraw={drawOnCanvas}
			drawOn={['pan']}
			key={Math.random().toString(36).substr(2, 7)}
		/>
	);
};
