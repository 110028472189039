import axios from 'axios';
import { store } from '../index';
import { setHeaderStatus } from './header';
import {
	FETCH_PROFIT_DATA_TODAY,
	FETCH_PROFIT_DATA_YESTERDAY,
	FETCH_PROFIT_DATA_D2,
	FETCH_PROFIT_DATA_D3,
	FETCH_PROFIT_DATA_D4,
	FETCH_PROFIT_DATA_D5,
	FETCH_PROFIT_DATA_D6,
	FETCH_PROFIT_DATA_D7,
	FETCH_PROFIT_DATA_D8,
	FETCH_PROFIT_DATA_D9,
	FETCH_PROFIT_DATA_D10,
	FETCH_PROFIT_DATA_WEEK,
	FETCH_PROFIT_DATA_LAST_WEEK,
	FETCH_PROFIT_DATA_MONTH,
	FETCH_PROFIT_DATA_LAST_MONTH,
	FETCH_PROFIT_DATA_YEAR,
	FETCH_PROFIT_DATA_LAST_YEAR,
	FETCH_BALANCE_WEEK,
	FETCH_BALANCE_MONTH,
	FETCH_BALANCE_QUARTER,
	FETCH_BALANCE_YEAR,
	FETCH_ACCOUNT_DETAILS,
	FETCH_ACCOUNT_LIST,
	FETCH_ACCOUNT_TYPE,
	SET_ACCOUNT_ID,
	FETCH_INSTRUMENT_LIST,
	SET_CHART_INSTRUMENT,
	SET_CHART_GRANULARITY,
	FETCH_CHART_CANDLES,
	FETCH_BALANCE_LASTMONTH,
	FETCH_BALANCE_LASTWEEK,
	FETCH_PROFIT_DATA_52_WEEK,
	FETCH_POSITIONS,
	FETCH_ORDERS,
	FETCH_DASHBOARD_DATA,
	FETCH_PROFIT_STATS,
	FETCH_TRADES,
	SET_INDICATOR_LIST,
	SET_INDIVIDUAL_POSITIONS,
	SET_CHART_TYPE,
	SET_CALENDAR,
	FETCH_STUCK_TRADES,
	MORE_CHART_CANDLES,
	FETCH_POSITION_BOOK,
	FETCH_ORDER_BOOK,
	FETCH_COT_DATA,
	SET_GENERIC_API_DATA,
	SET_NONMERGE_GENERIC_API_DATA,
	SET_CANDLE_TYPE,
	CANDLE_LOOP_DONE,
	SET_DASHBOARD_SIZE,
	FETCH_CONVERSION_RATES,
} from '../actions/types';
import { isArray } from '../libs/react-stockcharts/src/lib/utils';
import { getMatchingIndicator } from '../components/StockChart/config/indicatorOptions';

/**
 *
 * @param {bool} fetchAll - if true, fetches everything. If false, only fetches needed data
 * @returns
 */
export const fetchAllData = (fetchAll = true) => async (dispatch) => {
	dispatch(setHeaderStatus({ text: 'Fetching Oanda account data...', type: 'loading' }));

	// Dispatch is the callback function being passed in!
	await Promise.all([dispatch(fetchAccountDetails()), dispatch(fetchAccountList())]);

	// dispatch(fetchMonthStartBalance());
	dispatch(setHeaderStatus({ text: 'Fetching chart data...', type: 'loading' }));
	// await Promise.all([

	if (fetchAll) dispatch(fetchDashboardChartData());
	if (fetchAll) dispatch(fetchAccountInstruments());
	if (fetchAll) dispatch(fetchCurrencyConversion());

	/**
	 * We only need to fetch candles if the instrument has
	 * changed, which it doesn't when just changing accounts.
	 */
	if (fetchAll) dispatch(fetchChartCandles());
	// ]);

	if (fetchAll) dispatch(setHeaderStatus({ text: 'Fetching dashboard data..', type: 'loading' }));
	if (fetchAll) await Promise.all([dispatch(fetchAccountType())]);
	if (fetchAll) await Promise.all([dispatch(fetchProfitStats())]);

	dispatch(setHeaderStatus({ text: 'All data fetched...', type: 'info' }));
	//dispatch({ type: FETCH_PROFIT_DATA, payload: responseData });
};

export const setAccountID = (account) => async (dispatch) => {
	/**
	 * Do nothing if the chart instrument is already set to what is being
	 * sent.
	 */
	if (store.getState().oanda.accountID === account.id) return;

	await dispatch({ type: SET_ACCOUNT_ID, payload: account.id });

	const res = await axios.get(`/api/setAccountID/${account.id}`, {
		params: {
			useDemo: account.demo,
		},
	});

	dispatch({ type: FETCH_ACCOUNT_TYPE, payload: account.demo ? 'Demo' : 'Live' });
	dispatch({ type: FETCH_ACCOUNT_DETAILS, payload: res.data.account });
	dispatch(fetchAllData(false));
};

export const fetchProfitStats = () => async (dispatch) => {
	const res = await axios.get('/api/stats/profit'); // Could use defaultparams() function here to send
	dispatch({ type: FETCH_PROFIT_STATS, payload: res.data });
	dispatch(dashboardDataToBalanceFromStats(res));
};

export const fetchDashboardData = () => async (dispatch) => {
	const res = await axios.get('/api/getDashboardData'); // Could use defaultparams() function here to send
	dispatch({ type: FETCH_DASHBOARD_DATA, payload: res.data });
	dispatch(dashboardDataToBalance(res));
};

export const dashboardDataToBalance = (res) => async (dispatch) => {
	dispatch({ type: FETCH_BALANCE_WEEK, payload: { accountID: res.data.accountID, balance: res.data.profit.week.firstBalance } });
	dispatch({ type: FETCH_BALANCE_LASTWEEK, payload: { accountID: res.data.accountID, balance: res.data.profit.w1.firstBalance } });
	dispatch({ type: FETCH_BALANCE_MONTH, payload: { accountID: res.data.accountID, balance: res.data.profit.month.firstBalance } });
	dispatch({ type: FETCH_BALANCE_LASTMONTH, payload: { accountID: res.data.accountID, balance: res.data.profit.m1.firstBalance } });
	dispatch({ type: FETCH_BALANCE_YEAR, payload: { accountID: res.data.accountID, balance: res.data.profit.year.firstBalance } });
};

export const dashboardDataToBalanceFromStats = (res) => async (dispatch) => {
	for (const key in res.data) {
		let item = res.data[key];
		let accountID = key;

		if (item?.week?.firstBalance) dispatch({ type: FETCH_BALANCE_WEEK, payload: { accountID: accountID, balance: item.week.firstBalance } });
		if (item?.w1?.firstBalance) dispatch({ type: FETCH_BALANCE_LASTWEEK, payload: { accountID: accountID, balance: item.w1.firstBalance } });
		if (item?.month?.firstBalance) dispatch({ type: FETCH_BALANCE_MONTH, payload: { accountID: accountID, balance: item.month.firstBalance } });
		if (item?.m1?.firstBalance) dispatch({ type: FETCH_BALANCE_LASTMONTH, payload: { accountID: accountID, balance: item.m1.firstBalance } });
		if (item?.year?.firstBalance) dispatch({ type: FETCH_BALANCE_YEAR, payload: { accountID: accountID, balance: item.year.firstBalance } });
	}
};

export const fetchProfitDataToday = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=today'); // Could use defaultparams() function here to send
	dispatch({ type: FETCH_PROFIT_DATA_TODAY, payload: res.data });
};

export const fetchProfitDataYesterday = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=yesterday');
	dispatch({ type: FETCH_PROFIT_DATA_YESTERDAY, payload: res.data });
};

export const fetchProfitDataD2 = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=DaysAgo&xDaysAgo=2');
	dispatch({ type: FETCH_PROFIT_DATA_D2, payload: res.data });
};

export const fetchProfitDataD3 = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=DaysAgo&xDaysAgo=3');
	dispatch({ type: FETCH_PROFIT_DATA_D3, payload: res.data });
};

export const fetchProfitDataD4 = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=DaysAgo&xDaysAgo=4');
	dispatch({ type: FETCH_PROFIT_DATA_D4, payload: res.data });
};

export const fetchProfitDataD5 = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=DaysAgo&xDaysAgo=5');
	dispatch({ type: FETCH_PROFIT_DATA_D5, payload: res.data });
};

export const fetchProfitDataD6 = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=DaysAgo&xDaysAgo=6');
	dispatch({ type: FETCH_PROFIT_DATA_D6, payload: res.data });
};

export const fetchProfitDataD7 = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=DaysAgo&xDaysAgo=7');
	dispatch({ type: FETCH_PROFIT_DATA_D7, payload: res.data });
};

export const fetchProfitDataD8 = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=DaysAgo&xDaysAgo=8');
	dispatch({ type: FETCH_PROFIT_DATA_D8, payload: res.data });
};

export const fetchProfitDataD9 = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=DaysAgo&xDaysAgo=9');
	dispatch({ type: FETCH_PROFIT_DATA_D9, payload: res.data });
};

export const fetchProfitDataD10 = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=DaysAgo&xDaysAgo=10');
	dispatch({ type: FETCH_PROFIT_DATA_D10, payload: res.data });
};

export const fetchProfitDataWeek = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=thisWeek');
	dispatch({ type: FETCH_PROFIT_DATA_WEEK, payload: res.data });
};

export const fetchProfitDataLastWeek = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=lastWeek');
	dispatch({ type: FETCH_PROFIT_DATA_LAST_WEEK, payload: res.data });
};

export const fetchProfitDataMonth = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=thisMonth');
	dispatch({ type: FETCH_PROFIT_DATA_MONTH, payload: res.data });
};

export const fetchProfitDataLastMonth = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=lastMonth');
	dispatch({ type: FETCH_PROFIT_DATA_LAST_MONTH, payload: res.data });
};

export const fetchProfitDataYear = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=thisYear');
	dispatch({ type: FETCH_PROFIT_DATA_YEAR, payload: res.data });
};

export const fetchProfitDataLastYear = () => async (dispatch) => {
	const res = await axios.get('/api/getProfitData?dateType=lastYear');
	dispatch({ type: FETCH_PROFIT_DATA_LAST_YEAR, payload: res.data });
};

export const fetch52WeekProfitData = () => async (dispatch) => {
	const res = await axios.get('/api/get52WeekProfitData');
	dispatch({ type: FETCH_PROFIT_DATA_52_WEEK, payload: res.data });
};

export const fetchWeekStartBalance = () => async (dispatch) => {
	const res = await axios.get('/api/getBalanceAtTime/week');
	dispatch({ type: FETCH_BALANCE_WEEK, payload: res.data });
};

export const fetchLastWeekStartBalance = () => async (dispatch) => {
	const res = await axios.get('/api/getBalanceAtTime/lastweek');
	dispatch({ type: FETCH_BALANCE_LASTWEEK, payload: res.data });
};

export const fetchMonthStartBalance = () => async (dispatch) => {
	const res = await axios.get('/api/getBalanceAtTime/month');
	dispatch({ type: FETCH_BALANCE_MONTH, payload: res.data });
};

export const fetchLastMonthStartBalance = () => async (dispatch) => {
	const res = await axios.get('/api/getBalanceAtTime/lastmonth');
	dispatch({ type: FETCH_BALANCE_LASTMONTH, payload: res.data });
};

export const fetchQuarterStartBalance = () => async (dispatch) => {
	const res = await axios.get('/api/getBalanceAtTime/quarter');
	dispatch({ type: FETCH_BALANCE_QUARTER, payload: res.data });
};

export const fetchYearStartBalance = () => async (dispatch) => {
	const res = await axios.get('/api/getBalanceAtTime/year');
	dispatch({ type: FETCH_BALANCE_YEAR, payload: res.data });
};

export const fetchAccountDetails = () => async (dispatch) => {
	const res = await axios.get('/api/getAccountDetails');
	await dispatch({ type: SET_ACCOUNT_ID, payload: res.data.account.id });
	dispatch({ type: FETCH_ACCOUNT_DETAILS, payload: res.data.account });
	dispatch({ type: FETCH_POSITIONS, payload: res.data.account });
	dispatch({ type: FETCH_ORDERS, payload: res.data.account });
	dispatch({ type: FETCH_TRADES, payload: res.data.account });
};

export const fetchAccountList = () => async (dispatch) => {
	const res = await axios.get('/api/getAccounts');
	dispatch({ type: FETCH_ACCOUNT_LIST, payload: res.data.accounts });
};

export const fetchPositions = () => async (dispatch) => {
	// const res = await axios.get('/api/getPositions');
	// dispatch({ type: FETCH_POSITIONS, payload: res.data });
};

export const fetchStuckTrades = () => async (dispatch) => {
	const res = await axios.get('/api/getStuckTrades', {
		params: {
			filters: {
				status: { filterVal: 'active' },
			},
		},
	});
	dispatch({ type: FETCH_STUCK_TRADES, payload: res.data });
};

/**
 * Submit a new stuck trade
 *
 * @param {obj} trade
 */
export const newStuckTrade = (trade) => async (dispatch) => {
	if (!trade || trade == null) return;

	/**
	 * Send it
	 */
	const res = await axios.post('/api/new/stuckTrade', trade);

	/**
	 * We add the result since the server adds
	 * additional data to the stuck trade.
	 */
	dispatch({ type: FETCH_STUCK_TRADES, payload: res.data });
};

export const unstickStuckTrade = (id) => async (dispatch) => {
	if (!id || id == null) return;

	/**
	 * Send it
	 */
	const res = await axios.get(`/api/stuckTrade/deleted/${id}`);

	/**
	 * We add the result since the server adds
	 * additional data to the stuck trade.
	 */
	dispatch({ type: FETCH_STUCK_TRADES, payload: res.data });
};

/**
 * Fetch the Oanda Order Book
 */
export const fetchOrderBook = (instrument, time = '') => async (dispatch) => {
	/**
	 * Get the candleLoop
	 */
	const candleLoopDone = store?.getState()?.oanda?.chartSettings?.candleLoopDone || true;

	if (!instrument || candleLoopDone === false) return;

	const res = await axios.get(`/api/getOrderBook?instrument=${instrument}&time=${time}`);
	dispatch({ type: FETCH_ORDER_BOOK, payload: res.data.orderBook });
};

/**
 * Fetch the Oanda Position Book
 */
export const fetchPositionBook = (instrument, time = '') => async (dispatch) => {
	/**
	 * Get the candleLoop
	 */
	const candleLoopDone = store?.getState()?.oanda?.chartSettings?.candleLoopDone || true;

	if (!instrument || candleLoopDone === false) return;

	const res = await axios.get(`/api/getPositionBook?instrument=${instrument}&time=${time}`);
	dispatch({ type: FETCH_POSITION_BOOK, payload: res.data.positionBook });
};

/**
 * Fetch the COT data
 *
 * @param {obj} options - should have instrument, fromDate and toDate
 */
export const fetchCOTData = (options) => async (dispatch) => {
	/**
	 * Get the candleLoop
	 */
	const candleLoopDone = store?.getState()?.oanda?.chartSettings?.candleLoopDone || true;

	if (!options.instrument || candleLoopDone === false) return;

	const res = await axios.get(`/api/getCOT/${options.instrument}?fromDate=${options.fromDate}&toDate=${options.toDate}`);

	/**
	 * cotData.reverse() -> keyword for searching..
	 *
	 * Why reverse it? Just so that it matches up with the candlestick data.
	 * 0 is the oldest, the last index is the newest.
	 */
	dispatch({ type: FETCH_COT_DATA, payload: res.data ? res.data.reverse() : [] });
};

/**
 * Reset the COT data to blank so we don't have an
 * odd filler.
 */
export const resetCOTData = () => async (dispatch) => {
	/**
	 * Get the candleLoop
	 */
	const candleLoopDone = store?.getState()?.oanda?.chartSettings?.candleLoopDone || true;
	if (candleLoopDone === false) dispatch({ type: FETCH_COT_DATA, payload: [] });
};

/**
 * Cycle through the cycle types
 */
export const candleTypeCycle = () => async (dispatch) => {
	const candleType = store.getState().oanda.chartSettings.candleType;
	let newType;

	/**
	 * Cycle to the next candle
	 */
	switch (candleType) {
		case 'mid':
			newType = 'bid';
			break;
		case 'bid':
			newType = 'ask';
			break;
		default:
			newType = 'mid';
	}

	/**
	 * Dispatch it
	 */
	dispatch({ type: SET_CANDLE_TYPE, payload: newType });
};

export const fetchAccountType = () => async (dispatch) => {
	// const res = await axios.get('/api/getAccountType');
	// Use an arrow function in the find to find the account with id matching the event.
	let accountType = store.getState().oanda.accountList.find((acct) => acct.id === store.getState().oanda.accountID).demo ? 'Demo' : 'Live';
	dispatch({ type: FETCH_ACCOUNT_TYPE, payload: accountType });
};

/**
 * Get a list of all of the account related instruments
 * @returns
 */
export const fetchAccountInstruments = () => async (dispatch) => {
	const res = await axios.get('/api/getAccountInstrument');
	dispatch({ type: FETCH_INSTRUMENT_LIST, payload: res.data.instruments });
};

/**
 * Get the conversion rates of all pairs
 * @returns null
 */
export const fetchCurrencyConversion = () => async (dispatch) => {
	const res = await axios.get('/api/getUSDCurrencyConversion');
	dispatch({ type: FETCH_CONVERSION_RATES, payload: res.data });
};

export const setDashboardChartInstrument = (chartInstrument) => async (dispatch) => {
	/**
	 * Do nothing if the chart instrument is already set to what is being
	 * sent.
	 */
	if (store.getState().oanda.chartInstrument === chartInstrument) return;
	if (chartInstrument === 'undefined' || chartInstrument == null) return;

	dispatch({ type: SET_CHART_INSTRUMENT, payload: chartInstrument });
	await axios.get(`/api/setChartInstrument/${chartInstrument}`);
	dispatch(fetchChartCandles());
};

export const setDashboardChartGranularity = (chartGranularity) => async (dispatch) => {
	/**
	 * Do nothing if the chart instrument is already set to what is being
	 * sent.
	 */
	if (store.getState().oanda.chartGranularity === chartGranularity) return;

	await axios.get(`/api/setChartGranularity/${chartGranularity}`);
	await dispatch({ type: SET_CHART_GRANULARITY, payload: chartGranularity });
	dispatch(fetchChartCandles());
};

export const setIndicatorList = (values) => async (dispatch) => {
	/**
	 * Loop through all of the indicators and remove their own data
	 * if they have it attached.
	 *
	 * 2022-02-02 10:26:59 JD
	 */
	for (const value of values) {
		if (value.arguments.needsOwnData) value.arguments.data = null;
	}

	await axios.get(`/api/setChartIndicators`, { params: { indicators: values } });
	dispatch({ type: SET_INDICATOR_LIST, payload: values });

	/**
	 * Update data based on any new indicators.
	 */
	dispatch(fetchDataBasedOnIndis());
};

/**
 * The user has updated a value in the indicator list.
 *
 * @param {json} json
 * @returns
 */
export const updateIndicatorList = (json) => async (dispatch) => {
	if (json == null) return;

	// const chartIndicators = store.getState().oanda.chartSettings.indicators;

	// /**
	//  * Replace the indicator in the list with the new one.
	//  */
	// const foundIndex = chartIndicators.findIndex((x) => x.id === json.id);
	// chartIndicators[foundIndex] = json;

	/**
	 * Send it to the server and to redux
	 */
	dispatch(setIndicatorList(json));

	/**
	 * Update data based on any new indicators.
	 */
	dispatch(fetchDataBasedOnIndis());
};

/**
 * The user has updated a value in the indicator list.
 *
 * @param {json} json
 * @returns
 */
export const updateSingleIndicator = (json) => async (dispatch) => {
	if (json == null) return;

	const chartIndicators = store.getState().oanda.chartSettings.indicators;

	/**
	 * Replace the indicator in the list with the new one.
	 */
	const foundIndex = chartIndicators.findIndex((x) => x.id === json.id);

	/**
	 * The index can be 0 here which will return false if
	 * tested against.
	 */
	chartIndicators[foundIndex] = json;

	/**
	 * Send it to the server and to redux
	 */
	dispatch(setIndicatorList(chartIndicators));
};

/**
 * This makes the chart either single (average) position or multiple
 * positions.
 *
 * @param {bool} value
 * @returns
 */
export const setIndividualPositions = (value) => async (dispatch) => {
	axios.get(`/api/setIndividualPositions/${value}`);
	await dispatch({ type: SET_INDIVIDUAL_POSITIONS, payload: value });
};

/**
 * Change the chart type
 * @param {str} value
 * @returns
 */
export const setChartType = (value) => async (dispatch) => {
	axios.get(`/api/setChartType/${value}`);
	await dispatch({ type: SET_CHART_TYPE, payload: value });
};

export const changeDashboardExpand = () => async (dispatch) => {
	const dashboardSize = store.getState().oanda.chartSettings.dashboardSize;

	/**
	 * Cycle through the available sizes
	 */
	if (dashboardSize === 12) dispatch({ type: SET_DASHBOARD_SIZE, payload: 4 });
	else dispatch({ type: SET_DASHBOARD_SIZE, payload: dashboardSize + 4 });

	/**
	 * Trigger a resize event.
	 */
	window.dispatchEvent(new Event('resize'));
};

/**
 * Get all of the dashboard data needed to render our graphs
 * @returns
 */
export const fetchDashboardChartData = () => async (dispatch) => {
	// You were about to setup the getting of chart candles!! Oanda already provides this...
	let res = await axios.get(`/api/getChartSessionData`);

	/**
	 * In case we have bad data
	 */
	if (!isArray(res.data.indicators)) res.data.indicators = [];
	for (let i = 0; i < res.data.indicators.length; i++) res.data.indicators[i] = JSON.parse(res.data.indicators[i]);

	dispatch({ type: SET_CHART_GRANULARITY, payload: res.data.granularity });
	dispatch({ type: SET_CHART_INSTRUMENT, payload: res.data.instrument });
	dispatch({ type: SET_INDICATOR_LIST, payload: res.data.indicators });
	dispatch({ type: SET_INDIVIDUAL_POSITIONS, payload: res.data.individualPositions });
	dispatch({ type: SET_CHART_TYPE, payload: res.data.chartType });
};

export const resetDataBasedOnIndis = () => async (dispatch) => {
	const chartIndicators = store.getState().oanda.chartSettings.indicators;

	/**
	 * Loop through all of the indicators the user has set.
	 */
	for (const indicator of chartIndicators) {
		/**
		 * We need to find the indicator in the settings file
		 * because what the user has stored in session and
		 * what is in the file could be difference. Specifically,
		 * the fetchData functions which isn't stored correctly.
		 */
		const ioIndicator = getMatchingIndicator(indicator);
		if (!ioIndicator) continue;

		/**
		 * Call the reset method of the indicator if it exists
		 */
		if (ioIndicator.arguments && ioIndicator.arguments.resetData) dispatch(ioIndicator.arguments.resetData(ioIndicator));
	}
};

/**
 * Loop through our indicators and determine what data we
 * need to fetch.
 *
 * @param {ary} indicators
 */
export const fetchDataBasedOnIndis = () => async (dispatch) => {
	const chartSettings = store.getState().oanda.chartSettings;
	const chartInstrument = store.getState().oanda.chartInstrument;
	const chartGranularity = store.getState().oanda.chartGranularity;

	/**
	 * This is a cache variable so we don't fetch the same
	 * stuff over and over again.
	 */
	let indiCache = {};

	/**
	 * Loop through all of the indicators the user has set.
	 */
	for (const indicator of chartSettings.indicators) {
		/**
		 * We need to find the indicator in the settings file
		 * because what the user has stored in session and
		 * what is in the file could be difference. Specifically,
		 * the fetchData functions which isn't stored correctly.
		 */
		const ioIndicator = getMatchingIndicator(indicator);
		if (!ioIndicator) continue;

		/**
		 * Call the reset method of the indicator if it exists
		 */
		if (ioIndicator.arguments && ioIndicator.arguments.resetData) dispatch(ioIndicator.arguments.resetData(ioIndicator));

		/**
		 * If we have an indicator with it's own fetchData, use it
		 * and dispatch it.
		 */
		if (ioIndicator.arguments && ioIndicator.arguments.fetchData) {
			if (indiCache[ioIndicator.arguments.fetchName] == null || indiCache[ioIndicator.arguments.fetchName] === false) {
				/**
				 * Create our options obj
				 */
				let options = {
					instrument: chartInstrument,
					granularity: chartGranularity,
				};

				/**
				 * If the indicator has it's own options,
				 * use those instead.
				 *
				 * 2022-01-20 13:07:29 JD
				 */
				if (ioIndicator.arguments?.useOwnOptions && ioIndicator.arguments?.options) {
					options = ioIndicator.arguments.options;

					/**
					 * Change any instrument that has "current" to the
					 * current instrument.
					 *
					 * 2022-01-20 13:11:33 JD
					 */
					if (options.instrument === 'current') options.instrument = chartInstrument;
				}

				/**
				 * Set our cache to true
				 */
				indiCache[ioIndicator.arguments.fetchName] = true;

				/**
				 * Get the chart time
				 */
				if (ioIndicator.arguments?.needsTime) {
					/**
					 * We need the start and end date for this request
					 */
					const chartCandles = store.getState().oanda?.chartCandles?.[chartInstrument]?.[chartGranularity];

					/**
					 * If we have no chartCandles, continue on.
					 */
					if (chartCandles == null || chartCandles.length <= 0) continue;

					/**
					 * Extract the dates
					 */
					options.fromDate = chartCandles[0].time;
					options.toDate = chartCandles[chartCandles.length - 1].time;
				}

				dispatch(ioIndicator.arguments.fetchData(options, ioIndicator));
			} // Indicator cache
		} // fetchData if
	}
};

export const fetchChartCandles = (params = { params: { count: 2000, cursor: true } }, loopCounter = 1) => async (dispatch) => {
	/**
	 * We use the getCandles() route that has the instrument set on the session.
	 * Optional passing of PARAMS here. If no params are passed, this uses the default Granularity
	 * which is set in the session too.
	 *
	  {
		params: {
			granularity: 'H4', // See https://developer.oanda.com/rest-live-v20/instrument-ep/ for params
	  },
	 */
	const res = await axios.get(`/api/getCandles`, params);

	/**
	 * Dispatch or add more
	 *
	 * 1 ?? It always returns 1 when it reaches the end.. I do not know why.
	 */
	if (res.data.candles.length > 1) {
		// console.log(loopCounter);
		// console.log(res.data);

		if (loopCounter === 1) dispatch({ type: FETCH_CHART_CANDLES, payload: res.data });
		else dispatch({ type: MORE_CHART_CANDLES, payload: res.data });
	} else return;

	/**
	 * Depending on our gran, we fetch more or
	 * less candles.
	 */
	const chartGranularity = store.getState().oanda.chartGranularity;
	let loopCountMax = 1,
		nextCount = params.count;

	switch (chartGranularity) {
		case 'W':
		case 'D':
			loopCountMax = 1;
			break;
		case 'H12':
		case 'H8':
		case 'H6':
			loopCountMax = 1;
			break;
		case 'H4':
		case 'H3':
		case 'H2':
		case 'H1':
			loopCountMax = 1;
			// nextCount = 3000;
			break;
		case 'M30':
		case 'M15':
		case 'M10':
		case 'M5':
		case 'M4':
		case 'M2':
		case 'M1':
			loopCountMax = 1;
			break;
		case 'S30':
		case 'S15':
		case 'S10':
		case 'S5':
			loopCountMax = 1;
			break;
		default:
			loopCountMax = 1;
	}

	/**
	 * Fetch more candles
	 */
	if (loopCounter < loopCountMax) {
		dispatch(fetchChartCandles({ params: { beforeDate: res.data.candles[0].time, count: nextCount } }, loopCounter + 1));

		if (loopCounter >= loopCountMax) dispatch({ type: CANDLE_LOOP_DONE, payload: true });
		else dispatch({ type: CANDLE_LOOP_DONE, payload: false });
	} else dispatch({ type: CANDLE_LOOP_DONE, payload: true });

	/**
	 * Depending on what indicators we have, fetch different
	 * data.
	 */
	dispatch(fetchDataBasedOnIndis());
};

export const getDefaultParams = () => {
	let useDemo = store.getState().oanda.accountList.find((acct) => acct.id === store.getState().oanda.accountID).demo ? true : false;

	return {
		params: {
			useDemo: useDemo,
		},
	};
};

/**
 * This will fetch the oanda forex calendar
 *
 * We will use the default calendar range period here which
 * is -2592000, or one month.
 */
export const getCalendar = () => async (dispatch) => {
	const res = await axios.get(`/api/getCalendar`);
	dispatch({ type: SET_CALENDAR, payload: res.data });
};

/**
 * Reset generic data
 *
 * @param {str} genericName - the name to be used in the redux store
 * @param {bool} nonMerge - true dispatches an alternative type
 * @param {obj} indicatorThis - the this object of the indicator.
 *
 */
export const clearGenericApiData = (genericName, nonMerge = false, indicatorThis = null) => async (dispatch) => {
	if (!genericName) return;

	/**
	 * Now set the name as part of the result
	 */
	const data = {
		fetchName: genericName,
	};

	/**
	 * If indicatorThis is include and the indicator needs
	 * the data attached to itself, add that here.
	 *
	 * 2022-02-02 09:52:11 JD
	 */
	// if (indicatorThis != null && indicatorThis?.arguments?.needsOwnData) {
	// 	indicatorThis.arguments.data = data;
	// 	await dispatch(updateSingleIndicator(indicatorThis));
	// }

	/**
	 * Send it to the reducer
	 */
	if (nonMerge) await dispatch({ type: SET_NONMERGE_GENERIC_API_DATA, payload: data });
	else await dispatch({ type: SET_GENERIC_API_DATA, payload: data });
};

/**
 * This will fetch other data from the API and put it in the
 * other data section.
 *
 * @param {str} url - relative, should be /api/xxxx
 * @param {str} genericName - the name to be used in the redux store
 * @param {obj} query - any get query options
 * @param {bool} nonMerge - true dispatches an alternative type
 * @param {obj} indicatorThis - the this object of the indicator.
 *
 */
export const fetchGenericApiData = (url, genericName, query, nonMerge = false, indicatorThis = null) => async (dispatch) => {
	if (!url || !genericName) return;

	/**
	 * First fetch the URL
	 */
	const res = await axios.get(url, {
		params: {
			...query,
		},
	});

	/**
	 * Now set the name as part of the result
	 */
	res.data.fetchName = genericName;

	/**
	 * If indicatorThis is include and the indicator needs
	 * the data attached to itself, add that here.
	 *
	 * 2022-02-02 09:52:11 JD
	 */
	// if (indicatorThis != null && indicatorThis?.arguments?.needsOwnData) {
	// 	indicatorThis.arguments.data = res.data;
	// 	await dispatch(updateSingleIndicator(indicatorThis));
	// }

	/**
	 * Send it to the reducer
	 */
	if (nonMerge) await dispatch({ type: SET_NONMERGE_GENERIC_API_DATA, payload: res.data });
	else await dispatch({ type: SET_GENERIC_API_DATA, payload: res.data });
};

/**
 * Send a market order
 *
 *  type: 'string' DEFAULT: 'MARKET' LIMIT STOP MARKET_IF_TOUCHED TAKE_PROFIT STOP_LOSS TRAILING_STOP_LOSS
 *  instrument: 'string' (e.g. 'USD_CAD')
 *  units: integer (e.g. 100 || -100)
 *  timeInForce: 'string' DEFAULT:'GTC' GTC GTD DFD IOC
 *  priceBound: 'stringFloat'
 *  positionFill: 'string' DEFAULT: 'DEFAULT' OPEN_ONLY REDUCE_FIRST REDUCE_ONLY
 *  triggerCondition: 'string' DEFAULT 'DEFAULT' INVERSE BID ASK MID
 *  takeProfitonFill || stopLossOnFill: {
 *  	price: 'stringFloat'
 *  	timeInForce: 'string' DEFAULT: 'GTC' GTD GFD
 *  	gdtTime: 'DateTime' used if on GTD for timeInForce
 *  }
 * 	 trailingStopLossOnFill: {
 * 	 	distance: 'stringFloat' like price but amount of PIPs vs static price point
 *  	timeInForce: 'string' DEFAULT: 'GTC' GTD GFD
 *	  	gdtTime: 'DateTime' used if on GTD for timeInForce
 *  }
 *  distance: 'stringFloat' amount of PIPs for trailingStopLossOrders
 * }
 * @param {*} orderObj - the order object, see Oanda order request
 * @returns
 */
export const submitOandaOrder = (orderObj) => async (dispatch) => {
	if (!orderObj || orderObj == null || orderObj?.type == null) return;

	// /**
	//  * Create the order details
	//  */
	// const orderObj = {
	// 	type: 'MARKET',
	// 	instrument: instrument,
	// 	units: units,
	// 	timeInForce: 'FOK', // Filled or Killed
	// 	positionFill: 'DEFAULT',
	// 	triggerCondition: 'DEFAULT',
	// 	clientExtensions: {
	// 		tag: tag.type,
	// 		comment: tag.comment,
	// 	},
	// };

	/**
	 * Send it
	 */
	try {
		const res = await axios.post('/api/new/placeOrder', orderObj);

		/**
		 * Dispatch the response
		 */
		if (res?.data?.account?.id) {
			store.dispatch({ type: FETCH_ACCOUNT_DETAILS, payload: res.data.account });
			store.dispatch({ type: FETCH_POSITIONS, payload: res.data.account });
			store.dispatch({ type: FETCH_ORDERS, payload: res.data.account });
			store.dispatch({ type: FETCH_TRADES, payload: res.data.account });
		}
	} catch (error) {
		console.log(error);
	}

	return;
};

/**
 * Send a wobble order to our DB.
 *
 * @param {obj} orderObj - the order object, see Oanda order request
 * @returns
 */
export const submitWobbleOrder = (orderObj) => async (dispatch) => {
	if (!orderObj || orderObj == null || orderObj?.type == null) return;

	/**
	 * Send it
	 */
	try {
		const res = await axios.post('/api/new/placeWobbleOrder', orderObj);

		/**
		 * Dispatch the response
		 */
		if (res?.data?.account?.id) {
			store.dispatch({ type: FETCH_ACCOUNT_DETAILS, payload: res.data.account });
			store.dispatch({ type: FETCH_POSITIONS, payload: res.data.account });
			store.dispatch({ type: FETCH_ORDERS, payload: res.data.account });
			store.dispatch({ type: FETCH_TRADES, payload: res.data.account });
		}
	} catch (error) {
		console.log(error);
	}

	return;
};

/**
 * This will send a replacement order to the API/Oanda. It is the same
 * as the original order structure but contains a replacement ID.
 *
 * @param {int} replaceOrderID
 * @param {obj} orderObj
 * @returns
 */
export const replaceOandaOrder = (replaceOrderID, orderObj) => async (dispatch) => {
	if (!orderObj || orderObj == null || orderObj?.type == null || !replaceOrderID || replaceOrderID == null) return;

	/**
	 * Send it
	 */
	try {
		/**
		 * The response will be an updated account details from Oanda
		 */
		const res = await axios.post(`/api/replace/order/${replaceOrderID}`, orderObj);

		/**
		 * Dispatch the response
		 */
		if (res?.data?.account?.id) {
			store.dispatch({ type: FETCH_ACCOUNT_DETAILS, payload: res.data.account });
			store.dispatch({ type: FETCH_POSITIONS, payload: res.data.account });
			store.dispatch({ type: FETCH_ORDERS, payload: res.data.account });
			store.dispatch({ type: FETCH_TRADES, payload: res.data.account });
		}
	} catch (error) {
		console.log(error);
	}

	return;
};

/**
 * Cancel an Oanda Order
 *
 * @param {int} cancelOrderID
 * @returns
 */
export const cancelOandaOrder = (cancelOrderID) => async (dispatch) => {
	if (!cancelOrderID || cancelOrderID == null) return;

	/**
	 * Send it
	 */
	try {
		const res = await axios.post(`/api/cancel/order/${cancelOrderID}`);

		/**
		 * Send it to the reducer
		 */
		// await dispatch({ type: CANCEL_ORDER_BY_ID, payload: cancelOrderID });

		/**
		 * Dispatch the response
		 */
		if (res?.data?.account?.id) {
			store.dispatch({ type: FETCH_ACCOUNT_DETAILS, payload: res.data.account });
			store.dispatch({ type: FETCH_POSITIONS, payload: res.data.account });
			store.dispatch({ type: FETCH_ORDERS, payload: res.data.account });
			store.dispatch({ type: FETCH_TRADES, payload: res.data.account });
		}
	} catch (error) {
		console.log(error);
	}

	return;
};

/**
 * Cancel an Oanda Trade
 *
 * @param {int} closeTradeID
 * @returns
 */
export const closeOandaTrade = (closeTradeID) => async (dispatch) => {
	if (!closeTradeID || closeTradeID == null) return;

	/**
	 * Send it
	 */
	try {
		const res = await axios.post(`/api/close/trade/${closeTradeID}`);

		/**
		 * Dispatch the response
		 */
		if (res?.data?.account?.id) {
			store.dispatch({ type: FETCH_ACCOUNT_DETAILS, payload: res.data.account });
			store.dispatch({ type: FETCH_POSITIONS, payload: res.data.account });
			store.dispatch({ type: FETCH_ORDERS, payload: res.data.account });
			store.dispatch({ type: FETCH_TRADES, payload: res.data.account });
		}
	} catch (error) {
		console.log(error);
	}

	return;
};
