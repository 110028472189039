/**
 * this comes from https://github.com/rrag/react-stockcharts/issues/707
 */
import React from 'react';
import GenericChartComponent from './GenericChartComponent';
import { useSelector } from 'react-redux';
import { objectEqual } from '../../../../components/utils';
// import { chartColors } from '../../../../components/StockChart/config/genericConfig';

import { getAxisCanvas } from './GenericComponent';
import { chartColors } from '../../../../components/StockChart/config/genericConfig';
import { hexToRGBA } from './utils';

export const DrawVolumeBucketLines = ({
	stroke = 'rgba(255,255,255,0.05)',
	fill = hexToRGBA(chartColors.purple, 0.15), // 'rgba(66, 184, 131, 0.7)',
	strokeLargest = 'rgba(255,255,255,0.30)',
	fillLargest = hexToRGBA(chartColors.pink, 0.8), // 'rgba(66, 184, 131, 0.7)',
	strokeSecondaryLargest = 'rgba(255,255,255,0.2)',
	fillSecondaryLargest = hexToRGBA(chartColors.teal, 0.3), // 'rgba(66, 184, 131, 0.7)',
	rectHeight = 1,
	highLowFill = hexToRGBA(chartColors.greenNeon, 0.8),
	highLowStroke = 'rgba(255,255,255, 1)',
	highLowRectHeight = 3,
	recentHighLowFill = hexToRGBA(chartColors.orangeRed, 0.6),
	recentHighLowStroke = 'rgba(255,255,255, 1)',
	recentHighLowRectHeight = 2,
	rectCenterHeight = 1,
	strokeCenter = 'rgba(255,255,255, 0.30)',
	fillCenter = hexToRGBA(chartColors.lightGray, 0.8),
}) => {
	/**
	 * Get our data needed for the lines which is
	 * stored in the state.
	 *
	 * 2022-01-20 14:24:53 JD
	 */
	const volumeBucketRange = useSelector((state) => state.oanda.nonMergeApiData.volumeBucketRange, objectEqual);

	/**
	 * Actually drawe the lines.
	 *
	 * @param {*} ctx
	 * @param {*} moreProps
	 */
	const drawOnCanvas = (ctx, moreProps) => {
		const {
			plotData,
			// mouseXY,
			chartConfig: { yScale },
			width,
		} = moreProps;

		/**
		 * Make sure we have data
		 */
		if (!volumeBucketRange || !volumeBucketRange?.largestBuckets) return;

		/**
		 * The plotData is the bars being shown depending on
		 * zoom and pan.
		 */
		if (!plotData || plotData.length === 0 || !yScale) return null;

		/**
		 * Our width data
		 */
		// const begin = width / 2 - width / 4;

		/**
		 * Loop through the data and go!
		 */
		for (let i = 0; i < volumeBucketRange.largestBuckets.length; i++) {
			let bucket = volumeBucketRange.largestBuckets[i];

			/**
			 * Get our cooridnates. This is done using the xAccessor
			 * and passing in the entire bar data which contains the IDX
			 * (index) values.
			 */
			const y1 = yScale(bucket.bucketPrice);

			/**
			 * Create a falling recHeight scale
			 */
			let adjustedRectHeight = rectHeight;
			let adjustedFill = fill;
			let adjustedStroke = stroke;

			if (i === 0) {
				adjustedRectHeight = rectHeight + 3;
				adjustedFill = fillLargest;
				adjustedStroke = strokeLargest;
			} else if (i === 1) {
				adjustedRectHeight = rectHeight + 1;
				adjustedFill = fillSecondaryLargest;
				adjustedStroke = strokeSecondaryLargest;
			} else if (i === 2) {
				adjustedRectHeight = rectHeight + 1;
				adjustedFill = fillSecondaryLargest;
				adjustedStroke = strokeSecondaryLargest;
			}

			ctx.fillStyle = adjustedFill;
			ctx.strokeStyle = adjustedStroke;
			ctx.beginPath();
			ctx.rect(0, y1, width, adjustedRectHeight);
			ctx.strokeRect(0, y1, width, adjustedRectHeight);
			ctx.closePath();
			ctx.fill();
		}

		/**
		 * Draw the highesthigh and lowestlow lines
		 *
		 * 2022-01-20 14:24:45 JD
		 */
		if (volumeBucketRange?.highestHigh) {
			/**
			 * Get our cooridnates. This is done using the xAccessor
			 * and passing in the entire bar data which contains the IDX
			 * (index) values.
			 */
			const y1 = yScale(volumeBucketRange?.highestHigh);

			ctx.fillStyle = highLowFill;
			ctx.strokeStyle = highLowStroke;
			ctx.beginPath();
			ctx.rect(0, y1, width, highLowRectHeight);
			ctx.strokeRect(0, y1, width, highLowRectHeight);
			ctx.closePath();
			ctx.fill();
		}

		if (volumeBucketRange?.lowestLow) {
			/**
			 * Get our cooridnates. This is done using the xAccessor
			 * and passing in the entire bar data which contains the IDX
			 * (index) values.
			 */
			const y1 = yScale(volumeBucketRange?.lowestLow);

			ctx.fillStyle = highLowFill;
			ctx.strokeStyle = highLowStroke;
			ctx.beginPath();
			ctx.rect(0, y1, width, highLowRectHeight);
			ctx.strokeRect(0, y1, width, highLowRectHeight);
			ctx.closePath();
			ctx.fill();
		}

		/**
		 * Draw the recentHighestHigh and lowestlow lines
		 *
		 * 2022-01-20 14:24:45 JD
		 */
		if (volumeBucketRange?.recentHighestHigh) {
			/**
			 * Get our cooridnates. This is done using the xAccessor
			 * and passing in the entire bar data which contains the IDX
			 * (index) values.
			 */
			const y1 = yScale(volumeBucketRange?.recentHighestHigh);

			ctx.fillStyle = recentHighLowFill;
			ctx.strokeStyle = recentHighLowStroke;
			ctx.beginPath();
			ctx.rect(0, y1, width, recentHighLowRectHeight);
			ctx.strokeRect(0, y1, width, recentHighLowRectHeight);
			ctx.closePath();
			ctx.fill();
		}

		if (volumeBucketRange?.recentLowestLow) {
			/**
			 * Get our cooridnates. This is done using the xAccessor
			 * and passing in the entire bar data which contains the IDX
			 * (index) values.
			 */
			const y1 = yScale(volumeBucketRange?.recentLowestLow);

			ctx.fillStyle = recentHighLowFill;
			ctx.strokeStyle = recentHighLowStroke;
			ctx.beginPath();
			ctx.rect(0, y1, width, recentHighLowRectHeight);
			ctx.strokeRect(0, y1, width, recentHighLowRectHeight);
			ctx.closePath();
			ctx.fill();
		}

		/**
		 * Draw the center line of the selected range.
		 *
		 * 2022-02-09 09:22:44 JD
		 */
		if (volumeBucketRange?.centerLine) {
			/**
			 * Get our cooridnates. This is done using the xAccessor
			 * and passing in the entire bar data which contains the IDX
			 * (index) values.
			 */
			const y1 = yScale(volumeBucketRange?.centerLine);

			ctx.fillStyle = fillCenter;
			ctx.strokeStyle = strokeCenter;
			ctx.beginPath();
			ctx.rect(0, y1, width, rectCenterHeight);
			ctx.strokeRect(0, y1, width, rectCenterHeight);
			ctx.closePath();
			ctx.fill();
		}
	};

	const renderSVG = (moreProps) => {};

	return (
		<GenericChartComponent
			canvasToDraw={getAxisCanvas}
			canvasDraw={drawOnCanvas}
			svgDraw={renderSVG}
			drawOn={['pan']}
			key={Math.random().toString(36).substr(2, 7)}
		/>
	);
};
