import React from 'react';
import { useSelector, useDispatch } from 'react-redux'; // useDispatch()
import { setIndividualPositions, changeDashboardExpand } from '../../actions/oanda';
import { objectEqual } from '../utils';
import { chartColors } from './config/genericConfig';
import {
	changeModalInstrumentOpen,
	changeModalChartTypeOpen,
	changeModalGranularityOpen,
	changeModalIndicatorOpen,
	changeModalOrdersOpen,
} from '../../actions/navigation';
import { chartTypeSVGSelect } from './config/chartTypeOptions';
import { Link } from 'react-router-dom';

// To use redux hooks, you export a function component, not a react component.
export const CustomChartToolbar = (props) => {
	/**
	 * We use an arrow function whitch passes state to a
	 * callback function that returns the state we need.
	 */
	const chartGranularity = useSelector((state) => {
		return state.oanda.chartGranularity;
	}, objectEqual);
	const chartInstrument = useSelector((state) => {
		return state.oanda.chartInstrument;
	}, objectEqual);
	const instruments = useSelector((state) => {
		return state.oanda.instruments;
	}, objectEqual);
	// const indicators = useSelector((state) => {
	// 	return state.oanda.chartSettings.indicators;
	// }, objectEqual);
	const individualPositions = useSelector((state) => {
		return state.oanda.chartSettings.individualPositions;
	}, objectEqual);
	const chartType = useSelector((state) => {
		return state.oanda.chartSettings.chartType || 'Candlestick';
	}, objectEqual);
	const dispatch = useDispatch();

	/**
	 * When the user clicks on a different chart instrument.
	 *
	 * @param {*} event
	 */
	const handleInstrumentClick = () => dispatch(changeModalInstrumentOpen(true));

	/**
	 * Handle the click event when a user is expanding the chart
	 */
	const handleChartExpand = () => dispatch(changeDashboardExpand());

	/**
	 * When a user changes the time/gran
	 *
	 * @param {*} event
	 */
	const handleOrderPopup = () => dispatch(changeModalOrdersOpen(true));

	/**
	 * When a user changes the time/gran
	 *
	 * @param {*} event
	 */
	const handleGranularityClick = () => dispatch(changeModalGranularityOpen(true));

	/**
	 * When the dropdown select is changed, this will be
	 * fired.
	 *
	 * @param {obj} values
	 */
	const handleIndicatorClick = () => dispatch(changeModalIndicatorOpen(true));
	// const handleIndicatorRemoval = (indicator) => dispatch(setIndicatorList(indicators.filter((indi) => indi.id !== indicator.id)));

	/**
	 * When the dropdown select is changed, this will be
	 * fired.
	 *
	 * @param {obj} values
	 */
	// const chartTypeChange = (values) => dispatch(setChartType(values[0].value));
	const handleChartTypeClick = () => dispatch(changeModalChartTypeOpen(true));

	/**
	 * Change whether we are per posiiton or not
	 *
	 * @param {obj} value
	 */
	// const setPerPosition = (event) => dispatch(setIndividualPositions(true));
	// const removePerPosition = (event) => dispatch(setIndividualPositions(false));
	const handlePerPositionClick = () => {
		if (individualPositions) dispatch(setIndividualPositions(false));
		else dispatch(setIndividualPositions(true));
	};

	/**
	 * This will create the select dropdown for the granularity
	 * (the time periods)
	 */
	const renderGranularitySelect = () => {
		return (
			<div className="toolbarInputGroup " onClick={handleGranularityClick}>
				<div className="toolbarInputLabel">{chartGranularity}</div>
			</div>
		);
	};

	/**
	 * This will list all of the available instruments/symbols
	 */
	const renderInstrumentSelect = () => {
		if (!instruments || !Array.isArray(instruments)) return '';

		return (
			<div className="toolbarInputGroup " onClick={handleInstrumentClick}>
				<div className="toolbarInputLabel">{chartInstrument.replace('_', '/')}</div>
			</div>
		);
	};

	/**
	 * Cycle through all of the indicators selected and
	 * make a selection box.
	 *
	 * This was moved into the indicatorsearch.sj
	 */
	// const renderIndicators = () => {
	// 	if (!indicators || !Array.isArray(indicators) || indicators.length <= 0) return '';

	// 	return indicators.map((indicator) => {
	// 		return (
	// 			<span
	// 				key={`toolbarList_${indicator.id}`}
	// 				role="listitem"
	// 				direction="ltr"
	// 				className="react-dropdown-select-option css-w3djcp-OptionComponent e1l4eby50"
	// 				color="#0074D9"
	// 			>
	// 				<span className="react-dropdown-select-option-label">{indicator.label}</span>
	// 				<span className="react-dropdown-select-option-remove" onClick={() => handleIndicatorRemoval(indicator)}>
	// 					×
	// 				</span>
	// 			</span>
	// 		);
	// 	});
	// };

	/**
	 * This will render our react-dropdown-select which we will
	 * use for indicators
	 */
	const renderIndicatorSelect = () => {
		return (
			<div className="toolbarInputGroup">
				{/* <div className="toolbarInputLabel">{renderIndicators()}</div> */}
				<span onClick={handleIndicatorClick}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none">
						<path stroke="currentColor" d="M20 17l-5 5M15 17l5 5M9 11.5h7M17.5 8a2.5 2.5 0 0 0-5 0v11a2.5 2.5 0 0 1-5 0"></path>
					</svg>
				</span>
			</div>
		);
	};

	/**
	 * This will render our react-dropdown-select which we will
	 * use for the chart types
	 */
	const renderChartTypeSelect = () => {
		return (
			<div className="toolbarInputGroup" onClick={handleChartTypeClick}>
				<div className="toolbarInputLabel">{chartTypeSVGSelect(chartType)}</div>
			</div>
		);
	};

	/**
	 * This renders the save icon
	 */
	const renderSettings = () => {
		return (
			<div className="toolbarInputGroup" style={{ borderRight: 0 }}>
				<div className="toolbarInputLabel">
					<div className="toolbarInputGroup">
						<span onClick={handleOrderPopup} className="clickable">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" width="28" height="28">
								<g fill="none">
									<path
										stroke="currentcolor"
										d="M16.5 21.5l8.146 8.146a.914.914 0 0 1-1.292 1.293L16.5 24.086l-6.854 6.853a.914.914 0 0 1-1.292-1.293L16.5 21.5zm0 4l6.146 6.146a1.914 1.914 0 0 0 2.708-2.707L16.5 20.086l-8.854 8.853a1.914 1.914 0 1 0 2.708 2.707L16.5 25.5zm13 0l8.146-8.146a.914.914 0 0 0-1.292-1.293L29.5 22.914l-6.854-6.853a.914.914 0 0 0-1.292 1.293L29.5 25.5zm0-4l6.146-6.146a1.914 1.914 0 0 1 2.708 2.707L29.5 26.914l-8.854-8.853a1.914 1.914 0 1 1 2.708-2.707L29.5 21.5z"
									></path>
								</g>
							</svg>
						</span>
					</div>
					<div className="toolbarInputGroup ml-2">
						<span onClick={handleChartExpand} className="clickable mr-1">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16" width="19" height="16">
								<g fill="none">
									<path
										stroke="currentColor"
										d="M17.5 3.5v-1a2 2 0 0 0-2-2h-13a2 2 0 0 0-2 2v1m17 0v10a2 2 0 0 1-2 2h-13a2 2 0 0 1-2-2v-10m17 0H.5"
									></path>
								</g>
							</svg>
						</span>

						<Link to={window.location.pathname === '/chart' ? '/' : '/chart'} className="text-white">
							{window.location.pathname === '/chart' ? (
								<svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" width="28" height="28">
									<g fill="currentColor">
										<g fill="currentColor">
											<path d="M19 9h-2.58l3.29-3.29a1 1 0 1 0-1.42-1.42L15 7.57V5a1 1 0 0 0-1-1 1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 0-2z" />
											<path d="M10 13H5a1 1 0 0 0 0 2h2.57l-3.28 3.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L9 16.42V19a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1z" />
										</g>
									</g>
								</svg>
							) : (
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
									<g fill="currentColor">
										<path d="M21 7v4h1V6h-5v1z"></path>
										<path d="M16.854 11.854l5-5-.708-.708-5 5zM7 7v4H6V6h5v1z"></path>
										<path d="M11.146 11.854l-5-5 .708-.708 5 5zM21 21v-4h1v5h-5v-1z"></path>
										<path d="M16.854 16.146l5 5-.708.708-5-5z"></path>
										<g>
											<path d="M7 21v-4H6v5h5v-1z"></path>
											<path d="M11.146 16.146l-5 5 .708.708 5-5z"></path>
										</g>
									</g>
								</svg>
							)}
						</Link>
					</div>

					<span className={individualPositions ? 'clickable' : 'text-primary clickable'} onClick={handlePerPositionClick}>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
							<g fill="currentColor" fillRule="evenodd">
								<path fillRule="nonzero" d="M14 17a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-1a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path>
								<path d="M5.005 16A1.003 1.003 0 0 1 4 14.992v-1.984A.998.998 0 0 1 5 12h1.252a7.87 7.87 0 0 1 .853-2.06l-.919-.925c-.356-.397-.348-1 .03-1.379l1.42-1.42a1 1 0 0 1 1.416.007l.889.882A7.96 7.96 0 0 1 12 6.253V5c0-.514.46-1 1-1h2c.557 0 1 .44 1 1v1.253a7.96 7.96 0 0 1 2.06.852l.888-.882a1 1 0 0 1 1.416-.006l1.42 1.42a.999.999 0 0 1 .029 1.377s-.4.406-.918.926a7.87 7.87 0 0 1 .853 2.06H23c.557 0 1 .447 1 1.008v1.984A.998.998 0 0 1 23 16h-1.252a7.87 7.87 0 0 1-.853 2.06l.882.888a1 1 0 0 1 .006 1.416l-1.42 1.42a1 1 0 0 1-1.415-.007l-.889-.882a7.96 7.96 0 0 1-2.059.852v1.248c0 .56-.45 1.005-1.008 1.005h-1.984A1.004 1.004 0 0 1 12 22.995v-1.248a7.96 7.96 0 0 1-2.06-.852l-.888.882a1 1 0 0 1-1.416.006l-1.42-1.42a1 1 0 0 1 .007-1.415l.882-.888A7.87 7.87 0 0 1 6.252 16H5.005zm3.378-6.193l-.227.34A6.884 6.884 0 0 0 7.14 12.6l-.082.4H5.005C5.002 13 5 13.664 5 14.992c0 .005.686.008 2.058.008l.082.4c.18.883.52 1.71 1.016 2.453l.227.34-1.45 1.46c-.004.003.466.477 1.41 1.422l1.464-1.458.34.227a6.959 6.959 0 0 0 2.454 1.016l.399.083v2.052c0 .003.664.005 1.992.005.005 0 .008-.686.008-2.057l.399-.083a6.959 6.959 0 0 0 2.454-1.016l.34-.227 1.46 1.45c.003.004.477-.466 1.422-1.41l-1.458-1.464.227-.34A6.884 6.884 0 0 0 20.86 15.4l.082-.4h2.053c.003 0 .005-.664.005-1.992 0-.005-.686-.008-2.058-.008l-.082-.4a6.884 6.884 0 0 0-1.016-2.453l-.227-.34 1.376-1.384.081-.082-1.416-1.416-1.465 1.458-.34-.227a6.959 6.959 0 0 0-2.454-1.016L15 7.057V5c0-.003-.664-.003-1.992 0-.005 0-.008.686-.008 2.057l-.399.083a6.959 6.959 0 0 0-2.454 1.016l-.34.227-1.46-1.45c-.003-.004-.477.466-1.421 1.408l1.457 1.466z"></path>
							</g>
						</svg>
					</span>
				</div>
			</div>
		);
	};

	/**
	 * Get our props
	 */
	let backgroundColor = props.backgroundColor ? props.backgroundColor : chartColors.toolbarBackground;
	let borderBottomStyle = props.borderBottomStyle ? props.borderBottomStyle : `1px solid ${chartColors.toolbarBorderMain}`;

	return (
		<div
			className="trading-vue-toolbar-top"
			style={{ backgroundColor: backgroundColor, borderBottom: borderBottomStyle, width: '100%', height: '43px' }}
		>
			<div className="btn-toolbar justify-content-between">
				<div className="d-inline-flex">
					{renderChartTypeSelect()}
					{renderIndicatorSelect()}
					{renderInstrumentSelect()}
					{renderGranularitySelect()}
				</div>

				{renderSettings()}
			</div>
		</div>
	);
};
