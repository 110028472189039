import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Loader from '../../../../../components/Loader/Loader';
import { setDashboardChartInstrument } from '../../../../../actions/oanda';
import { chartColors, cancelOrderWithAlert } from '../../../../../components/StockChart/config/genericConfig';
import { floatToNumberFormat, objectEqual, customSortCaret, NoDataIndication, addDataToTPSL } from '../../../../../components/utils';

// To use redux hooks, you export a function component, not a react component.
export const ActiveOrdersTable = () => {
	/**
	 * We use an arrow function whitch passes state to a
	 * callback function that returns the state we need.
	 */
	const chartInstrument = useSelector((state) => state.oanda.chartInstrument);
	const orders = useSelector((state) => {
		if (state.oanda.accountID != null && state.oanda.orders[state.oanda.accountID] != null) {
			return state.oanda.orders[state.oanda.accountID];
		}
	}, objectEqual);
	const trades = useSelector((state) => {
		if (state?.oanda?.accountID != null && chartInstrument != null && state?.oanda?.trades[state?.oanda?.accountID] != null) {
			return state.oanda.trades[state.oanda.accountID];
		}
	}, objectEqual);
	const dispatch = useDispatch();

	/**
	 * Create an array of stats for our action buttons
	 */
	const [actionLoading, setActionLoading] = useState([]);

	/**
	 * Run this everytime we have an update
	 * on trades
	 */
	useEffect(() => {
		setActionLoading([]);
	}, [orders]);

	/**
	 * Show a loading screen until we have the data.
	 */
	if (!orders || orders === undefined) return <Loader size={50} />;

	/**
	 * Find all of our stop loss or take
	 * profit orders which are in the orders but the
	 * order TP/SL does not have a chart instrument. So we
	 * find the TP/SL number from the trades and match it to
	 * the orders.
	 */
	let tpSLArray = [];
	if (trades) {
		for (const trade of trades) {
			if (trade?.stopLossOrderID) tpSLArray.push({ id: trade.stopLossOrderID, units: trade.currentUnits, instrument: trade.instrument });
			if (trade?.trailingStopLossOrderID)
				tpSLArray.push({ id: trade.trailingStopLossOrderID, units: trade.currentUnits, instrument: trade.instrument });
			if (trade?.takeProfitOrderID) tpSLArray.push({ id: trade.takeProfitOrderID, units: trade.currentUnits, instrument: trade.instrument });
		}
	}

	/**
	 * This is the selected row finder
	 */
	let selected = null;

	/**
	 * This is just custom formatting to make all of the
	 * unique key errors go away.
	 */
	for (let i = 0; i < orders.length; i++) {
		orders[i].randomKey = `${orders[i].id}_${Math.random().toString(36).substr(2, 7)}`;
		orders[i].side = orders[i].units > 0 ? 1 : -1;

		/**
		 * This function will add some additional information that is missing
		 * for SLs and Trailing SLs.
		 *
		 *
		 * 2021-04-09 09:35:42
		 */
		if (tpSLArray.length > 0) orders[i] = addDataToTPSL(orders[i], tpSLArray);

		/**
		 * Selected
		 */
		if (orders[i].instrument === chartInstrument) selected = orders[i].randomKey;
	}

	/**
	 * These are the table headers which will be used by
	 * reactTable
	 */
	const columns = [
		{
			text: 'ID',
			dataField: 'id',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: 'Symbol',
			dataField: 'instrument',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			formatter: (cell, row, index, extraData) => {
				return row.instrument === extraData.chartInstrument ? <span className="text-warning">{cell}</span> : cell;
			},
			formatExtraData: {
				chartInstrument,
			},
			filter: textFilter({ delay: 2000 }),
		},
		{
			text: 'Side',
			dataField: 'side',
			formatter: (cell) => {
				return parseFloat(cell) > 0 ? (
					<span style={{ color: chartColors.greenTradingVue2, fontWeight: 'bold' }}>long</span>
				) : (
					<span style={{ color: chartColors.redTradingView, fontWeight: 'bold' }}>short</span>
				);
			},
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: 'Units',
			dataField: 'units',
			formatter: (cell) => floatToNumberFormat(cell),
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				if (order === 'asc') return a - b;
				else return b - a; // desc
			},
		},
		{
			text: 'Price',
			dataField: 'price',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: 'Type',
			dataField: 'type',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			filter: textFilter({ delay: 2000 }),
		},
		{
			text: 'State',
			dataField: 'state',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: 'ClientExt',
			dataField: 'clientExtensions',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			filter: textFilter({ delay: 2000 }),
			formatter: (cell, row, index, extraData) => {
				try {
					return JSON.stringify(cell, null, 4)
						.replace(/["[,\]]/g, '')
						.replace(/["[\\]/g, ' ');
				} catch (e) {
					return cell;
				}
			},
		},
		{
			text: 'TradeExt',
			dataField: 'tradeClientExtensions',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			filter: textFilter({ delay: 2000 }),
			formatter: (cell, row, index, extraData) => {
				try {
					return JSON.stringify(cell, null, 4)
						.replace(/["[,\]]/g, '')
						.replace(/["[\\]/g, ' ');
				} catch (e) {
					return cell;
				}
			},
		},
		{
			text: '',
			dataField: '_id',
			sort: false,
			headerStyle: { outline: 0, width: '10px' },
			sortCaret: customSortCaret,
			formatter: (cell, row, index, extraData) => {
				/**
				 * Determine if we are loading or not
				 */
				if (extraData.actionLoading.filter((al) => al === row.id).length !== 0) return <i className="la la-spinner la-spin" />;

				return (
					<i
						className="la la-times text-danger"
						key={Math.random().toString(36).substr(2, 7)}
						style={{ cursor: 'pointer' }}
						onClick={(e) => handleCloseClick(row)}
					/>
				);
			},
			formatExtraData: {
				actionLoading,
			},
		},
	];

	const defaultSorted = [
		{
			dataField: 'instrument',
			order: 'asc',
		},
	];

	const selectRow = {
		mode: 'radio',
		selected: [selected],
		clickToSelect: true,
		hideSelectColumn: true,
		bgColor: chartColors.dark,
	};

	/**
	 * When something happens on a row in
	 * bootstrap table, we call this function
	 */
	const rowEvents = {
		onClick: (e, row, rowIndex) => {
			dispatch(setDashboardChartInstrument(row.instrument));
		},
	};

	/**
	 * The user is closing an active trade
	 *
	 * 2022-02-09 10:36:47 JD
	 */
	const handleCloseClick = (row) => {
		if (!row) return null;

		/**
		 * Add it to the spinner so that it will spin until
		 * updated.
		 *
		 * 2022-02-09 10:44:01 JD
		 */
		setActionLoading([...actionLoading, row.id]);

		/**
		 * Send it to the popup confirmation.
		 */
		cancelOrderWithAlert(row, null, dispatch);
	};

	/**
	 * This is the actual display that we are returning.
	 */
	return (
		<BootstrapTable
			keyField="randomKey"
			data={orders}
			columns={columns}
			defaultSorted={defaultSorted}
			filter={filterFactory()}
			rowEvents={rowEvents}
			noDataIndication={() => <NoDataIndication />}
			classes="text-white tr-curosor"
			filterPosition="top"
			selectRow={selectRow}
		/>
	);
};
