import { format } from 'd3-format';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import displayValuesFor from './displayValuesFor';
import GenericChartComponent from '../GenericChartComponent';
import { functor } from '../utils';
import ToolTipText from './ToolTipText';
import ToolTipTSpanLabel from './ToolTipTSpanLabel';
// import { rawFloatFormat, rawCommaFormat } from '../../../../../components/utils';

class GenericSeriesTooltip extends Component {
	constructor(props) {
		super(props);
		this.renderSVG = this.renderSVG.bind(this);
	}
	/**
	 * Create a generic yAccessor
	 *
	 * @param {obj} d
	 * @param {str} type
	 * 	- netDealer, netInstitutional, netLeveraged, netOther
	 *     or any value in cotData
	 */
	yAccessor(d, fetchName, i) {
		return d && d[fetchName] && d[fetchName].allData ? parseFloat(d[fetchName].allData[i].value) : 0;
	}

	/**
	 *
	 * @param {obj} d - data
	 * @param {str} fetchName - name in the data
	 * @param {int} lineCountNumber - this is the current line count number from below
	 * @returns
	 */
	getLabels(d, fetchName, lineCountNumber) {
		/**
		 * If we have custom l
		 */
		if (this.props?.labels && Array.isArray(this.props.labels)) {
			if (this.props?.labels?.[lineCountNumber]) return this.props.labels[lineCountNumber];
		} else if (this.props?.labels && typeof this.props.labels === 'function') {
			return this.props.labels(this.props);
		} else if (d && d[fetchName]) {
			/**
			 * If we have a custom label or shortName in the data, use that.
			 */
			if (d[fetchName].labels && d[fetchName].labels.length > 0) return d[fetchName].labels[lineCountNumber];
			else if (d[fetchName].shortName) return d[fetchName].shortName;
		}

		return fetchName;
	}

	renderSVG(moreProps) {
		const {
			onClick,
			fontFamily,
			fontSize,
			className,
			labelFill,
			textFill,
			displayValuesFor,
			origin: originProp,
			stroke,
			lineSeriesCount,
			fetchName,
			yAccessor,
			yAccessorDivergence,
		} = this.props;

		const {
			chartConfig: { width, height },
		} = moreProps;

		const currentItem = displayValuesFor(this.props, moreProps);

		// const cot = isDefined(currentItem) && yAccessor(currentItem);
		// const value = (cot && displayFormat(cot)) || 'n/a';

		const origin = functor(originProp);
		const [x, y] = origin(width, height);

		/**
		 * Create a holder
		 */
		let tooltipSeriesHolder = [];

		/**
		 * Why do we do this here?
		 *
		 * This is because if you have a divergence only generic series,
		 * you'll have lineSeriesCount as 0 which prevents this from executing
		 * at all.
		 */
		let loopCount = lineSeriesCount === 0 ? 1 : lineSeriesCount;

		/**
		 * Loop through all of the data and create a lineseries
		 * for it.
		 */
		for (let i = 0; i < loopCount; i++) {
			/**
			 * Create our yAccessor
			 */
			let localYAccessor = null;
			if (typeof yAccessor === 'function') localYAccessor = yAccessor;
			else if (Array.isArray(yAccessor)) localYAccessor = yAccessor[i];
			else if (typeof yAccessorDivergence === 'function') localYAccessor = yAccessorDivergence;

			/**
			 * yLocation calculation
			 */
			let yLocationLine = i * 11 - 11 + i;
			let yLocationLine2 = yLocationLine + 11 + i;
			let yLocation = i * 11 + i;

			tooltipSeriesHolder.push(
				<g key={Math.random().toString(36).substr(2, 7)}>
					<line
						x1={0}
						y1={yLocationLine}
						x2={0}
						y2={yLocationLine2}
						stroke={stroke[i][0]}
						strokeWidth="4px"
						key={Math.random().toString(36).substr(2, 7)}
					/>
					<ToolTipText x={6} y={yLocation} fontFamily={fontFamily} fontSize={fontSize} key={Math.random().toString(36).substr(2, 7)}>
						<ToolTipTSpanLabel fill={labelFill}>{this.getLabels(currentItem, fetchName, i)}: </ToolTipTSpanLabel>
						<tspan fill={textFill}>
							{localYAccessor == null ? this.yAccessor(currentItem, fetchName, i) : localYAccessor(currentItem, this.props)}
						</tspan>
					</ToolTipText>
				</g>
			);
		}

		return (
			<g className={className} transform={`translate(${x}, ${y})`} onClick={onClick}>
				{tooltipSeriesHolder}
			</g>
		);
	}
	render() {
		return <GenericChartComponent clip={false} svgDraw={this.renderSVG} drawOn={['mousemove']} />;
	}
}

GenericSeriesTooltip.propTypes = {
	origin: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
	className: PropTypes.string,
	fontFamily: PropTypes.string,
	fontSize: PropTypes.number,
	onClick: PropTypes.func,
	yAccessor: PropTypes.func,
	displayFormat: PropTypes.func,
	displayValuesFor: PropTypes.func,
	textFill: PropTypes.string,
	labelFill: PropTypes.string,
};

GenericSeriesTooltip.defaultProps = {
	displayFormat: format('.2f'),
	displayValuesFor: displayValuesFor,
	origin: [0, 0],
	className: 'react-stockcharts-generic-series-tooltip',
	zeroLineStroke: '#000000',
	zeroLineOpacity: 0.3,
	fill: {
		divergencePositive: 'green',
		divergenceNegative: 'red',
	},
	stroke: [
		['#1870DC', 1], // Color, width
		['#B8C2CC', 1],
		['#8795A1', 1],
		['#B8C2CC', 1],
		['#58D777', 1],
		['#f0af03', 1],
		['#ef5350', 1],
		['#474D84', 1],
		['#14d3d3', 1],
	],
	clip: true,
	divergenceStroke: false,
	widthRatio: 0.5,
};

export default GenericSeriesTooltip;
