import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LineSeries from './LineSeries';
import StraightLine from './StraightLine';
import BarSeries from './BarSeries';

class COTSeries extends Component {
	// constructor(props) {
	// 	super(props);
	// }

	/**
	 * Create a generic yAccessor
	 *
	 * @param {obj} d
	 * @param {str} type
	 * 	- netDealer, netInstitutional, netLeveraged, netOther
	 *     or any value in cotData
	 */
	yAccessor(d, type) {
		if (this.props.usePercent) type = `${type}Pct`;
		return d.cotData && d.cotData[type] ? d.cotData[type] : 0;
	}
	yAccessorDivergence(d) {
		if (this.props.usePercent) {
			// Orig below
			return d.cotData && d.cotData.netDealerPct ? -d.cotData.netDealerPct + -d.cotData.netInstitutionalPct : 0;

			// // Alternate version
			// return d.cotData && d.cotData.netDealerPct ? -d.cotData.netDealerPct + -d.cotData.netInstitutionalPct + d.cotData.netLeveragedPct : 0;
		} else return d.cotData && d.cotData.netDealer ? -d.cotData.netDealer + -d.cotData.netInstitutional : 0;
	}

	render() {
		const { className, stroke, opacity, divergenceStroke, widthRatio, width } = this.props;
		const { fill, clip, zeroLineStroke, zeroLineOpacity } = this.props;

		return (
			<g className={className}>
				<StraightLine stroke={zeroLineStroke} opacity={zeroLineOpacity} yValue={0} />
				<BarSeries
					baseAt={(xScale, yScale) => yScale(0)}
					className="macd-divergence"
					width={width}
					widthRatio={widthRatio}
					stroke={divergenceStroke}
					fill={(d) => (this.yAccessorDivergence(d) > 0 ? fill.divergencePositive : fill.divergenceNegative)}
					opacity={opacity}
					clip={clip}
					yAccessor={(d) => this.yAccessorDivergence(d)}
				/>
				<LineSeries
					className={className}
					yAccessor={(d) => this.yAccessor(d, 'netDealer')}
					stroke={stroke.netDealer}
					strokeWidth={stroke.width}
				/>
				<LineSeries
					className={className}
					yAccessor={(d) => this.yAccessor(d, 'netInstitutional')}
					stroke={stroke.netInstitutional}
					strokeWidth={stroke.width}
				/>
				<LineSeries
					className={className}
					yAccessor={(d) => this.yAccessor(d, 'netLeveraged')}
					stroke={stroke.netLeveraged}
					strokeWidth={stroke.width}
				/>
				<LineSeries
					className={className}
					yAccessor={(d) => this.yAccessor(d, 'netOther')}
					stroke={stroke.netOther}
					strokeWidth={stroke.width}
				/>
			</g>
		);
	}
}

COTSeries.propTypes = {
	className: PropTypes.string,
	yAccessor: PropTypes.func,
	divergenceStroke: PropTypes.bool,
	usePercent: PropTypes.bool,
	zeroLineStroke: PropTypes.string,
	zeroLineOpacity: PropTypes.number,
	stroke: PropTypes.shape({
		netDealer: PropTypes.string.isRequired,
		netInstitutional: PropTypes.string.isRequired,
		netLeveraged: PropTypes.string.isRequired,
		netOther: PropTypes.string.isRequired,
		width: PropTypes.number,
	}).isRequired,
	fill: PropTypes.shape({
		divergencePositive: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
		divergenceNegative: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
	}).isRequired,
};

COTSeries.defaultProps = {
	className: 'react-stockcharts-cot-series',
	zeroLineStroke: '#000000',
	zeroLineOpacity: 0.3,
	usePercent: true,
	fill: {
		divergencePositive: 'green',
		divergenceNegative: 'red',
	},
	stroke: {
		netDealer: '#000000',
		netInstitutional: '#B8C2CC',
		netLeveraged: '#8795A1',
		netOther: '#B8C2CC',
		width: 1,
	},
	clip: true,
	divergenceStroke: false,
	widthRatio: 0.5,
	width: BarSeries.defaultProps.width,
};

export default COTSeries;
