import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LineSeries from './LineSeries';
import AreaOnlySeries from './AreaOnlySeries';

class BollingerSeries extends Component {
	constructor(props) {
		super(props);
		this.yAccessorForTop = this.yAccessorForTop.bind(this);
		this.yAccessorForMiddle = this.yAccessorForMiddle.bind(this);
		this.yAccessorForBottom = this.yAccessorForBottom.bind(this);
		this.genericYAccessor = this.genericYAccessor.bind(this);
		this.renderAdditional = this.renderAdditional.bind(this);
		this.yAccessorForScalledBottom = this.yAccessorForScalledBottom.bind(this);
	}
	yAccessorForTop(d) {
		const { yAccessor } = this.props;
		return yAccessor(d) && yAccessor(d).top;
	}
	yAccessorForMiddle(d) {
		const { yAccessor } = this.props;
		return yAccessor(d) && yAccessor(d).middle;
	}
	yAccessorForBottom(d) {
		const { yAccessor } = this.props;
		return yAccessor(d) && yAccessor(d).bottom;
	}
	yAccessorForScalledBottom(scale, d) {
		const { yAccessor } = this.props;
		return scale(yAccessor(d) && yAccessor(d).bottom);
	}
	genericYAccessor(d, i, type, doScale = false, scale) {
		const { yAccessor } = this.props;
		let otherOne = 0;

		/**
		 * If we are less than 0, we need to use the original top/bottom,
		 * not the additional ones.
		 *
		 * 2021-04-04 07:10:56 JD
		 */
		if (doScale && i < 0) {
			otherOne = yAccessor(d)?.[type] || 0;
		} else otherOne = yAccessor(d)?.otherData?.additionalMultis?.[i]?.[type] || 0;

		/**
		 * Return scale or not
		 */
		return doScale ? scale(otherOne) : otherOne;
	}
	renderAdditional() {
		const { stroke, strokeWidth, additionalMultipliers, additionalFills, areaClassName, fill } = this.props;

		/**
		 * Create an adjusting opacity
		 */
		let opacity = 0.05;

		/**
		 * This variable holds any additional lines we need to
		 * create.
		 */
		if (additionalMultipliers && Array.isArray(additionalMultipliers)) {
			/**
			 * Our return holder
			 */
			let jsxReturn = [];

			/**
			 * Loop through and create.
			 */
			for (let i = 0; i < additionalMultipliers.length; i++) {
				jsxReturn.push(
					<LineSeries
						yAccessor={(d) => this.genericYAccessor(d, i, 'top')}
						stroke={additionalFills?.top?.[i]?.stroke ? additionalFills.top[i].stroke : stroke.top}
						fill="none"
						strokeWidth={additionalFills?.top?.[i]?.strokeWidth ? additionalFills.top[i].strokeWidth : strokeWidth.top}
						key={Math.random().toString(36).substr(2, 7)}
					/>,
					<LineSeries
						yAccessor={(d) => this.genericYAccessor(d, i, 'bottom')}
						stroke={additionalFills?.bottom?.[i]?.stroke ? additionalFills.bottom[i].stroke : stroke.bottom}
						fill="none"
						strokeWidth={additionalFills?.bottom?.[i]?.strokeWidth ? additionalFills.bottom[i].strokeWidth : strokeWidth.bottom}
						key={Math.random().toString(36).substr(2, 7)}
					/>,
					<AreaOnlySeries
						className={areaClassName}
						yAccessor={(d) => this.genericYAccessor(d, i, 'top')}
						base={(scale, d) => this.genericYAccessor(d, i - 1, 'top', true, scale)}
						stroke="none"
						fill={additionalFills?.top?.[i]?.fill ? additionalFills.top[i].fill : fill}
						opacity={additionalFills?.top?.[i]?.opacity ? additionalFills.top[i].opacity : opacity}
						key={Math.random().toString(36).substr(2, 7)}
					/>,
					<AreaOnlySeries
						className={areaClassName}
						yAccessor={(d) => this.genericYAccessor(d, i, 'bottom')}
						base={(scale, d) => this.genericYAccessor(d, i - 1, 'bottom', true, scale)}
						stroke="none"
						fill={additionalFills?.bottom?.[i]?.fill ? additionalFills.bottom[i].fill : fill}
						opacity={additionalFills?.bottom?.[i]?.opacity ? additionalFills.bottom[i].opacity : opacity}
						key={Math.random().toString(36).substr(2, 7)}
					/>
				);

				/**
				 * Increase our opacity
				 */
				opacity += 0.05;
			}

			return jsxReturn;
		}

		return '';
	}
	render() {
		const { areaClassName, className, opacity, additionalMultipliers } = this.props;
		const { stroke, fill, strokeWidth } = this.props;

		return (
			<g className={className}>
				<LineSeries yAccessor={this.yAccessorForTop} stroke={stroke.top} fill="none" strokeWidth={strokeWidth.top} />
				<LineSeries yAccessor={this.yAccessorForMiddle} stroke={stroke.middle} fill="none" strokeWidth={strokeWidth.middle} />
				<LineSeries yAccessor={this.yAccessorForBottom} stroke={stroke.bottom} fill="none" strokeWidth={strokeWidth.bottom} />
				{additionalMultipliers && Array.isArray(additionalMultipliers) ? (
					''
				) : (
					<AreaOnlySeries
						className={areaClassName}
						yAccessor={this.yAccessorForTop}
						base={this.yAccessorForScalledBottom}
						stroke="none"
						fill={fill}
						opacity={opacity}
					/>
				)}
				{this.renderAdditional()}
			</g>
		);
	}
}

BollingerSeries.propTypes = {
	yAccessor: PropTypes.func.isRequired,
	className: PropTypes.string,
	areaClassName: PropTypes.string,
	opacity: PropTypes.number,
	type: PropTypes.string,
	stroke: PropTypes.shape({
		top: PropTypes.string.isRequired,
		middle: PropTypes.string.isRequired,
		bottom: PropTypes.string.isRequired,
	}).isRequired,
	fill: PropTypes.string.isRequired,
};

BollingerSeries.defaultProps = {
	className: 'react-stockcharts-bollinger-band-series',
	areaClassName: 'react-stockcharts-bollinger-band-series-area',
	opacity: 0.2,
};

export default BollingerSeries;
