import {
	FETCH_PROFIT_DATA_TODAY,
	FETCH_PROFIT_DATA_YESTERDAY,
	FETCH_PROFIT_DATA_D2,
	FETCH_PROFIT_DATA_D3,
	FETCH_PROFIT_DATA_D4,
	FETCH_PROFIT_DATA_D5,
	FETCH_PROFIT_DATA_D6,
	FETCH_PROFIT_DATA_D7,
	FETCH_PROFIT_DATA_D8,
	FETCH_PROFIT_DATA_D9,
	FETCH_PROFIT_DATA_D10,
	FETCH_PROFIT_DATA_WEEK,
	FETCH_PROFIT_DATA_LAST_WEEK,
	FETCH_PROFIT_DATA_MONTH,
	FETCH_PROFIT_DATA_LAST_MONTH,
	FETCH_PROFIT_DATA_YEAR,
	FETCH_PROFIT_DATA_LAST_YEAR,
	FETCH_BALANCE_WEEK,
	FETCH_BALANCE_MONTH,
	FETCH_BALANCE_QUARTER,
	FETCH_BALANCE_YEAR,
	FETCH_ACCOUNT_DETAILS,
	FETCH_ACCOUNT_LIST,
	FETCH_ACCOUNT_TYPE,
	SET_ACCOUNT_ID,
	FETCH_INSTRUMENT_LIST,
	SET_CHART_INSTRUMENT,
	SET_CHART_GRANULARITY,
	FETCH_CHART_CANDLES,
	UPDATE_CHART_CANDLES,
	FETCH_BALANCE_LASTMONTH,
	FETCH_BALANCE_LASTWEEK,
	FETCH_PROFIT_DATA_52_WEEK,
	FETCH_POSITIONS,
	FETCH_ORDERS,
	FETCH_DASHBOARD_DATA,
	FETCH_PROFIT_STATS,
	FETCH_TRADES,
	SET_INDICATOR_LIST,
	SET_INDIVIDUAL_POSITIONS,
	SET_CHART_TYPE,
	SET_CALENDAR,
	ADD_NEW_INDICATOR_LIST,
	FETCH_STUCK_TRADES,
	MORE_CHART_CANDLES,
	FETCH_ORDER_BOOK,
	FETCH_POSITION_BOOK,
	FETCH_COT_DATA,
	SET_GENERIC_API_DATA,
	SET_NONMERGE_GENERIC_API_DATA,
	SET_CANDLE_TYPE,
	CANDLE_LOOP_DONE,
	SET_DASHBOARD_SIZE,
	FETCH_CONVERSION_RATES,
	CANCEL_ORDER_BY_ID,
	SHOW_FOOTER_LOADING,
} from '../actions/types';
import { concatNewCandleData } from '../components/utils';
//import { setAccountID } from '../actions/oanda';

const defaultState = {
	profit: {},
	balance: {},
	accountID: '',
	accountDetails: {},
	accountList: [],
	accountType: '',
	instruments: [],
	chartInstrument: '',
	chartGranularity: '',
	chartSettings: {
		indicators: [],
		individualPositions: true,
		chartType: 'Candlestick',
		candleType: 'mid',
		backtesting: false,
		candleLoopDone: false,
		dashboardSize: 8, // This is the bootstrap col size
	},
	conversionRates: {},
	chartCandles: {},
	positions: {},
	orders: {},
	trades: {},
	calendar: [],
	stuckTrades: [],
	orderBook: {},
	positionBook: {},
	cotData: [],
	genericApiData: {},
	showFooterLoading: false, // See note below
};

/**
 *
 * Candles: {
 * []
 *
 * }
 */

const oanda = (state = defaultState, action) => {
	/**
	 * Global vars
	 */
	let oandaAccountID, instrument, granularity, period, fetchName;

	switch (action.type) {
		case FETCH_PROFIT_STATS:
			return { ...state, profit: action.payload } || state; // empty string is false, return false
		case FETCH_DASHBOARD_DATA:
			oandaAccountID = action.payload.accountID;

			return { ...state, profit: { ...state.profit, [oandaAccountID]: action.payload } } || state; // empty string is false, return false
		case FETCH_PROFIT_DATA_TODAY:
			oandaAccountID = action.payload.accountID;
			period = action.payload.dateType === 'thisYear' ? 'year' : action.payload.dateType;

			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], [period]: action.payload } } } || state; // empty string is false, return false
		case FETCH_PROFIT_DATA_YESTERDAY:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], yesterday: action.payload } } } || state;
		case FETCH_PROFIT_DATA_D2:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], d2: action.payload } } } || state;
		case FETCH_PROFIT_DATA_D3:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], d3: action.payload } } } || state;
		case FETCH_PROFIT_DATA_D4:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], d4: action.payload } } } || state;
		case FETCH_PROFIT_DATA_D5:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], d5: action.payload } } } || state;
		case FETCH_PROFIT_DATA_D6:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], d6: action.payload } } } || state;
		case FETCH_PROFIT_DATA_D7:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], d7: action.payload } } } || state;
		case FETCH_PROFIT_DATA_D8:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], d8: action.payload } } } || state;
		case FETCH_PROFIT_DATA_D9:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], d9: action.payload } } } || state;
		case FETCH_PROFIT_DATA_D10:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], d10: action.payload } } } || state;
		case FETCH_PROFIT_DATA_WEEK:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], week: action.payload } } } || state;
		case FETCH_PROFIT_DATA_LAST_WEEK:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], w1: action.payload } } } || state;
		case FETCH_PROFIT_DATA_MONTH:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], month: action.payload } } } || state;
		case FETCH_PROFIT_DATA_LAST_MONTH:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], m1: action.payload } } } || state;
		case FETCH_PROFIT_DATA_YEAR:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], year: action.payload } } } || state;
		case FETCH_PROFIT_DATA_LAST_YEAR:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], y1: action.payload } } } || state;
		case FETCH_PROFIT_DATA_52_WEEK:
			oandaAccountID = action.payload.accountID;
			return { ...state, profit: { ...state.profit, [oandaAccountID]: { ...state.profit[oandaAccountID], w52: action.payload } } } || state;
		case FETCH_BALANCE_WEEK:
			oandaAccountID = action.payload.accountID;
			return (
				{ ...state, balance: { ...state.balance, [oandaAccountID]: { ...state.balance[oandaAccountID], weekStart: action.payload.balance } } } ||
				false
			);
		case FETCH_BALANCE_LASTWEEK:
			oandaAccountID = action.payload.accountID;
			return (
				{ ...state, balance: { ...state.balance, [oandaAccountID]: { ...state.balance[oandaAccountID], lastWeekStart: action.payload.balance } } } ||
				false
			);
		case FETCH_BALANCE_MONTH:
			oandaAccountID = action.payload.accountID;
			return (
				{ ...state, balance: { ...state.balance, [oandaAccountID]: { ...state.balance[oandaAccountID], monthStart: action.payload.balance } } } ||
				false
			);
		case FETCH_BALANCE_LASTMONTH:
			oandaAccountID = action.payload.accountID;
			return (
				{ ...state, balance: { ...state.balance, [oandaAccountID]: { ...state.balance[oandaAccountID], lastMonthStart: action.payload.balance } } } ||
				false
			);
		case FETCH_BALANCE_QUARTER:
			oandaAccountID = action.payload.accountID;
			return (
				{ ...state, balance: { ...state.balance, [oandaAccountID]: { ...state.balance[oandaAccountID], quarterStart: action.payload.balance } } } ||
				false
			);
		case FETCH_BALANCE_YEAR:
			oandaAccountID = action.payload.accountID;
			return (
				{ ...state, balance: { ...state.balance, [oandaAccountID]: { ...state.balance[oandaAccountID], yearStart: action.payload.balance } } } ||
				false
			);
		case FETCH_ACCOUNT_DETAILS:
			oandaAccountID = action.payload.id;
			return { ...state, accountDetails: { ...state.accountDetails, [oandaAccountID]: action.payload } } || state;
		case FETCH_CHART_CANDLES:
			if (!action.payload.candles || action.payload.candles.length <= 0) return state;

			granularity = action.payload.granularity;
			instrument = action.payload.instrument;
			return (
				{
					...state,
					chartCandles: { ...state.chartCandles, [instrument]: { ...state.chartCandles[instrument], [granularity]: action.payload.candles } },
				} || state
			);
		case MORE_CHART_CANDLES:
			granularity = action.payload.granularity;
			instrument = action.payload.instrument;

			return (
				{
					...state,
					chartCandles: {
						...state.chartCandles,
						[instrument]: {
							...state.chartCandles[instrument],
							[granularity]: [...action.payload.candles, ...state.chartCandles[instrument][granularity]],
						},
					},
				} || state
			);
		case UPDATE_CHART_CANDLES:
			granularity = action.payload.granularity;
			instrument = action.payload.instrument;

			/**
			 * If the variables are not present in state, we
			 * can't update yet.
			 */
			if (!state.chartCandles[instrument] || !state.chartCandles[instrument][granularity]) return state;

			/**
			 * Create a new array form the old using the new values
			 * in the update.
			 *
			 * Confirmed 2021-04-28 05:24:31
			 * ----------
			 * The newest candle in the state is the last candle
			 * ----------
			 * Confirmed - 2021-04-28 05:24:24
			 * Confirmed again.... - 2021-05-03 09:12:01
			 *
			 * 2021-04-27 11:42:44
			 */
			const newCandles = concatNewCandleData(state.chartCandles[instrument][granularity], action.payload.candles);

			return (
				{
					...state,
					chartCandles: {
						...state.chartCandles,
						[instrument]: {
							...state.chartCandles[instrument],
							[granularity]: newCandles,
						},
					},
				} || state
			);
		case FETCH_POSITIONS:
			oandaAccountID = action.payload.id;
			return { ...state, positions: { ...state.positions, [oandaAccountID]: action.payload.positions } } || state;
		case FETCH_ORDERS:
			oandaAccountID = action.payload.id;
			return { ...state, orders: { ...state.orders, [oandaAccountID]: action.payload.orders } } || state;
		case CANCEL_ORDER_BY_ID: // Not used any longer, caused flashing on the chart
			let newState = {
				...state,
				orders: {
					...state.orders,
					[state.accountID]: Object.keys(state.orders[state.accountID]).reduce(function (r, e) {
						if (parseInt(state.orders[state.accountID][e].id) !== parseInt(action.payload)) {
							r[e] = state.orders[state.accountID][e];
						} else {
							r[e] = state.orders[state.accountID][e];
							r[e].state = 'CANCELLED';
						}
						return r;
					}, {}),
				},
			};

			console.log(newState.orders[state.accountID]);
			return newState;
		case FETCH_TRADES:
			oandaAccountID = action.payload.id;
			return { ...state, trades: { ...state.trades, [oandaAccountID]: action.payload.trades } } || state;
		case FETCH_STUCK_TRADES:
			return { ...state, stuckTrades: action.payload } || state;
		case FETCH_POSITION_BOOK:
			return { ...state, positionBook: action.payload } || state;
		case FETCH_ORDER_BOOK:
			return { ...state, orderBook: action.payload } || state;
		case FETCH_COT_DATA:
			return { ...state, cotData: action.payload } || state;
		case FETCH_ACCOUNT_LIST:
			return { ...state, accountList: action.payload } || state;
		case FETCH_ACCOUNT_TYPE:
			return { ...state, accountType: action.payload } || state;
		case SET_ACCOUNT_ID:
			return { ...state, accountID: action.payload } || state;
		case SET_CHART_INSTRUMENT:
			return { ...state, chartInstrument: action.payload } || state;
		case FETCH_CONVERSION_RATES:
			return { ...state, conversionRates: action.payload } || state;
		case SET_CHART_GRANULARITY:
			return { ...state, chartGranularity: action.payload } || state;
		case FETCH_INSTRUMENT_LIST:
			return { ...state, instruments: action.payload } || state;
		case SET_INDICATOR_LIST:
			return { ...state, chartSettings: { ...state.chartSettings, indicators: action.payload } } || state;
		case ADD_NEW_INDICATOR_LIST:
			return { ...state, chartSettings: { ...state.chartSettings, indicators: [...state.chartSettings.indicators, action.payload] } } || state;
		case SET_CHART_TYPE:
			return { ...state, chartSettings: { ...state.chartSettings, chartType: action.payload } } || state;
		case SET_CANDLE_TYPE:
			return { ...state, chartSettings: { ...state.chartSettings, candleType: action.payload } } || state;
		case SET_DASHBOARD_SIZE:
			return { ...state, chartSettings: { ...state.chartSettings, dashboardSize: action.payload } } || state;
		case SET_INDIVIDUAL_POSITIONS:
			return { ...state, chartSettings: { ...state.chartSettings, individualPositions: action.payload } } || state;
		case CANDLE_LOOP_DONE:
			return { ...state, chartSettings: { ...state.chartSettings, candleLoopDone: action.payload } } || state;
		case SHOW_FOOTER_LOADING:
			/**
			 * Why do we have SHOW_FOOTER_LOADING if we have CANDLE_LOOP_DONE ??
			 * Because, CANDLE_LOOP_DONE is in the chartsettings which causes double
			 * reloads when issued here - the double being here and in the customchart.js.
			 * This value is outside of the chartsettings and does not cause double reloads.
			 */
			return { ...state, showFooterLoading: action.payload } || state;
		case SET_CALENDAR:
			return { ...state, calendar: action.payload } || state;
		case SET_GENERIC_API_DATA:
			fetchName = action.payload.fetchName;
			if (!fetchName) return state;

			return (
				{
					...state,
					genericApiData: {
						...state.genericApiData,
						[fetchName]: action.payload,
					},
				} || state
			);
		case SET_NONMERGE_GENERIC_API_DATA:
			fetchName = action.payload.fetchName;
			if (!fetchName) return state;

			return (
				{
					...state,
					nonMergeApiData: {
						...state.nonMergeApiData,
						[fetchName]: action.payload,
					},
				} || state
			);
		default:
			return state;
	}
};

export default oanda;
