//import { setAccountID } from '../actions/oanda';
import {
	FETCH_BACKTEST_DATA,
	FETCH_BACKTEST_CANDLES,
	FETCH_OPTIMIZATION_DATA,
	FETCH_OPTIMIZATION_BROWSE_DATA,
	FETCH_OPTIMIZATION_BROWSE_COUNT,
	SET_OPTIMIZATION_CONFIG,
	SET_OPTIMIZATION_SELECTS,
	FETCH_BACKTEST_BROWSE_DATA,
	FETCH_BACKTEST_BROWSE_COUNT,
	SET_BACKTEST_CONFIG,
	REMOVE_OPTIMIZATION_BROWSE_DATA,
	REMOVE_BACKTEST_BROWSE_DATA,
	FETCH_TRANSACTION_BROWSE_DATA,
	FETCH_TRANSACTION_BROWSE_COUNT,
	SET_TRANSACTION_CONFIG,
	REMOVE_TRANSACTION_BROWSE_DATA,
	FETCH_TRANSACTION_DATA,
} from '../actions/types';

const defaultState = {
	data: {},
	candles: {},
	browse: {
		optimizationConfig: {
			pageIndex: 0,
			pageSize: 50,
			sortBy: null,
			filters: null,
			lastID: null,
			total: 0,
			pageCount: 0,
			selectData: {
				strategies: [],
				symbols: [],
			},
		},
		backtestConfig: {
			pageIndex: 0,
			pageSize: 50,
			sortBy: null,
			filters: null,
			lastID: null,
			total: 0,
			pageCount: 0,
			selectData: {},
		},
		transactionConfig: {
			pageIndex: 0,
			pageSize: 50,
			sortBy: null,
			filters: null,
			lastID: null,
			total: 0,
			pageCount: 0,
			selectData: {},
		},
		optimizationData: [],
		backtestData: [],
		transactionsData: [],
	},
};

export default function (state = defaultState, action) {
	switch (action.type) {
		case FETCH_BACKTEST_DATA:
			return { ...state, data: { ...state.data, backtest: action.payload } } || false;
		case FETCH_OPTIMIZATION_DATA:
			return { ...state, data: { ...state.data, optimization: action.payload } } || false;
		case FETCH_TRANSACTION_DATA:
			return { ...state, data: { ...state.data, transaction: action.payload } } || false;
		case FETCH_BACKTEST_CANDLES:
			return { ...state, candles: action.payload } || false;
		case SET_OPTIMIZATION_SELECTS:
			return (
				{
					...state,
					browse: {
						...state.browse,
						optimizationConfig: {
							...state.browse.optimizationConfig,
							selectData: action.payload,
						},
					},
				} || false
			);
		case FETCH_OPTIMIZATION_BROWSE_DATA:
			return { ...state, browse: { ...state.browse, optimizationData: action.payload } } || false;
		case REMOVE_OPTIMIZATION_BROWSE_DATA:
			return { ...state, browse: { ...state.browse, optimizationData: [] } } || false;
		case FETCH_OPTIMIZATION_BROWSE_COUNT:
			return (
				{
					...state,
					browse: {
						...state.browse,
						optimizationConfig: {
							...state.browse.optimizationConfig,
							total: action.payload.count,
							pageCount: Math.ceil(action.payload.count / state.browse.optimizationConfig.pageSize),
						},
					},
				} || false
			);
		case SET_OPTIMIZATION_CONFIG:
			return (
				{
					...state,
					browse: {
						...state.browse,
						optimizationConfig: {
							...state.browse.optimizationConfig,
							pageIndex: action.payload.pageIndex,
							pageSize: action.payload.pageSize,
							sortBy: action.payload.sortBy,
							filters: action.payload.filters,
							lastID: action.payload.lastID,
						},
					},
				} || false
			);
		case FETCH_BACKTEST_BROWSE_DATA:
			return { ...state, browse: { ...state.browse, backtestData: action.payload } } || false;
		case REMOVE_BACKTEST_BROWSE_DATA:
			return { ...state, browse: { ...state.browse, backtestData: [] } } || false;
		case FETCH_BACKTEST_BROWSE_COUNT:
			return (
				{
					...state,
					browse: {
						...state.browse,
						backtestConfig: {
							...state.browse.backtestConfig,
							total: action.payload.count,
							pageCount: Math.ceil(action.payload.count / state.browse.backtestConfig.pageSize),
						},
					},
				} || false
			);
		case SET_BACKTEST_CONFIG:
			return (
				{
					...state,
					browse: {
						...state.browse,
						backtestConfig: {
							...state.browse.backtestConfig,
							pageIndex: action.payload.pageIndex,
							pageSize: action.payload.pageSize,
							sortBy: action.payload.sortBy,
							filters: action.payload.filters,
							lastID: action.payload.lastID,
						},
					},
				} || false
			);

		case FETCH_TRANSACTION_BROWSE_DATA:
			return { ...state, browse: { ...state.browse, transactionsData: action.payload } } || false;
		case REMOVE_TRANSACTION_BROWSE_DATA:
			return { ...state, browse: { ...state.browse, transactionsData: [] } } || false;
		case FETCH_TRANSACTION_BROWSE_COUNT:
			return (
				{
					...state,
					browse: {
						...state.browse,
						transactionConfig: {
							...state.browse.transactionConfig,
							total: action.payload.count,
							pageCount: Math.ceil(action.payload.count / state.browse.transactionConfig.pageSize),
						},
					},
				} || false
			);
		case SET_TRANSACTION_CONFIG:
			return (
				{
					...state,
					browse: {
						...state.browse,
						transactionConfig: {
							...state.browse.transactionConfig,
							pageIndex: action.payload.pageIndex,
							pageSize: action.payload.pageSize,
							sortBy: action.payload.sortBy,
							filters: action.payload.filters,
							lastID: action.payload.lastID,
						},
					},
				} || false
			);

		default:
			return state;
	}
}
