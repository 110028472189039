import React from 'react';
import { useDispatch } from 'react-redux'; // useDispatch()
import { Button } from 'reactstrap';
import { chartColors } from './config/genericConfig';
import { changeModalIndicatorOpen } from '../../actions/navigation';

// To use redux hooks, you export a function component, not a react component.
export const CustomChartToolbarBacktest = (props) => {
	/**
	 * We use an arrow function whitch passes state to a
	 * callback function that returns the state we need.
	 */
	const chartInstrument = props.backtestData.symbol;
	const chartGranularity = props.backtestData.granularity;
	const dispatch = useDispatch();

	/**
	 * Get our props
	 */
	let backgroundColor = props.backgroundColor ? props.backgroundColor : chartColors.toolbarBackground;
	let borderBottomStyle = props.borderBottomStyle ? props.borderBottomStyle : `1px solid ${chartColors.toolbarBorder}`;

	/**
	 * When the dropdown select is changed, this will be
	 * fired.
	 *
	 * @param {obj} values
	 */
	const handleIndicatorClick = () => dispatch(changeModalIndicatorOpen(true));

	/**
	 * This will render our react-dropdown-select which we will
	 * use for indicators
	 */
	const renderIndicatorSelect = () => {
		return (
			<div className="toolbarInputGroup">
				{/* <div className="toolbarInputLabel">{renderIndicators()}</div> */}
				<span onClick={handleIndicatorClick}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none">
						<path stroke="currentColor" d="M20 17l-5 5M15 17l5 5M9 11.5h7M17.5 8a2.5 2.5 0 0 0-5 0v11a2.5 2.5 0 0 1-5 0"></path>
					</svg>
				</span>
			</div>
		);
	};

	return (
		<div
			className="trading-vue-toolbar-top"
			style={{ backgroundColor: backgroundColor, borderBottom: borderBottomStyle, width: '100%', height: '43px' }}
		>
			<div className="btn-toolbar justify-content-between">
				<div className="d-inline-flex">
					<Button color="default" className="toolbarInputGroup" style={{ border: 'none' }}>
						{chartInstrument}
					</Button>
					<Button color="default" className="toolbarInputGroup" style={{ border: 'none' }}>
						{chartGranularity}
					</Button>
					{renderIndicatorSelect()}
				</div>
			</div>
		</div>
	);
};
