import React, { useState, useRef, useEffect, useCallback } from 'react';
import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
import { ChartCanvas, Chart, ZoomButtons } from '../../libs/react-stockcharts/src';
import {
	CandlestickSeries,
	BollingerSeries,
	VolumeProfileSeries,
	BarSeries,
	LineSeries,
	AreaSeries,
	MACDSeries,
	RSISeries,
	COTSeries,
	GenericSeries,
	RenkoSeries,
	OHLCSeries,
	// StackedBarSeries,
	// GroupedBarSeries,
	KagiSeries,
	PointAndFigureSeries,
	// ScatterSeries,
	// AreaOnlySeries,
	// AlternatingFillAreaSeries,
} from '../../libs/react-stockcharts/src/lib/series';
import { OrdersPriceCoordinateStore } from './components/OrdersPriceCoordinateStore';
import { PositionPriceCoordinateStore } from './components/PositionPriceCoordinateStore';
import { TradesPriceCoordinateStore } from './components/TradesPriceCoordinateStore';
import { XAxis, YAxis } from '../../libs/react-stockcharts/src/lib/axes';
import {
	CrossHairCursor,
	MouseCoordinateX,
	MouseCoordinateY,
	EdgeIndicator,
	CurrentCoordinate,
} from '../../libs/react-stockcharts/src/lib/coordinates';
import { discontinuousTimeScaleProviderBuilder } from '../../libs/react-stockcharts/src/lib/scale';
import {
	OHLCTooltip,
	MovingAverageTooltip,
	MACDTooltip,
	RSITooltip,
	StaticValueTooltip,
	COTTooltip,
	GenericSeriesTooltip,
	SingleValueTooltip,
	BollingerBandTooltip,
} from '../../libs/react-stockcharts/src/lib/tooltip';
import {
	mouseEdgeAppearanceY,
	mouseEdgeAppearanceX,
	candlesAppearance,
	KagiAppearance,
	yAxisAppearance,
	xAxisAppearance,
	CursorAppearance,
	OHLCAppearance,
	chartColors,
	movingAverageToolTipAppearance,
	StaticValueToolTipAppearance,
	longAnnotation,
	shortAnnotation,
	takeProfitFromShortAnnotation,
	takeProfitFromLongAnnotation,
} from './config/genericConfig';
import {
	volumeProfileApperance,
	volumeApperance,
	edgeIndicatorAppearance,
	edgeBidIndicatorAppearance,
	edgeAskIndicatorAppearance,
	macdAppearance,
	rsiToolTipApperance,
	cotAppearance,
	genericSeriesAppearance,
} from './config/indicatorSetings';
import { getMatchingIndicator } from './config/indicatorOptions';
import { findDataIndexClosestTime, getFormat, getPipDifference, rawFloatFormat } from '../utils';
import { ema, sma, wma, tma, macd, rsi, atr, bollingerBand, uscMurreyMath } from '../../libs/react-stockcharts/src/lib/indicator';
import { Annotate, LabelAnnotation } from '../../libs/react-stockcharts/src/lib/annotation';
import { DrawPositions } from '../../libs/react-stockcharts/src/lib/DrawPositions';
import Zone from '../../libs/react-stockcharts/src/lib/Zone';
import { PositionBookPriceCoordinateStore } from './components/PositionBookPriceCoordinateStore';
import { LongShortPercentText } from './components/LongShortPercentText';
import { GenericLongShortPercentText } from './components/GenericLongShortPercentText';
import { ClickCallback } from '../../libs/react-stockcharts/src/lib/interactive';
import { DrawHorizontalVolumeLines } from '../../libs/react-stockcharts/src/lib/DrawHorizontalVolumeLines';
import { ATRText } from '../../libs/react-stockcharts/src/lib/tooltip/ATRText';
import { ConsolidationZones } from '../../libs/react-stockcharts/src/lib/ConsolidationZones';
import { Zigzag } from '../../libs/react-stockcharts/src/lib/Zigzag';
import { SuperTrend } from '../../libs/react-stockcharts/src/lib/supertrend';
import { DrawVolumeBucketLines } from '../../libs/react-stockcharts/src/lib/DrawVolumeBucketLines';
import { DrawSRLines } from '../../libs/react-stockcharts/src/lib/DrawSRLines';

/**
 * Only for dev
 */
// import useWhyDidYouUpdate from '../useWhyDidYouUpdate';

// import { searchIndicatorSettings } from './utils';

const CustomChart = (props) => {
	/**
	 * Pull data from props
	 */
	const { chartInstrument, data } = props;

	/**
	 * This allows me to see what caused this component to update.
	 */
	// useWhyDidYouUpdate('CustomChart', props);

	/**
	 * The suffix is used in identifying the chart and
	 * also for reloading the chart when zoom is reset.
	 */
	const node = useRef(null);
	const [suffix, setSuffix] = useState(1);
	const [fullResetSuffix, setFullResetSuffix] = useState(1);
	const xScale = useRef(null);
	const xExtentsMoveEvent = useRef([]);
	const yExtentsMoveEvent = useRef({});
	const xAccessor = useRef(null);
	const displayXAccessor = useRef(null);
	const indicators = useRef(null);
	const yExtents = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const chartData = useRef([]);

	/**
	 * this is a holder for our emas
	 */
	let calculatedIndicators = useRef(new Map());

	/**
	 * What makes these two calculatedindis different? They do not
	 * called the localyExtents or have a .accessor() function.
	 */
	let volCalculatedIndicators = useRef(new Map());
	let otherCalculatedIndicators = useRef(new Map());
	let movingAverageTooltipOptions = useRef([]);

	/**
	 * Get the chart size passed from props (chartsettings)
	 */
	// let dashboardSize = useRef(props.chartSettings.dashboardSize || 8);

	/**
	 * Things that need to be updated
	 */
	useEffect(() => {
		calculatedIndicators.current = new Map();
		volCalculatedIndicators.current = new Map();
		otherCalculatedIndicators.current = new Map();
		movingAverageTooltipOptions.current = [];
		xExtentsMoveEvent.current = [];
		yExtentsMoveEvent.current = {};
		xScale.current = null;
		yExtents.current = null;
		xAccessor.current = null;
		displayXAccessor.current = null;

		/**
		 * Force a redraw on resize
		 */
		// if (dashboardSize.current !== props.chartSettings.dashboardSize) {
		// 	dashboardSize.current = props.chartSettings.dashboardSize || 8;
		// 	handleReset();
		// }

		/**
		 * Run our calculation for the chart
		 */
		if (props?.data) runCalculation(props.data, {});
		setFullResetSuffix((s) => s + 1);
	}, [chartInstrument, props.chartSettings.indicators]);

	/**
	 * Our data has changed!
	 */
	useEffect(() => {
		if (!isLoaded) return;

		/**
		 * This is a one liner to find the difference between
		 * two array of objects
		 *
		 * Keywords (for search later): difference array of objects different compare arrays
		 */
		const candleDifference = data.filter(
			({ dateMS: dateMS1, close: close1 }) =>
				!chartData.current.some(({ dateMS: dateMS2, close: close2 }) => dateMS1 === dateMS2 && close1 === close2)
		);

		/**
		 * Don't do anything if there is no difference.
		 *
		 *
		 * Removed. This was preventing the graph from updating when no new candle values
		 * were sent through (IE: a new indicator was added).
		 *
		 * 2021-12-27 14:04:11 JD
		 */
		// if (candleDifference.length <= 0) return;

		// console.log('Difference:', candleDifference.length, 'New Data Length:', data.length, 'xExtentsMoveEvent current:', xExtentsMoveEvent.current);

		/**
		 * Was there a big difference?
		 */
		if (candleDifference.length > 5 && xExtentsMoveEvent.current.length === 2) {
			/**
			 * Move both values forward by the difference
			 */
			xExtentsMoveEvent.current = [xExtentsMoveEvent.current[0] + candleDifference.length, xExtentsMoveEvent.current[1] + candleDifference.length];

			// console.log('New xextents', moreDataXExtents.current);
		} else {
			/**
			 * Difference is small.
			 */
			// console.log('Difference is small, no update.');
		}

		/**
		 * Find the index where these are different
		 */
		// const startIndex = chartData.current.findIndex((candleElem) => candleElem.dateMS === candleDifference[0].dateMS);

		/**
		 * This was abandonded.
		 */
		runCalculation(data, {});

		setSuffix((s) => s + 1);
	}, [data]);

	/**
	 * Trigger a reload on new timePeriod data
	 */
	useEffect(() => {
		// console.log('new timePeriod');
		setSuffix((s) => s + 1);
	}, [props.timePeriod]);

	/**
	 * In order to use a ref with useEffect, you have
	 * to useCallback instead and wait until it is called.
	 *
	 * https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
	 */
	const nodeRef = useCallback((nodeElem) => {
		if (nodeElem !== null) {
			node.current = nodeElem;

			/**
			 *
			 * Subscribe to updates
			 */
			node.current.subscribe('subscribeUpdate', { listener: handleEvents });

			// Specify how to clean up after this effect:
			return function cleanup() {
				node.current.unsubscribe('subscribeUpdate');
			};
		}
	}, []);

	/**
	 * This is used for subscribing to updates, up above.
	 *
	 *
	 * @param {*} type
	 * @param {*} moreProps
	 * @param {*} state
	 */
	const handleEvents = (type, moreProps, state) => {
		// do fun stuff here :)
		// you could also do node.getDataInfo()
		// which returns some useful internal info, though
		// all this information should already be available in `moreProps` and `state`
		// you can get the xDomain as moreProps.xScale.domain() but I doubt
		// that would be useful for you if you use discontinuous scale
		// if you *do* use discontinuous scale I suggest you do
		// moreProps.plotData -> get the first and last value from this array
		// and use that to calculate the `xExtents` of your other chart
		if (moreProps?.xScale?.domain() && moreProps?.xScale?.domain() !== xExtentsMoveEvent.current)
			xExtentsMoveEvent.current = moreProps.xScale.domain();

		if (type === 'yAxisZoom' && moreProps?.chartConfig.length > 0) {
			// yExtentsMoveEvent.current = {
			// 	yPan: moreProps.chartConfig[0]
			// }
			// console.log(moreProps);
		}
		// console.log(type + ' (Node) Domain: ' + moreProps?.xScale?.domain() + ' (State) Domain: ' + state.xScale.domain());
	};

	const resetYDomain = () => {
		if (node?.current?.resetYDomain) node.current.resetYDomain();
	};

	const handleReset = () => resetYDomain();

	/**
	 * This gets the height of all of the indicators and/or the
	 * last outside ID.
	 *
	 * @param {str} type - both, height or id
	 */
	const getOutsideIndicatorHeight = (type = 'height') => {
		/**
		 * Get vars from props
		 */
		const { indicators, candleLoopDone } = props.chartSettings;

		/**
		 * This is to determine the height we need
		 * on the main chart when we add charts below it
		 * (IE: RSI, MacD, etc)
		 */
		let outsideChartHeightTotal = 0;

		/**
		 * Find the last outside chart ID.
		 */
		let lastOutsideChartID = -1;

		for (let i = 0; i < indicators.length; i++) {
			/**
			 * If nothing is found, continue on!
			 */
			if (indicators[i] == null) continue;

			/**
			 * Find the outside
			 */
			if (indicators[i].isOutside) {
				/**
				 * We only want to show this if we have the COT data
				 * loaded.
				 */
				if (candleLoopDone && indicators[i].type === 'cot') if (!props.data || !props.data.some((line) => line.cotData)) continue;

				/**
				 * Make the last outside chart ID this ID
				 */
				if (i > lastOutsideChartID) lastOutsideChartID = i;

				/**
				 * Increase our total height for outside indies.
				 */
				if (indicators[i].seriesArguments == null) indicators[i].seriesArguments = { height: 150 };
				outsideChartHeightTotal += indicators[i].seriesArguments.height;
			}
		}

		/**
		 * Return
		 */
		if (type === 'id') return lastOutsideChartID;
		if (type === 'both') return [lastOutsideChartID, outsideChartHeightTotal];
		return outsideChartHeightTotal;
	};

	/**
	 * This will loop through our indicator settings
	 * and return them for rendering on the screen.
	 */
	const renderIndicators = (indicatorData) => {
		if (!props.chartSettings) return '';

		// Extract our indicators
		let { indicators } = props.chartSettings;

		/**
		 * This is our consolidated JSX string
		 * holder.
		 */
		let jsxReturn = {
			insideChart: [],
			outsideChart: [],
		};

		/**
		 * Reset the moving average
		 */
		movingAverageTooltipOptions.current = [];

		/**
		 * Loop through here and find the last
		 * outside chart indicator. Also calculate
		 * the height of all outside chart indies.
		 */
		const [lastOutsideChartID, outsideChartHeightTotal] = getOutsideIndicatorHeight('both');

		/**
		 * This is a counter for the height of
		 * each indicator in sequence which is applied to
		 * subtractionHeight.
		 */
		let runningHeight = JSON.parse(JSON.stringify(outsideChartHeightTotal));
		let subtractionHeight = [];

		/**
		 * One more loop to calculate the
		 * subtraction height which should be the inverse
		 * (IE: the first indicator should have a higher
		 * subtraction than the bottom indicator. The bottom indi should
		 * be at the max height minus it's height)
		 */
		for (let i = 0; i < indicators.length; i++) {
			indicators[i] = getMatchingIndicator(indicators[i]);

			/**
			 * If nothing is found, continue on!
			 */
			if (indicators[i] == null) continue;

			/**
			 * Test if the indicator is outside or not.
			 */
			if (indicators[i].isOutside) {
				/**
				 * This is the subtraction height (y index) of
				 * an outside graph.
				 */
				subtractionHeight.push(runningHeight);
				runningHeight -= indicators[i].seriesArguments.height;
			} else subtractionHeight.push(0);
		}

		/**
		 * Run the actual loop.
		 */
		for (let i = 0; i < indicators.length; i++) {
			/**
			 * I couldn't get this to work dynamically with
			 * React.createElement so here it is semi manual.
			 */
			if (indicators[i].type === 'VolumeProfileSeries')
				jsxReturn.insideChart.push(<VolumeProfileSeries key={i} {...indicators[i].arguments} {...volumeProfileApperance} />);

			if (indicators[i].type === 'VolumeProfileLines') jsxReturn.insideChart.push(<DrawHorizontalVolumeLines key={i} {...indicators[i].arguments} />);

			/**
			 * Volume based range
			 *
			 * 2022-01-20 13:00:27 JD
			 */
			if (indicators[i].type === 'VolumeBucketRange') jsxReturn.insideChart.push(<DrawVolumeBucketLines key={i} {...indicators[i].arguments} />);

			/**
			 * S&R Lines
			 *
			 * 2022-02-15 12:05:26 JD
			 */
			if (indicators[i].type === 'S&R') jsxReturn.insideChart.push(<DrawSRLines key={i} {...indicators[i].arguments} />);

			/**
			 * Draw lines on the boxes created
			 *
			 * 2021-03-29 11:21:36 - JD
			 */
			if (indicators[i].type === 'ConsolidationZones') jsxReturn.insideChart.push(<ConsolidationZones key={i} {...indicators[i].arguments} />);

			/**
			 * Draw ZigZag lines
			 *
			 * 2021-05-25 15:00:07
			 */
			if (indicators[i].type === 'ZigZag')
				jsxReturn.insideChart.push(<Zigzag key={i} {...indicators[i].arguments} instrument={props.chartInstrument} />);

			/**
			 * Draw SuperTrend lines
			 *
			 * 2021-08-08 13:05:07
			 */
			if (indicators[i].type === 'SuperTrend')
				jsxReturn.insideChart.push(<SuperTrend key={i} {...indicators[i].arguments} instrument={props.chartInstrument} />);

			/**
			 * Oanda position book info
			 */
			if (indicators[i].type === 'positionbook' || indicators[i].type === 'orderbook')
				jsxReturn.insideChart.push(<PositionBookPriceCoordinateStore key={i} {...indicators[i].arguments} />);

			if (indicators[i].type === 'text') {
				if (indicators[i].id === 'LongShortText')
					jsxReturn.insideChart.push(<LongShortPercentText key={i} {...indicators[i].arguments} {...StaticValueToolTipAppearance} />);

				/**
				 * This is a rolling ATR average over the
				 * last 14 bars, displayed in pips.
				 */
				if (indicators[i].id === 'ATRText') {
					jsxReturn.insideChart.push(
						<ATRText key={i} {...indicators[i].arguments} {...StaticValueToolTipAppearance} instrument={props.chartInstrument} />
					);
				}

				/**
				 * COT Text
				 */
				if (indicators[i].id === 'COTLongShortText') {
					/**
					 * We only want to show this if we have the COT data
					 * loaded.
					 */
					if (!indicatorData || !indicatorData.some((line) => line.cotData)) continue;
					/**
					 * Get the net long/short data
					 */
					const lastBar = indicatorData?.[indicatorData.length - 1];
					const longData = Math.abs(lastBar.cotData.Pct_of_OI_Asset_Mgr_Long_All);
					const shortData = Math.abs(lastBar.cotData.Pct_of_OI_Asset_Mgr_Short_All);

					jsxReturn.insideChart.push(
						<GenericLongShortPercentText key={i} {...indicators[i].arguments} long={longData} short={shortData} {...StaticValueToolTipAppearance} />
					);
				}
			}

			/**
			 * Add any selected zones
			 */
			if (indicators[i].type === 'zone')
				jsxReturn.insideChart.push(<Zone key={Math.random().toString(36).substr(2, 7)} zones={indicators[i].arguments.data} />);

			/**
			 * These are our previous entries and exits
			 */
			if (indicators[i].type === 'SignalLines')
				jsxReturn.insideChart.push(<DrawPositions className="react-stockcharts-enable-interaction" key={Math.random().toString(36).substr(2, 7)} />);
			/**
			 * These are our previous entries and exits
			 */
			if (indicators[i].type === 'PositionCoordinates' && !props.chartSettings.backtesting)
				jsxReturn.insideChart.push(<PositionPriceCoordinateStore key={Math.random().toString(36).substr(2, 7)} />);

			/**
			 * These are our previous entries and exits
			 */
			if (indicators[i].type === 'TradesCoordinates' && !props.chartSettings.backtesting)
				jsxReturn.insideChart.push(<TradesPriceCoordinateStore key={Math.random().toString(36).substr(2, 7)} />);
			/**
			 * These are our previous entries and exits
			 */
			if (indicators[i].type === 'OrdersCoordinates' && !props.chartSettings.backtesting)
				jsxReturn.insideChart.push(<OrdersPriceCoordinateStore key={Math.random().toString(36).substr(2, 7)} />);

			/**
			 * Draw markers
			 */
			if (
				indicators[i].arguments?.showEntrySignals &&
				typeof indicators[i].arguments?.entryLongWhen === 'function' &&
				!props.chartSettings.backtesting
			) {
				let modifiedLongAnnotation = {
					...longAnnotation,
					onClick: console.log.bind(console),
					tooltip: (d) => `${indicators[i].label} @ ${d.close}`,
				};

				jsxReturn.insideChart.push(
					<Annotate
						with={LabelAnnotation}
						when={indicators[i].arguments.entryLongWhen}
						usingProps={modifiedLongAnnotation}
						arguments={indicators[i].arguments}
						key={Math.random().toString(36).substr(2, 7)}
					/>
				);
			}

			if (
				indicators[i].arguments?.showEntrySignals &&
				typeof indicators[i].arguments?.entryShortWhen === 'function' &&
				!props.chartSettings.backtesting
			) {
				let modifiedShortAnnotation = {
					...shortAnnotation,
					onClick: console.log.bind(console),
					tooltip: (d) => `${indicators[i].label} @ ${d.close}`,
				};

				jsxReturn.insideChart.push(
					<Annotate
						with={LabelAnnotation}
						when={indicators[i].arguments.entryShortWhen}
						usingProps={modifiedShortAnnotation}
						arguments={indicators[i].arguments}
						key={Math.random().toString(36).substr(2, 7)}
					/>
				);
			}

			/**
			 * macd
			 */
			if (indicators[i].type === 'macd') {
				/**
				 * Get the MACD function
				 */
				let functionHolder = macd()
					.options({
						fast: indicators[i].arguments.fast,
						slow: indicators[i].arguments.slow,
						signal: indicators[i].arguments.signal,
					})
					.merge((d, c) => {
						d[indicators[i].value] = c;
					})
					.accessor((d) => d[indicators[i].value]);

				/**
				 * Set the function in the map for later
				 */
				otherCalculatedIndicators.current.set(indicators[i].value, functionHolder);

				/**
				 * Get a reference to the newly created indicator
				 */
				let refIndicator = otherCalculatedIndicators.current.get(indicators[i].value);

				/**
				 * This is just the volume indicator,
				 */
				jsxReturn.outsideChart.push(
					<Chart
						key={`macdGraph-${indicators[i].value}`}
						id={`macdGraph-${indicators[i].value}${i}`}
						height={indicators[i].seriesArguments.height}
						yExtents={refIndicator.accessor()}
						origin={(w, h) => [0, h - subtractionHeight[i]]}
						padding={{ top: 10, bottom: 10 }}
					>
						<YAxis axisAt="right" orient="right" ticks={2} displayFormat={format('.5f')} {...yAxisAppearance} />
						<MouseCoordinateY at="right" orient="right" displayFormat={format('.5f')} {...mouseEdgeAppearanceY} />

						<XAxis axisAt="bottom" orient="bottom" {...xAxisAppearance} showTicks={lastOutsideChartID === i ? true : false} />
						{lastOutsideChartID === i ? (
							<MouseCoordinateX at="bottom" orient="bottom" displayFormat={timeFormat('%Y-%m-%d %H:%M')} {...mouseEdgeAppearanceX} />
						) : (
							''
						)}

						<MACDSeries yAccessor={(d) => d[indicators[i].value]} {...macdAppearance} />
						<MACDTooltip
							origin={[5, 25]}
							yAccessor={(d) => d[indicators[i].value]}
							options={refIndicator.options()}
							appearance={macdAppearance}
							{...macdAppearance}
							onClick={() => props.handleIndicatorEditClick(indicators[i].id)}
						/>
					</Chart>
				);
			}
			/**
			 * murreyMath
			 */
			if (indicators[i].type === 'ucsmath') {
				/**
				 * Get the MACD function
				 */
				let functionHolder = uscMurreyMath()
					.options(indicators[i].arguments)
					.merge((d, c) => {
						d[indicators[i].value] = c;
					})
					.accessor((d) => d[indicators[i].value]);

				/**
				 * Set the function in the map for later
				 */
				otherCalculatedIndicators.current.set(indicators[i].value, functionHolder);

				/**
				 * Get a reference to the newly created indicator
				 */
				let refIndicator = otherCalculatedIndicators.current.get(indicators[i].value);

				/**
				 * This is just the volume indicator,
				 */
				jsxReturn.outsideChart.push(
					<Chart
						key={`ucsmath-${indicators[i].value}`}
						id={`ucsmath-${indicators[i].value}${i}`}
						height={indicators[i].seriesArguments.height}
						yExtents={refIndicator.accessor()}
						origin={(w, h) => [0, h - subtractionHeight[i]]}
						padding={{ top: 10, bottom: 10 }}
					>
						<YAxis axisAt="right" orient="right" ticks={2} displayFormat={format('.5f')} {...yAxisAppearance} />
						<MouseCoordinateY at="right" orient="right" displayFormat={format('.5f')} {...mouseEdgeAppearanceY} />

						<XAxis axisAt="bottom" orient="bottom" {...xAxisAppearance} showTicks={lastOutsideChartID === i ? true : false} />
						{lastOutsideChartID === i ? (
							<MouseCoordinateX at="bottom" orient="bottom" displayFormat={timeFormat('%Y-%m-%d %H:%M')} {...mouseEdgeAppearanceX} />
						) : (
							''
						)}

						<GenericSeries {...genericSeriesAppearance} {...indicators[i].arguments} yAccessorDivergence={refIndicator.accessor()} />
						<GenericSeriesTooltip
							origin={[5, 20]}
							{...genericSeriesAppearance}
							{...indicators[i].arguments}
							onClick={() => props.handleIndicatorEditClick(indicators[i].id)}
						/>
					</Chart>
				);
			}

			/**
			 * RSI
			 */
			if (indicators[i].type === 'rsi') {
				/**
				 * Get the MACD function
				 */
				let functionHolder = rsi()
					.options({ windowSize: indicators[i].arguments.length })
					.merge((d, c) => {
						d[indicators[i].value] = c;
					})
					.accessor((d) => d[indicators[i].value]);

				/**
				 * Set the function in the map for later
				 */
				otherCalculatedIndicators.current.set(indicators[i].value, functionHolder);

				/**
				 * Get a reference to the newly created indicator
				 */
				let refIndicator = otherCalculatedIndicators.current.get(indicators[i].value);

				/**
				 * This is just the volume indicator,
				 */
				jsxReturn.outsideChart.push(
					<Chart
						key={`rsi-${indicators[i].value}`}
						id={`rsi-${indicators[i].value}${i}`}
						height={indicators[i].seriesArguments.height}
						yExtents={refIndicator.accessor()}
						origin={(w, h) => [0, h - subtractionHeight[i]]}
						padding={{ top: 10, bottom: 10 }}
					>
						<YAxis axisAt="right" orient="right" ticks={2} displayFormat={format('.5f')} {...yAxisAppearance} />
						<MouseCoordinateY at="right" orient="right" displayFormat={format('.5f')} {...mouseEdgeAppearanceY} />

						<XAxis axisAt="bottom" orient="bottom" {...xAxisAppearance} showTicks={lastOutsideChartID === i ? true : false} />
						{lastOutsideChartID === i ? (
							<MouseCoordinateX at="bottom" orient="bottom" displayFormat={timeFormat('%Y-%m-%d %H:%M')} {...mouseEdgeAppearanceX} />
						) : (
							''
						)}

						<RSISeries yAccessor={(d) => d[indicators[i].value]} />
						<RSITooltip
							origin={[5, 20]}
							yAccessor={(d) => d[indicators[i].value]}
							options={refIndicator.options()}
							{...rsiToolTipApperance}
							onClick={() => props.handleIndicatorEditClick(indicators[i].id)}
						/>
					</Chart>
				);
			}

			/**
			 * atr
			 */
			if (indicators[i].type === 'ATR') {
				/**
				 * Get the RSI
				 */
				let functionHolder = atr()
					.options({ windowSize: indicators[i].arguments.length })
					.merge((d, c) => {
						d[indicators[i].value] = c;
					})
					.accessor((d) => d[indicators[i].value]);

				/**
				 * Set the function in the map for later
				 */
				otherCalculatedIndicators.current.set(indicators[i].value, functionHolder);

				/**
				 * Get a reference to the newly created indicator
				 */
				let refIndicator = otherCalculatedIndicators.current.get(indicators[i].value);

				/**
				 * This is just the volume indicator,
				 */
				jsxReturn.outsideChart.push(
					<Chart
						key={`atr-${indicators[i].value}`}
						id={`atr-${indicators[i].value}${i}`}
						height={indicators[i].seriesArguments.height}
						yExtents={refIndicator.accessor()}
						origin={(w, h) => [0, h - subtractionHeight[i]]}
						padding={{ top: 10, bottom: 10 }}
					>
						<YAxis axisAt="right" orient="right" displayFormat={getFormat(props.chartInstrument)} {...yAxisAppearance} ticks={2} />
						<MouseCoordinateY at="right" orient="right" displayFormat={getFormat(props.chartInstrument)} {...mouseEdgeAppearanceY} />

						<XAxis axisAt="bottom" orient="bottom" {...xAxisAppearance} showTicks={lastOutsideChartID === i ? true : false} />
						{lastOutsideChartID === i ? (
							<MouseCoordinateX at="bottom" orient="bottom" displayFormat={timeFormat('%Y-%m-%d %H:%M')} {...mouseEdgeAppearanceX} />
						) : (
							''
						)}

						<LineSeries yAccessor={refIndicator.accessor()} {...indicators[i].arguments} />
						<SingleValueTooltip
							yAccessor={refIndicator.accessor()}
							yLabel={`ATR (${refIndicator.options().windowSize})`}
							yDisplayFormat={getFormat(props.chartInstrument)}
							labelFill="#fff"
							valueFill={refIndicator.stroke()}
							origin={[0, 15]}
							{...indicators[i].arguments}
							onClick={() => props.handleIndicatorEditClick(indicators[i].id)}
						/>
					</Chart>
				);
			}

			/**
			 * COT
			 */
			if (indicators[i].type === 'cot') {
				/**
				 * We only want to show this if we have the COT data
				 * loaded.
				 */
				if (!indicatorData || !indicatorData.some((line) => line.cotData)) continue;

				/**
				 * This is just the volume indicator,
				 */
				jsxReturn.outsideChart.push(
					<Chart
						key={`cot-${indicators[i].value}`}
						id={`cot-${indicators[i].value}${i}`}
						height={indicators[i].seriesArguments.height}
						yExtents={(d) => {
							if (indicators[i].arguments.usePercent)
								return d.cotData && d.cotData.netDealerPct
									? [d.cotData.netDealerPct, d.cotData.netInstitutionalPct, d.cotData.netLeveragedPct, d.cotData.netOtherPct]
									: 0;
							else
								return d.cotData && d.cotData.netDealer
									? [d.cotData.netDealer, d.cotData.netInstitutional, d.cotData.netLeveraged, d.cotData.netOther]
									: 0;
						}}
						origin={(w, h) => [0, h - subtractionHeight[i]]}
						padding={{ top: 10, bottom: 10 }}
					>
						<YAxis axisAt="right" orient="right" ticks={2} displayFormat={format('.5f')} {...yAxisAppearance} />
						<MouseCoordinateY at="right" orient="right" displayFormat={format('.5f')} {...mouseEdgeAppearanceY} />

						<XAxis axisAt="bottom" orient="bottom" {...xAxisAppearance} showTicks={lastOutsideChartID === i ? true : false} />
						{lastOutsideChartID === i ? (
							<MouseCoordinateX at="bottom" orient="bottom" displayFormat={timeFormat('%Y-%m-%d %H:%M')} {...mouseEdgeAppearanceX} />
						) : (
							''
						)}

						<COTSeries {...cotAppearance} {...indicators[i].arguments} />
						<COTTooltip
							origin={[5, 20]}
							{...cotAppearance}
							{...indicators[i].arguments}
							onClick={() => props.handleIndicatorEditClick(indicators[i].id)}
						/>
					</Chart>
				);
			}

			/**
			 * Generic Series
			 */
			if (indicators[i].type === 'genericSeries') {
				/**
				 * We only want to show this if we have the actual data
				 * loaded.
				 */
				if (!indicatorData || !indicatorData.some((line) => line[indicators[i].arguments.fetchName])) continue;

				/**
				 * This is just the volume indicator,
				 */
				jsxReturn.outsideChart.push(
					<Chart
						key={`${indicators[i].arguments.fetchName}-${indicators[i].value}`}
						id={`${indicators[i].arguments.fetchName}-${indicators[i].value}${i}`}
						height={indicators[i].seriesArguments.height}
						yExtents={(d) => {
							/**
							 * If the user passed an extents function, send it back instead.
							 */
							if (typeof indicators[i].arguments.yExtents === 'function') return indicators[i].arguments.yExtents(d, indicators[i].arguments);

							/**
							 * Otherwise, just map the items out.
							 */
							return d[indicators[i].arguments.fetchName] && d[indicators[i].arguments.fetchName].value
								? [d[indicators[i].arguments.fetchName].allData.map((item) => item.value)]
								: 0;
						}}
						origin={(w, h) => [0, h - subtractionHeight[i]]}
						padding={{ top: 10, bottom: 10 }}
					>
						<YAxis axisAt="right" orient="right" ticks={2} displayFormat={format('.5f')} {...yAxisAppearance} />
						<MouseCoordinateY at="right" orient="right" displayFormat={format('.5f')} {...mouseEdgeAppearanceY} />

						<XAxis axisAt="bottom" orient="bottom" {...xAxisAppearance} showTicks={lastOutsideChartID === i ? true : false} />
						{lastOutsideChartID === i ? (
							<MouseCoordinateX at="bottom" orient="bottom" displayFormat={timeFormat('%Y-%m-%d %H:%M')} {...mouseEdgeAppearanceX} />
						) : (
							''
						)}

						<GenericSeries {...genericSeriesAppearance} {...indicators[i].arguments} />
						<GenericSeriesTooltip
							origin={[5, 20]}
							{...genericSeriesAppearance}
							{...indicators[i].arguments}
							onClick={() => props.handleIndicatorEditClick(indicators[i].id)}
						/>
					</Chart>
				);
			}

			/**
			 * Volume indicator
			 */
			if (indicators[i].type === 'Volume' || indicators[i].type === 'vSMA50') {
				/**
				 * Create our voly yExtents
				 */
				let volYExtents = [];
				volYExtents.push((d) => d.volume);

				/**
				 * This is the function holder for the
				 * volume SMA JSX
				 */
				let volumeJSX = [];

				/**
				 * This is the SMA 50 on top of the volume
				 */
				if (indicators[i].type === 'vSMA50') {
					let functionHolder = sma()
						.options({ windowSize: 20, sourcePath: 'volume' })
						.merge((d, c) => {
							d[indicators[i].value] = c;
						})
						.accessor((d) => d[indicators[i].value])
						.stroke(chartColors.lightBlue)
						.fill(chartColors.grayBlueFadedTradingVue);

					/**
					 * Set the function in the map for later
					 */
					volCalculatedIndicators.current.set(indicators[i].value, functionHolder);

					/**
					 * Get a reference to the newly created indicator
					 */
					let refIndicator = volCalculatedIndicators.current.get(indicators[i].value);

					/**
					 * Push the new yExtents
					 */
					volYExtents.push(refIndicator.accessor());

					/**
					 * Push all of the needed info into the volume
					 * JSX holder.
					 */
					volumeJSX.push(
						<AreaSeries key="volAreaSeries1" yAccessor={refIndicator.accessor()} stroke={refIndicator.stroke()} fill={refIndicator.fill()} />
					);
					volumeJSX.push(<CurrentCoordinate key="volCurrentCoordinate1" yAccessor={(d) => d.volume} fill={chartColors.dark} />);
					volumeJSX.push(<CurrentCoordinate key="volCurrentCoordinate2" yAccessor={refIndicator.accessor()} fill={refIndicator.stroke()} />);
				}

				/**
				 * This is just the volume indicator,
				 */
				jsxReturn.outsideChart.push(
					<Chart
						key={`volumegraf-${indicators[i].value}`}
						id={`volumegraf-${indicators[i].value}${i}`}
						yExtents={volYExtents}
						height={indicators[i].seriesArguments.height}
						origin={(w, h) => [0, h - outsideChartHeightTotal - indicators[i].seriesArguments.height]}
					>
						<YAxis axisAt={40} orient="left" ticks={5} tickFormat={format('.2s')} {...yAxisAppearance} />
						<MouseCoordinateY at="45" orient="left" displayFormat={format('.4s')} {...mouseEdgeAppearanceY} />
						<BarSeries yAccessor={(d) => d.volume} {...volumeApperance} />
						{volumeJSX}
					</Chart>
				);
			}

			/**
			 * Open Interest Volume indicator
			 */
			if (indicators[i].type === 'openInterestVolume') {
				/**
				 * Create our voly yExtents
				 */
				let volYExtents = [];
				volYExtents.push((d) => (d.cotData ? d.cotData.Open_Interest_All : 0));

				/**
				 * This is the function holder for the
				 * volume SMA JSX
				 */
				let volumeJSX = [];

				let functionHolder = sma()
					.options({ windowSize: 20, sourcePath: 'cotData.Open_Interest_All' })
					.merge((d, c) => {
						d[indicators[i].value] = c;
					})
					.accessor((d) => d[indicators[i].value])
					.stroke(chartColors.lightBlue)
					.fill(chartColors.grayBlueFadedTradingVue);

				/**
				 * Set the function in the map for later
				 */
				volCalculatedIndicators.current.set(indicators[i].value, functionHolder);

				/**
				 * Get a reference to the newly created indicator
				 */
				let refIndicator = volCalculatedIndicators.current.get(indicators[i].value);

				/**
				 * Push the new yExtents
				 */
				volYExtents.push(refIndicator.accessor());

				/**
				 * Push all of the needed info into the volume
				 * JSX holder.
				 */
				volumeJSX.push(
					<AreaSeries key="oivolAreaSeries1" yAccessor={refIndicator.accessor()} stroke={refIndicator.stroke()} fill={refIndicator.fill()} />
				);
				volumeJSX.push(<CurrentCoordinate key="oivolCurrentCoordinate1" yAccessor={(d) => d.volume} fill={chartColors.dark} />);
				volumeJSX.push(<CurrentCoordinate key="oivolCurrentCoordinate2" yAccessor={refIndicator.accessor()} fill={refIndicator.stroke()} />);

				/**
				 * This is just the volume indicator,
				 */
				jsxReturn.outsideChart.push(
					<Chart
						key={`openinterestvolumegraf-${indicators[i].value}`}
						id={`openinterestvolumegraf-${indicators[i].value}${i}`}
						yExtents={volYExtents}
						height={indicators[i].seriesArguments.height}
						origin={(w, h) => [0, h - outsideChartHeightTotal - indicators[i].seriesArguments.height]}
					>
						<YAxis axisAt={40} orient="left" ticks={5} tickFormat={format('.2s')} {...yAxisAppearance} />
						<MouseCoordinateY at="45" orient="left" displayFormat={format('.4s')} {...mouseEdgeAppearanceY} />
						<BarSeries yAccessor={(d) => (d.cotData ? d.cotData.Open_Interest_All : 0)} {...volumeApperance} />
						{volumeJSX}
					</Chart>
				);
			}

			/**
			 * BBands
			 */
			if (indicators[i].type === 'BollingerBand') {
				let functionHolder = bollingerBand()
					.options({
						windowSize: indicators[i].arguments.length,
						multiplier: indicators[i].arguments.stdDev,
						movingAverageType: indicators[i].arguments.movingAverageType,
						sessionType: indicators[i].arguments.sessionType,
						additionalMultipliers: indicators[i].arguments.additionalMultipliers,
					})
					.merge((d, c) => {
						d[indicators[i].value] = c;
					})
					.accessor((d) => d[indicators[i].value]);

				/**
				 * Set the function in the map for later
				 */
				calculatedIndicators.current.set(indicators[i].value, functionHolder);

				/**
				 * Get a reference to the newly created indicator
				 */
				const refIndicator = calculatedIndicators.current.get(indicators[i].value);

				/**
				 * Push this to our chart array
				 */
				jsxReturn.insideChart.push(
					<BollingerSeries
						yAccessor={refIndicator.accessor()}
						key={`bollingerBands${indicators[i].arguments.length}${Math.random().toString(36).substr(2, 7)}`}
						{...indicators[i].seriesArguments}
						{...indicators[i].arguments}
					/>
				);

				jsxReturn.insideChart.push(
					<BollingerBandTooltip
						origin={[10, 50]}
						yAccessor={refIndicator.accessor()}
						options={functionHolder.options()}
						{...indicators[i].tooltipArguments}
						key={`bollingerBandsToolTip${indicators[i].arguments.length}${Math.random().toString(36).substr(2, 7)}`}
						onClick={() => props.handleIndicatorEditClick(indicators[i].id)}
					/>
				);
			}

			/**
			 * EMAs
			 *
			 */
			if (indicators[i].type === 'EMA' || indicators[i].type === 'SMA' || indicators[i].type === 'WMA' || indicators[i].type === 'TMA') {
				/**
				 * This is just a function place holder
				 */
				let functionHolder = null;

				/**
				 * Create the moving average in an array
				 */
				if (indicators[i].type === 'EMA') {
					functionHolder = ema()
						.options({ windowSize: indicators[i].arguments.length })
						.merge((d, c) => {
							d[indicators[i].value] = c;
						})
						.accessor((d) => d[indicators[i].value])
						.stroke(indicators[i].arguments.stroke);
				} else if (indicators[i].type === 'SMA') {
					functionHolder = sma()
						.options({ windowSize: indicators[i].arguments.length })
						.merge((d, c) => {
							d[`${indicators[i].value}`] = c;
						})
						.accessor((d) => d[`${indicators[i].value}`])
						.stroke(indicators[i].arguments.stroke);
				} else if (indicators[i].type === 'WMA') {
					functionHolder = wma()
						.options({ windowSize: indicators[i].arguments.length })
						.merge((d, c) => {
							d[`${indicators[i].value}`] = c;
						})
						.accessor((d) => d[`${indicators[i].value}`])
						.stroke(indicators[i].arguments.stroke);
				} else if (indicators[i].type === 'TMA') {
					functionHolder = tma()
						.options({ windowSize: indicators[i].arguments.length })
						.merge((d, c) => {
							d[`${indicators[i].value}`] = c;
						})
						.accessor((d) => d[`${indicators[i].value}`])
						.stroke(indicators[i].arguments.stroke);
				}

				/**
				 * Set the function in the map for later
				 */
				calculatedIndicators.current.set(indicators[i].value, functionHolder);

				/**
				 * Get a reference to the newly created indicator
				 */
				let refIndicator = calculatedIndicators.current.get(indicators[i].value);

				/**
				 * Push this to our chart array
				 */
				jsxReturn.insideChart.push(
					<LineSeries
						key={`emaLineSeries${indicators[i].arguments.length}${Math.random().toString(36).substr(2, 7)}`}
						yAccessor={refIndicator.accessor()}
						stroke={refIndicator.stroke()}
						{...indicators[i].seriesArguments}
					/>
				);
				jsxReturn.insideChart.push(
					<CurrentCoordinate
						key={`emaCoordinate${indicators[i].arguments.length}${Math.random().toString(36).substr(2, 7)}`}
						yAccessor={refIndicator.accessor()}
						fill={refIndicator.stroke()}
					/>
				);

				/**
				 * Add it to our moving average tooltip
				 */
				movingAverageTooltipOptions.current.push({
					yAccessor: refIndicator.accessor(),
					type: refIndicator.type(),
					stroke: refIndicator.stroke(),
					windowSize: refIndicator.options().windowSize,
					onClick: () => props.handleIndicatorEditClick(indicators[i].id),
					echo: 'some echo here',
				});
			}

			/**
			 * Execute any signals here
			 */
			// if (indicators[i]?.arguments?.entrySignals && typeof indicators[i]?.arguments?.entrySignals === 'function')
			// otherCalculatedIndicators.current.set('entryExitMarkers', (data, other) => indicators[i].arguments.entrySignals(data, indicators[i]));
		}

		/**
		 * Send it back!
		 */
		return jsxReturn;
	};

	const renderChartType = () => {
		let { chartType } = props.chartSettings;

		switch (chartType) {
			case 'Renko':
				return <RenkoSeries />;
			// case 'Area':
			// 	return <AreaOnlySeries />;
			// case 'StackedBars':
			// 	return <StackedBarSeries />;
			// case 'GroupedBars':
			// 	return <GroupedBarSeries />;
			// case 'Line':
			// 	return <LineSeries />;
			case 'Kagi':
				return <KagiSeries {...KagiAppearance} />;
			case 'PointFigure':
				return <PointAndFigureSeries />;
			case 'OHLC':
				return <OHLCSeries {...OHLCAppearance} />;
			// case 'Scatter':
			// 	return <ScatterSeries />;
			// case 'AlternatingArea':
			// 	return <AlternatingFillAreaSeries />;
			default:
				return <CandlestickSeries {...candlesAppearance} />;
		}
	};

	/**
	 * This will render annotations for
	 * signals provided.
	 */
	const renderSignalAnnotations = () => {
		let modifiedLongAnnotation = {
			...longAnnotation,
			onClick: console.log.bind(console),
			tooltip: (d) => `${d.entry.quantity} @ ${d.entry.price}`,
		};
		let modifiedShortAnnotation = {
			...shortAnnotation,
			onClick: console.log.bind(console),
			tooltip: (d) => `${d.entry.quantity} @ ${d.entry.price}`,
		};
		let modifiedTakeProfitFromShortAnnotation = {
			...takeProfitFromShortAnnotation,
			onClick: console.log.bind(console),
			tooltip: (d) => `$${rawFloatFormat(d.exit.profit)} (${d.exit.pips} pips) @ $${d.exit.price}`,
		};
		let modifiedTakeProfitFromLongAnnotation = {
			...takeProfitFromLongAnnotation,
			onClick: console.log.bind(console),
			tooltip: (d) => `$${rawFloatFormat(d.exit.profit)} (${d.exit.pips} pips) @ $${d.exit.price}`,
		};

		return (
			<>
				<Annotate with={LabelAnnotation} when={(d) => d.entry && d.entry.posInt === 1} usingProps={modifiedLongAnnotation} />
				<Annotate with={LabelAnnotation} when={(d) => d.entry && d.entry.posInt === -1} usingProps={modifiedShortAnnotation} />
				<Annotate with={LabelAnnotation} when={(d) => d.exit && d.exit.posInt === -1} usingProps={modifiedTakeProfitFromShortAnnotation} />
				<Annotate with={LabelAnnotation} when={(d) => d.exit && d.exit.posInt === 1} usingProps={modifiedTakeProfitFromLongAnnotation} />
			</>
		);
	};

	const renderBidAskLines = () => {
		if (chartData?.current?.[chartData.current.length - 1]?.bid?.c == null) return;

		return (
			<>
				<EdgeIndicator
					{...edgeBidIndicatorAppearance}
					itemType="last"
					orient="right"
					edgeAt="right"
					yAccessor={() => chartData.current[chartData.current.length - 1].bid.c}
					displayFormat={getFormat(props.chartInstrument)}
				/>
				<EdgeIndicator
					{...edgeAskIndicatorAppearance}
					itemType="last"
					orient="right"
					edgeAt="right"
					yAccessor={() => chartData.current[chartData.current.length - 1].ask.c}
					displayFormat={getFormat(props.chartInstrument)}
				/>
			</>
		);
	};

	/**
	 * The user wants to load more candles, load more by calling
	 * the load more function of the parent.
	 */
	const handleLoadMore = async (start, end) => {
		if (Math.ceil(start) === end) return;

		/**
		 * Get the data
		 */
		const newCandles = await props.handleLoadMore(start, end, chartData.current);

		/**
		 * No data :(
		 */
		if (!newCandles || newCandles?.length <= 0) return;

		/**
		 * Adjust our xExtents
		 */
		// let rowsToDownload = end - Math.ceil(start);

		/**
		 * We have data, now send it to be calculated
		 */
		// setTimeout(async () => {
		// await runCalculation(newCandles, { loadMore: true, start, end });

		/**
		 * It's loaded!
		 *
		 * We do not need to redraw. Redrawing will reset the extents!
		 */
		// setSuffix(suffix + 1);
		// }, 5000);
	};

	/**
	 * This run the calculation and creates all of the indicators
	 * for the chart.
	 *
	 * @param {obj} candleData - the candlestick data
	 * @param {obj} params
	 * 	@param {bool} append - for adding new data to the end
	 * 	@param {bool} loadMore - true if sending more data in.
	 * 	@param {int} start - only for loadmore
	 * 	@param {int} end - only for loadmore
	 */
	const runCalculation = (candleData, { append = false, loadMore = false, start = 0, end = 0, startIndex = -1 }) => {
		// Extract our indicators
		// let { indicators } = props.chartSettings;

		/**
		 * Setup the indicators
		 */
		const jsxIndicators = renderIndicators(candleData);

		/**
		 * We have to calculate the indicators over
		 * the initial data (the bars)
		 */
		let calculatedData = candleData;
		let localYExtents = [];

		/**
		 * Push a default into yExtents
		 */
		localYExtents.push((d) => [d.high, d.low]);

		/**
		 * If the length is more than 0, loop through it
		 * otherwise, the calculatedData is just the
		 * initial data.
		 */
		calculatedIndicators?.current?.forEach((indicatorFunction, k) => {
			// Supposedly 50% faster than for
			// The indicatorFunction is the "value" in value / key
			calculatedData = indicatorFunction(calculatedData);
			localYExtents.push(indicatorFunction.accessor());
		});

		/**
		 * We do the same for the volume indicators
		 */
		volCalculatedIndicators?.current?.forEach((indicatorFunction) => (calculatedData = indicatorFunction(calculatedData)));
		otherCalculatedIndicators?.current?.forEach((indicatorFunction) => (calculatedData = indicatorFunction(calculatedData)));

		/**
		 * Caclulate our index
		 */
		let indexCalculator, xScaleProvider, xScaleProviderData;

		/**
		 * Loading more, we need to add to the beginning
		 */
		if (loadMore) {
			let rowsToDownload = end - Math.ceil(start);

			indexCalculator = discontinuousTimeScaleProviderBuilder().initialIndex(Math.ceil(start)).indexCalculator();
			let { index } = indexCalculator(calculatedData.concat(chartData.current));
			xScaleProvider = discontinuousTimeScaleProviderBuilder().initialIndex(Math.ceil(start)).withIndex(index);
			xScaleProviderData = xScaleProvider(calculatedData.slice(-rowsToDownload).concat(chartData.current));
		} else if (append) {
			/**
			 * This was abandonded.
			 */

			/**
			 * For appending, we are adding to the new (new candles)
			 */
			indexCalculator = discontinuousTimeScaleProviderBuilder().initialIndex(Math.ceil(startIndex)).indexCalculator();
			let { index } = indexCalculator(chartData.current.concat(calculatedData));
			xScaleProvider = discontinuousTimeScaleProviderBuilder().initialIndex(Math.ceil(startIndex)).withIndex(index);
			xScaleProviderData = xScaleProvider(chartData.current.concat(calculatedData));
		} else {
			indexCalculator = discontinuousTimeScaleProviderBuilder().indexCalculator();
			let { index } = indexCalculator(calculatedData);
			xScaleProvider = discontinuousTimeScaleProviderBuilder().withIndex(index);
			xScaleProviderData = xScaleProvider(calculatedData);
		}

		/**
		 * Set this in state
		 */
		if (xScaleProviderData.data.length > 0) {
			indicators.current = jsxIndicators;
			xScale.current = xScaleProviderData.xScale;
			xAccessor.current = xScaleProviderData.xAccessor;
			chartData.current = xScaleProviderData.data;
			displayXAccessor.current = xScaleProviderData.displayXAccessor;
			yExtents.current = localYExtents;
		}

		/**
		 * It's loaded!
		 */
		// if (!loadMore)
		if (!isLoaded) setIsLoaded(true);
		else {
			// if (node.current) {
			// node.current.redraw();
			// node.current.draw({ force: true });
			// }
		}
	};

	const renderChart = () => {
		const { zoomAnchor, timePeriod, toolbarHeight } = props;

		/**
		 * Moved out of the main due to constant re-render.
		 */
		const margin = { left: 0, right: 70, top: 10, bottom: 30 };

		/**
		 * Since we have a toolbar, we have to subtract the
		 * height of it from below.
		 */
		let height = props.height - toolbarHeight;

		/**
		 * Get our outside height count
		 */
		const [lastOutsideChartID, outsideChartHeightTotal] = getOutsideIndicatorHeight('both');

		/**
		 * Grid data
		 */
		const gridHeight = height - margin.top - margin.bottom;
		const gridWidth = props.width - margin.left - margin.right;

		const showGrid = false;
		//const yGrid = showGrid ? { innerTickSize: -1 * gridWidth, tickStrokeOpacity: 0.2 } : {};
		//const xGrid = showGrid ? { innerTickSize: -1 * gridHeight, tickStrokeOpacity: 0.2 } : {};
		const yGrid = showGrid
			? {
					innerTickSize: -1 * gridWidth,
					tickStrokeDasharray: 'Solid',
					tickStrokeOpacity: 0.2,
					tickStrokeWidth: 1,
			  }
			: {};
		const xGrid = showGrid
			? {
					innerTickSize: -1 * gridHeight,
					tickStrokeDasharray: 'Solid',
					tickStrokeOpacity: 0.2,
					tickStrokeWidth: 1,
			  }
			: {};

		/**
		 * Some personal notes on last and xAccessor.
		 *
		 * Last starts at the LAST index of the data array and returns
		 *   the first working object.
		 *
		 * xAccessor returns the index, that is it. So with 10,000 candles (0 start),
		 *   xAccessor(last()) returns 9999.
		 *
		 * You could use this to return last week, last month, etc.
		 *
		 * The comments to the right are the original functions used.
		 *
		 */

		// COMMENTED OUT HERE
		// let start = data.length > 65 ? data.length - 65 : 0; //xAccessor(last(data));
		let start = null;

		// /*
		//  * Depending on what the user has selected, give us a start/end.
		//  */
		if (timePeriod != null) {
			/**
			 * 3 months. Changing the state here will cause the
			 * component to re-render but we prevent that above
			 *
			 * 3 months in ms = 7889400000
			 *
			 * timePeriod will be MS in time, like the above.
			 */
			const startIndex = findDataIndexClosestTime(chartData.current, chartData.current[chartData.current.length - 1].dateMS - timePeriod);

			/**
			 * Assign the value if it isn't null.
			 */
			start = startIndex != null ? startIndex : null;

			/**
			 * Reset the time period
			 */
			if (props.handleTimePeriodReset) props.handleTimePeriodReset();
		}

		/**
		 * We use a percentage here so that we don't have huge margines when
		 * 1D vs no margin when 1y.
		 *
		 * -- Old: Why the 15? This gives it a margin to the right
		 */
		// COMMENTED OUT HERE
		let end = data.length + Math.ceil((data.length - start) * 0.3); // data.length + 15; //xAccessor(data[Math.max(0, data.length - 150)]);
		let finalXExtents = start != null ? [start, end] : null;

		/**
		 * If we had a major data change, we should have the
		 * eXtents locked in here.
		 */
		// if (moreDataXExtents.current != null) {
		// 	finalXExtents = moreDataXExtents.current;
		// 	moreDataXExtents.current = null;
		// }
		if (finalXExtents == null && xExtentsMoveEvent.current.length === 2) finalXExtents = xExtentsMoveEvent.current;
		else if (finalXExtents != null) xExtentsMoveEvent.current = finalXExtents;

		/**
		 * Calculate the height of the first chart
		 */
		let firstChartHeight = height - outsideChartHeightTotal - 38; // Wtf is this 38 you ask? It is the guestamation height of the bottom yAxis.

		return (
			<ChartCanvas
				zoomMultiplier={1.05}
				ref={nodeRef}
				height={height}
				ratio={window.devicePixelRatio}
				width={props.width}
				margin={margin}
				mouseMoveEvent={true}
				panEvent={true}
				zoomEvent={true}
				clamp={false}
				zoomAnchor={zoomAnchor}
				type="hybrid"
				seriesName={`${props.chartInstrument}_${suffix}`}
				fullReset={`${props.chartInstrument}_${fullResetSuffix}`}
				data={chartData.current}
				xScale={xScale.current}
				xExtents={finalXExtents != null ? finalXExtents : undefined}
				xAccessor={xAccessor.current}
				displayXAccessor={displayXAccessor.current}
				onLoadMore={handleLoadMore}
			>
				<Chart id={1} yExtents={yExtents.current} height={firstChartHeight}>
					<XAxis axisAt="bottom" orient="bottom" {...xGrid} {...xAxisAppearance} showTicks={lastOutsideChartID === -1 ? true : false} />
					<YAxis
						axisAt="right"
						orient="right"
						tickFormat={getFormat(props.chartInstrument)}
						{...yGrid}
						{...yAxisAppearance}
						onDoubleClick={resetYDomain}
					/>

					<MouseCoordinateY at="right" orient="right" displayFormat={getFormat(props.chartInstrument)} {...mouseEdgeAppearanceY} />
					{lastOutsideChartID === -1 ? (
						<MouseCoordinateX at="bottom" orient="bottom" displayFormat={timeFormat('%Y-%m-%d %H:%M')} {...mouseEdgeAppearanceX} />
					) : (
						''
					)}

					<EdgeIndicator
						{...edgeIndicatorAppearance}
						itemType="last"
						orient="right"
						edgeAt="right"
						yAccessor={(d) => d.close}
						displayFormat={getFormat(props.chartInstrument)}
					/>

					{renderBidAskLines()}
					{/* {renderSignalAnnotations()} */}
					{renderChartType()}

					{indicators?.current?.insideChart ? indicators.current.insideChart : ''}
					{/* <LineSeries yAccessor={(d) => d.open} stroke="#ff7f0e" /> */}
					<OHLCTooltip
						origin={[10, 0]}
						ohlcFormat={getFormat(props.chartInstrument)}
						{...OHLCAppearance}
						onClick={() => props.handleIndicatorEditClick(99999999)}
					/>
					<StaticValueTooltip
						yLabel="S"
						value={getPipDifference(
							chartData.current[chartData.current.length - 1].bid.c,
							chartData.current[chartData.current.length - 1].ask.c,
							props.chartInstrument
						)}
						origin={[10, 23]}
						onClick={() => props.handleIndicatorEditClick(99999999)}
						{...StaticValueToolTipAppearance}
					/>

					<MovingAverageTooltip
						onClick={(e) => console.log(e)}
						origin={[12, 35]}
						options={movingAverageTooltipOptions.current}
						displayFormat={getFormat(props.chartInstrument)}
						{...movingAverageToolTipAppearance}
					/>

					<ZoomButtons onReset={resetYDomain} />

					<ClickCallback
						// onMouseMove={(moreProps, e) => {
						// 	console.log('onMouseMove', moreProps, e);
						// }}
						// onMouseDown={(moreProps, e) => {
						// 	console.log('onMouseDown', moreProps, e);
						// }}
						// onClick={(moreProps, e) => {
						// 	console.log('onClick', moreProps, e);
						// }}
						// onDoubleClick={(moreProps, e) => {
						// 	console.log('onDoubleClick', moreProps, e);
						// }}
						onContextMenu={props.handleContextMenu}
						// onPan={(moreProps, e) => {
						// 	console.log('onPan', moreProps, e);
						// }}
						// onPanEnd={(moreProps, e) => {
						// 	console.log('onPanEnd', moreProps, e);
						// }}
					/>
				</Chart>

				{indicators?.current?.outsideChart ? indicators.current.outsideChart : ''}

				<CrossHairCursor {...CursorAppearance} />
			</ChartCanvas>
		);
	};

	return isLoaded ? renderChart() : '';
};

export default CustomChart;
