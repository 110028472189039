import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GenericChartComponent from '../GenericChartComponent';

import ToolTipText from './ToolTipText';
import ToolTipTSpanLabel from './ToolTipTSpanLabel';
import { functor } from '../utils';

class StaticValueTooltip extends Component {
	constructor(props) {
		super(props);
		this.renderSVG = this.renderSVG.bind(this);
	}
	renderSVG(moreProps) {
		const { onClick, fontFamily, fontSize, labelFill, valueFill, className } = this.props;
		const { yLabel, value } = this.props;

		const {
			chartConfig: { width, height },
		} = moreProps;

		const { origin: originProp } = this.props;
		const origin = functor(originProp);
		const [x, y] = origin(width, height);

		return (
			<g className={className} transform={`translate(${x}, ${y})`} onClick={onClick}>
				<ToolTipText x={0} y={0} fontFamily={fontFamily} fontSize={fontSize}>
					{yLabel ? <ToolTipTSpanLabel fill={labelFill}>{`${yLabel}: `}</ToolTipTSpanLabel> : null}
					<tspan fill={valueFill}>{value}</tspan>
				</ToolTipText>
			</g>
		);
	}
	render() {
		return <GenericChartComponent clip={false} svgDraw={this.renderSVG} drawOn={['mousemove']} />;
	}
}

StaticValueTooltip.propTypes = {
	xLabel: PropTypes.string,
	yLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	labelFill: PropTypes.string.isRequired,
	valueFill: PropTypes.string,
	origin: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
	className: PropTypes.string,
	fontFamily: PropTypes.string,
	fontSize: PropTypes.number,
	onClick: PropTypes.func,
};

StaticValueTooltip.defaultProps = {
	origin: [0, 0],
	labelFill: '#4682B4',
	valueFill: '#000000',
	className: 'react-stockcharts-tooltip',
};

export default StaticValueTooltip;
