import { createVerticalLinearGradient, hexToRGBA } from '../../../libs/react-stockcharts/src/lib/utils';
import { longColor, shortColor, chartColors } from './genericConfig';

// SAR Config
export const accelerationFactor = 0.02;
export const maxAccelerationFactor = 0.2;

// Trenline
export const trendlineApperance = {
	stroke: '#00DDFF',
	strokeOpacity: 1,
	strokeWidth: 1,
	strokeDasharray: 'Solid',
	edgeStrokeWidth: 1,
	edgeFill: chartColors.axis,
	edgeStroke: chartColors.redHot,
};

// Bollinger Band
export const bbStroke = {
	top: '#964B00',
	middle: '#000000',
	bottom: '#964B00',
};

export const bbFill = chartColors.skyBlue;

// MACD
export const macdAppearance = {
	stroke: {
		macd: chartColors.redTradingVue,
		signal: chartColors.greenTradingVue,
	},
	fill: {
		divergence: chartColors.downColorDark,
	},
	fontFamily:
		'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
};

export const cotAppearance = {
	zeroLineStroke: chartColors.grayBlueFadedTradingVue,
	zeroLineOpacity: 0.3,
	stroke: {
		netDealer: chartColors.redTradingVue,
		netInstitutional: chartColors.greenTradingVue,
		netLeveraged: chartColors.teal,
		netOther: chartColors.purple,
	},
	fill: {
		divergencePositive: chartColors.upColorSilver,
		divergenceNegative: chartColors.downColorDark,
	},
	textFill: chartColors.white,
	labelFill: chartColors.downColorDark,
	fontFamily:
		'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
};

export const genericSeriesAppearance = {
	zeroLineStroke: chartColors.grayBlueFadedTradingVue,
	zeroLineOpacity: 0.3,
	fill: {
		divergencePositive: chartColors.upColorSilver,
		divergenceNegative: chartColors.downColorDark,
	},
	textFill: chartColors.white,
	labelFill: chartColors.downColorDark,
	fontFamily:
		'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
};

export const rsiToolTipApperance = {
	labelFill: chartColors.downColorDark,
	fontFamily:
		'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
	textFill: chartColors.white,
};

// ATR
export const atrAppearance = {
	stroke: chartColors.orangeRed,
};

// Axis
export const axisAppearance = {
	stroke: chartColors.axis,
	fill: chartColors.axis,
	tickStroke: chartColors.axis,
};

// Axis
export const edgeIndicatorAppearance = {
	strokeOpacity: 0.3,
	strokeWidth: 1,
	// arrowWidth: 0,
	lineStroke: (d) => (d.close > d.open ? longColor : shortColor),
	lineOpacity: 0.3,
	fill: chartColors.bgColorTradingView,
	stroke: (d) => (d.close > d.open ? longColor : shortColor),
	textFill: chartColors.white,
	lineStrokeDasharray: 'Dash',
};
export const edgeBidIndicatorAppearance = {
	strokeOpacity: 0,
	strokeWidth: 1,
	// arrowWidth: 0,
	lineStroke: (d) => shortColor,
	lineOpacity: 0.2,
	fill: chartColors.bgColorTradingView,
	stroke: shortColor,
	textFill: chartColors.white,
	lineStrokeDasharray: 'Dot',
};
export const edgeAskIndicatorAppearance = {
	strokeOpacity: 0,
	strokeWidth: 1,
	// arrowWidth: 0,
	lineStroke: longColor,
	lineOpacity: 0.2,
	fill: chartColors.bgColorTradingView,
	stroke: longColor,
	textFill: chartColors.white,
	lineStrokeDasharray: 'Dot',
};

// Mouse
export const mouseEdgeAppearance = {
	textFill: chartColors.white,
	stroke: chartColors.primary,
	strokeOpacity: 1,
	strokeWidth: 1,
	arrowWidth: 0,
	fill: chartColors.primary,
};

export const stoAppearance = {
	stroke: {
		top: '#964B00',
		middle: '#000000',
		bottom: '#964B00',
		dLine: '#EA2BFF',
		kLine: '#74D400',
	},
};

export const canvasGradient = createVerticalLinearGradient([
	{ stop: 0, color: hexToRGBA(chartColors.skyBlue2, 0.2) },
	{ stop: 0.7, color: hexToRGBA(chartColors.skyBlue1, 0.4) },
	{ stop: 1, color: hexToRGBA(chartColors.skyBlue, 0.8) },
]);

/**
 * These are the settings available for the volume Profile.
 * We leave those commented out that we don't use for future
 * reference.
 */
export const volumeProfileApperance = {
	// className: 'line ',
	// bins: 20,
	// opacity: 0.5,
	// maxProfileWidthPercent: 50,
	fill: ({ type }) => (type === 'up' ? hexToRGBA(longColor, 0.2) : hexToRGBA(shortColor, 0.2)),
	stroke: chartColors.blackTrans(0.24), //'#FFFFFF',
	// showSessionBackground: false,
	sessionBackGround: chartColors.grayBlueFadedTradingVueOpac,
	// sessionBackGroundOpacity: 0.3,
	// source: (d) => d.close,
	// volume: (d) => d.volume,
	// absoluteChange: (d) => d.absoluteChange,
	// bySession: false,
	// /* eslint-disable no-unused-vars */
	// sessionStart: ({ d, i, plotData }) => i > 0 && plotData[i - 1].date.getWeek() !== d.date.getWeek(),
	// /* eslint-enable no-unused-vars */
	// orient: 'left',
	// partialStartOK: true,
	// partialEndOK: true,
};

/**
 * This is for the volume bar which is a second graph.
 */
export const volumeApperance = {
	widthRatio: 0.95,
	opacity: 0.01, // This doesn't appear to do anything.
	fill: (d) => (d.close > d.open ? chartColors.redFadedTradingVue : chartColors.grayBlueFadedTradingVue),
	stroke: false,
	strokeColor: chartColors.whiteHalfOp,
};
