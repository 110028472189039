import { SET_HEADER_STATUS } from '../actions/types';

/**
 *
 * @param {json} status - should contain the text and type
 * 				{ text: "hai", type: "loading"}
 * 				- Type is optional
 */
export const setHeaderStatus = (status) => async (dispatch) => {
	dispatch({ type: SET_HEADER_STATUS, payload: status });
};
