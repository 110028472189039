import React from 'react';
import Draggable from 'react-draggable';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// To use redux hooks, you export a function component, not a react component.
export const BaseModal = (props) => {
	/**
	 * Get our handle functions from the props
	 */
	const { isOpen, title, children, onClose, footerContent, mainClass } = props;
	const nullFunction = () => null;

	/**
	 * Return the simple construct
	 */
	return (
		<Draggable handle=".modal-header">
			<Modal
				isOpen={isOpen}
				toggle={() => onClose()}
				className={mainClass}
				autoFocus={false}
				onOpened={props.onOpened != null ? props.onOpened : nullFunction}
			>
				<ModalHeader toggle={() => onClose()}>{title}</ModalHeader>
				<ModalBody>{children}</ModalBody>
				{footerContent != null ? (
					<ModalFooter>
						{footerContent}
						{/* <Button color="dark" onClick={() => onClose()}>
						Close
					</Button>
					<Button color="primary">Save changes</Button> */}
					</ModalFooter>
				) : (
					''
				)}
			</Modal>
		</Draggable>
	);
};
