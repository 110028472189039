import React from 'react';
import CurrencyFormat from 'react-currency-format';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
// import isEqual from 'react-fast-compare';
import { tsvParse } from 'd3-dsv';
import { format } from 'd3-format';
import { timeParse } from 'd3-time-format';
import { isNotDefined, isDefined } from '../libs/react-stockcharts/src/lib/utils';
// import moment from 'moment-timezone';

/**
 * Return a 2 digit fixed parsed float/double.
 *
 * @param {double} number
 */
export const floatToCurrencyFormat = (number) => {
	if (!number || number === undefined) return 0;

	return <CurrencyFormat value={number} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} />;
	// return parseFloat(number).toFixed(2);
};

/**
 * Return the raw float to currency formatted as
 * a number without any UI wrapper/graphics.
 *
 * This function essentially calls the rawFloatFormat
 * and appends a $.
 *
 * @param {float} number
 * @param {int} decimal - length of decimals
 */
export const rawFloatToCurrencyFormat = (number, decimal = 0) => {
	if (number == null) throw new Error('no number');

	return '$' + rawFloatFormat(number, decimal, 3, ',', '.');
};

/**
 * Return a 2 digit fixed parsed float/double.
 *
 * @param {float} number
 */
export const floatToNumberFormat = (number) => {
	if (!number || number === undefined) return 0;

	return <NumberFormat value={number} displayType={'text'} decimalScale={2} thousandSeparator={true} />;
	// return parseFloat(number).toFixed(2);
};

/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param {float} number - the number
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
export const rawFloatFormat = (number, n, x, s, c) => {
	if (isNaN(number) || number == null) return 0;

	var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
		num = number.toFixed(Math.max(0, ~~n));

	return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

/**
 * Add commas to a number, returns a string.
 *
 * @param {str} number
 */
export const rawCommaFormat = (number) => {
	if (isNaN(number) || number == null) return 0;

	return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(number);
};

/**
 * Calculates in percent, the change between 2 numbers.
 * e.g from 1000 to 500 = 50%
 *
 * @param oldNumber The initial value
 * @param newNumber The value that changed
 */
export const getPercentageChange = (oldNumber, newNumber) => {
	var decreaseValue = oldNumber - newNumber;
	return (decreaseValue / oldNumber) * 100;
};

/**
 * This will search through an object and find the matching
 * object by instrument name (for oanda)
 *
 * @param {object} obj
 * @param {string} instrument
 */
export function findObjByInstrument(obj, instrument) {
	if (!obj || obj === undefined) return {};
	return _.find(obj, { instrument: instrument });
}

/**
 * This will search through an object and find the matching
 * object by instrument name (for oanda).
 *
 * This will return an array of matching items instead of
 * just the one (like the above).
 *
 * @param {object} obj
 * @param {string} instrument
 * @param {array} tpSLArray - if provided, will find matching TPs and SLs
 * 					tpSLArray =
 * 					{
 * 						id: id,
 * 					   units: units
 * 					}
 */
export function findAllInObjByInstrument(obj, instrument, tpSLArray = []) {
	if (!obj || obj === undefined) return {};

	/**
	 * Create our return array
	 */
	let returnArray = [];

	/**
	 * Do the looping
	 */
	for (let i = 0; i < obj.length; i++) {
		if (obj[i].instrument === instrument) {
			returnArray.push(obj[i]);
			continue;
		}

		/**
		 * See if the SL or TP matches the ID
		 */
		if (tpSLArray.length > 0) addDataToTPSL(obj[i], tpSLArray);
	}

	/**
	 * Return it
	 */
	return returnArray;
}

/**
 *
 * @param {obj} obj - a single object
 * @param {*} tpSLArray - the trade array
 */
export function addDataToTPSL(obj, tpSLArray) {
	if (!obj || obj === undefined) return obj;

	/**
	 * See if the SL or TP matches the ID
	 */
	if (tpSLArray.length > 0) {
		for (const elem of tpSLArray) {
			if (elem.id === obj.id) {
				/**
				 * This isn't in the data naturally so we add it here
				 */
				obj.units = elem.units;
				obj.instrument = elem.instrument;

				/**
				 * Trailing Stop Loss does not have a price since it is
				 * by distance.
				 */
				if (obj.type === 'TRAILING_STOP_LOSS') obj.price = obj.trailingStopValue;
			}
		}
	}

	return obj;
}

/**
 * Find duplicates in an object array by a property name.
 *
 * @param {obj array} obj
 * @param {string} propertyName
 */
export function findDuplicateObj(obj, propertyName) {
	return _.difference(obj, _.uniqBy(obj, propertyName), propertyName);
}

/**
 * A dirty little function that determines if two objects are
 * equale.
 *
 * Replaced with the lodash  _.isEqual on 10-19-2020
 *
 * @param {*} right
 * @param {*} left
 */
export const objectEqual = (right, left) => {
	if (Array.isArray(right)) if (right.length !== left.length) return false;

	// return isEqual(left, right);
	return _.isEqual(left, right);
	// if (JSON.stringify(right) === JSON.stringify(left)) return true;
	// return false;
};

/**
 * Format number precision
 *
 * @param {float} floatNumber - the decimal number to fix
 * @param {int} precision - the number of decimals, default 10
 */
export const floatPrecisionFix = (floatNumber, precision = 10) => {
	let number = Math.floor(floatNumber * 10000000000) / 10000000000;

	/**
	 * If the user didn't use the default precision, adjust it and
	 * create a float again.
	 */
	if (precision !== 10) number = parseFloat(number.toFixed(precision));

	// Return it
	return number;
};

/**
 * Get the pip difference between two prices.
 *
 * @param {float} price1
 * @param {float} price2
 *
 * @returns {float} pip difference
 */
export const getPipDifference = (price1, price2, symbol) => {
	let priceMovement = Math.abs(floatPrecisionFix(price1 - price2));

	/**
	 * Only the JPY pairs have 3 deimals, the rest have 5.
	 *
	 * Why the <= 3? Because a number like 125.960 will be 2
	 * decimals...
	 */
	if (isTwoDecimalPipSymbol(symbol)) return floatPrecisionFix(floatPrecisionFix(priceMovement) * 100, 2);
	else return floatPrecisionFix(floatPrecisionFix(priceMovement) * 10000, 2);
};

/**
 * This will add pips to a price.
 *
 * @param {int} pips
 * @param {float} price
 * @param {int} posInt - 1 or -1 (long or short)
 */
export const addPipsToPrice = (pips, price, posInt, symbol) => {
	if (!pips || pips === 0 || !price || price === 0) return 0;

	// One pip is at this decimal place:
	let onePip = 0.0001 * posInt; // posInt will just be -1 or 1

	// See if we have a JPY pair.
	/**
	 * Only the JPY pairs have 3 deimals, the rest have 5.
	 *
	 * Why the <= 3? Because a number like 125.960 will be 2
	 * decimals...
	 */
	if (isTwoDecimalPipSymbol(symbol)) onePip = 0.01 * posInt; // posInt will just be -1 or 1

	// Return our Pip addition
	if (isTwoDecimalPipSymbol(symbol)) return floatPrecisionFix(floatPrecisionFix(pips) * floatPrecisionFix(onePip) + floatPrecisionFix(price), 3);
	return floatPrecisionFix(floatPrecisionFix(pips) * floatPrecisionFix(onePip) + floatPrecisionFix(price), 5);
};

/**
 * This is just the decimal representation of pips,
 * as a price.
 *
 * @param {int} pips
 * @param {int} posInt - 1 or -1 (long or short)
 */
export const pipsToPricePoint = (pips, symbol) => {
	if (!pips || pips === 0 || !symbol) return 0;

	/**
	 * To convert, you just multiply the pips
	 * by where the pip location is in the decimal number.
	 */
	if (isTwoDecimalPipSymbol(symbol)) return floatPrecisionFix(0.01 * pips, 3);
	return floatPrecisionFix(0.0001 * pips, 5);
};

/**
 * This converts a price to pips. Used in the ATR which
 * gives a price number such as 0.00143
 *
 * @param {float} price
 * @param {int} posInt - 1 or -1 (long or short)
 */
export const priceToPips = (price, symbol) => {
	if (!price || price === 0 || !symbol) return 0;

	/**
	 * To convert, you just multiply the price by 10000 or
	 */
	if (isTwoDecimalPipSymbol(symbol)) return floatPrecisionFix(100 * price, 2);
	return floatPrecisionFix(10000 * price, 2);
};

/**
 * This will convert pips into a USD Dollar
 * amount.
 *
 * @param {int} pips
 * @param {int} lots
 * @param {str} instrument
 *
 * See: https://www.ig.com/us/glossary-trading-terms/pip-value-definition#:~:text=Because%20pips%20are%20tiny%20in,value%20of%20the%20currency%20pair.
 *
 *
 * Also see: https://www.reddit.com/r/Forex/comments/icwin9/i_am_just_getting_into_forex_but_i_do_not/?%24deep_link=true&correlation_id=3675f6df-b23d-4389-b637-cba82dd513b0&ref=email_digest&ref_campaign=email_digest&ref_source=email&utm_content=post_title&utm_medium=digest&utm_name=top_posts&utm_source=email&utm_term=day&%243p=e_as&%24original_url=https%3A%2F%2Fwww.reddit.com%2Fr%2FForex%2Fcomments%2Ficwin9%2Fi_am_just_getting_into_forex_but_i_do_not%2F%3F%24deep_link%3Dtrue%26correlation_id%3D3675f6df-b23d-4389-b637-cba82dd513b0%26ref%3Demail_digest%26ref_campaign%3Demail_digest%26ref_source%3Demail%26utm_content%3Dpost_title%26utm_medium%3Ddigest%26utm_name%3Dtop_posts%26utm_source%3Demail%26utm_term%3Dday&_branch_match_id=782029074509266795
 */
export const getPipValue = (priceNow, lots, instrument, rates) => {
	if (!priceNow || priceNow === null || priceNow <= 0 || priceNow === undefined) return 0;

	// One pip is at this decimal place:
	let onePip = 0.0001;

	/**
	 * See if we have a JPY pair.
	 * Why not use decimal count? Because .030 is 2 decimals
	 * and .012000 is 3 decimals even though they should be
	 * 3 and 5...
	 */
	//if (countDecimalPlaces(priceNow) <= 3) onePip = 0.01;
	if (isTwoDecimalPipSymbol(instrument)) onePip = 0.01;

	/**
	 * This will return the value in whatever base currency it is in.
	 * EG: USD_SGD will return in USD but
	 * EUR_NZD will return in EUR.
	 */
	let pipValue = floatPrecisionFix((onePip / priceNow) * lots);
	// Decimal.mul(Decimal.div(onePip, priceNow), lots).toNumber();

	/**
	 * We need to convert this into USD now.
	 */
	const instrumentArray = instrument.split('_');

	/**
	 * Get the average (mid) between the ask and the
	 * bid of the current conversion
	 */
	const currencyAverage = floatPrecisionFix(floatPrecisionFix(rates.rates[instrument].bid + rates.rates[instrument].ask) / 2);

	/**
	 * If it isn't in USD already, convert it
	 */
	if (instrumentArray[0] !== 'USD' && instrumentArray[1] === 'USD') return floatPrecisionFix(pipValue * currencyAverage, 5);
	if (instrumentArray[0] !== 'USD' && instrumentArray[1] !== 'USD') {
		/**
		 * The currency rates are already converted into USD in the DB.
		 * There is no need to convert anything here.
		 *
		 * The 100 here: The price without the 100 is exactly right / 100.. :|
		 * This is with JPY pairs (GBPJPY, EURJPY, etc)
		 *
		 * We only need to multiply by 100 if the price has 3 decimals. If 5, we
		 * are good to go.
		 *
		 * Ok, I figured out the problem! In JS, when you have a decimal of .030 this
		 * is only 2 decimal places, not three. So, the above onePip value was never
		 * being adjusted for 3 decimal types (IE: JPY). Fixing that made everything below
		 * unnecessary.
		 *
		 */
		//if (countDecimalPlaces(priceNow) <= 3) return Decimal.mul(Decimal.mul(pipValue, currencyAverage), 100).toNumber().toFixed(5);
		//else
		return floatPrecisionFix(pipValue * currencyAverage, 5);
	} else return floatPrecisionFix(pipValue, 5);
};

/**
 * These symbols are all of the two decimal
 * based symbols.
 *
 * @param {str} symbol
 */
export const isTwoDecimalPipSymbol = (symbol) => {
	if (symbol === 'EUR_JPY') return true;
	if (symbol === 'EUR_HUF') return true;
	if (symbol === 'TRY_JPY') return true;
	if (symbol === 'ZAR_JPY') return true;
	if (symbol === 'GBP_JPY') return true;
	if (symbol === 'USD_THB') return true;
	if (symbol === 'USD_JPY') return true;
	if (symbol === 'CHF_JPY') return true;
	if (symbol === 'SGD_JPY') return true;
	if (symbol === 'CAD_JPY') return true;
	if (symbol === 'NZD_JPY') return true;
	if (symbol === 'USD_HUF') return true;
	if (symbol === 'AUD_JPY') return true;

	return false;
};

/**
 * This is a replica of the TV minimum tick.
 *
 * @param {str} symbol
 * @returns
 */
export const getMinTick = (symbol) => {
	if (!isTwoDecimalPipSymbol(symbol)) return 0.00001;
	return 0.001;
};

export const parseData = (parse) => {
	return function (d) {
		d.date = parse(d.date);
		d.open = +d.open;
		d.high = +d.high;
		d.low = +d.low;
		d.close = +d.close;
		d.volume = +d.volume;

		return d;
	};
};

export const parseDate = timeParse('%Y-%m-%d %H:%M');

/**
 *
 * @param {obj} data
 * @param {obj} signals - if included, will add signal info
 */
export const formatOandaData = (data, candleType = 'mid') => {
	/**
	 * This takes about 72ms on average, 300ms on start
	 *
	 * Almost identical results on a for loop. It was even
	 * a bit slower.
	 */
	return data.map((row, index, dataArray) => {
		return {
			date: new Date(row.time),
			dateMS: row.timeMS ? row.timeMS : Date.parse(row.time),
			//moment(row.time).utcOffset(0, true).toDate(), // not correct
			// moment(new Date(row.time)).tz('Europe/Moscow').toDate(), // Does nothing.
			// date: new Date(new Date(row.time).toLocaleString('en-US', { timeZone: 'Europe/Moscow' })), // Correct
			time: row.time, // Used for signal comparison
			open: parseFloat(row[candleType].o),
			high: parseFloat(row[candleType].h),
			low: parseFloat(row[candleType].l),
			close: parseFloat(row[candleType].c),
			volume: parseFloat(row.volume),
			bid: row.bid,
			ask: row.ask,
			absoluteChange: index > 0 ? parseFloat(row[candleType].c) - parseFloat(dataArray[index - 1][candleType].c) : 0,
			closeMinusOpen: index > 0 ? parseFloat(row[candleType].c) - parseFloat(dataArray[index][candleType].o) : 0,
		};
	});
};

/**
 * Return only the relevante data items from a
 * data object, a data object being a candle data object.
 * @param {obj} data
 */
export const returnBarDataOnly = (data) => {
	if (data == null) return null;

	return {
		absoluteChange: data.absoluteChange,
		openMinusClose: data.openMinusClose,
		close: data.close,
		date: data.date,
		high: data.high,
		low: data.low,
		open: data.open,
		time: data.time,
		volume: data.volume,
		bid: data.bid,
		ask: data.ask,
		idx: data.idx,
		exit: data.exit,
	};
};

/**
 * Remove any trends.
 *
 * 2021-05-27 11:16:35 - JD
 *
 * @param {obj} data - Should be plotdata type object
 * @returns
 */
export const detrendBars = (data) => {
	if (data == null) return null;

	/**
	 * Some placeholders
	 */
	let mean = 0.0;

	/**
	 * Loop through and get the average price of all the bars
	 */
	for (let i = 1; i < data.length; i++) mean += Math.abs(Math.abs(data[i].close) - Math.abs(data[i - 1].close));

	/**
	 * Calculate the average
	 */
	const detrendAverage = floatPrecisionFix(mean / data.length);

	/**
	 * The actual math algo for detrending.
	 *
	 * ( Log(CurrentPrice/LastPrice) - Average(Log(CurrentPrice/LastPrice)) )
	 *
	 * From : https://quant.stackexchange.com/questions/4286/detrending-price-data-for-analysis-of-signal-returns
	 * (which is from the book)
	 *
	 * @param {float} price
	 * @param {float} previous
	 * @returns
	 */
	const detrendAlgo = (price, previous) => {
		return floatPrecisionFix(parseFloat(price) / parseFloat(previous) - detrendAverage);
	};

	/**
	 * Place holder
	 */
	const newDataArray = [];

	/**
	 * Loop through and subtract the average from all bars
	 *
	 */
	for (let index = data.length - 1; index > 0; index--) {
		newDataArray.push({
			...data[index],
			open: index > 0 ? detrendAlgo(data[index].open, data[index - 1].open) : data[index].open,
			high: index > 0 ? detrendAlgo(data[index].high, data[index - 1].high) : data[index].high,
			low: index > 0 ? detrendAlgo(data[index].low, data[index - 1].low) : data[index].low,
			close: index > 0 ? detrendAlgo(data[index].close, data[index - 1].close) : data[index].close,
		});
	}

	return newDataArray;
};

/**
 * This will attempt to find the candle data
 * between a time.
 *
 * @param {array} data (plotdata)
 * @param {float} price
 *
 * @return {bool}
 */
export const isPriceInData = (data, price) => {
	/**
	 * If we don't have valid data, return false
	 */
	if (data == null || price == null) return false;

	/**
	 * Loop through the data points
	 */
	for (let candle of data) {
		/**
		 * See if the price is between the data price.
		 */
		if (price <= candle.high && price >= candle.low) return true;
	}

	return false;
};

/**
 * This will attempt to find the candle data
 * between a time.
 *
 * @param {int} index - the current index
 * @param {array} data
 * @param {date} time
 *
 * @return {bool}
 */
export const isBarBetweenTime = (index, data, time) => {
	/**
	 * If we hit an index that isn't valid, return
	 */
	if (data[index] == null || data[index].date == null) return false;

	/**
	 * Create our variables
	 */
	const findTime = time; //new Date(time);
	const candleStartTime = data[index].dateMS; // Already in date format
	const candleEndTime = index >= data.length - 1 ? data[index].dateMS : data[index + 1].dateMS;

	/**
	 * Return if it is between
	 */
	if (findTime >= candleStartTime && findTime < candleEndTime) return true;

	/**
	 * Return if it matches eactly
	 * based on the strings (no date() objs)
	 */
	if (time === data[index].time) return true;

	return false;
};

/**
 * This will attempt to find the candle data
 * between a time.
 *
 * @param {int} barIndex - the current index
 * @param {int} genericDataIndex - the current COT index
 * @param {array} data
 * @param {array} genericData
 *
 * @return {bool}
 */
export const isBarBetweenGenericDataTime = (barIndex, genericDataIndex, data, genericData) => {
	/**
	 * If we hit an barIndex that isn't valid, return
	 */
	if (data[barIndex] == null || data[barIndex].date == null) return false;

	/**
	 * Create our variables
	 */
	const findTime = parseInt(genericData[genericDataIndex].timeMS); //new Date(genericData[genericDataIndex]['Report_Date_as_YYYY-MM-DD']);
	const candleTime = data[barIndex].dateMS; // Already in date format
	const genericEndTime =
		genericDataIndex >= genericData.length - 1 ? genericData[genericDataIndex].timeMS : parseInt(genericData[genericDataIndex + 1].timeMS);

	// if (genericDataIndex < 1 && barIndex < 1) {
	// if (barIndex > data.length - 10 && genericDataIndex > genericData.length - 10) {
	// if (candleTime === 1635454800000) {
	// 	console.log(barIndex, genericDataIndex);
	// 	console.log(`findTime: ${findTime} candleTime: ${candleTime} genericEndTime: ${genericEndTime}`);
	// 	console.log(`Generic Date/Time: ${genericData[genericDataIndex].time} Bar Date/Time: ${data[barIndex].date} `);
	// }

	/**
	 * Return if it is between
	 */
	if (candleTime >= findTime && candleTime < genericEndTime) return true;

	/**
	 * If we are at the end of the cot data, just
	 * test if the candleTime is >=
	 */
	if (genericDataIndex >= genericData.length - 1) {
		if (candleTime >= findTime) return true;
	}

	return false;
};

/**
 * This will attempt to find the candle data
 * between a time.
 *
 * @param {array} data
 * @param {int} time - in MS
 */
export const findDataBetweenTime = (data, time) => {
	if (!data || !time) return null;

	/**
	 * Vars
	 */
	const findTime = time;

	for (let d = 0; d < data.length; d++) {
		const candleStartTime = data[d].dateMS; // Already in date format
		const candleEndTime = d >= data.length - 1 ? data[d].dateMS : data[d + 1].dateMS;

		/**
		 * Return if it is between
		 */
		if (findTime >= candleStartTime && findTime < candleEndTime) return returnBarDataOnly(data[d]);

		/**
		 * Return if it matches eactly
		 * based on the strings (no date() objs)
		 */
		if (time === data[d].time) return returnBarDataOnly(data[d]);
	}

	return null;
};

/**
 * This will attempt to find the candle data
 * between a time and return the INDEX
 *
 * @param {array} data
 * @param {int} time - in MS
 */
export const findDataIndexBetweenTime = (data, time) => {
	if (!data || !time) return null;

	for (let d = 0; d < data.length; d++) {
		const candleStartTime = data[d].dateMS; // Already in date format
		const candleEndTime = d >= data.length - 1 ? data[d].dateMS + (data[d].dateMS - data[d - 1]?.dateMS) : data[d + 1].dateMS;

		/**
		 * Return if it is between
		 */
		if (time >= candleStartTime && time < candleEndTime) return d;

		/**
		 * Return if it matches eactly
		 * based on the strings (no date() objs)
		 */
		if (time === data[d].dateMS) return d;
	}

	return null;
};

/**
 * This will attempt to find the candle data
 * between a time and return the INDEX
 *
 * @param {array} data
 * @param {int} time - in MS
 */
export const findDataIndexClosestTime = (data, time) => {
	if (!data || !time) return null;

	const closest = data.reduce((a, b, currentIndex) => {
		let aDiff = Math.abs(a.dateMS - time);
		let bDiff = Math.abs(b.dateMS - time);

		if (aDiff === bDiff) {
			// Choose largest vs smallest (> vs <)
			return a.dateMS > b.dateMS ? a : b;
		} else {
			return bDiff < aDiff ? b : a;
		}
	});

	/**
	 * Now find the index of the closest one.
	 */
	for (let d = 0; d < data.length; d++) {
		/**
		 * Return if it is between
		 */
		if (closest.dateMS === data[d].dateMS) return d;
	}

	return null;
};

/**
 * This will append additional data to the data by index number.
 * This does no complex check and appends by index.
 *
 * IE: data[0] = genericData[name][0]
 *
 * @param {array} data
 * @param {array} genericData
 * @param {str} name - name of the data in the array
 */
export const addGenericToDataByIndex = (data, genericData, name) => {
	if (!data || !genericData || genericData.length <= 0) return null;

	/**
	 * Cycle through all of the genericData
	 */
	for (let i = 0; i < genericData.length; i++) data[i][name] = genericData[i];

	/**
	 * Send it back!
	 */
	return data;
};

/**
 * This will append the COT data to the candlestick
 * data.
 *
 * @param {array} data
 * @param {array} genericData
 * @param {str} name - name of the data in the array
 */
export const addGenericToData = (data, genericData, name) => {
	if (!data || !genericData || genericData.length <= 0) return null;

	/**
	 * Let's start by finding the first date of the
	 * signals and then going backwards in the data to find the
	 * first bar it could possibly be.
	 */
	// const startIndex = 0;
	const startIndex = findDataIndexBetweenTime(data, genericData[0].timeMS) || 0;

	/**
	 * Cycle through all of the genericData
	 */
	for (let i = 0; i < genericData.length; i++) {
		let entered = false;

		/**
		 * Now let's look through the data to find a matching bar. The
		 * data looks like the data above in formatOandaData()
		 */
		for (let d = startIndex; d < data.length; d++) {
			if (isBarBetweenGenericDataTime(d, i, data, genericData)) {
				data[d][name] = genericData[i];

				/**
				 * We can't break here because the data needs to be
				 * added to multiple bars so.. How can we break?
				 *
				 * Maybe once we enter a found position and after we leave, break?
				 *
				 * !!!!!!!!!!!!!!!!!!!!!!
				 */
				entered = true;
			} else if (entered) {
				entered = false;
				break;
			}
		} // End loop through data;
	} // End loop through genericData

	/**
	 * Send it back!
	 */
	return data;
};

/**
 * This will combine new candle data into one and replace
 * any existing value with the old.
 *
 * 2021-04-28 05:20:16 - JD
 *
 * @param {array} data
 * @param {array} newData
 */
export const concatNewCandleData = (data, newData) => {
	if (!data || !newData || newData.length <= 0) return null;

	/**
	 * Combine the arrays
	 */
	const newArray = [...data, ...newData];

	/**
	 * Filter based on the mongo ID
	 */
	return newArray.filter((value, index, array) => array.findIndex((item) => item.timeMS === value.timeMS) === index);

	/**
	 * Cycle through all of the genericData
	 */
	for (let i = 0; i < newData.length; i++) {
		let entered = false;

		/**
		 * Now let's look through the data to find a matching bar.
		 */
		for (let d = data.length - 1; d >= 0; d--) {
			if (data[d].timeMS === newData[i].timeMS) {
				data[d] = newData[i];
				entered = true;
				break;
			}
		} // End loop through data;

		/**
		 * If we are here and entered === false
		 * that means we didn't find a match and this is
		 * new data. Add it to the first (0 position).
		 */
		if (!entered) data.push(newData[i]);
	} // End loop through genericData

	/**
	 * Send it back!
	 */
	return data;
};

/**
 * This function will cycle through all of the signals and
 * find the matching data row it belongs to and append itself to that
 * object.
 *
 * @param {obj} data
 * @param {obj} signals
 */
export const addSignalsToData = (data, signals) => {
	if (!data || !signals) return null;

	/**
	 * Let's start by finding the first date of the
	 * signals and then going backwards in the data to find the
	 * first bar it could possibly be.
	 */
	const startIndex = findDataIndexBetweenTime(data, signals[0].entry.timeMS) || 0;

	/**
	 * First we cycle through all of the signals
	 * which looks like this:
	 *
	 * 0:
	 *  entry: {time: "2020-08-09T21:10:00.000Z", price: 1.30636, quantity: 10000, commission: 0.45, slippage: 0, …}
	 *  exit: {time: "2020-08-10T02:18:00.000Z", price: 1.3073, reason: "TP_2E24E", type: "win", bar: {…}, …}
	 *  profit: {amount: 9.458844, percent: null, pips: 9.4, capital: 100009.458844}
	 *  stats: {timeHeld: 18480000, timeHeldHuman: "5 hours"}
	 */
	for (let i = 0; i < signals.length; i++) {
		/**
		 * Now let's look through the data to find a matching bar. The
		 * data looks like the data above in formatOandaData()
		 */
		for (let d = startIndex; d < data.length; d++) {
			// if (data[d].time === signals[i].entry.time) {
			if (isBarBetweenTime(d, data, signals[i].entry.timeMS)) {
				/**
				 * Fetch our exit bar
				 */
				let exitBar = signals[i].exit != null ? findDataBetweenTime(data, signals[i].exit.timeMS) : null;

				// Entry!
				data[d].entry = {
					posInt: signals[i].entry.posInt,
					position: signals[i].entry.position,
					price: signals[i].entry.price,
					quantity: signals[i].entry.quantity,
					time: signals[i].entry.time, // This was exit.time - bug?
					entryTime: signals[i].entry.time,
					exitBar:
						exitBar != null
							? {
									...exitBar,
							  }
							: null,
					exitSignal: signals[i].exit != null ? signals[i].exit : null,
					profit: signals[i].profit != null ? signals[i].profit : null,
					stats: signals[i].stats != null ? signals[i].stats : null,
				};

				/**
				 * We don't need to go any further.
				 */
				break;
			} else if (signals[i].exit != null && isBarBetweenTime(d, data, signals[i].exit.timeMS)) {
				//if (data[d].time === signals[i].exit.time) {
				// Exit
				data[d].exit = {
					posInt: signals[i].entry.posInt,
					position: signals[i].entry.position,
					type: signals[i].exit.type,
					price: signals[i].exit.price,
					reason: signals[i].exit.reason,
					profit: signals[i].profit.amount,
					pips: signals[i].profit.pips,
					time: signals[i].exit.time,
					entryTime: signals[i].entry.time,
				};

				/**
				 * We don't need to go any further.
				 */
				break;
			}
		} // End data Loop
	} // End signal loop

	/**
	 * Send it back!
	 */
	return data;
};

export function formatOandaDataForHC(data, candleType = 'mid') {
	return data.map(function (row) {
		return [
			new Date(row.time),
			// moment(new Date(row.time)).utcOffset(1, true).valueOf(),
			// new Date(new Date(row.time).toLocaleString('en-US', { timeZone: 'Europe/Moscow' })).getTime(),
			parseFloat(row[candleType].o),
			parseFloat(row[candleType].h),
			parseFloat(row[candleType].l),
			parseFloat(row[candleType].c),
		];
	});
}

export function formatOandaVolumeForHC(data) {
	return data.map(function (row) {
		return [
			new Date(row.time),
			// moment(new Date(row.time)).utcOffset(1, true).toDate(),
			//new Date(new Date(row.time).toLocaleString('en-US', { timeZone: 'Europe/Moscow' })),
			parseFloat(row.volume),
		];
	});
}

export function getData() {
	const promiseMSFT = fetch('https://cdn.rawgit.com/rrag/react-stockcharts/master/docs/data/MSFT.tsv')
		.then((response) => response.text())
		.then((data) => tsvParse(data, parseData(parseDate)));
	return promiseMSFT;
}

/**
 * This is specifically for the graph settings
 * state and will search through the indicators and
 * return the indicator or false if it exists.
 *
 * @param {obj} chartSettings - graph settings from state
 * @param {str} searchFor - indicator to search for
 */
export const searchIndicatorSettings = (chartSettings, searchFor) => {
	if (!chartSettings || !searchFor) return false;

	for (let i = 0; i < chartSettings.indicators.length; i++) if (chartSettings.indicators[i][0] === searchFor) return chartSettings.indicators[i][0];

	return false;
};

export function saveInteractiveNode(chartId) {
	return (node) => {
		this[`node_${chartId}`] = node;
	};
}

export function handleSelection(type, chartId) {
	return (selectionArray) => {
		const key = `${type}_${chartId}`;
		const interactive = this.state[key].map((each, idx) => {
			return {
				...each,
				selected: selectionArray[idx],
			};
		});
		this.setState({
			[key]: interactive,
		});
	};
}

export function saveInteractiveNodes(type, chartId) {
	return (node) => {
		if (isNotDefined(this.interactiveNodes)) {
			this.interactiveNodes = {};
		}
		const key = `${type}_${chartId}`;
		if (isDefined(node) || isDefined(this.interactiveNodes[key])) {
			// console.error(node, key)
			// console.log(this.interactiveNodes)
			this.interactiveNodes = {
				...this.interactiveNodes,
				[key]: { type, chartId, node },
			};
		}
	};
}

export function getInteractiveNodes() {
	return this.interactiveNodes;
}

/**
 * Convert bytes into human redable forms (IE: MB, GB)
 * From: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 *
 * @param {int} bytes
 * @param {int} decimals
 */
export function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * This addes extra fields to the position line items
 * that make it easier to parse.
 *
 * @param {obj} position
 */
export const formatPositionData = (position) => {
	/**
	 * Data & integ check
	 */
	if (position == null || position.long == null || position.short == null) return position;

	// Units (long or short)
	position.units = parseFloat(position.long.units) > 0 ? position.long.units : position.short.units;
	position.averagePrice = 0;
	position.tradeCount = 0;

	/**
	 * Only continue if we are actually in a trade
	 */
	if (parseInt(position.units) !== 0) {
		// Average price
		position.averagePrice = parseFloat(position.long.units) > 0 ? position.long.averagePrice : position.short.averagePrice;

		// Get the number of trades each has.
		position.tradeCount = parseFloat(position.long.units) > 0 ? position.long.tradeIDs.length : position.short.tradeIDs.length;
	}

	// Return
	return position;
};

/**
 * This returns the number format needed which is 3 for
 * JPY pairs and 5 for all else.
 */
export const getFormat = (chartInstrument) => {
	return !isTwoDecimalPipSymbol(chartInstrument) ? format('.5f') : format('.3f');
};

/**
 * Create a custom sort icon
 */
export const customSortCaret = (order, column) => {
	if (!order) return <span></span>;
	else if (order === 'asc')
		return (
			<span key={Math.random().toString(36).substr(2, 7)}>
				&nbsp;&nbsp;
				<i className="fa fa-sort-amount-asc pl-2" />
			</span>
		);
	else if (order === 'desc')
		return (
			<span key={Math.random().toString(36).substr(2, 7)}>
				&nbsp;&nbsp;
				<i className="fa fa-sort-amount-desc pl-2" />
			</span>
		);
	return null;
};

/**
 * The spinner when loading data or no data
 */
export const NoDataIndication = () => <i className="la la-spinner la-spin display-3" />;

/**
 * This function will detect up/down keys and move the
 * focus up or down.
 *
 * @param {obj} ref
 */
export const moveFocus = (e, focusSearch) => {
	e.preventDefault();

	const active = document.activeElement;
	if (e.key === 'ArrowDown' && active.nextSibling) {
		active.nextSibling.focus();
	} else if (e.key === 'ArrowUp' && active.previousSibling) {
		active.previousSibling.focus();
	} else if (e.key === 'Enter') {
		active.click();
	} else {
		// if (e.which < 48 || e.which > 57) return;
		if (
			!e.metaKey && // cmd/ctrl
			!e.which <= 0 && // arrow keys
			e.which !== 8 && // delete key
			!/[0-9a-zA-Z]/.test(String.fromCharCode(e.which))
			// numbers
		)
			return;

		if (focusSearch) {
			/**
			 * Return the focus
			 */
			focusSearch.current.focus();
			if (e.which !== 8 && !e.metaKey && e.which > 0) focusSearch.current.value += e.key;

			/**
			 * Simulate a change event
			 */
			const event = new Event('change', { bubbles: true });
			focusSearch.current.dispatchEvent(event);
		}
	}
};

export const searchKeyDown = (e, focusRefs) => {
	if (focusRefs[0] != null && focusRefs[0].current != null && (e.key === 'ArrowDown' || e.key === 'ArrowUp')) focusRefs[0].current.focus();
	if (
		!e.metaKey && // cmd/ctrl
		!e.which <= 0 && // arrow keys
		e.which !== 8 && // delete key
		e.key !== 'Shift' &&
		e.key !== '/' &&
		e.key !== '_' &&
		e.key !== '-' &&
		!/[0-9a-zA-Z ]/.test(String.fromCharCode(e.which))
		// numbers
	)
		e.preventDefault();
};

/**
 * This will return a default time period based on the chart
 * granularity.
 *
 * @param {str} chartGranularity
 */
export const getDefaultTimePeriod = (chartGranularity) => {
	if (!chartGranularity || chartGranularity == null) return 15778800000; // 6m

	switch (chartGranularity) {
		case 'W':
			return 31557600000; // 1y
		case 'D':
		case 'H12':
		case 'H8':
		case 'H6':
			return 15778800000; // 6m
		case 'H4':
		case 'H3':
		case 'H2':
			return 7889400000; // 3m
		case 'H1':
			return 2629800000; // 1m
		case 'M30':
		case 'M15':
		case 'M10':
			return 1209600000; // 2w
		case 'M5':
		case 'M4':
		case 'M2':
		case 'M1':
			return 604800000; // 1w
		case 'S30':
		case 'S15':
		case 'S10':
		case 'S5':
		default:
			return 259200000; // 3D
	}
};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
	return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
	return _.merge(target, ...sources);
}

/**
 * Returns the highest high bar.
 *
 * @param {obj} plotData [array]
 * 			- this expects data in the form of plotData as found
 * 				in the charts.
 * *
 * @returns
 */
export const getHighestHighPlotData = (plotData) =>
	Math.max.apply(
		Math,
		plotData.map(function (b) {
			return b.high;
		})
	);
export const getLowestLowPlotData = (plotData) =>
	Math.min.apply(
		Math,
		plotData.map(function (b) {
			return b.low;
		})
	);
/**
 * Returns the highest high bar.
 *
 * @param {array} data
 * 			- expects the data to be in an array with
 * 				numbers. IE: [0] => 0.123, [1] => 0.234
 * 				This is how the slidingWindow() function
 * 				sends data.
 *
 * 2021-12-26 14:07:17 JD
 * *
 * @returns {float}
 */
export const getHighestHighData = (data) =>
	Math.max.apply(
		Math,
		data.map(function (b) {
			return b;
		})
	);
export const getLowestLowData = (data) =>
	Math.min.apply(
		Math,
		data.map(function (b) {
			return b;
		})
	);

/**
 * Returns the INDEX of the highest bar
 *
 * 2021-05-31 06:19:15
 *
 * @param {obj} plotData [array]
 * 			- this expects data in the form of plotData as found
 * 				in the charts.
 * *
 * @returns {int}
 */
export const getHighestHighIndexPlotData = (plotData) => {
	let highIndex = 0,
		highest = 0.0;

	/**
	 * Loop through the plotdata and find the highest.
	 */
	for (let index = plotData.length - 1; index > 0; index--) {
		if (parseFloat(plotData[index].high) > highest) {
			highest = parseFloat(plotData[index].high);
			highIndex = index;
		}
	}

	/**
	 * Return the index
	 */
	return highIndex;
};

/**
 * Returns the INDEX of the lowest bar
 *
 * 2021-05-31 06:19:15
 *
 * @param {obj} plotData [array]
 * 			- this expects data in the form of plotData as found
 * 				in the charts.
 * *
 * @returns {int}
 */
export const getLowestLowIndexPlotData = (plotData) => {
	let lowIndex = 0,
		lowest = 0.0;

	/**
	 * Loop through the plotdata and find the lowest.
	 */
	for (let index = plotData.length - 1; index > 0; index--) {
		if (parseFloat(plotData[index].low) < lowest) {
			lowest = parseFloat(plotData[index].low);
			lowIndex = index;
		}
	}

	/**
	 * Return the index
	 */
	return lowIndex;
};
