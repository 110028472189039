import { getHighestHighData, getLowestLowData } from '../../../../../components/utils';
import { UCSMath as defaultOptions } from './defaultOptionsForComputation';
import { slidingWindow, last } from '../utils';

export default function () {
	let options = defaultOptions;

	function calculator(data) {
		const { multiplier, windowSize, sourcePath } = options;

		const calcUcsMath = (windowData) => {
			const high = getHighestHighData(windowData);
			const low = getLowestLowData(windowData);
			const range = high - low;
			const rangeMulti = range * multiplier;
			const midLine = low + rangeMulti * 4;
			const murreysNumber = (last(windowData) - midLine) / (range / 2);

			return murreysNumber || 0;
		};
		const calculation = slidingWindow()
			.windowSize(windowSize)
			.sourcePath(sourcePath)
			.accumulator((values) => calcUcsMath(values));

		return calculation(data);
	}
	calculator.undefinedLength = function () {
		const { windowSize } = options;
		return windowSize - 1;
	};
	calculator.options = function (x) {
		if (!arguments.length) {
			return options;
		}
		options = { ...defaultOptions, ...x };
		return calculator;
	};

	return calculator;
}
