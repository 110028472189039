import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addNewNote, updateNoteStatus } from '../../../../../actions/notes';
import { NotesTable } from './NotesTable';
import { WysiwygEditor } from './WysiwygEditor';

// To use redux hooks, you export a function component, not a react component.
export const NotesAndLists = React.memo((props) => {
	/**
	 * Get the type from our props
	 */
	let { type } = props;

	/**
	 * If type is blank or not valid, assign
	 * it a default value.
	 */
	if (!type || (type !== 'todo' && type !== 'notes' && type !== 'records' && type !== 'quotes' && type !== 'links')) type = 'notes';

	/**
	 * Create our state
	 */
	const [noteStatus, setNoteStatus] = useState('split');
	const notes = useSelector((state) => state.notes.data);
	const dispatch = useDispatch();

	/**
	 * When the user clicks send, this is
	 * what is executed.
	 */
	const handleSendData = (htmlData) => {
		/**
		 * Send it to the server
		 */
		dispatch(addNewNote(htmlData, type));
	};

	/**
	 * Delete a note item.
	 *
	 * @param {str} id - mongo id
	 */
	const handleDelete = (id) => {
		if (!id || id.length <= 0) return;
		dispatch(updateNoteStatus(id, 'deleted'));
	};
	const handleArchived = (id) => {
		if (!id || id.length <= 0) return;
		dispatch(updateNoteStatus(id, 'archived'));
	};
	const handleUndelete = (id) => {
		if (!id || id.length <= 0) return;
		dispatch(updateNoteStatus(id, 'active'));
	};

	/**
	 * This coordinates what render method to
	 * use based on the prop type.
	 */
	const renderNotesByType = () => {
		if (!notes || notes.length <= 0) return;

		/**
		 * Filter the notes array by the
		 * type selected
		 */
		const curratedNotes =
			noteStatus === 'split'
				? notes
						.filter((note) => note.type === type && note.status !== 'deleted')
						.filter((note) => note.status !== 'archived' || new Date().getTime() - new Date(note.updated).getTime() < 432000000)
				: // 5 days in ms)

				  notes.filter((note) => note.type === type && note.status === noteStatus);

		/**
		 * Return our custom notes
		 */
		return (
			<NotesTable
				data={curratedNotes}
				type={type}
				handleDelete={handleDelete}
				handleArchived={handleArchived}
				handleUndelete={handleUndelete}
				setNoteStatus={setNoteStatus}
				noteStatus={noteStatus}
			/>
		);
	};

	/**
	 * Render the input/view
	 */
	return (
		<>
			<h5 className="notes-header">{type}</h5>
			{renderNotesByType()}
			<footer className="mt-3">
				<WysiwygEditor handleSendData={handleSendData} type={type} />
			</footer>
		</>
	);
});
