import React from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../../../../components/Loader/Loader';

// To use redux hooks, you export a function component, not a react component.
export const Console = () => {
	const consoleData = useSelector((state) => state.console.data);

	/**
	 * Return nothing if no console data
	 */
	if (consoleData == null) return <Loader />;

	/**
	 * This will render our line items
	 */
	const renderLines = () =>
		consoleData.map((line) => {
			return (
				<div key={Math.random().toString(36).substr(2, 7)}>
					<span className="line4">
						<small>
							[{line.identifier}@{line.host} ~ ]$
						</small>
					</span>{' '}
					<span className="line1">{line.message} </span>
				</div>
			);
		});

	return (
		<div className="fakeConsole">
			<div className="fakeScreen">
				{renderLines()}
				<p className="line4">
					{'>'}
					<span className="cursor4">_</span>
				</p>
			</div>
		</div>
	);
};
