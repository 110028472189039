import { fetchCOTData, fetchGenericApiData, fetchPositionBook, fetchOrderBook, resetCOTData, clearGenericApiData } from '../../../actions/oanda';
import { bollingerband } from '../../../libs/react-stockcharts/src/lib/calculator';
import sma from '../../../libs/react-stockcharts/src/lib/calculator/sma';
import { hexToRGBA } from '../../../libs/react-stockcharts/src/lib/utils';
import { mergeDeep } from '../../utils';
import { chartColors } from './genericConfig';

/* eslint-disable no-extend-native */
// Date.prototype.getWeek = function () {
// 	var onejan = new Date(this.getFullYear(), 0, 1);
// 	var today = new Date(this.getFullYear(), this.getMonth(), this.getDate());
// 	var dayOfYear = (today - onejan + 86400000) / 86400000;
// 	return Math.ceil(dayOfYear / 7);
// };
Date.prototype.getWeek = function () {
	var dt = new Date(this.getFullYear(), 0, 1);
	return Math.ceil(((this - dt) / 86400000 + dt.getDay() + 1) / 7);
};

// Date.prototype.getWeek = function () {
// 	// We have to compare against the first monday of the year not the 01/01
// 	// 60*60*24*1000 = 86400000
// 	// 'onejan_next_monday_time' reffers to the miliseconds of the next monday after 01/01

// 	var day_miliseconds = 86400000,
// 		onejan = new Date(this.getFullYear(), 0, 1, 0, 0, 0),
// 		onejan_day = onejan.getDay() == 0 ? 7 : onejan.getDay(),
// 		days_for_next_monday = 8 - onejan_day,
// 		onejan_next_monday_time = onejan.getTime() + days_for_next_monday * day_miliseconds,
// 		// If one jan is not a monday, get the first monday of the year
// 		first_monday_year_time = onejan_day > 1 ? onejan_next_monday_time : onejan.getTime(),
// 		this_date = new Date(this.getFullYear(), this.getMonth(), this.getDate(), 0, 0, 0), // This at 00:00:00
// 		this_time = this_date.getTime(),
// 		days_from_first_monday = Math.round((this_time - first_monday_year_time) / day_miliseconds);

// 	var first_monday_year = new Date(first_monday_year_time);

// 	// We add 1 to "days_from_first_monday" because if "days_from_first_monday" is *7,
// 	// then 7/7 = 1, and as we are 7 days from first monday,
// 	// we should be in week number 2 instead of week number 1 (7/7=1)
// 	// We consider week number as 52 when "days_from_first_monday" is lower than 0,
// 	// that means the actual week started before the first monday so that means we are on the firsts
// 	// days of the year (ex: we are on Friday 01/01, then "days_from_first_monday"=-3,
// 	// so friday 01/01 is part of week number 52 from past year)
// 	// "days_from_first_monday<=364" because (364+1)/7 == 52, if we are on day 365, then (365+1)/7 >= 52 (Math.ceil(366/7)=53) and thats wrong

// 	return days_from_first_monday >= 0 && days_from_first_monday < 364 ? Math.ceil((days_from_first_monday + 1) / 7) : 52;
// };
/* eslint-disable no-extend-native */

/**
 * Why the function? Import caches the array if not...
 * 2021-04-01 10:17:46 - JD
 *
 * @returns array
 */
export const indicatorOptionsFunction = () => {
	let indicatorOptions = [
		{
			id: 'ATR',
			label: 'ATR',
			value: 'ATR',
			type: 'ATR', // This is used in the chart indicators
			category: 'Momentum', // Used in indicator sorting
			isOutside: true,
			arguments: {
				length: 14, // The length/period (of bars)
				stroke: chartColors.orangeRed,
				valueFill: chartColors.orangeRed,
				labelFill: chartColors.white,
				highlightOnHover: true,
				strokeWidth: 2,
			},
			seriesArguments: {
				height: 100,
			},
		},
		{
			id: 'ATRText',
			label: 'ATR Text',
			value: 'ATRText',
			type: 'text', // This is used in the chart indicators
			category: 'Momentum', // Used in indicator sorting
			isOutside: false,
			arguments: {
				length: 14, // The length/period (of bars)
				origin: (w, h) => [w - 70, 5], // x, y on the chart
				yLabel: 'ATR',
			},
		},
		{
			id: 'BollingerBand',
			label: 'Bollinger Bands',
			value: 'BollingerBand',
			type: 'BollingerBand', // This is used in the chart indicators
			category: 'Momentum', // Used in indicator sorting
			isOutside: false,
			arguments: {
				length: 20, // The length/period (of bars) - this is called windowsize in the calculator
				stdDev: 2, // This is called "multiplier" in the calculator.
				movingAverageType: 'ema',
				sessionType: 'none',
				additionalMultipliers: [],
			},
			seriesArguments: {
				stroke: {
					top: chartColors.faded,
					middle: chartColors.whiteHalfOp,
					bottom: chartColors.faded,
				},
				strokeWidth: {
					top: 1,
					middle: 1,
					bottom: 1,
				},
				opacity: 0.05,
				fill: chartColors.genericBlueGray,
			},
			tooltipArguments: {
				textFill: chartColors.white,
				labelFill: chartColors.downColorDark,
				fontFamily:
					'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
			},
		},
		{
			id: 'BBWidth',
			label: 'Bollinger Bands Width', // This is how it is displayed in the dropdown
			value: 'BBWidth', // This is the actual value in the dropdown, also used as the map name
			type: 'genericSeries', // This is used in the chart indicators
			category: 'Momentum', // Used in indicator sorting
			isOutside: true,
			arguments: {
				mergeFunctions: [
					{
						execute: (data, options) => {
							const func = bollingerband().options(options);
							return func(data);
						},
					},
				],
				mergeOptions: {
					windowSize: 20, // Length
					multiplier: 2, // stdev
					movingAverageType: 'ema',
					sessionType: 'none',
				},
				yAccessor: (d, props) => d?.[props?.fetchName]?.otherData?.bbWidth || 0,
				yExtents: (d, props) => d?.[props?.fetchName]?.otherData?.bbWidth || 0,
				get fetchName() {
					let params = '';
					for (const key in this.mergeOptions) params += this.mergeOptions[key];

					return `BBWidth_${params.replace(/\D/g, '')}`;
				}, // Used in CustomChart to make sure the value exists
				lineSeriesCount: 1, // The number of lines needed to draw, this is 1 + allData length
				stroke: [['#A2F5BF', 1]],
				labels: (props) => {
					/**
					 * The props passed in here are from arguments down, nothing before or after (outside) of
					 * it.
					 */
					return `${props.value} (${props.mergeOptions.windowSize}, ${props.mergeOptions.multiplier}, ${props.mergeOptions.movingAverageType})`;
				},
				noZeroLine: true,
			},
			seriesArguments: {
				height: 100,
			},
		},
		{
			id: 'BBWidthNorm',
			label: 'Bollinger Bands Width Normalized', // This is how it is displayed in the dropdown
			value: 'BBWidth Normalized', // This is the actual value in the dropdown, also used as the map name
			type: 'genericSeries', // This is used in the chart indicators
			category: 'Momentum', // Used in indicator sorting
			isOutside: true,
			arguments: {
				mergeFunctions: [
					{
						execute: (data, options) => {
							const func = bollingerband().options(options);
							return func(data);
						},
					},
				],
				mergeOptions: {
					windowSize: 20, // Length
					multiplier: 2, // stdev
					normalizeWidth: true,
					normalizePositive: 0.25, // If higher than this, it is positive.
					sessionType: 'none',
					movingAverageType: 'default',
				},
				yAccessor: (d, props) => d?.[props?.fetchName]?.otherData?.bbWidthNorm || 0,
				// yAccessorDivergence: (d, props) => d?.BBWidthNorm?.otherData?.bbWidthNorm || 0,
				yExtents: (d, props) => d?.[props?.fetchName]?.otherData?.bbWidthNorm || 0,
				get fetchName() {
					let params = '';
					for (const key in this.mergeOptions) params += this.mergeOptions[key];

					return `BBWidthNorm_${params.replace(/\D/g, '')}`;
				}, // Used in CustomChart to make sure the value exists
				lineSeriesCount: 1, // The number of lines needed to draw, this is 1 + allData length
				// divergenceStroke: '#A2F5BF',
				stroke: [[chartColors.moodyPurple, 1]],
				fill: {
					divergencePositive: chartColors.moodyPurple,
					divergenceNegative: chartColors.mehroon,
				},
				labels: (props) => {
					/**
					 * The props passed in here are from arguments down, nothing before or after (outside) of
					 * it.
					 */
					return `BBW Norm (${props.mergeOptions.windowSize}, ${props.mergeOptions.multiplier}, ${props.mergeOptions.normalizePositive})`;
				},
				// labels: ['Norm BBW'],
				lineAt: [0.25],
				noZeroLine: true,
			},
			seriesArguments: {
				height: 100,
			},
		},
		{
			id: 'BBPercentR',
			label: 'Bollinger Bands %R', // This is how it is displayed in the dropdown
			value: 'BBPercentR', // This is the actual value in the dropdown, also used as the map name
			type: 'genericSeries', // This is used in the chart indicators
			category: 'Momentum', // Used in indicator sorting
			isOutside: true,
			arguments: {
				mergeFunctions: [
					{
						execute: (data, options) => {
							const func = bollingerband().options(options);
							return func(data);
						},
					},
				],
				mergeOptions: {
					windowSize: 20, // Length
					multiplier: 2, // stdev
					sessionType: 'none',
				},
				yAccessor: (d, props) => d?.[props?.fetchName]?.otherData?.bbPercentR || 0,
				yExtents: (d, props) => d?.[props?.fetchName]?.otherData?.bbPercentR || 0,
				get fetchName() {
					let params = '';
					for (const key in this.mergeOptions) params += this.mergeOptions[key];

					return `BBPercentR_${params.replace(/\D/g, '')}`;
				},
				lineSeriesCount: 1, // The number of lines needed to draw, this is 1 + allData length
				stroke: [[chartColors.lightBlue, 1]],
				labels: ['BB%'],
				lineAt: 1,
			},
			seriesArguments: {
				height: 100,
			},
		},
		{
			id: 'ConsolidationZones',
			label: 'Consolidation Zones',
			value: 'ConsolidationZones',
			type: 'ConsolidationZones', // This is used in the chart indicators
			category: 'Zone', // Used in indicator sorting
			isOutside: false,
			arguments: {
				length: 20,
				breakOutBars: 2,
				lookbackPeriod: 20, // Loopback Period (bars back)
				consolidationLen: 10, // Consolidation Length/bars
				atrLength: 25,
			},
			seriesArguments: {
				strokeWidth: 1, // Stroke width on Line Series
				strokeOpacity: 1, // Stroke Opacity on Line Series
				hoverStrokeWidth: 4, // Hover Stroke Opacity on Line Series
				fill: 'none',
				strokeDasharray: 'Solid', // See bottom for options
				hoverTolerance: 6, // Unsure what this is
				highlightOnHover: true, // Boolean
			},
		},
		{
			id: 'EnterExits',
			label: 'Entries & Exits (testing)',
			value: 'EnterExits',
			type: 'EnterExits', // This is used in the chart indicators
			category: 'Own', // Used in indicator sorting
			isOutside: false,
			arguments: {
				enterWhen: () => {},
				exitWhen: () => {},
			},
		},
		{
			id: 'SignalLines',
			label: 'Signal Lines',
			value: 'SignalLines',
			type: 'SignalLines', // This is used in the chart indicators
			category: 'Own', // Used in indicator sorting
			isOutside: false,
			arguments: {},
		},
		{
			id: 'PositionCoordinates',
			label: 'Positions',
			value: 'PositionCoordinates',
			type: 'PositionCoordinates', // This is used in the chart indicators
			category: 'Own', // Used in indicator sorting
			isOutside: false,
			arguments: {},
		},
		{
			id: 'TradesCoordinates',
			label: 'Trades',
			value: 'TradesCoordinates',
			type: 'TradesCoordinates', // This is used in the chart indicators
			category: 'Own', // Used in indicator sorting
			isOutside: false,
			arguments: {},
		},
		{
			id: 'OrdersCoordinates',
			label: 'Orders',
			value: 'OrdersCoordinates',
			type: 'OrdersCoordinates', // This is used in the chart indicators
			category: 'Own', // Used in indicator sorting
			isOutside: false,
			arguments: {},
			seriesArguments: {},
		},
		{
			id: 'Detrend',
			label: 'Detrend',
			value: 'Detrend',
			type: 'Detrend', // This is used in the chart indicators
			category: 'Trend', // Used in indicator sorting
			isOutside: false,
			arguments: {},
			seriesArguments: {},
		},
		{
			id: 'EMA20',
			label: 'EMA20',
			value: 'EMA20',
			type: 'EMA', // This is used in the chart indicators
			category: 'Moving Average', // Used in indicator sorting
			isOutside: false,
			arguments: {
				length: 20,
				stroke: chartColors.orangeRed,
			},
			seriesArguments: {
				strokeWidth: 1, // Stroke width on Line Series
				strokeOpacity: 1, // Stroke Opacity on Line Series
				hoverStrokeWidth: 4, // Hover Stroke Opacity on Line Series
				fill: 'none',
				strokeDasharray: 'Solid', // See bottom for options
				hoverTolerance: 6, // Unsure what this is
				highlightOnHover: true, // Boolean
			},
		},
		{
			id: 'EMA50',
			label: 'EMA50',
			value: 'EMA50',
			type: 'EMA', // This is used in the chart indicators
			category: 'Moving Average', // Used in indicator sorting
			isOutside: false,
			arguments: {
				length: 50,
				stroke: chartColors.red,
			},
			seriesArguments: {
				strokeWidth: 2, // Stroke width on Line Series
				strokeOpacity: 1, // Stroke Opacity on Line Series
				hoverStrokeWidth: 4, // Hover Stroke Opacity on Line Series
				fill: 'none',
				strokeDasharray: 'Solid', // See bottom for options
				hoverTolerance: 6, // Unsure what this is
				highlightOnHover: true, // Boolean
			},
		},
		{
			id: 'MACD',
			label: 'MACD (12.26.9)', // This is how it is displayed in the dropdown
			value: 'MACD12.26.9', // This is the actual value in the dropdown, also used as the map name
			type: 'macd', // This is used in the chart indicators
			category: 'Moving Average', // Used in indicator sorting
			isOutside: true,
			arguments: {
				fast: 12,
				slow: 26,
				signal: 9,
			},
			seriesArguments: {
				height: 150,
			},
		},
		{
			id: 'Qstick',
			label: 'Qstick', // This is how it is displayed in the dropdown
			value: 'Qstick', // This is the actual value in the dropdown, also used as the map name
			type: 'genericSeries', // This is used in the chart indicators
			category: 'Momentum', // Used in indicator sorting
			isOutside: true,
			arguments: {
				mergeFunctions: [
					{
						execute: (data, options) => {
							const func = sma().options(options);
							return func(data);
						},
					},
				],
				mergeOptions: {
					windowSize: 50, // Length
					sourcePath: 'closeMinusOpen',
				},
				yAccessorDivergence: (d) => {
					return d.QstickSMA1;
				},
				yExtents: (d) => d.QstickSMA1,
				fetchName: 'QstickSMA1', // Used in the cache to prevent multiple fetches.
				lineSeriesCount: 0, // The number of lines needed to draw, this is 1 + allData length
				stroke: [
					['#A2F5BF', 2],
					['#e671b8', 2],
				],
			},
			seriesArguments: {
				height: 100,
			},
		},
		{
			id: 'RSI',
			label: 'RSI', // This is how it is displayed in the dropdown
			value: 'RSI', // This is the actual value in the dropdown, also used as the map name
			type: 'rsi', // This is used in the chart indicators
			category: 'Momentum', // Used in indicator sorting
			isOutside: true,
			arguments: {
				length: 14, // The length/period (of bars)
			},
			seriesArguments: {
				height: 150,
			},
		},
		{
			id: 'SMA20', // This doesn't appear to be used in the dropdown?
			label: 'SMA20', // This is how it is displayed in the dropdown
			value: 'SMA20', // This is the actual value in the dropdown, also used as the map name, also used as yaccessor
			type: 'SMA', // This is used in the chart indicators
			category: 'Moving Average', // Used in indicator sorting
			isOutside: false,
			arguments: {
				length: 20,
				stroke: 'yellow',
			},
			seriesArguments: {
				strokeWidth: 1, // Stroke width on Line Series
				strokeOpacity: 1, // Stroke Opacity on Line Series
				hoverStrokeWidth: 4, // Hover Stroke Opacity on Line Series
				fill: 'none',
				strokeDasharray: 'Solid', // See bottom for options
				hoverTolerance: 6, // Unsure what this is
				highlightOnHover: true, // Boolean
			},
		},
		{
			id: 'SMA50', // This doesn't appear to be used in the dropdown?
			label: 'SMA50', // This is how it is displayed in the dropdown
			value: 'SMA50', // This is the actual value in the dropdown, also used as the map name
			type: 'SMA', // This is used in the chart indicators
			category: 'Moving Average', // Used in indicator sorting
			isOutside: false,
			arguments: {
				length: 50,
				stroke: chartColors.red,
			},
			seriesArguments: {
				strokeWidth: 2, // Stroke width on Line Series
				strokeOpacity: 1, // Stroke Opacity on Line Series
				hoverStrokeWidth: 4, // Hover Stroke Opacity on Line Series
				fill: 'none',
				strokeDasharray: 'Solid', // See bottom for options
				hoverTolerance: 6, // Unsure what this is
				highlightOnHover: true, // Boolean
			},
		},
		{
			id: 'VolumeProfileLines', // Visible volume Profile
			label: 'VProf (Lines)',
			value: 'VolumeProfileLines',
			type: 'VolumeProfileLines', // This is used in the chart indicators
			category: 'Volume', // Used in indicator sorting
			isOutside: false,
			arguments: {
				bucketCount: 30,
			},
		},
		{
			id: 'VolumeProfileVisible', // Visible volume Profile
			label: 'VProf (Vis)',
			value: 'VolumeProfileSeriesVis',
			type: 'VolumeProfileSeries', // This is used in the chart indicators
			category: 'Volume', // Used in indicator sorting
			isOutside: false,
			arguments: {
				bySession: false,
				orient: 'left',
				showSessionBackground: false,
				maxProfileWidthPercent: 90, // The higher, the smaller it gets which is contrary...
				bins: 30,
			},
		},
		{
			id: 'VolumeProfileH',
			label: 'VProf (Hour)',
			value: 'VolumeProfileSeriesH',
			type: 'VolumeProfileSeries', // This is used in the chart indicators
			category: 'Volume', // Used in indicator sorting
			isOutside: false,
			arguments: {
				bySession: true,
				orient: 'right',
				showSessionBackground: true,
				sessionStart: ({ d, i, plotData }) => i > 0 && plotData[i - 1].date.getHours() !== d.date.getHours(),
			},
		},
		{
			id: 'VolumeProfileD',
			label: 'VProf (D)',
			value: 'VolumeProfileSeriesD',
			type: 'VolumeProfileSeries', // This is used in the chart indicators
			category: 'Volume', // Used in indicator sorting
			isOutside: false,
			arguments: {
				bySession: true,
				orient: 'right',
				showSessionBackground: true,
				sessionStart: ({ d, i, plotData }) => i > 0 && plotData[i - 1].date.getDay() !== d.date.getDay(),
			},
		},
		{
			id: 'VolumeProfileW',
			label: 'VProf (W)',
			value: 'VolumeProfileSeriesW',
			type: 'VolumeProfileSeries', // This is used in the chart indicators
			category: 'Volume', // Used in indicator sorting
			isOutside: false,
			arguments: {
				bySession: true,
				orient: 'right',
				showSessionBackground: true,
				sessionStart: ({ d, i, plotData }) => i > 0 && plotData[i - 1].date.getWeek() !== d.date.getWeek(),
			},
		},
		{
			id: 'VolumeProfileM',
			label: 'VProf (M)',
			value: 'VolumeProfileSeriesM',
			type: 'VolumeProfileSeries', // This is used in the chart indicators
			category: 'Volume', // Used in indicator sorting
			isOutside: false,
			arguments: {
				bySession: true,
				orient: 'right',
				showSessionBackground: true,
				sessionStart: ({ d, i, plotData }) => i > 0 && plotData[i - 1].date.getMonth() !== d.date.getMonth(),
			},
		},
		{
			id: 'VolumeProfileY',
			label: 'VProf (Y)',
			value: 'VolumeProfileSeriesY',
			type: 'VolumeProfileSeries', // This is used in the chart indicators
			category: 'Volume', // Used in indicator sorting
			isOutside: false,
			arguments: {
				bySession: true,
				orient: 'right',
				showSessionBackground: true,
				sessionStart: ({ d, i, plotData }) => i > 0 && plotData[i - 1].date.getYear() !== d.date.getYear(),
			},
		},
		{
			id: 'Volume-1',
			label: 'Volume',
			value: 'Volume',
			type: 'Volume', // This is used in the chart indicators
			category: 'Volume', // Used in indicator sorting
			isOutside: false,
			arguments: {},
			seriesArguments: {
				height: 150,
			},
		},
		{
			id: 'Volume-2', // This doesn't appear to be used in the dropdown?
			label: 'Vol & SMA50', // This is how it is displayed in the dropdown
			value: 'vSMA50', // This is the actual value in the dropdown, also used as the map name
			type: 'vSMA50', // This is used in the chart indicators array
			category: 'Volume', // Used in indicator sorting
			isOutside: false,
			arguments: {
				length: 50,
				stroke: chartColors.lightBlue,
			},
			seriesArguments: {
				strokeWidth: 4, // Stroke width on Line Series
				strokeOpacity: 1, // Stroke Opacity on Line Series
				hoverStrokeWidth: 4, // Hover Stroke Opacity on Line Series
				fill: chartColors.lightBlue,
				strokeDasharray: 'Solid', // See bottom for options
				hoverTolerance: 6, // Unsure what this is
				highlightOnHover: true, // Boolean
				height: 150,
			},
		},
		{
			id: 'OpenInterest',
			label: 'Open Interest Volume',
			value: 'cot',
			type: 'openInterestVolume', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: false,
			arguments: {
				fetchData: (options) => fetchCOTData(options),
				resetData: () => resetCOTData(),
				fetchName: 'cot', // Used in the cache to prevent multiple fetches.
				needsTime: true,
			},
			seriesArguments: {
				height: 150,
			},
		},
		{
			id: 'COTPercent',
			label: 'COT Data (%)',
			value: 'cotPercent',
			type: 'cot', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: true,
			arguments: {
				usePercent: true,
				fetchData: (options) => fetchCOTData(options),
				resetData: () => resetCOTData(),
				fetchName: 'cot', // Used in the cache to prevent multiple fetches.
				needsTime: true,
			},
			seriesArguments: {
				height: 150,
			},
		},
		{
			id: 'COT',
			label: 'COT Data',
			value: 'cot',
			type: 'cot', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: true,
			arguments: {
				usePercent: false,
				fetchData: (options) => fetchCOTData(options),
				resetData: () => resetCOTData(),
				fetchName: 'cot', // Used in the cache to prevent multiple fetches.
				needsTime: true,
			},
			seriesArguments: {
				height: 150,
			},
		},
		{
			id: 'COTLongShortText',
			label: 'COT Long/Short %',
			value: 'COTLongShortText',
			type: 'text', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: false,
			arguments: {
				usePercent: false,
				fetchData: (options) => fetchCOTData(options),
				resetData: () => resetCOTData(),
				fetchName: 'cot', // Used in the cache to prevent multiple fetches.
				needsTime: true,
				origin: [175, 23],
			},
		},
		{
			id: 'LongShortText',
			label: 'Long/Short %',
			value: 'LongShortText',
			type: 'text', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: false,
			arguments: {
				origin: [50, 23],
				fetchData: ({ instrument }) => fetchPositionBook(instrument),
				fetchName: 'positionBook', // Used in the cache to prevent multiple fetches.
				needsTime: false,
			},
		},
		{
			id: 'FRED_GDP', //Federal Reserve Economic Data
			label: 'FRED Gross Domestic Product',
			value: 'FRED_GDP',
			type: 'genericSeries', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: true,
			arguments: {
				fetchData: (options) => fetchGenericApiData('/api/quandl/dataset?database_code=FRED&dataset_code=GDPPOT', 'FRED_GDP', options),
				resetData: (indicator) => clearGenericApiData(indicator.arguments.fetchName),
				fetchName: 'FRED_GDP', // Used in the cache to prevent multiple fetches.
				needsTime: true,
				lineSeriesCount: 1, // The number of lines needed to draw, this is 1 + allData length
			},
			seriesArguments: {
				height: 150,
			},
		},
		{
			id: 'FRED_RS', //Federal Reserve Economic Data Retail SAles
			label: 'FRED Retail Sales',
			value: 'FRED_RS',
			type: 'genericSeries', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: true,
			arguments: {
				fetchData: (options) => fetchGenericApiData('/api/quandl/dataset?database_code=FRED&dataset_code=RSXFS', 'FRED_RS', options),
				resetData: (indicator) => clearGenericApiData(indicator.arguments.fetchName),
				fetchName: 'FRED_RS', // Used in the cache to prevent multiple fetches.
				needsTime: true,
				lineSeriesCount: 1, // The number of lines needed to draw, this is 1 + allData length
				stroke: [['#474D84', 2]],
			},
			seriesArguments: {
				height: 150,
			},
		},
		{
			id: 'FRED_RoU', //Federal Reserve Economic Data
			label: 'FRED Rate of Unemployment',
			value: 'FRED_RoU',
			type: 'genericSeries', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: true,
			arguments: {
				fetchData: (options) => fetchGenericApiData('/api/quandl/dataset?database_code=FRED&dataset_code=NROUST', 'FRED_RoU', options),
				resetData: (indicator) => clearGenericApiData(indicator.arguments.fetchName),
				fetchName: 'FRED_RoU', // Used in the cache to prevent multiple fetches.
				needsTime: true,
				lineSeriesCount: 1, // The number of lines needed to draw, this is 1 + allData length
			},
			seriesArguments: {
				height: 150,
			},
		},
		{
			id: 'LongTermInterest', //Federal Reserve Economic Data
			label: 'Long Term Interest',
			value: 'LongTermInterest',
			type: 'genericSeries', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: true,
			arguments: {
				fetchData: (options) => fetchGenericApiData(`/api/getLongShortInterest/${options.instrument}`, 'LongTermInterest', options),
				resetData: (indicator) => clearGenericApiData(indicator.arguments.fetchName),
				fetchName: 'LongTermInterest', // Used in the cache to prevent multiple fetches.
				needsTime: true,
				lineSeriesCount: 2, // The number of lines needed to draw, this is 1 + allData length
				stroke: [
					['#A2F5BF', 2],
					['#e671b8', 2],
				],
			},
			seriesArguments: {
				height: 75,
			},
		},
		{
			id: 'LongTermInterestMACD', //Federal Reserve Economic Data
			label: 'Long Term Interest MACD',
			value: 'LongTermInterestMACD',
			type: 'genericSeries', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: true,
			arguments: {
				fetchData: (options) => fetchGenericApiData(`/api/getLongShortInterest/${options.instrument}`, 'LongTermInterest', options),
				resetData: (indicator) => clearGenericApiData(indicator.arguments.fetchName),
				yAccessorDivergence: (d) => {
					return d.LongTermInterest.allData[0].value - d.LongTermInterest.allData[1].value;
				},
				yExtents: (d) => {
					if (d.LongTermInterest && d.LongTermInterest.allData) return [d.LongTermInterest.allData[0].value - d.LongTermInterest.allData[1].value, 0];
					else return 0;
				},
				fetchName: 'LongTermInterest', // Used in the cache to prevent multiple fetches.
				needsTime: true,
				lineSeriesCount: 0, // The number of lines needed to draw, this is 1 + allData length
				stroke: [
					['#A2F5BF', 2],
					['#e671b8', 2],
				],
			},
			seriesArguments: {
				height: 75,
			},
		},
		{
			id: 'ShortTermInterest', //Federal Reserve Economic Data
			label: 'Short Term Interest',
			value: 'ShortTermInterest',
			type: 'genericSeries', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: true,
			arguments: {
				fetchData: (options) =>
					fetchGenericApiData(`/api/getLongShortInterest/${options.instrument}`, 'ShortTermInterest', { ...options, collection: 'short' }),
				resetData: (indicator) => clearGenericApiData(indicator.arguments.fetchName),
				fetchName: 'ShortTermInterest', // Used in the cache to prevent multiple fetches.
				needsTime: true,
				lineSeriesCount: 2, // The number of lines needed to draw, this is 1 + allData length
				stroke: [
					['#A2F5BF', 2],
					['#e671b8', 2],
				],
			},
			seriesArguments: {
				height: 75,
			},
		},
		{
			id: 'MAN_PMI',
			label: 'MAN PMI',
			value: 'MAN_PMI',
			type: 'genericSeries', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: true,
			arguments: {
				fetchData: (options) => fetchGenericApiData('/api/quandl/dataset?database_code=ISM&dataset_code=MAN_PMI', 'MAN_PMI', options),
				resetData: (indicator) => clearGenericApiData(indicator.arguments.fetchName),
				fetchName: 'MAN_PMI', // Used in the cache to prevent multiple fetches.
				needsTime: true,
				lineSeriesCount: 1, // The number of lines needed to draw, this is 1 + allData length
			},
			seriesArguments: {
				height: 150,
			},
		},
		{
			id: 'TRES_YIELD',
			label: 'US Treasury Yield',
			value: 'TRES_YIELD',
			type: 'genericSeries', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: true,
			arguments: {
				fetchData: (options) => fetchGenericApiData('/api/quandl/dataset?database_code=USTREASURY&dataset_code=YIELD', 'TRES_YIELD', options),
				resetData: (indicator) => clearGenericApiData(indicator.arguments.fetchName),
				fetchName: 'TRES_YIELD', // Used in the cache to prevent multiple fetches.
				needsTime: true,
				lineSeriesCount: 1, // The number of lines needed to draw, this is 1 + allData length
			},
			seriesArguments: {
				height: 150,
			},
		},
		{
			id: 'UMICH',
			label: 'University of Michigan Consumer Survey',
			value: 'UMICH',
			type: 'genericSeries', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: true,
			arguments: {
				fetchData: (options) => fetchGenericApiData('/api/quandl/dataset?database_code=UMICH&dataset_code=SOC1', 'UMICH', options),
				resetData: (indicator) => clearGenericApiData(indicator.arguments.fetchName),
				fetchName: 'UMICH', // Used in the cache to prevent multiple fetches.
				needsTime: true,
				lineSeriesCount: 1, // The number of lines needed to draw, this is 1 + allData length
				stroke: [['#474D84', 2]],
			},
			seriesArguments: {
				height: 150,
			},
		},
		{
			id: 'PositionBookIndex',
			label: 'Position Book Index',
			value: 'PositionBookIndex',
			type: 'genericSeries', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: true,
			arguments: {
				fetchData: (options) => fetchGenericApiData('/api/getBookHistoryFromDate/position', 'PositionBookIndex', options),
				resetData: (indicator) => clearGenericApiData(indicator.arguments.fetchName),
				fetchName: 'PositionBookIndex', // Used in the cache to prevent multiple fetches.
				needsTime: true,
				yExtents: (d, props) => {
					if (d?.[props?.fetchName]?.value) return [d[props.fetchName].value];
					else return 0;
				},
				yAccessorDivergence: (d, props) => {
					if (d?.[props?.fetchName]?.value) return d[props.fetchName].value;
					else return 0;
				},
				lineSeriesCount: 0, // The number of lines needed to draw, this is 1 + allData length
				stroke: [[chartColors.moodyPurple, 1]],
				fill: {
					divergencePositive: chartColors.moodyPurple,
					divergenceNegative: chartColors.mehroon,
				},
				labels: (props) => {
					/**
					 * The props passed in here are from arguments down, nothing before or after (outside) of
					 * it.
					 */
					return 'Positions';
				},
				// labels: ['Norm BBW'],
				lineAt: [0],
				noZeroLine: true,
				showEntrySignals: false,
				entryLongWhen: (currentValue, idx, chartData) => {
					return false;
					//idx > 0 && chartData?.[idx]?.PositionBookIndex?.value >= 20 && chartData?.[idx - 1]?.PositionBookIndex?.value < 20
				},
				entryShortWhen: (currentValue, idx, chartData) =>
					idx > 0 && chartData?.[idx]?.PositionBookIndex?.value <= -20 && chartData?.[idx - 1]?.PositionBookIndex?.value > -20,
			},
			seriesArguments: {
				height: 75,
			},
		},
		{
			id: 'OrderBookIndex',
			label: 'Order Book Index',
			value: 'OrderBookIndex',
			type: 'genericSeries', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: true,
			arguments: {
				fetchData: (options) => fetchGenericApiData('/api/getBookHistoryFromDate/order', 'OrderBookIndex', options),
				resetData: (indicator) => clearGenericApiData(indicator.arguments.fetchName),
				fetchName: 'OrderBookIndex', // Used in the cache to prevent multiple fetches.
				needsTime: true,
				yExtents: (d, props) => {
					if (d?.[props?.fetchName]?.value) return [d[props.fetchName].value];
					else return 0;
				},
				yAccessorDivergence: (d, props) => {
					if (d?.[props?.fetchName]?.value) return d[props.fetchName].value;
					else return 0;
				},
				lineSeriesCount: 0, // The number of lines needed to draw, this is 1 + allData length
				stroke: [[chartColors.moodyPurple, 1]],
				fill: {
					divergencePositive: chartColors.moodyPurple,
					divergenceNegative: chartColors.mehroon,
				},
				labels: (props) => {
					/**
					 * The props passed in here are from arguments down, nothing before or after (outside) of
					 * it.
					 *
					 *
					 */
					return 'Orders';
				},
				// labels: ['Norm BBW'],
				lineAt: [0],
				noZeroLine: true,
			},
			seriesArguments: {
				height: 75,
			},
		},
		{
			id: 'PositionBookNet',
			label: 'Position Book (net)',
			value: 'PositionBookNet',
			type: 'positionbook', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: false,
			arguments: {
				netPosition: true,
				fetchData: ({ instrument }) => fetchPositionBook(instrument),
				fetchName: 'positionBook', // Used in the cache to prevent multiple fetches.
				needsTime: false,
			},
		},
		{
			id: 'PositionBook',
			label: 'Position Book',
			value: 'PositionBook',
			type: 'positionbook', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: false,
			arguments: {
				netPosition: false,
				fetchData: ({ instrument }) => fetchPositionBook(instrument),
				fetchName: 'positionBook', // Used in the cache to prevent multiple fetches.
				needsTime: false,
			},
		},
		{
			id: 'PositionBookLine',
			label: 'Position Book (Line)',
			value: 'PositionBookLine',
			type: 'positionbook', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: false,
			arguments: {
				netPosition: false,
				drawType: 'line',
				centerLineOpacity: 0.1,
				fetchData: ({ instrument }) => fetchPositionBook(instrument),
				fetchName: 'positionBook', // Used in the cache to prevent multiple fetches.
				needsTime: false,
			},
		},
		{
			id: 'PositionBookLineNet',
			label: 'Position Book (Line Net)',
			value: 'PositionBookLineNet',
			type: 'positionbook', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: false,
			arguments: {
				netPosition: true,
				drawType: 'line',
				centerLineOpacity: 0.1,
				fetchData: ({ instrument }) => fetchPositionBook(instrument),
				fetchName: 'positionBook', // Used in the cache to prevent multiple fetches.
				needsTime: false,
			},
		},
		{
			id: 'OrderBookNet',
			label: 'Order Book (net)',
			value: 'OrderBookNet',
			type: 'orderbook', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: false,
			arguments: {
				netPosition: true,
				bookType: 'order',
				showLargestLines: true, // Draws a line on the largest positions ....
				largestLineCount: 2, // ... like the MT4 indicator
				fetchData: ({ instrument }) => fetchOrderBook(instrument),
				fetchName: 'orderBook', // Used in the cache to prevent multiple fetches.
				needsTime: false,
			},
		},
		{
			id: 'OrderBook',
			label: 'Order Book',
			value: 'OrderBook',
			type: 'orderbook', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: false,
			arguments: {
				netPosition: false,
				bookType: 'order',
				showLargestLines: true, // Draws a line on the largest positions ....
				largestLineCount: 2, // ... like the MT4 indicator
				fetchData: ({ instrument }) => fetchOrderBook(instrument),
				fetchName: 'orderBook', // Used in the cache to prevent multiple fetches.
				needsTime: false,
			},
		},
		{
			id: 'OrderBookLine',
			label: 'Order Book (Line)',
			value: 'OrderBookLine',
			type: 'orderbook', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: false,
			arguments: {
				netPosition: false,
				drawType: 'line',
				centerLineOpacity: 0.1,
				bookType: 'order',
				showLargestLines: true, // Draws a line on the largest positions ....
				largestLineCount: 2, // ... like the MT4 indicator
				fetchData: ({ instrument }) => fetchOrderBook(instrument),
				fetchName: 'orderBook', // Used in the cache to prevent multiple fetches.
				needsTime: false,
			},
		},
		{
			id: 'OrderBookLineNet',
			label: 'Order Book (Line Net)',
			value: 'OrderBookLineNet',
			type: 'orderbook', // This is used in the chart indicators
			category: 'Sentiment', // Used in indicator sorting
			isOutside: false,
			arguments: {
				netPosition: true,
				drawType: 'line',
				centerLineOpacity: 0.1,
				bookType: 'order',
				showLargestLines: true, // Draws a line on the largest positions ....
				largestLineCount: 2, // ... like the MT4 indicator
				fetchData: ({ instrument }) => fetchOrderBook(instrument),
				fetchName: 'orderBook', // Used in the cache to prevent multiple fetches.
				needsTime: false,
			},
		},
		{
			id: 'SuperTrend', // This doesn't appear to be used in the dropdown?
			label: 'SuperTrend', // This is how it is displayed in the dropdown
			value: 'SuperTrend', // This is the actual value in the dropdown, also used as the map name
			type: 'SuperTrend', // This is used in the chart indicators
			category: 'Trend', // Used in indicator sorting
			isOutside: false,
			arguments: {
				trends: [
					{
						factor: 2,
						atrLength: 10,
						lineWidth: 2,
						longFill: hexToRGBA(chartColors.positiveText, 1),
						shortFill: hexToRGBA(chartColors.shortBar, 1),
					},
				],
			},
			seriesArguments: {},
		},
		{
			id: 'SuperTrendx3', // This doesn't appear to be used in the dropdown?
			label: 'SuperTrendx3', // This is how it is displayed in the dropdown
			value: 'SuperTrendx3', // This is the actual value in the dropdown, also used as the map name
			type: 'SuperTrend', // This is used in the chart indicators
			category: 'Trend', // Used in indicator sorting
			isOutside: false,
			arguments: {
				trends: [
					{
						factor: 3,
						atrLength: 10,
						lineWidth: 2,
						longFill: hexToRGBA(chartColors.positiveText, 1),
						shortFill: hexToRGBA(chartColors.shortBar, 1),
					},
					{
						factor: 6,
						atrLength: 50,
						lineWidth: 3,
						longFill: hexToRGBA('#a5d6a7', 1),
						shortFill: hexToRGBA('#ff8652', 1),
					},
					{
						factor: 9,
						atrLength: 100,
						lineWidth: 4,
						longFill: hexToRGBA('#5b9cf6', 1),
						shortFill: hexToRGBA('#ffba52', 1),
					},
				],
				rsiLen: 7,
				rsiOverSold: 30,
				rsiOverBought: 70,
				showEntrySignals: false,
				showAlternateSignals: false,
				signalLong: hexToRGBA(chartColors.positiveText, 1),
				signalShort: hexToRGBA(chartColors.shortBar, 1),
			},
			seriesArguments: {},
		},
		{
			id: 'ZigZag', // This doesn't appear to be used in the dropdown?
			label: 'ZigZag', // This is how it is displayed in the dropdown
			value: 'ZigZag', // This is the actual value in the dropdown, also used as the map name
			type: 'ZigZag', // This is used in the chart indicators
			category: 'Trend', // Used in indicator sorting
			isOutside: false,
			arguments: {
				percentRetrace: 35,
				lineWidth: 2,
				longFill: hexToRGBA(chartColors.positiveText, 1),
				shortFill: hexToRGBA(chartColors.shortBar, 1),
			},
			seriesArguments: {},
		},
		{
			id: 'DualZigZag', // This doesn't appear to be used in the dropdown?
			label: 'Dual ZigZag', // This is how it is displayed in the dropdown
			value: 'DualZigZag', // This is the actual value in the dropdown, also used as the map name
			type: 'ZigZag', // This is used in the chart indicators
			category: 'Trend', // Used in indicator sorting
			isOutside: false,
			arguments: {
				percentRetrace: 25,
				percentRetrace2: 45,
				backstep: 2,
				backstep2: 4,
				lineWidth: 2,
				lineWidth2: 4,
				longFill: hexToRGBA(chartColors.positiveText, 1),
				longFill2: hexToRGBA(chartColors.fadedBlue, 1),
				shortFill: hexToRGBA(chartColors.shortBar, 1),
				shortFill2: hexToRGBA(chartColors.redFadedTradingVue, 1),
				showEntrySignals: false,
				entryOnCircleBreak: true,
			},
			seriesArguments: {},
		},
		{
			id: 'UCSMath',
			label: 'USC Murry Math', // This is how it is displayed in the dropdown
			value: 'UCSMath', // This is the actual value in the dropdown, also used as the map name
			type: 'ucsmath', // This is used in the chart indicators
			category: 'Momentum', // Used in indicator sorting
			isOutside: true,
			arguments: {
				windowSize: 100, // Length
				multiplier: 0.125,
				labels: ['USCMath'],
				get lineAt() {
					return [
						this.multiplier * 2,
						this.multiplier * 4,
						this.multiplier * 6,
						this.multiplier * 8,
						-this.multiplier * 2,
						-this.multiplier * 4,
						-this.multiplier * 6,
						-this.multiplier * 8,
					];
				},
				lineSeriesCount: 0, // This must be present for the label to work.
				stroke: [[chartColors.moodyPurple, 1]],
				// yAccessor: (d, props) => d?.UCSMath || 0,
				yAccessorDivergence: (d) => d?.UCSMath || 0,
				fill: {
					divergencePositive: chartColors.moodyPurple,
					divergenceNegative: chartColors.mehroon,
				},
				zeroLineOpacity: 0.1,
				zeroLineStroke: chartColors.grayBlueFadedTradingVueOpac,
				showEntrySignals: false,
				signalQuadrant: [2, 6], // The index location in the lineAt array
				/**
				 * We do not use an Arrow function here because we need to
				 * reference "this" which is passed from annotate.js in order
				 * to provide us the props.
				 *
				 * @param {*} currentValue
				 * @param {*} idx
				 * @param {*} chartData
				 * @returns
				 */
				entryLongWhen: function (currentValue, idx, chartData) {
					return (
						idx > 0 &&
						chartData?.[idx]?.UCSMath >= this.props.arguments.lineAt[this.props.arguments.signalQuadrant[1]] &&
						chartData?.[idx - 1]?.UCSMath < this.props.arguments.lineAt[this.props.arguments.signalQuadrant[1]]
					);
				},
				entryShortWhen: function (currentValue, idx, chartData) {
					return (
						idx > 0 &&
						chartData?.[idx]?.UCSMath >= this.props.arguments.lineAt[this.props.arguments.signalQuadrant[0]] &&
						chartData?.[idx - 1]?.UCSMath < this.props.arguments.lineAt[this.props.arguments.signalQuadrant[0]]
					);
				},
			},
			seriesArguments: {
				height: 100,
			},
		},
		{
			id: 'VolumeBucketRange',
			label: 'Volume Bucket Range',
			value: 'VolumeBucketRange',
			type: 'VolumeBucketRange', // This is used in the chart indicators
			category: 'Volume', // Used in indicator sorting
			isOutside: false,
			arguments: {
				fetchData: function (options, ioIndicator) {
					return fetchGenericApiData('api/getVolumeBuckets', 'volumeBucketRange', options, true, ioIndicator);
				},
				resetData: function (indicator) {
					return clearGenericApiData(indicator.arguments.fetchName, true, this);
				},
				fetchName: 'volumeBucketRange', // Used in the cache to prevent multiple fetches.
				needsTime: false,
				useOwnOptions: true,
				needsOwnData: true,
				data: null, // This will contain the volume bucket range data from default.js
				options: {
					instrument: 'current',
					granularity: 'H4',
					type: 'mid', // Candle type
					bucketCount: 100,
					largestLineCount: 50,
					recentTopBottom: '',
					recentTopBottomInMonths: '',
					toStop: '',
					onlyInRecent: false,
					useEqual: false,
				},
				stroke: 'rgba(255,255,255,0.15)',
				fill: hexToRGBA(chartColors.purple, 0.3),
				strokeLargest: 'rgba(255,255,255, 0.30)',
				fillLargest: hexToRGBA(chartColors.pink, 0.8),
				strokeCenter: 'rgba(255,255,255, 0.30)',
				fillCenter: hexToRGBA(chartColors.lightGray, 0.8),
				rectCenterHeight: 1,
				strokeSecondaryLargest: 'rgba(255,255,255, 0.25)',
				fillSecondaryLargest: hexToRGBA(chartColors.purple, 0.5),
				rectHeight: 1,
				highLowFill: hexToRGBA(chartColors.greenNeon, 0.8),
				highLowStroke: 'rgba(255,255,255, 0.1)',
				highLowRectHeight: 3,
				recentHighLowFill: hexToRGBA(chartColors.orangeRed, 0.6),
				recentHighLowStroke: 'rgba(255,255,255, 0.1)',
				recentHighLowRectHeight: 2,
				showEntrySignals: false,
				entryLongWhen: function (currentValue, idx, chartData) {
					if (idx <= 0 || this?.props?.arguments?.data == null) return false;

					/**
					 * Extract our data out
					 */
					const data = this.props.arguments.data;

					/**
					 * We go long when price is below the largest line and crosses
					 * another bucket.
					 *
					 * 2022-01-27 13:41:05 JD
					 */
					if (chartData[idx]?.close < data?.largestBuckets?.[0]?.bucketPrice) {
						const buckets = data?.largestBuckets.filter((x) => x.bucketPrice < data.largestBuckets[0].bucketPrice);

						/**
						 * Did we just cross any of these buckets?
						 */
						for (let i = 0; i < buckets.length; i++)
							if (chartData[idx]?.close < buckets[i].bucketPrice && chartData[idx - 1]?.close > buckets[i].bucketPrice) return true;
					}

					return false;
				},
				entryShortWhen: function (currentValue, idx, chartData) {
					if (idx <= 0 || this?.props?.arguments?.data == null) return false;

					/**
					 * Extract our data out
					 */
					const data = this.props.arguments.data;

					/**
					 * We go short when price is above the largest line and crosses
					 * another bucket.
					 *
					 * 2022-01-27 13:41:05 JD
					 */
					if (chartData[idx]?.close > data?.largestBuckets?.[0]?.bucketPrice) {
						const buckets = data?.largestBuckets.filter((x) => x.bucketPrice > data.largestBuckets[0].bucketPrice);

						/**
						 * Did we just cross any of these buckets?
						 */
						for (let i = 0; i < buckets.length; i++)
							if (chartData[idx]?.close > buckets[i].bucketPrice && chartData[idx - 1]?.close < buckets[i].bucketPrice) return true;
					}
					return false;
				},
			},
		},
		{
			id: 'S&R',
			label: 'S&R',
			value: 'S&R',
			type: 'S&R', // This is used in the chart indicators
			category: 'Other', // Used in indicator sorting
			isOutside: false,
			arguments: {
				fetchData: function (options, ioIndicator) {
					return fetchGenericApiData('api/getSupportResistance', 'supportResistance', options, true, ioIndicator);
				},
				resetData: function (indicator) {
					return clearGenericApiData(indicator.arguments.fetchName, true, this);
				},
				fetchName: 'supportResistance', // Used in the cache to prevent multiple fetches.
				needsTime: false,
				useOwnOptions: true,
				needsOwnData: true,
				data: null, // This will contain the volume bucket range data from default.js
				options: {
					instrument: 'current',
					granularity: 'H1',
					type: 'mid', // Candle type
					monthIncrementAdd: 3,
					monthIncrement: 20,
					toStop: '',
				},
				stroke: 'rgba(255,255,255,0.15)',
				fill: hexToRGBA(chartColors.mehroon, 1),
				rectHeight: 1,
				showEntrySignals: false,
				entryLongWhen: function (currentValue, idx, chartData) {
					if (idx <= 0 || this?.props?.arguments?.data == null) return false;

					/**
					 * Extract our data out
					 */
					const data = this.props.arguments.data;

					/**
					 * We go long when price is below the largest line and crosses
					 * another bucket.
					 *
					 * 2022-01-27 13:41:05 JD
					 */
					if (chartData[idx]?.close < data?.supportResistance?.[0]?.bucketPrice) {
						const buckets = data?.supportResistance.filter((x) => x.bucketPrice < data.supportResistance[0].bucketPrice);

						/**
						 * Did we just cross any of these buckets?
						 */
						for (let i = 0; i < buckets.length; i++)
							if (chartData[idx]?.close < buckets[i].bucketPrice && chartData[idx - 1]?.close > buckets[i].bucketPrice) return true;
					}

					return false;
				},
				entryShortWhen: function (currentValue, idx, chartData) {
					if (idx <= 0 || this?.props?.arguments?.data == null) return false;

					/**
					 * Extract our data out
					 */
					const data = this.props.arguments.data;

					/**
					 * We go short when price is above the largest line and crosses
					 * another bucket.
					 *
					 * 2022-01-27 13:41:05 JD
					 */
					if (chartData[idx]?.close > data?.supportResistance?.[0]?.bucketPrice) {
						const buckets = data?.supportResistance.filter((x) => x.bucketPrice > data.supportResistance[0].bucketPrice);

						/**
						 * Did we just cross any of these buckets?
						 */
						for (let i = 0; i < buckets.length; i++)
							if (chartData[idx]?.close > buckets[i].bucketPrice && chartData[idx - 1]?.close < buckets[i].bucketPrice) return true;
					}
					return false;
				},
			},
		},
		{
			id: 'ZoneUS', // This doesn't appear to be used in the dropdown?
			label: 'Zone (US)', // This is how it is displayed in the dropdown
			value: 'zoneUS', // This is the actual value in the dropdown, also used as the map name
			type: 'zone', // This is used in the chart indicators array
			category: 'Zone', // Used in indicator sorting
			isOutside: false,
			arguments: {
				data: [
					{
						id: 'openclose',
						start: { hours: 8, minutes: 0, seconds: 0 },
						end: { hours: 17, minutes: 0, seconds: 0 },
						fill: '#999999',
						opacity: 0.1,
						startLine: {
							stroke: '#3d7cd4',
							strokeWidth: 1,
							strokeOpacity: 0.8,
							strokeDasharray: 'LongDash',
						},
						endLine: {
							stroke: '#777777',
							strokeWidth: 1,
							strokeOpacity: 0.7,
							strokeDasharray: 'ShortDash',
						},
					},
				],
			},
		},
		{
			id: 'ZoneLondon', // This doesn't appear to be used in the dropdown?
			label: 'Zone (London)', // This is how it is displayed in the dropdown
			value: 'zoneLondon', // This is the actual value in the dropdown, also used as the map name
			type: 'zone', // This is used in the chart indicators array
			category: 'Zone', // Used in indicator sorting
			isOutside: false,
			arguments: {
				data: [
					{
						id: 'openclose',
						start: { hours: 3, minutes: 0, seconds: 0 },
						end: { hours: 11, minutes: 0, seconds: 0 },
						fill: '#999999',
						opacity: 0.1,
						startLine: {
							stroke: '#3d7cd4',
							strokeWidth: 1,
							strokeOpacity: 0.8,
							strokeDasharray: 'LongDash',
						},
						endLine: {
							stroke: '#777777',
							strokeWidth: 1,
							strokeOpacity: 0.7,
							strokeDasharray: 'ShortDash',
						},
					},
				],
			},
		},
		{
			id: 'ZoneFrankfurt', // This doesn't appear to be used in the dropdown?
			label: 'Zone (Frankfurt)', // This is how it is displayed in the dropdown
			value: 'zoneFrankfurt', // This is the actual value in the dropdown, also used as the map name
			type: 'zone', // This is used in the chart indicators array
			category: 'Zone', // Used in indicator sorting
			isOutside: false,
			arguments: {
				data: [
					{
						id: 'openclose',
						start: { hours: 2, minutes: 0, seconds: 0 },
						end: { hours: 10, minutes: 0, seconds: 0 },
						fill: '#999999',
						opacity: 0.1,
						startLine: {
							stroke: '#3d7cd4',
							strokeWidth: 1,
							strokeOpacity: 0.8,
							strokeDasharray: 'LongDash',
						},
						endLine: {
							stroke: '#777777',
							strokeWidth: 1,
							strokeOpacity: 0.7,
							strokeDasharray: 'ShortDash',
						},
					},
				],
			},
		},
		{
			id: 'ZoneSydney', // This doesn't appear to be used in the dropdown?
			label: 'Zone (Sydney)', // This is how it is displayed in the dropdown
			value: 'zoneSydney', // This is the actual value in the dropdown, also used as the map name
			type: 'zone', // This is used in the chart indicators array
			category: 'Zone', // Used in indicator sorting
			isOutside: false,
			arguments: {
				data: [
					{
						id: 'openclose',
						start: { hours: 16, minutes: 0, seconds: 0 },
						end: { hours: 23, minutes: 59, seconds: 59 },
						fill: '#999999',
						opacity: 0.1,
						startLine: {
							stroke: '#3d7cd4',
							strokeWidth: 1,
							strokeOpacity: 0.8,
							strokeDasharray: 'LongDash',
						},
						endLine: {
							stroke: '#777777',
							strokeWidth: 1,
							strokeOpacity: 0.7,
							strokeDasharray: 'ShortDash',
						},
					},
				],
			},
		},
		{
			id: 'ZoneTokyo', // This doesn't appear to be used in the dropdown?
			label: 'Zone (Tokyo)', // This is how it is displayed in the dropdown
			value: 'zoneTokyo', // This is the actual value in the dropdown, also used as the map name
			type: 'zone', // This is used in the chart indicators array
			category: 'Zone', // Used in indicator sorting
			isOutside: false,
			arguments: {
				data: [
					{
						id: 'openclose',
						start: { hours: 18, minutes: 0, seconds: 0 },
						end: { hours: 2, minutes: 0, seconds: 0 },
						fill: '#999999',
						opacity: 0.1,
						startLine: {
							stroke: '#3d7cd4',
							strokeWidth: 1,
							strokeOpacity: 0.8,
							strokeDasharray: 'LongDash',
						},
						endLine: {
							stroke: '#777777',
							strokeWidth: 1,
							strokeOpacity: 0.7,
							strokeDasharray: 'ShortDash',
						},
					},
				],
			},
		},
	];

	/**
	 * Create an alias here to vwap
	 */
	let vwapDuplicate = indicatorOptions.find((elem) => elem.id === 'BollingerBand');

	/**
	 * We have it, now clone it
	 */
	if (vwapDuplicate) {
		vwapDuplicate = {
			...vwapDuplicate,
			id: 'VWAP',
			label: 'VWAP',
			value: 'VWAP', // This is what is mapped in the data.
			arguments: {
				...vwapDuplicate.arguments,
				movingAverageType: 'vwap',
				sessionType: 'week',
				stdDev: 2.0608, // This is called "multiplier" in the calculator.
				additionalMultipliers: [3.2361, 4.0411, 4.9749, 7.4561], // Original was [3.2361, 4.0411, 4.9749, 6.4561]
				showEntrySignals: false,
				/**
				 * Original, just when it crosses one
				 */
				entryLongWhen: (currentValue, idx, chartData) =>
					idx > 0 &&
					((chartData[idx]?.close < chartData[idx]?.['VWAP']?.otherData?.additionalMultis?.[1]?.bottom &&
						chartData[idx - 1]?.close > chartData[idx - 1]?.['VWAP']?.otherData?.additionalMultis?.[1]?.bottom) ||
						(chartData[idx]?.close < chartData[idx]?.['VWAP']?.otherData?.additionalMultis?.[2]?.bottom &&
							chartData[idx - 1]?.close > chartData[idx - 1]?.['VWAP']?.otherData?.additionalMultis?.[2]?.bottom) ||
						(chartData[idx]?.close < chartData[idx]?.['VWAP']?.otherData?.additionalMultis?.[3]?.bottom &&
							chartData[idx - 1]?.close > chartData[idx - 1]?.['VWAP']?.otherData?.additionalMultis?.[3]?.bottom)),
				entryShortWhen: (currentValue, idx, chartData) =>
					idx > 0 &&
					((chartData[idx]?.close > chartData[idx]?.['VWAP']?.otherData?.additionalMultis?.[1]?.top &&
						chartData[idx - 1]?.close < chartData[idx - 1]?.['VWAP']?.otherData?.additionalMultis?.[1]?.top) ||
						(chartData[idx]?.close > chartData[idx]?.['VWAP']?.otherData?.additionalMultis?.[2]?.top &&
							chartData[idx - 1]?.close < chartData[idx - 1]?.['VWAP']?.otherData?.additionalMultis?.[2]?.top) ||
						(chartData[idx]?.close > chartData[idx]?.['VWAP']?.otherData?.additionalMultis?.[3]?.top &&
							chartData[idx - 1]?.close < chartData[idx - 1]?.['VWAP']?.otherData?.additionalMultis?.[3]?.top)),

				/**
				 * When price crosses one and then comes back down/up.
				 * It's like a late entry... :|
				 *
				 * 2021-05-16 16:45:32
				 */
				// entryLongWhen: (currentValue, idx, chartData) =>
				// 	idx > 0 &&
				// 	((chartData[idx]?.close > chartData[idx]?.['VWAP']?.otherData?.additionalMultis?.[2]?.bottom &&
				// 		chartData[idx - 1]?.close < chartData[idx - 1]?.['VWAP']?.otherData?.additionalMultis?.[2]?.bottom) ||
				// 		(chartData[idx]?.close > chartData[idx]?.['VWAP']?.otherData?.additionalMultis?.[3]?.bottom &&
				// 			chartData[idx - 1]?.close < chartData[idx - 1]?.['VWAP']?.otherData?.additionalMultis?.[3]?.bottom)),
				// entryShortWhen: (currentValue, idx, chartData) =>
				// 	idx > 0 &&
				// 	((chartData[idx]?.close < chartData[idx]?.['VWAP']?.otherData?.additionalMultis?.[2]?.top &&
				// 		chartData[idx - 1]?.close > chartData[idx - 1]?.['VWAP']?.otherData?.additionalMultis?.[2]?.top) ||
				// 		(chartData[idx]?.close < chartData[idx]?.['VWAP']?.otherData?.additionalMultis?.[3]?.top &&
				// 			chartData[idx - 1]?.close > chartData[idx - 1]?.['VWAP']?.otherData?.additionalMultis?.[3]?.top)),
			},
			seriesArguments: {
				stroke: {
					top: chartColors.faded,
					middle: chartColors.yellow,
					bottom: chartColors.faded,
				},
				strokeWidth: {
					top: 1,
					middle: 2,
					bottom: 1,
				},
				opacity: 0.05,
				fill: chartColors.genericBlueGray,
				additionalFills: {
					// These will correspond with the additional multipliers above
					top: [
						{
							stroke: chartColors.faded,
							strokeWidth: 1,
							opacity: 0.05,
							fill: chartColors.genericBlueGray,
						},
						{
							stroke: chartColors.faded,
							strokeWidth: 1,
							opacity: 0.07,
							fill: chartColors.genericBlueGray,
						},
						{
							stroke: chartColors.faded,
							strokeWidth: 2,
							opacity: 0.08,
							fill: chartColors.shortBar,
						},
						{
							stroke: chartColors.faded,
							strokeWidth: 2,
							opacity: 0.1,
							fill: chartColors.shortBar,
						},
					],
					bottom: [
						{
							stroke: chartColors.faded,
							strokeWidth: 1,
							opacity: 0.05,
							fill: chartColors.genericBlueGray,
						},
						{
							stroke: chartColors.faded,
							strokeWidth: 1,
							opacity: 0.07,
							fill: chartColors.genericBlueGray,
						},
						{
							stroke: chartColors.faded,
							strokeWidth: 1,
							opacity: 0.08,
							fill: chartColors.positiveText,
						},
						{
							stroke: chartColors.faded,
							strokeWidth: 1,
							opacity: 0.1,
							fill: chartColors.positiveText,
						},
					],
				},
			},
		};

		/**
		 * Add it to the array
		 */
		indicatorOptions.push(vwapDuplicate);
	}

	return indicatorOptions.slice(); // No changes motherfucker
};

/**
 * Find the true indicator from a matching indicator (IE: from chartsettings)
 *
 * @param {obj} indicator
 */
export const getMatchingIndicator = (indicator) => {
	/**
	 * Get our indicator list
	 */
	const indicatorOptions = indicatorOptionsFunction();

	/**
	 * Keep the original arguments
	 */
	const originalArguments = indicator?.arguments || null;

	/**
	 * Let's find the matching indicator in the indicator
	 * file. This is in order to preserve functions passed
	 * in.
	 */
	let mergedIndicator = indicatorOptions.filter((singleIndi) => {
		if (singleIndi.id === indicator.id) return singleIndi;
	})[0];

	/**
	 * If nothing is found, continue on!
	 */
	if (mergedIndicator == null) return indicator;

	/**
	 * Apply the original arguments back to the indicator
	 */
	if (originalArguments) {
		// Need to do a deep merge because FUNCTIONS do not come back from session...
		mergedIndicator = mergeDeep(mergedIndicator, indicator);
	}

	return mergedIndicator;
};

/**
 * StrokeDasharray options from strokeDasharray.js
 *
 * 	"Solid",
	"ShortDash",
	"ShortDash2",
	"ShortDot",
	"ShortDashDot",
	"ShortDashDotDot",
	"Dot",
	"Dash",
	"LongDash",
	"DashDot",
	"LongDashDot",
	"LongDashDotDot",
 */
