/**
 * this comes from https://github.com/rrag/react-stockcharts/issues/707
 */
import React from 'react';
import GenericChartComponent from './GenericChartComponent';
import { floatPrecisionFix } from '../../../../components/utils';
// import { chartColors } from '../../../../components/StockChart/config/genericConfig';

import { getAxisCanvas } from './GenericComponent';
import { chartColors } from '../../../../components/StockChart/config/genericConfig';
import { hexToRGBA } from './utils';

export const DrawHorizontalVolumeLines = ({
	stroke = 'rgba(255,255,255,0.05)',
	fill = hexToRGBA(chartColors.purple, 0.15), // 'rgba(66, 184, 131, 0.7)',
	strokeLargest = 'rgba(255,255,255,0.2)',
	fillLargest = hexToRGBA(chartColors.purple, 0.3), // 'rgba(66, 184, 131, 0.7)',
	rectHeight = 1,
	largestLineCount = 3, // ... like the MT4 indicator
	bucketCount = 20,
}) => {
	const drawOnCanvas = (ctx, moreProps) => {
		const {
			plotData,
			// mouseXY,
			chartConfig: { yScale },
			width,
		} = moreProps;

		/**
		 * These are the actual upper and lower yaxis
		 * values as shown.
		 *
		 * use realYDomain to get just the charbar price
		 * values.
		 *
		 * IE:
		 * const { chartConfig: { yScale, realYDomain } } = moreProps;
		 * realYDomain[0] == lower, realYDomain[1] = upper;
		 *
		 */
		const [lowerYValue, upperYValue] = yScale.domain();

		/**
		 * The plotData is the bars being shown depending on
		 * zoom and pan.
		 */
		if (!plotData || plotData.length === 0 || !yScale) return null;

		/**
		 * Our width data
		 */
		const begin = width / 2 - width / 3;

		/**
		 * Get our bucket data
		 */
		const stepSize = Math.abs(Math.abs(upperYValue) - Math.abs(lowerYValue)) / bucketCount;

		/**
		 * Create our bucket holder
		 */
		let volumeBuckets = [];
		for (let i = 0; i < bucketCount; i++)
			volumeBuckets[i] = {
				volume: 0,
				bucketPrice: floatPrecisionFix(lowerYValue + stepSize * i, 5),
				previousBucketPrice: i === 0 ? 0 : floatPrecisionFix(lowerYValue + stepSize * (i - 1), 5),
				nextBucketPrice: floatPrecisionFix(lowerYValue + stepSize * (i + 1), 5),
			};

		/**
		 * Loop through all of our plotable data now.
		 */
		for (const plot of plotData) {
			/**
			 * We have bucketCounter which is an index
			 *
			 * if (plot.price > lowerYValue + stepSize * bucketCounter + 1), increase the counter.
			 * If not, do nothing. At the end, bucketCounter will be the bucket it belongs in.
			 */
			let bucketCounter = 0;
			// Find bucket, put values on the outer size of the range in the last bucket
			while (bucketCounter < bucketCount - 1 && parseFloat(plot.close) > lowerYValue + stepSize * (bucketCounter + 1)) {
				bucketCounter++;
			}

			volumeBuckets[bucketCounter].volume += plot.volume;
		}

		// console.log(volumeBuckets);

		/**
		 * Find the largest ones
		 */
		const largestBuckets = volumeBuckets
			.slice()
			.sort((a, b) => (parseFloat(a.volume) < parseFloat(b.volume) ? 1 : -1))
			.splice(0, largestLineCount);

		for (let i = 0; i < largestBuckets.length; i++) {
			let bucket = largestBuckets[i];

			/**
			 * Get our cooridnates. This is done using the xAccessor
			 * and passing in the entire bar data which contains the IDX
			 * (index) values.
			 */
			const y1 = yScale(bucket.bucketPrice);

			ctx.fillStyle = i === 0 ? fillLargest : fill;
			ctx.strokeStyle = i === 0 ? strokeLargest : stroke;
			ctx.beginPath();
			ctx.rect(begin + 1, y1, width - width / 3, rectHeight);
			ctx.strokeRect(begin + 1, y1, width - width / 3, rectHeight);
			ctx.closePath();
			ctx.fill();
		}
	};

	const renderSVG = (moreProps) => {};

	return (
		<GenericChartComponent
			canvasToDraw={getAxisCanvas}
			canvasDraw={drawOnCanvas}
			svgDraw={renderSVG}
			drawOn={['pan']}
			key={Math.random().toString(36).substr(2, 7)}
		/>
	);
};
