import React, { useEffect, useState } from 'react';
import Loader from '../../../../../components/Loader/Loader';
import axios from 'axios';
import react from '../../../../../images/react.png';
import babypips from '../../../../../images/logos/babypips.png';
import elitetrader from '../../../../../images/logos/elitetrader.png';
import tradingview from '../../../../../images/logos/tradingview.png';
import reddit from '../../../../../images/logos/reddit.png';
import fred from '../../../../../images/logos/fred.jpg';
import wilmott from '../../../../../images/logos/wilmott.png';
import { Table } from 'reactstrap';

// To use redux hooks, you export a function component, not a react component.
export const NewsFeeds = () => {
	const [data, setData] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			const result = await axios('/api/rssFeeds');
			setData(result.data);
		};

		fetchData();
	}, []);

	/**
	 * Return nothing if no NewsFeeds data
	 */
	if (data == null) return <Loader />;

	/**
	 * This will render each individual
	 * news item.
	 */
	const renderNewsItems = () =>
		data.map((line) => {
			/**
			 * Determine our logo
			 */
			let siteLogo = react;
			if (line.link.indexOf('babypips') !== -1) siteLogo = babypips;
			if (line.link.indexOf('elitetrader') !== -1) siteLogo = elitetrader;
			if (line.link.indexOf('tradingview') !== -1) siteLogo = tradingview;
			if (line.link.indexOf('reddit') !== -1) siteLogo = reddit;
			if (line.link.indexOf('fred') !== -1) siteLogo = fred;
			if (line.link.indexOf('wilmott') !== -1) siteLogo = wilmott;

			/**
			 * Get our dangerous content
			 */
			let dangerousContent = line.contentSnippet; //line.content;
			// if (line.link.indexOf('tradingview') !== -1) dangerousContent = line['content:encodedSnippet'];
			// else if (line['content:encoded']) dangerousContent = line['content:encoded'];

			return (
				<tr key={`feedNews_${Math.random().toString(36).substr(2, 7)}`}>
					<td>
						<span className="thumb">
							<img className="rounded-circle" src={siteLogo} alt="..." />
						</span>
					</td>
					<td style={{ width: '25%' }}>
						<a href={line.link} target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
							{line.title}
						</a>
					</td>
					<td>
						{/* <div className="card-body" dangerouslySetInnerHTML={{ __html: dangerousContent }}></div> */}
						{dangerousContent}
					</td>
					<td>{line.creator}</td>
					<td>{line.pubDate}</td>
				</tr>
			);
			// 	return (
			// 		<div className="card news-card" key={Math.random().toString(36).substr(2, 7)}>
			// 			<div className="card-header">
			// 				<div className="d-flex">
			// 					<span className="thumb">
			// 						<img className="rounded-circle" src={siteLogo} alt="..." />
			// 					</span>
			// 					<span className="card-title">
			// 						<a href={line.link} target="_blank" rel="noopener noreferrer">
			// 							{line.title}
			// 						</a>{' '}
			// 						{line.creator ? `- ${line.creator}` : ''}
			// 					</span>
			// 				</div>
			// 			</div>
			// 			{/* <div className="card-body" dangerouslySetInnerHTML={{ __html: dangerousContent }}></div> */}
			// 			<div className="card-body">{dangerousContent}</div>
			// 			<div className="card-footer">{line.pubDate}</div>
			// 		</div>
			// 	);
		});

	/**
	 * Return the news items
	 */
	return (
		<Table className="table-striped text-white">
			<thead>
				<tr>
					<th></th>
					<th>Title</th>
					<th>Description</th>
					<th>Creator</th>
					<th>Data</th>
				</tr>
			</thead>
			<tbody>{renderNewsItems()}</tbody>
		</Table>
	);
	// return <div style={{ display: 'flex' }}>{renderNewsItems()}</div>;
};
