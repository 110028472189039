import { sum } from 'd3-array';

import { accumulatingWindow, identity, slidingWindow } from '../utils';
import { getSessionStart } from '../utils/sessionType';
import { VWAP as defaultOptions } from './defaultOptionsForComputation';

export default function () {
	let options = defaultOptions;

	function calculator(data, returnObj = false) {
		const { windowSize, sessionType, partialStartOK = true, partialEndOK = true } = options;

		/**
		 * The actual VWAP caluclation
		 *
		 * 2021-04-03 13:08:48 - JD
		 *
		 * @param {obj} windowData
		 * @returns
		 */
		const calcVWAP = (windowData) => {
			const cum_vol = sum(windowData.map((ohlcv) => ohlcv.volume));
			const cum_weighted_price = sum(windowData.map((ohlcv) => ((ohlcv.low + ohlcv.close + ohlcv.high) / 3) * ohlcv.volume));
			return cum_weighted_price / cum_vol;
		};

		/**
		 * If by session we need to set the windowSize as a
		 * function
		 */
		if (sessionType && sessionType !== 'none') {
			/**
			 * Get our session function
			 */
			let sessionStart = getSessionStart(sessionType);

			/**
			 * Create a session builder
			 */
			const sessionBuilder = accumulatingWindow()
				.discardTillStart(!partialStartOK)
				.discardTillEnd(!partialEndOK)
				.accumulateTill((d, i) => {
					return sessionStart({ d, i, plotData: data });
				})
				.accumulator(identity);

			/**
			 * Get all of our sessions as an array
			 */
			let mappedData = [];
			let sessions = sessionBuilder(data);

			/**
			 * Loop through all of the sessions
			 */
			for (let s = 0; s < sessions.length; s++) {
				const session = sessions[s];

				/**
				 * Each session contains an array of data points. We need
				 * to calculate the vwap for each point one at a time as if
				 * we are moving along the with the real data.
				 *
				 * 2021-04-03 15:00:21 - JD
				 */
				for (let i = 0; i < session.length; i++) {
					let vwap = calcVWAP(session.slice(0, i + 1));

					if (isNaN(vwap)) vwap = (session[i].low + session[i].close + session[i].high) / 3 || session[i].close;
					// New session, reset.

					if (returnObj) session[i].mean = vwap;
					mappedData.push(vwap);
				}
			}

			/**
			 * Return the sessions if requested.
			 */
			if (returnObj) return sessions;
			return mappedData;
		} else {
			/**
			 * Calculate
			 */
			var waverange = slidingWindow() //slidingWindow is a data processing protocol that allows you to easily process a large amount of data
				.windowSize(windowSize)
				.source((d) => {
					return d;
				})
				.accumulator(calcVWAP);

			return waverange(data);
		}
	}

	calculator.undefinedLength = function () {
		const { windowSize } = options;

		return windowSize - 1;
	};
	calculator.options = function (x) {
		if (!arguments.length) {
			return options;
		}
		options = { ...defaultOptions, ...x };
		return calculator;
	};

	return calculator;
}
