/**
 * this comes from https://github.com/rrag/react-stockcharts/issues/707
 */
import React, { useState, useEffect } from 'react';
import GenericChartComponent from './GenericChartComponent';
import { floatPrecisionFix } from '../../../../components/utils';
// import { chartColors } from '../../../../components/StockChart/config/genericConfig';
import { BasicToolTip } from './tooltip/BasicToolTip';
import { getStrokeDasharray, hexToRGBA } from './utils';
import { getAxisCanvas } from './GenericComponent';
import { chartColors } from '../../../../components/StockChart/config/genericConfig';

export const DrawPositionBook = ({
	longStroke = 'rgba(255,255,255,0.7)',
	longFill = hexToRGBA(chartColors.longPositive, 0.4), // 'rgba(66, 184, 131, 0.7)',
	longFillLine = hexToRGBA(chartColors.longPositive, 0.3), // 'rgba(66, 184, 131, 0.3)',
	longLargestLineFill = 'rgb(66, 184, 131, 0.3)',
	longStrokeOpacity = 0.4,
	longLineStrokeOpacity = 0.3,
	longFillOpacity = 0.4,
	longStrokeWidth = 1,
	shortStroke = 'rgba(255,255,255,0.7)',
	shortFill = hexToRGBA(chartColors.shortNegative, 0.4), //'rgba(239,83,80, 0.7)',
	shortFillLine = hexToRGBA(chartColors.shortNegative, 0.3), //'rgba(239,83,80, 0.3)',
	shortLargestLineFill = 'rgb(229, 66, 80, 0.3)',
	shortStrokeOpacity = 0.4,
	shortLineStrokeOpacity = 0.3,
	shortFillOpacity = 0.4,
	shortStrokeWidth = 1,
	centerLineStroke = 'rgba(255,255,255,0.2)',
	centerLineOpacity = 0.2,
	className = 'react-stockcharts-drawpositions react-stockcharts-hover-tooltip',
	rectHeight = 2,
	drawType = 'rect', // Line or rect
	netPosition = true, // subtracts the two fields and only shows the winner
	showLargestLines = false, // Draws a line on the largest positions ....
	largestLineCount = 2, // ... like the MT4 indicator
	data,
}) => {
	/**
	 * Local state for the tabs
	 */
	const [showHover, setShowHover] = useState('');
	const [isMounted, setIsMounted] = useState(true);

	/**
	 * Place holder for our timeout var
	 */
	const hoverTimeout = null;

	/**
	 * We just use this function to clean up.
	 */
	useEffect(() => {
		return () => {
			setIsMounted(false);

			/**
			 * Cancel all requests
			 */
			removeShowHoverTimeout();
		};
	}, []);

	/**
	 * Create a hover timeout function
	 */
	const setShowHoverTimeout = (hover) => {
		if (!hover || !isMounted) return;

		/**
		 * First, clear out any hovers
		 */
		setShowHover('');

		/**
		 * And the previous timeout
		 */
		clearTimeout(hoverTimeout);

		/**
		 * Set the new hover
		 */
		setShowHover(hover);

		/**
		 * Make it revert to othing in xx ms
		 */
		// setTimeout(() => setShowHover(''), 15000);
	};

	/**
	 * We need to clear the timeout when we leave
	 * hover to prevent any updating on non-mounted
	 * components.
	 *
	 */
	const removeShowHoverTimeout = () => {
		/**
		 * First, clear out any hovers
		 */
		setShowHover('');

		/**
		 * And the previous timeout
		 */
		clearTimeout(hoverTimeout);
	};

	const drawOnCanvas = (ctx, moreProps) => {
		const {
			height,
			plotData,
			// mouseXY,
			chartConfig: { yScale },
			width,
		} = moreProps;

		/**
		 * These are the actual upper and lower yaxis
		 * values as shown.
		 *
		 * use realYDomain to get just the charbar price
		 * values.
		 *
		 * IE:
		 * const { chartConfig: { yScale, realYDomain } } = moreProps;
		 * realYDomain[0] == lower, realYDomain[1] = upper;
		 *
		 */
		const [lowerYValue, upperYValue] = yScale.domain();

		/**
		 * The plotData is the bars being shown depending on
		 * zoom and pan.
		 */
		if (!plotData || plotData.length === 0 || !yScale) return null;

		/**
		 * Make sure we have data passed in
		 */
		if (!data) return null;

		/**
		 * Our width data
		 */
		const begin = width / 2;

		/**
		 * PlaceHolders
		 */
		let largestLongs, largestShorts;

		/**
		 * Draw the lines on the largest x orders/postisions
		 */
		if (showLargestLines) {
			/**
			 * Get our largest for both longs and shorts
			 */
			largestLongs = data
				.slice()
				.filter((el) => parseFloat(el.price) >= lowerYValue && parseFloat(el.price) <= upperYValue)
				.sort((a, b) => (parseFloat(a.longCountPercent) < parseFloat(b.longCountPercent) ? 1 : -1))
				.splice(0, largestLineCount);
			largestShorts = data
				.slice()
				.filter((el) => parseFloat(el.price) >= lowerYValue && parseFloat(el.price) <= upperYValue)
				.sort((a, b) => (parseFloat(a.shortCountPercent) < parseFloat(b.shortCountPercent) ? 1 : -1))
				.splice(0, largestLineCount);
		}
		/**
		 * Map through all of the position and create the lines.
		 */
		for (const position of data) {
			/**
			 * The realYDomain is the top price and the bottom price of the chart. We
			 * want to only show the values between those two.
			 */
			if (position.price && parseFloat(position.price) >= lowerYValue && parseFloat(position.price) <= upperYValue) {
				/**
				 * If netPosition, we net the totals and only display
				 * the one that has anything left over.
				 */
				if (netPosition) {
					position.longCountPercent =
						parseFloat(position.longCountPercent) > parseFloat(position.shortCountPercent)
							? parseFloat(position.longCountPercent) - parseFloat(position.shortCountPercent)
							: 0;
					position.shortCountPercent =
						parseFloat(position.shortCountPercent) > parseFloat(position.longCountPercent)
							? parseFloat(position.shortCountPercent) - parseFloat(position.longCountPercent)
							: 0;
				}

				/**
				 * Calculate the lengths of our sentiment based on the
				 * percentage we have.
				 */
				const longLength = parseFloat(position.longCountPercent * 100);
				const shortLength = parseFloat(position.shortCountPercent * 100);
				// const bookID = position.price;

				/**
				 * The lines require the ending point while the
				 * rectangles require a length value.
				 */
				const shortLineStart = begin - parseFloat(position.shortCountPercent * 100);
				const longLineEnd = begin + parseFloat(position.longCountPercent * 100);

				/**
				 * Get our cooridnates. This is done using the xAccessor
				 * and passing in the entire bar data which contains the IDX
				 * (index) values.
				 */
				const y1 = yScale(position.price);
				const y2 = y1; // They are the same because this is a straight line/rect

				/**
				 * No invalid data.
				 */
				if (isNaN(y2) || isNaN(begin)) return null;

				/**
				 * Create our arrays
				 */
				if (drawType === 'rect') {
					/**
					 * Do the longs first
					 */
					if (longLength > 0) {
						ctx.fillStyle = longFill;
						ctx.strokeStyle = longStroke;
						ctx.beginPath();
						ctx.rect(begin + 1, y1, longLength, rectHeight);
						ctx.strokeRect(begin + 1, y1, longLength, rectHeight);
						ctx.closePath();
						ctx.fill();
					}

					if (shortLength > 0) {
						/**
						 * Now do the shorts
						 */
						ctx.fillStyle = shortFill;
						ctx.strokeStyle = shortStroke;
						ctx.beginPath();
						ctx.rect(begin - shortLength - 1, y1, shortLength, rectHeight);
						ctx.strokeRect(begin - shortLength - 1, y1, shortLength, rectHeight);
						ctx.closePath();
						ctx.fill();
					}
				} else {
					// Lines

					if (longLineEnd > 0) {
						// Long
						ctx.strokeStyle = longFillLine;
						ctx.lineWidth = 1;
						ctx.beginPath();
						ctx.setLineDash([]);
						ctx.moveTo(begin + 2, y1);
						ctx.lineTo(longLineEnd, y2);
						ctx.closePath();
						ctx.stroke();
					}

					if (shortLineStart > 0) {
						// Short
						ctx.strokeStyle = shortFillLine;
						ctx.lineWidth = 1;
						ctx.beginPath();
						ctx.setLineDash([]);
						ctx.moveTo(shortLineStart + 2, y1);
						ctx.lineTo(begin - 2, y2);
						ctx.closePath();
						ctx.stroke();
					}
				}

				/**
				 * If this item is one of our largest lines,
				 * add a line going out to it.
				 */
				if (showLargestLines && largestLongs.some((el) => el.price === position.price)) {
					ctx.strokeStyle = longLargestLineFill;
					ctx.lineWidth = 1;
					ctx.beginPath();
					ctx.setLineDash(getStrokeDasharray('LongDash').split(','));
					ctx.moveTo(begin + 2, y1);
					ctx.lineTo(width - 20, y2);
					ctx.closePath();
					ctx.stroke();
				}
				if (showLargestLines && largestShorts.some((el) => el.price === position.price)) {
					ctx.strokeStyle = shortLargestLineFill;
					ctx.lineWidth = 1;
					ctx.beginPath();
					ctx.setLineDash(getStrokeDasharray('LongDash').split(','));
					ctx.moveTo(begin + 2, y1);
					ctx.lineTo(width - 20, y2);
					ctx.closePath();
					ctx.stroke();
				}
			}
		}

		/**
		 * Draw the center line
		 */
		ctx.strokeStyle = centerLineStroke;
		ctx.lineWidth = 0.5;
		ctx.beginPath();
		ctx.setLineDash([]);
		ctx.moveTo(begin, 0);
		ctx.lineTo(begin, height);
		ctx.closePath();
		ctx.stroke();
	};

	const renderSVG = (moreProps) => {};

	/* eslint-disable no-unused-vars */
	/**
	 * Render the component in SVG.
	 *
	 * @param {obj} moreProps
	 */
	const renderSVGOrig = (moreProps) => {
		const {
			height,
			plotData,
			mouseXY,
			chartConfig: { yScale },
			width,
		} = moreProps;

		/**
		 * These are the actual upper and lower yaxis
		 * values as shown.
		 *
		 * use realYDomain to get just the charbar price
		 * values.
		 *
		 * IE:
		 * const { chartConfig: { yScale, realYDomain } } = moreProps;
		 * realYDomain[0] == lower, realYDomain[1] = upper;
		 *
		 */
		const [lowerYValue, upperYValue] = yScale.domain();

		/**
		 * The plotData is the bars being shown depending on
		 * zoom and pan.
		 */
		if (!plotData || plotData.length === 0 || !yScale) return null;

		/**
		 * Make sure we have data passed in
		 */
		if (!data) return null;

		/**
		 * Our width data
		 */
		const begin = width / 2;

		/**
		 * PlaceHolders
		 */
		let largestLongs, largestShorts;

		/**
		 * Disalbe, temp
		 */
		showLargestLines = false;

		/**
		 * Draw the lines on the largest x orders/postisions
		 */
		if (showLargestLines) {
			/**
			 * Get our largest for both longs and shorts
			 */
			largestLongs = data
				.slice()
				.filter((el) => parseFloat(el.price) >= lowerYValue && parseFloat(el.price) <= upperYValue)
				.sort((a, b) => (parseFloat(a.longCountPercent) < parseFloat(b.longCountPercent) ? 1 : -1))
				.splice(0, largestLineCount);
			largestShorts = data
				.slice()
				.filter((el) => parseFloat(el.price) >= lowerYValue && parseFloat(el.price) <= upperYValue)
				.sort((a, b) => (parseFloat(a.shortCountPercent) < parseFloat(b.shortCountPercent) ? 1 : -1))
				.splice(0, largestLineCount);
		}
		/**
		 * Map through all of the position and create the lines.
		 */
		let positionBookLines = data.map((position) => {
			/**
			 * The realYDomain is the top price and the bottom price of the chart. We
			 * want to only show the values between those two.
			 */
			if (position.price && parseFloat(position.price) >= lowerYValue && parseFloat(position.price) <= upperYValue) {
				/**
				 * If netPosition, we net the totals and only display
				 * the one that has anything left over.
				 */
				if (netPosition) {
					position.longCountPercent =
						parseFloat(position.longCountPercent) > parseFloat(position.shortCountPercent)
							? parseFloat(position.longCountPercent) - parseFloat(position.shortCountPercent)
							: 0;
					position.shortCountPercent =
						parseFloat(position.shortCountPercent) > parseFloat(position.longCountPercent)
							? parseFloat(position.shortCountPercent) - parseFloat(position.longCountPercent)
							: 0;
				}

				/**
				 * Calculate the lengths of our sentiment based on the
				 * percentage we have.
				 */
				const longLength = parseFloat(position.longCountPercent * 100);
				const shortLength = parseFloat(position.shortCountPercent * 100);
				const bookID = position.price;

				/**
				 * The lines require the ending point while the
				 * rectangles require a length value.
				 */
				const shortLineStart = begin - parseFloat(position.shortCountPercent * 100);
				const longLineEnd = begin + parseFloat(position.longCountPercent * 100);

				/**
				 * Get our cooridnates. This is done using the xAccessor
				 * and passing in the entire bar data which contains the IDX
				 * (index) values.
				 */
				const y1 = yScale(position.price);
				const y2 = y1; // They are the same because this is a straight line/rect

				/**
				 * No invalid data.
				 */
				if (isNaN(y2) || isNaN(begin)) return null;

				/**
				 * If this item is one of our largest lines,
				 * add a line going out to it.
				 */
				let addLargestLongLines =
					showLargestLines && largestLongs.some((el) => el.price === position.price) ? (
						<g>
							{showHover === `largestLongLine_${bookID}` ? (
								<BasicToolTip
									content={`Long Order ${floatPrecisionFix(position.longCountPercent, 1)}% @ $${position.price}`}
									x={mouseXY && mouseXY[0] > 0 ? mouseXY[0] : begin + 20}
									y={mouseXY && mouseXY[1] > 1 ? mouseXY[1] : y1 + 10}
									width={205}
									opacity={1}
									key={Math.random().toString(36).substr(2, 7)}
									className="react-svg-tooltip-book-long"
								/>
							) : (
								''
							)}
							<line
								x1={begin + 2}
								y1={y1}
								x2={width - 20}
								y2={y2}
								key={Math.random().toString(36).substr(2, 7)}
								stroke={longLargestLineFill}
								strokeWidth={longStrokeWidth}
								strokeOpacity={longLineStrokeOpacity}
								// strokeDasharray="10"
								onMouseEnter={() => setShowHoverTimeout(`largestLongLine_${bookID}`)}
								onMouseLeave={() => removeShowHoverTimeout()}
								onMouseOut={() => removeShowHoverTimeout()}
							/>
						</g>
					) : (
						''
					);
				let addLargestShortLines =
					showLargestLines && largestShorts.some((el) => el.price === position.price) ? (
						<g>
							{showHover === `largestShortLine_${bookID}` ? (
								<BasicToolTip
									content={`Short Order ${floatPrecisionFix(position.shortCountPercent, 1)}% @ $${position.price}`}
									x={mouseXY && mouseXY[0] > 0 ? mouseXY[0] : begin + 20}
									y={mouseXY && mouseXY[1] > 1 ? mouseXY[1] : y1 + 10}
									width={205}
									opacity={1}
									key={Math.random().toString(36).substr(2, 7)}
									className="react-svg-tooltip-book-short"
								/>
							) : (
								''
							)}
							<line
								//x1={0 + 20} // From the left to the center
								x1={begin + 2}
								y1={y1}
								// x2={begin - 2} // From the left to center
								x2={width - 20}
								y2={y2}
								key={Math.random().toString(36).substr(2, 7)}
								stroke={shortLargestLineFill}
								strokeWidth={shortStrokeWidth}
								strokeOpacity={shortLineStrokeOpacity}
								// strokeDasharray="10"
								onMouseEnter={() => setShowHoverTimeout(`largestShortLine_${bookID}`)}
								onMouseLeave={() => removeShowHoverTimeout()}
								onMouseOut={() => removeShowHoverTimeout()}
							/>
						</g>
					) : (
						''
					);

				/**
				 * Create our arrays
				 */
				return drawType === 'rect' ? (
					<g key={Math.random().toString(36).substr(2, 7)}>
						<g key={Math.random().toString(36).substr(2, 7)}>
							{showHover === `booklong_${bookID}` ? (
								<BasicToolTip
									content={`Long ${floatPrecisionFix(position.longCountPercent, 1)}% @ $${position.price}`}
									x={mouseXY && mouseXY[0] > 0 ? mouseXY[0] : begin + 10}
									y={mouseXY && mouseXY[1] > 1 ? mouseXY[1] : y1 + 10}
									width={205}
									opacity={1}
									key={Math.random().toString(36).substr(2, 7)}
									className="react-svg-tooltip-book-long"
								/>
							) : (
								''
							)}
							<rect
								x={begin + 1}
								y={y1}
								width={longLength}
								height={rectHeight}
								key={Math.random().toString(36).substr(2, 7)}
								stroke={longStroke}
								strokeWidth={longStrokeWidth}
								strokeOpacity={longStrokeOpacity}
								fill={longFill}
								fillOpacity={longFillOpacity}
								onMouseEnter={() => setShowHoverTimeout(`booklong_${bookID}`)}
								onMouseLeave={() => removeShowHoverTimeout()}
								onMouseOut={() => removeShowHoverTimeout()}
							/>
						</g>
						<g key={Math.random().toString(36).substr(2, 7)}>
							{showHover === `bookshort_${bookID}` ? (
								<BasicToolTip
									content={`Short ${floatPrecisionFix(position.shortCountPercent, 1)}% @ $${position.price}`}
									x={mouseXY && mouseXY[0] > 0 ? mouseXY[0] : shortLineStart + 10}
									y={mouseXY && mouseXY[1] > 1 ? mouseXY[1] : y1 + 10}
									width={205}
									opacity={1}
									key={Math.random().toString(36).substr(2, 7)}
									className="react-svg-tooltip-book-short"
								/>
							) : (
								''
							)}
							<rect
								x={begin - shortLength - 1}
								y={y1}
								width={shortLength}
								height={rectHeight}
								key={Math.random().toString(36).substr(2, 7)}
								stroke={shortStroke}
								strokeWidth={shortStrokeWidth}
								strokeOpacity={shortStrokeOpacity}
								fill={shortFill}
								fillOpacity={shortFillOpacity}
								onMouseEnter={() => setShowHoverTimeout(`bookshort_${bookID}`)}
								onMouseLeave={() => removeShowHoverTimeout()}
								onMouseOut={() => removeShowHoverTimeout()}
							/>
						</g>
						{addLargestLongLines}
						{addLargestShortLines}
					</g>
				) : (
					<g key={Math.random().toString(36).substr(2, 7)}>
						<g key={Math.random().toString(36).substr(2, 7)}>
							{showHover === `booklong_${bookID}` ? (
								<BasicToolTip
									content={`Long ${floatPrecisionFix(position.longCountPercent, 1)}% @ $${position.price}`}
									x={mouseXY && mouseXY[0] > 0 ? mouseXY[0] : begin + 10}
									y={mouseXY && mouseXY[1] > 1 ? mouseXY[1] : y1 + 10}
									width={205}
									opacity={1}
									key={Math.random().toString(36).substr(2, 7)}
									className="react-svg-tooltip-book-long"
								/>
							) : (
								''
							)}
							<line
								x1={begin + 2}
								y1={y1}
								x2={longLineEnd}
								y2={y2}
								key={Math.random().toString(36).substr(2, 7)}
								stroke={longFill}
								strokeWidth={longStrokeWidth}
								strokeOpacity={longLineStrokeOpacity}
								onMouseEnter={() => setShowHoverTimeout(`booklong_${bookID}`)}
								onMouseLeave={() => removeShowHoverTimeout()}
								onMouseOut={() => removeShowHoverTimeout()}
							/>
						</g>
						<g key={Math.random().toString(36).substr(2, 7)}>
							{showHover === `bookshort_${bookID}` ? (
								<BasicToolTip
									content={`Short ${floatPrecisionFix(position.shortCountPercent, 1)}% @ $${position.price}`}
									x={mouseXY && mouseXY[0] > 0 ? mouseXY[0] : shortLineStart + 10}
									y={mouseXY && mouseXY[1] > 1 ? mouseXY[1] : y1 + 10}
									width={205}
									opacity={1}
									key={Math.random().toString(36).substr(2, 7)}
									className="react-svg-tooltip-book-short"
								/>
							) : (
								''
							)}
							<line
								x1={shortLineStart - 2}
								y1={y1}
								x2={begin - 2}
								y2={y2}
								key={Math.random().toString(36).substr(2, 7)}
								stroke={shortFill}
								strokeWidth={shortStrokeWidth}
								strokeOpacity={shortLineStrokeOpacity}
								onMouseEnter={() => setShowHoverTimeout(`bookshort_${bookID}`)}
								onMouseLeave={() => removeShowHoverTimeout()}
								onMouseOut={() => removeShowHoverTimeout()}
							/>
						</g>
						{addLargestLongLines}
						{addLargestShortLines}
					</g>
				);
			}

			return '';
		});

		/**
		 * Return it all!
		 */
		return (
			<g className={className} key={Math.random().toString(36).substr(2, 7)}>
				<g className="react-stockcharts-drawpositions-line" key={Math.random().toString(36).substr(2, 7)}>
					<line
						x1={begin}
						y1={0}
						x2={begin}
						y2={height}
						key={Math.random().toString(36).substr(2, 7)}
						stroke={centerLineStroke}
						strokeWidth={1}
						strokeOpacity={centerLineOpacity}
						onMouseEnter={() => setShowHoverTimeout(`bookMiddleLine`)}
						onMouseLeave={() => removeShowHoverTimeout()}
						onMouseOut={() => removeShowHoverTimeout()}
					/>
					{positionBookLines}
				</g>
			</g>
		);
	};
	/* eslint-enable no-unused-vars */

	return (
		<GenericChartComponent
			canvasToDraw={getAxisCanvas}
			canvasDraw={drawOnCanvas}
			svgDraw={renderSVG}
			drawOn={['pan']}
			key={Math.random().toString(36).substr(2, 7)}
		/>
	);
};
