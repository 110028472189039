import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Input, InputGroup, InputGroupAddon, Label, FormGroup } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BaseModal } from './BaseModal';
import { changeModalLimitOpen, changeModalOrdersOpen, changeModalTPSLOpen } from '../../actions/navigation';
import {
	findAllInObjByInstrument,
	floatToNumberFormat,
	getPipDifference,
	objectEqual,
	addPipsToPrice,
	getPipValue,
	floatPrecisionFix,
	pipsToPricePoint,
} from '../utils';
import { replaceOandaOrder, submitOandaOrder, submitWobbleOrder } from '../../actions/oanda';
import TooltipItem from '../TooltipItem/TooltipItem';

/**
 * Our valid market types
 */
const orderTypes = ['MARKET', 'LIMIT', 'STOP', 'MARKET_IF_TOUCHED', 'TAKE_PROFIT', 'STOP_LOSS', 'TRAILING_STOP_LOSS'];

// To use redux hooks, you export a function component, not a react component.
export const OrdersBuySell = (props) => {
	const { chartInstrument, modals } = props;

	/**
	 * Fetch our redux states
	 */
	const isOpen = modals?.ordersOpen || false;
	const isOpenTPSL = modals?.tpslOpen || 0;
	const isOpenLimit = modals?.limitOpen || 0;

	/**
	 * Get the rest of our data
	 */
	const chartCandles = useSelector((state) => {
		if (
			state?.oanda?.chartCandles != null &&
			state?.oanda?.chartInstrument != null &&
			state?.oanda?.chartGranularity != null &&
			state?.oanda?.chartCandles?.[chartInstrument]?.[state?.oanda?.chartGranularity] != null
		) {
			return state.oanda.chartCandles[chartInstrument][state.oanda.chartGranularity];
		}
	}, objectEqual);
	const allTrades = useSelector((state) => {
		if (state.oanda.accountID != null && chartInstrument != null && state.oanda.trades[state.oanda.accountID] != null)
			return findAllInObjByInstrument(state.oanda.trades[state.oanda.accountID], chartInstrument);
	}, objectEqual);
	const conversionRates = useSelector((state) => state.oanda.conversionRates);
	const instrumentData = useSelector((state) => {
		if (state.oanda?.instruments != null && chartInstrument) {
			return state.oanda.instruments.filter((elem) => elem.name === chartInstrument)[0];
		}
	});
	const actBalance = useSelector((state) => {
		if (state.oanda.accountID != null && state.oanda.accountDetails[state.oanda.accountID] != null)
			return state.oanda.accountDetails[state.oanda.accountID].balance;
	}, objectEqual);
	const orders = useSelector((state) => {
		if (state.oanda.accountID != null && state.oanda.orders[state.oanda.accountID] != null) {
			return state.oanda.orders[state.oanda.accountID];
		}
	}, objectEqual);
	const dispatch = useDispatch();

	/**
	 * Get all trades
	 */
	const lastTrade = allTrades?.[allTrades?.length - 1] || null;

	/**
	 * Calculate the spread in pips
	 *
	 * Start by grabing the last chart
	 */
	const lastCandle = chartCandles?.[chartCandles.length - 1];
	const pipSpread = lastCandle ? getPipDifference(lastCandle?.bid?.c, lastCandle?.ask?.c, chartInstrument) : 0;

	/**
	 * To get the distance, just take the current
	 * price and find the difference from where it
	 * is now to where the current.
	 *
	 *
	 * We use the mid here because if you use ask or bid, you get a distance
	 * + spread and need to subtract spread... which is just the mid..
	 */
	const lastPrice = lastCandle?.mid?.c;

	/**
	 * If this is a popup for an existing order, find that
	 */
	const orderInQuestion = parseInt(isOpenTPSL) > 0 ? allTrades.filter((elem) => parseInt(elem.id) === parseInt(isOpenTPSL))[0] : null;

	/**
	 * Our local state
	 */
	const [positionSide, setPositionSide] = useState(lastTrade?.currentUnits > 0 ? 1 : lastTrade?.currentUnits < 0 ? -1 : 0);
	const [activeTab, setActiveTab] = useState(isOpenLimit > 0 ? '2' : '1');
	const [formValues, setFormValues] = useState({
		units: 1001,
		wobbleSplit: 5,
		wobblePipMove: 5,
		initialUnits: 0,
		checkTakeProfit: parseInt(orderInQuestion?.takeProfitOrderID) > 0 ? true : false,
		checkStopLoss: parseInt(orderInQuestion?.stopLossOrderID) > 0 ? true : false,
		checkTrailingStopLoss: false,
		limitPrice: isOpenLimit > 0 ? isOpenLimit : 0,
	});
	const [tpValues, setTpValues] = useState({
		tpPips: 10, // Note that if it start with TP or SL it will render calculation
		tpPrice: 0, // Note that if it start with TP or SL it will render calculation
		tpUSD: 0, // Note that if it start with TP or SL it will render calculation
		tpPercent: 0, // Note that if it start with TP or SL it will render calculation
	});
	const [slValues, setSlValues] = useState({
		slPips: 10, // Note that if it start with TP or SL it will render calculation
		slPrice: 0, // Note that if it start with TP or SL it will render calculation
		slUSD: 0, // Note that if it start with TP or SL it will render calculation
		slPercent: 0, // Note that if it start with TP or SL it will render calculation
		slDistance: 0,
	});
	const [sending, setSending] = useState(false);
	const [hasTyped, setHasTyped] = useState(false);

	/**
	 * We use a different price for buy and selling
	 * Buy on the ask, sell on the bid
	 */
	const orderPrice =
		parseFloat(orderInQuestion?.price) > 0 ? parseFloat(orderInQuestion?.price) : positionSide === -1 ? lastCandle?.bid?.c : lastCandle?.ask?.c;

	/**
	 * Return a text for which position we are on, if
	 * any.
	 */
	const getPositionSideText = () => {
		if (lastTrade?.currentUnits) {
			return lastTrade?.currentUnits > 0 ? '- Long' : '- Short';
		}
		return '';
	};

	const getIsOpenTPSLText = () => (isOpenTPSL ? ` (${isOpenTPSL})` : '');

	/**
	 * Create a title for the modal
	 */
	const modalTitle = `${chartInstrument}${getIsOpenTPSLText()}, ${orderPrice} ${getPositionSideText()}`;

	/**
	 * Set the units once it has been given
	 */
	useEffect(() => {
		const lastTradeUnits = Math.abs(parseInt(lastTrade?.initialUnits));

		if (lastTradeUnits > 0) {
			/**
			 * Make sure it isn't a dup and already changed
			 */
			if (formValues.initialUnits === 0 || formValues.initialUnits !== lastTradeUnits) {
				setFormValues({
					...formValues,
					units: lastTradeUnits + 1,
					limitPrice: isOpenLimit > 0 ? isOpenLimit : orderPrice ? orderPrice : parseInt(lastTrade.price),
					initialUnits: lastTradeUnits,
				});
			}
		}
	}, [lastTrade, chartInstrument]);

	/**
	 * We do all of this so that we can execute a
	 * callback. The setState hook does not have a callback
	 * like the old this->setState();
	 */
	useEffect(() => {
		if (!hasTyped) {
			calculateAllVAlues({
				target: {
					name: 'updates',
					value: formValues.units,
				},
			});
		}
	}, [chartCandles, conversionRates, chartInstrument]);

	/**
	 * This will set the check marks and recalc for the
	 * TP/SL.
	 */
	useEffect(() => {
		if (!orderInQuestion || (isNaN(orderInQuestion?.stopLossOrderID) && isNaN(orderInQuestion?.takeProfitOrderID))) return;

		if (parseInt(orderInQuestion?.stopLossOrderID) > 0) {
			/**
			 * Get our matching SL order
			 */
			const slOrder = orders.filter((elem) => elem.id === orderInQuestion.stopLossOrderID)[0];

			/**
			 * Recalc all
			 */
			calculateAllVAlues({
				target: {
					name: 'slPrice',
					value: slOrder.price,
				},
			});
		}
		if (parseInt(orderInQuestion?.takeProfitOrderID) > 0) {
			/**
			 * Get our matching SL order
			 */
			const tpOrder = orders.filter((elem) => elem.id === orderInQuestion.takeProfitOrderID)[0];

			/**
			 * Recalc all
			 */
			calculateAllVAlues({
				target: {
					name: 'tpPrice',
					value: tpOrder.price,
				},
			});
		}
	}, [orderInQuestion, orders]);

	/**
	 * Set our local state when a user clicks a
	 * side
	 */
	const handlePositionClick = (pos) => setPositionSide(pos);

	/**
	 * The onClose function
	 */
	const onClose = () => {
		/**
		 * Remove the checks from the TP/SL
		 */
		setFormValues({ ...formValues, checkStopLoss: false, checkTakeProfit: false, checkTrailingStopLoss: false });

		/**
		 * Close the modal
		 */
		dispatch(changeModalOrdersOpen(false));
		dispatch(changeModalTPSLOpen(0));
		dispatch(changeModalLimitOpen(0));
	};

	/**
	 * Check the validity of an input
	 *
	 * @param {obj} e - event
	 * @param {bool} calculate - calculateAll or not
	 */
	const setValue = (e, calculate = true) => {
		if (e && e?.target?.validity?.valid) {
			setFormValues({ ...formValues, [e.target.name]: e.target.value });
			if (calculate) calculateAllVAlues(e);
		}
	};

	/**
	 * For the checkboxes.
	 *
	 * @param {obj} e - click event
	 */
	const setCheckValue = (e) => {
		/**
		 * Since the checkTrailingStopLoss and checkStopLoss is in the same
		 * state, if we try to update both of them at the same time (with setCheckValue and
		 * activateColumn), one gets true and the other remains false. This fixes that issue.
		 */
		if (e.target.name === 'checkTrailingStopLoss' && !formValues[e.target.name])
			setFormValues({ ...formValues, [e.target.name]: !formValues[e.target.name], checkStopLoss: true });
		/**
		 * normal
		 */ else setFormValues({ ...formValues, [e.target.name]: !formValues[e.target.name] });
	};

	/**
	 * This function will submit two seperate orders
	 * if the user has TP and SL checked on the TPSL only.
	 */
	const handleTPSLOnly = async () => {
		if (parseInt(isOpenTPSL) <= 0) return;

		/**
		 * TP
		 */
		if (formValues.checkTakeProfit) await handleOrder('TAKE_PROFIT', false, true);
		if (formValues.checkStopLoss && !formValues.checkTrailingStopLoss) await handleOrder('STOP_LOSS', false, true);
		if (formValues.checkStopLoss && formValues.checkTrailingStopLoss) await handleOrder('TRAILING_STOP_LOSS', false, true);

		/**
		 * Remove the spinners from the buttons
		 */
		setSending(false);

		/**
		 * Close the modal
		 */
		onClose();
	};

	/**
	 * This is the market order send button.
	 *
	 * @param {str} type - DEFAULT: 'MARKET' LIMIT STOP MARKET_IF_TOUCHED TAKE_PROFIT STOP_LOSS TRAILING_STOP_LOSS
	 * @param {bool} wobble - true is to wobble
	 * @param {bool} noClose - This prevents the popup from closing, used in handleTPSLOnly so that we can submit multiple orders.
	 */
	const handleOrder = async (type = 'MARKET', wobble = false, noClose = false) => {
		if (positionSide === 0 || (positionSide !== 1 && positionSide !== -1) || sending) return;

		/**
		 * Make sure we have a valid type
		 */
		if (orderTypes.indexOf(type) === -1) return;

		/**
		 * Add the spinners to the buttons
		 */
		setSending(true);

		/**
		 * Collect all of our data
		 */
		const orderObject = {
			type,
			instrument: chartInstrument,
			units: formValues.units * positionSide, // Will be 1 or -1
			timeInForce: 'FOK', // The Order must be immediately “Filled Or Killed” - market order
			positionFill: 'DEFAULT',
			triggerCondition: 'DEFAULT',
		};

		/**
		 * If this is a TP or SL modification, we need
		 * the tradeID as well.
		 */
		if (parseInt(isOpenTPSL) > 0) {
			orderObject.tradeID = `${isOpenTPSL}`; // this has to be a string
			orderObject.timeInForce = 'GTC';
			orderObject.price = type === 'TAKE_PROFIT' ? `${tpValues.tpPrice}` : `${slValues.slPrice}`; // These have to be strings...
		}

		/**
		 * Now we add our type
		 */
		if (
			/**
			 * We need a price for certain types
			 */
			type === 'LIMIT' ||
			type === 'MARKET_IF_TOUCHED' ||
			type === 'STOP' ||
			type === 'TAKE_PROFIT' ||
			type === 'STOP_LOSS'
		) {
			if (parseInt(isOpenTPSL) <= 0) orderObject.price = `${formValues.limitPrice}`;
			orderObject.timeInForce = 'GTC';
			orderObject.triggerCondition = 'MID';
		} else if (type === 'TRAILING_STOP_LOSS') {
			/**
			 * Trailing SL only
			 */
			orderObject.distance = `${pipsToPricePoint(slValues.slPips, chartInstrument)}`;
			orderObject.timeInForce = 'GTC';

			/**
			 * Of the options available (see link below), these two
			 * seem like the best option to keep trades from being
			 * "spread closed".
			 *
			 * MID: Trigger an Order by comparing its price to the midpoint
			 * 		regardless of whether it is long or short.
			 *
			 * INVERSE: Trigger an Order the opposite of the “natural” way:
			 * 		compare its price the bid for long Orders and ask for short Orders.
			 *
			 * https://developer.oanda.com/rest-live-v20/order-df/#OrderTriggerCondition
			 */
			orderObject.triggerCondition = 'MID';
		}

		/**
		 * Take Profit
		 */
		if (formValues.checkTakeProfit && parseInt(isOpenTPSL) <= 0)
			orderObject.takeProfitOnFill = {
				price: `${tpValues.tpPrice}`, // These are strings...
				timeInForce: 'GTC',
			};

		/**
		 * Stop Loss
		 */
		if (formValues.checkStopLoss && parseInt(isOpenTPSL) <= 0 && !formValues.checkTrailingStopLoss)
			orderObject.stopLossOnFill = {
				price: `${slValues.slPrice}`,
				timeInForce: 'GTC',
			};
		/**
		 * Trailing
		 */
		if (formValues.checkTrailingStopLoss && parseInt(isOpenTPSL) <= 0)
			orderObject.trailingStopLossOnFill = {
				distance: `${pipsToPricePoint(slValues.slPips, chartInstrument)}`,
				timeInForce: 'GTC',

				/**
				 * Of the options available (see link below), these two
				 * seem like the best option to keep trades from being
				 * "spread closed".
				 *
				 * MID: Trigger an Order by comparing its price to the midpoint
				 * 		regardless of whether it is long or short.
				 *
				 * INVERSE: Trigger an Order the opposite of the “natural” way:
				 * 		compare its price the bid for long Orders and ask for short Orders.
				 *
				 * https://developer.oanda.com/rest-live-v20/order-df/#OrderTriggerCondition
				 */
				triggerCondition: 'MID',
			};

		/**
		 * Wobble it!
		 */
		if (wobble)
			orderObject.wobble = {
				positionSide: positionSide,
				wobbleStartPrice: orderPrice,
				wobbleSplit: formValues.wobbleSplit,
				wobblePipMove: formValues.wobblePipMove,
				wobbleUnitsPerSplit: parseInt(formValues.units / formValues.wobbleSplit),
			};

		/**
		 * Depending on type, send it on!
		 */
		if ((type === 'STOP_LOSS' || type === 'TRAILING_STOP_LOSS') && orderInQuestion?.stopLossOrderID)
			await dispatch(replaceOandaOrder(orderInQuestion.stopLossOrderID, orderObject));
		else if (type === 'TAKE_PROFIT' && orderInQuestion?.takeProfitOrderID)
			await dispatch(replaceOandaOrder(orderInQuestion.takeProfitOrderID, orderObject));
		else if (wobble) await dispatch(submitWobbleOrder(orderObject));
		else await dispatch(submitOandaOrder(orderObject));

		/**
		 * Remove the spinners from the buttons
		 */
		if (!noClose) setSending(false);

		/**
		 * Close the modal
		 */
		if (!noClose) onClose();
	};

	/**
	 * When a change is made in any of the inputs,
	 * calculate it here.
	 */
	const calculateAllVAlues = async (e) => {
		/**
		 * If we have not selected a side, select one.
		 */
		if (positionSide === 0) setPositionSide(1);

		/**
		 * If it isNAN, just set the value and move on
		 */
		if (isNaN(parseFloat(e.target.value))) return setValue(e, false);

		/**
		 * Fetch our value
		 */
		const value = parseFloat(e.target.value); //parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : 0;

		/**
		 * Activate the column if needed
		 */
		activateColumn(e);

		/**
		 * Helper functions
		 */
		const getProfitFromPips = (pips) => floatPrecisionFix(getPipValue(orderPrice, formValues.units, chartInstrument, conversionRates) * pips, 3);
		const getPipsFromProfit = (profit) => floatPrecisionFix(profit / getPipValue(orderPrice, formValues.units, chartInstrument, conversionRates), 3);
		const getProfitFromPercent = (percent) => floatPrecisionFix(actBalance * (percent / 100), 3);

		/**
		 * If we can determine the pips per each one,
		 * we can set all other values based on that.
		 */
		const setAllTPFromPips = (pips, e) => {
			/**
			 * In order for this to be acurate, we have to
			 * add the spread in.
			 */
			const tpPipSpread = pips; // + pipSpread; // This caused the price to change each time a user adjusted it.

			/**
			 * Why do we use the e.target.value?
			 *
			 * Because JS will strip the . or .00 off of anything a
			 * user inputs when converted into a decimal. That means if the
			 * user types 125. or 125.0 then it will result in 125. The user
			 * will never be able to type any decimal place.
			 */
			setTpValues({
				tpPips: pips,
				tpPrice: e.target.name === 'tpPrice' ? e.target.value : addPipsToPrice(tpPipSpread, orderPrice, positionSide, chartInstrument),
				tpUSD: e.target.name === 'tpUSD' ? e.target.value : getProfitFromPips(pips),
				tpPercent: e.target.name === 'tpPercent' ? e.target.value : floatPrecisionFix((getProfitFromPips(pips) / actBalance) * 100, 5),
			});
		};
		const setAllSLFromPips = (pips, e) => {
			/**
			 * In order for this to be acurate, we have to
			 * add the spread in.
			 */
			const slPipSpread = pips; // + pipSpread; // This caused the price to change each time a user adjusted it.

			setSlValues({
				slPips: pips,
				slPrice:
					e.target.name === 'slPrice'
						? e.target.value
						: addPipsToPrice(slPipSpread, formValues.checkTrailingStopLoss ? lastPrice : orderPrice, -positionSide, chartInstrument), // Do the opposite for positionSide
				slUSD: e.target.name === 'slUSD' ? e.target.value : getProfitFromPips(pips),
				slPercent: e.target.name === 'slPercent' ? e.target.value : floatPrecisionFix((getProfitFromPips(pips) / actBalance) * 100, 5),
				slDistance: e.target.name === 'slDistance' ? e.target.value : floatPrecisionFix(pipsToPricePoint(pips, chartInstrument), 5),
			});
		};

		/**
		 * Make sure we have a value
		 *
		 * Yes, there is two rates...
		 */
		if (value == null || conversionRates?.rates?.[chartInstrument]?.bid == null) return; //setValue(e);

		/**
		 * Make the correct calcuation
		 */
		switch (e.target.name) {
			case 'tpPips': {
				setAllTPFromPips(value, e);
				break;
			}
			case 'tpPrice': {
				setAllTPFromPips(getPipDifference(value, orderPrice, chartInstrument), e);
				break;
			}
			case 'tpUSD': {
				setAllTPFromPips(getPipsFromProfit(value), e);
				break;
			}
			case 'tpPercent': {
				setAllTPFromPips(getPipsFromProfit(getProfitFromPercent(value)), e);
				break;
			}
			case 'slPips': {
				setAllSLFromPips(value, e);
				break;
			}
			case 'slPrice': {
				if (formValues.checkTrailingStopLoss) setAllSLFromPips(getPipDifference(value, lastPrice, chartInstrument), e);
				else setAllSLFromPips(getPipDifference(value, orderPrice, chartInstrument), e);
				break;
			}
			case 'slUSD': {
				setAllSLFromPips(getPipsFromProfit(value), e);
				break;
			}
			case 'slPercent': {
				setAllSLFromPips(getPipsFromProfit(getProfitFromPercent(value)), e);
				break;
			}
			/**
			 * For everything else, call the set value on
			 * the item and do a generic recalc using pips
			 */
			default: {
				setAllTPFromPips(tpValues.tpPips, e);
				setAllSLFromPips(slValues.slPips, e);
				break;
			}
		}

		/**
		 * This will prevent updating when candles
		 * change, after a user has typed.
		 *
		 * 2021-04-12 05:30:54 - JD
		 */
		if (e?.type === 'change') setHasTyped(true);
	};

	/**
	 * This will activate a section/column when
	 * clicked.
	 */
	const activateColumn = (e) => {
		if (e.target.name.startsWith('tp') && !formValues.checkTakeProfit) setFormValues({ ...formValues, checkTakeProfit: true });
		else if ((e.target.name.startsWith('sl') || e.target.name === 'checkTrailingStopLoss') && !formValues.checkStopLoss)
			setFormValues({ ...formValues, checkStopLoss: true });
	};

	/**
	 * Determine what to render
	 */
	const renderDisplay = () => {
		if (isOpen || isOpenLimit !== 0) return renderFull();
		if (isOpenTPSL !== 0) return renderTPSLOnly();
	};

	/**
	 * This will show data about the current instrument.
	 *
	 * @returns
	 */
	const renderBottomInstDisplay = () => {
		return (
			<>
				<div className="row">
					<div className="col-sm-6 col-lg-6">Finance Long:</div> <div className="col-sm-6 col-lg-6">{instrumentData.financing.longRate}</div>
				</div>
				<div className="row">
					<div className="col-sm-6 col-lg-6">Finance Short:</div> <div className="col-sm-6 col-lg-6">{instrumentData.financing.shortRate}</div>
				</div>
				<div className="row">
					<div className="col-sm-6 col-lg-6">Min Trailing Distance:</div>{' '}
					<div className="col-sm-6 col-lg-6">{instrumentData.minimumTrailingStopDistance}</div>
				</div>
			</>
		);
	};

	/**
	 * Render the TP and SL only
	 * @returns
	 */
	const renderTPSLOnly = () => {
		return (
			<div className="modalOrders mt-2 mb-4">
				{renderTPSL()}
				<div className="row ml-2 mr-2 mt-2">
					<div className="col-sm-12 col-lg-1"></div>
					<div className="col-sm-12 col-lg-9">
						<button
							className={`col-12 btnRounded btn mt-2 ${positionSide === 1 ? 'btn-green' : positionSide === -1 ? 'btn-red' : 'btn-disabled'}`}
							onClick={() => handleTPSLOnly()}
						>
							{sending ? <i className="la la-spinner la-spin" style={{ fontSize: '16px' }} /> : 'Modify'}
						</button>
					</div>
				</div>
				<div className="row ml-2 mr-2">
					<div className="col-sm-12 col-lg-2"></div>
					<div className="col-sm-12 col-lg-10 infoSegment">{renderBottomInstDisplay()}</div>
					<div className="col-sm-12 col-lg-1"></div>
				</div>
			</div>
		);
	};
	/**
	 * Render the TP/SL only
	 */
	const renderTPSL = () => {
		return (
			<div className="row">
				<div className="col-sm-12 col-lg-1"></div>
				<div className="col-sm-12 col-lg-4">{renderTP()}</div>
				<div className="col-sm-12 col-lg-1 pl-0 ml-0">
					<div className="col-12">&nbsp;</div>
					<div className="col-12 separatorColumnCenterLabel mt-1 mb-1"></div>
					<div className="row pl-0 ml-0">
						<div className="col-12 centerColumn pl-0 ml-0">Pips</div>
						<div className="col-12 centerColumn pl-0 ml-0">Price</div>
						<div className="col-12 centerColumn pl-0 ml-0">USD</div>
						<div className="col-12 centerColumn pl-0 ml-0">%</div>
						{formValues.checkTrailingStopLoss ? <div className="col-12 centerColumn pl-0 ml-0">Distance</div> : ''}
					</div>
				</div>
				<div className="col-sm-12 col-lg-4 mr-0">{renderSL()}</div>
				<div className="col-sm-12 col-lg-1 pl-0 ml-0 verticalTrailing">{renderTrailingStop()}</div>
			</div>
		);
	};

	const renderSL = () => {
		return (
			<FormGroup className="checkbox abc-checkbox abc-checkbox-danger" check>
				<Input id="checkStopLoss" name="checkStopLoss" type="checkbox" checked={formValues.checkStopLoss} onChange={setCheckValue} />{' '}
				<Label for="checkStopLoss" check>
					Stop Loss
				</Label>
				<div className="col-12 separatorColumnCenterLabel mt-1 mb-1"></div>
				<div className={`row pl-0 ml-0 ${formValues.checkStopLoss ? '' : 'disabledColumn'}`}>
					<div className="col-12 pl-0 ml-0">
						<Input
							type="text"
							pattern="^([-+,0-9\b.]+)"
							value={slValues.slPips}
							onChange={calculateAllVAlues}
							onClick={activateColumn}
							placeholder="pips"
							name="slPips"
							className="inputColumn inputColumnRight"
						/>
					</div>
					<div className="col-12 pl-0 ml-0">
						<Input
							type="text"
							pattern="[0-9\b.]*"
							value={slValues.slPrice}
							onChange={calculateAllVAlues}
							onClick={activateColumn}
							placeholder="Price"
							name="slPrice"
							className="inputColumn inputColumnRight"
						/>
					</div>
					<div className="col-12 pl-0 ml-0">
						<Input
							type="text"
							pattern="[0-9\b.]*"
							value={slValues.slUSD}
							onChange={calculateAllVAlues}
							onClick={activateColumn}
							placeholder="USD"
							name="slUSD"
							className="inputColumn inputColumnRight"
						/>
					</div>
					<div className="col-12 pl-0 ml-0">
						<Input
							type="text"
							pattern="[0-9\b.]*"
							value={slValues.slPercent}
							onChange={calculateAllVAlues}
							onClick={activateColumn}
							placeholder="Percent"
							name="slPercent"
							className="inputColumn inputColumnRight"
						/>
					</div>
					{formValues.checkTrailingStopLoss ? (
						<div className="col-12 pl-0 ml-0">
							<Input
								type="text"
								pattern="[0-9\b.]*"
								defaultValue={slValues.slDistance}
								value={slValues.slDistance}
								placeholder="Distance"
								name="slDistance"
								className="inputColumn inputColumnRight"
							/>
						</div>
					) : (
						''
					)}
				</div>
			</FormGroup>
		);
	};

	/**
	 * Render the TP only
	 */
	const renderTP = () => {
		return (
			<FormGroup className="checkbox abc-checkbox abc-checkbox-success" check>
				<Input id="checkTakeProfit" name="checkTakeProfit" type="checkbox" checked={formValues.checkTakeProfit} onChange={setCheckValue} />{' '}
				<Label for="checkTakeProfit" check>
					Take Profit
				</Label>
				<div className="col-12 separatorColumnCenterLabel mt-1 mb-1 pl-0 pl-0"></div>
				<div className={`row pl-0 ml-0 ${formValues.checkTakeProfit ? '' : 'disabledColumn'}`}>
					<div className="col-12 pl-0 ml-0">
						<Input
							type="text"
							pattern="[0-9-]*"
							value={tpValues.tpPips}
							onChange={calculateAllVAlues}
							onClick={activateColumn}
							placeholder="pips"
							name="tpPips"
							className="inputColumn"
						/>
					</div>
					<div className="col-12 pl-0 ml-0">
						<Input
							type="text"
							pattern="[0-9\b.]*"
							value={tpValues.tpPrice}
							onChange={calculateAllVAlues}
							onClick={activateColumn}
							placeholder="Price"
							name="tpPrice"
							className="inputColumn"
						/>
					</div>
					<div className="col-12 pl-0 ml-0">
						<Input
							type="text"
							pattern="[0-9\b.]*"
							value={tpValues.tpUSD}
							onChange={calculateAllVAlues}
							onClick={activateColumn}
							placeholder="USD"
							name="tpUSD"
							className="inputColumn"
						/>
					</div>
					<div className="col-12 pl-0 ml-0">
						<Input
							type="text"
							pattern="[0-9\b.]*"
							value={tpValues.tpPercent}
							onChange={calculateAllVAlues}
							onClick={activateColumn}
							placeholder="Percent"
							name="tpPercent"
							className="inputColumn"
						/>
					</div>
					{formValues.checkTrailingStopLoss ? <div className="col-12 pl-0 ml-0"> </div> : ''}
				</div>
			</FormGroup>
		);
	};

	/**
	 * Displays the trailing stoploss checkbox
	 *
	 * @returns render
	 */
	const renderTrailingStop = () => {
		return (
			<FormGroup className="checkbox abc-checkbox abc-checkbox-danger" check>
				<Input
					id="checkTrailingStopLoss"
					name="checkTrailingStopLoss"
					type="checkbox"
					checked={formValues.checkTrailingStopLoss}
					onChange={setCheckValue}
				/>{' '}
				<Label for="checkTrailingStopLoss" className="verticalSmallCheck" check>
					Trailing
				</Label>
			</FormGroup>
		);
	};

	/**
	 * Render everything, with tabs and all.
	 */
	const renderFull = () => {
		return (
			<div className="modalOrders mt-2">
				<div className="side">
					<div className="sideControl">
						<div className={`section sell ${positionSide === -1 ? 'active' : ''}`} tabIndex="-1" onClick={() => handlePositionClick(-1)}>
							<div className="title">
								<span>Sell</span>
							</div>
							<div className="value">
								<div>{lastCandle?.bid?.c}</div>
							</div>
						</div>
						<div className={`section buy ${positionSide === 1 ? 'active' : ''}`} tabIndex="-1" onClick={() => handlePositionClick(1)}>
							<div className="title">
								<span>Buy</span>
							</div>
							<div className="value">
								<div>{lastCandle?.ask?.c}</div>
							</div>
						</div>
						<div className="spread">{pipSpread}</div>
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-12">
						<Nav tabs>
							<NavItem>
								<NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
									<span>Market</span>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
									<span>Limit</span>
								</NavLink>
							</NavItem>
						</Nav>
					</div>
					<div className="col-12">
						{/* <div className="separator"></div> */}
						<TabContent className="mb-0 " activeTab={activeTab}>
							<TabPane tabId="1" className="">
								<div className="row ">
									<div className="col-12">
										<InputGroup className="">
											<div className="input-group-prepend">
												<div className="input-group-text">Units</div>
											</div>
											<Input
												type="text"
												pattern="[0-9]*"
												value={formValues.units}
												onChange={setValue}
												placeholder="Number Units..."
												autoFocus={true}
												name="units"
											/>
											<InputGroupAddon addonType="prepend">
												<button
													className={`btn ${positionSide === 1 ? 'btn-green' : positionSide === -1 ? 'btn-red' : 'btn-disabled'}`}
													onClick={() => handleOrder('MARKET', false)}
												>
													{sending ? <i className="la la-spinner la-spin" style={{ fontSize: '16px' }} /> : 'Send'}
												</button>
											</InputGroupAddon>
										</InputGroup>

										{/* <div className="unitText">Units</div> */}
										<div className="blankSpacer pt-1 pb-1"></div>
										{renderTPSL()}
									</div>

									<div className="col-12">
										<div className="blankSpacer pt-2 pb-2"></div>
										<div className="textWithLine mb-3 mt-3 ml-4">Wobble It</div>
										<InputGroup className="">
											<div className="input-group-prepend">
												<div className="input-group-text">Split</div>
											</div>
											<Input type="text" pattern="[0-9]*" value={formValues.wobbleSplit} onChange={setValue} placeholder="5" name="wobbleSplit" />
											<InputGroupAddon addonType="prepend">
												<button
													className={`btn ${positionSide === 1 ? 'btn-green' : positionSide === -1 ? 'btn-red' : 'btn-disabled'}`}
													onClick={() => handleOrder('MARKET', true)}
												>
													{sending ? <i className="la la-spinner la-spin" style={{ fontSize: '16px' }} /> : 'Send'}
												</button>
											</InputGroupAddon>
										</InputGroup>
										<div className="infoCentered mb-1 mt-3">
											<span className="mr-2">{floatToNumberFormat(parseInt(formValues.units / formValues.wobbleSplit))}</span> every
											<Input
												type="text"
												pattern="[0-9]*"
												value={formValues.wobblePipMove}
												onChange={setValue}
												placeholder="pips"
												autoFocus={true}
												name="wobblePipMove"
												className="tinyTextBox"
											/>
											pips
										</div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId="2">
								<div className="row ">
									<div className="col-12">
										<InputGroup className="">
											<div className="input-group-prepend">
												<div className="input-group-text">Units</div>
											</div>
											<Input
												type="text"
												pattern="[0-9]*"
												value={formValues.units}
												onChange={setValue}
												placeholder="Number Units..."
												autoFocus={true}
												name="units"
											/>
											<div className="input-group-prepend">
												<div className="input-group-text">@</div>
											</div>
											<Input
												type="text"
												pattern="[0-9\b.]*"
												value={formValues.limitPrice}
												onChange={setValue}
												placeholder="Price"
												autoFocus={true}
												name="limitPrice"
												id="limitPrice"
											/>

											<TooltipItem placement="top" target="limitPrice">
												Price
											</TooltipItem>

											<InputGroupAddon addonType="prepend">
												<button
													className={`btn ${positionSide === 1 ? 'btn-green' : positionSide === -1 ? 'btn-red' : 'btn-disabled'} btn-double-button`}
													onClick={() => handleOrder('LIMIT', false)}
													id="btnLimitOrder"
												>
													{sending ? <i className="la la-spinner la-spin" style={{ fontSize: '16px' }} /> : 'LO'}
												</button>
												<button
													className={`btn ${positionSide === 1 ? 'btn-green' : positionSide === -1 ? 'btn-red' : 'btn-disabled'} btn-double-button`}
													onClick={() => handleOrder('MARKET_IF_TOUCHED', false)}
													id="btnMitOrder"
												>
													{sending ? <i className="la la-spinner la-spin" style={{ fontSize: '16px' }} /> : 'MiT'}
												</button>
												<button
													className={`btn ${positionSide === 1 ? 'btn-green' : positionSide === -1 ? 'btn-red' : 'btn-disabled'}`}
													onClick={() => handleOrder('STOP', false)}
													id="btnStopOrder"
												>
													{sending ? <i className="la la-spinner la-spin" style={{ fontSize: '16px' }} /> : 'SO'}
												</button>

												<TooltipItem placement="top" target="btnLimitOrder">
													Limit Order
												</TooltipItem>
												<TooltipItem placement="top" target="btnMitOrder">
													Market if Touched
												</TooltipItem>
												<TooltipItem placement="top" target="btnStopOrder">
													Stop Order
												</TooltipItem>
											</InputGroupAddon>
										</InputGroup>
										<div className="infoSegment">
											<ul>
												<li>
													<span className="infoLine">Short:</span>
													<ul>
														<li>
															You want to short above current price? Use <span className="infoLine">Limit</span>
														</li>
														<li>
															You want to short below current price? Use <span className="infoLine">MiT</span> or <span className="infoLine">SO</span>
														</li>
													</ul>
												</li>
												<li className="mt-3">
													<span className="infoLine">Long:</span>
													<ul>
														<li>
															You want to buy below current price? Use <span className="infoLine">Limit</span>
														</li>
														<li>
															You want to buy above current price? Use <span className="infoLine">MiT</span> or <span className="infoLine">SO</span>
														</li>
													</ul>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
				</div>
			</div>
		);
	};

	/**
	 * The required return
	 */
	return (
		<BaseModal isOpen={isOpen || isOpenTPSL > 0 || isOpenLimit > 0} onClose={onClose} title={modalTitle} mainClassNAmeclassName="orderModal">
			{renderDisplay()}
		</BaseModal>
	);
};
