import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeSidebar } from '../../actions/navigation';
import { fetchAllData } from '../../actions/oanda';
import { chartColors } from '../../components/StockChart/config/genericConfig';
import { ChartComponent } from '../../components/StockChart/default';
import useDimensions from '../../components/useDimensions';

// To use redux hooks, you export a function component, not a react component.
export const Chart = (props) => {
	/**
	 * Call the state variables and dispatch into
	 * existance.
	 */
	// const backtest = useSelector((state) => state.backtest.data.backtest);
	const dispatch = useDispatch();

	/**
	 * Get the chart width and height
	 */
	const [chartDivRef, dimensions] = useDimensions({ noScroll: true });

	/**
	 * Similar to componentDidMount and componentDidUpdate:
	 * The optional second param ([]) in useEffect tells it to only
	 * run if whatever data in that array has changed. If left blank (just []),
	 * it will only run once.
	 */
	useEffect(() => {
		/**
		 * Load our chart data
		 */
		dispatch(fetchAllData());

		/**
		 * Keep the sidebar closed if we are on the graph chart.
		 */
		dispatch(closeSidebar());
	}, [dispatch, props.match.params.id]);

	return (
		<div ref={chartDivRef} style={{ height: '100vh' }}>
			<ChartComponent
				height={dimensions.height}
				backgroundColor={chartColors.chartBackground}
				toolbarBackgroundColor={chartColors.toolbarBackground}
			/>
		</div>
	);
};
