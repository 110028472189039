import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // useDispatch()
// import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import s from './Sidebar.module.scss';
import LinksGroup from './LinksGroup';
import { changeActiveSidebarItem } from '../../actions/navigation';
import { HeartbeatSignals } from './components/HeartbeatSignals';
import { slide as Menu } from 'react-burger-menu';

const Sidebar = (props) => {
	/**
	 * Pull our navigation element out
	 */
	const navigation = useSelector((state) => state.navigation);
	const dispatch = useDispatch();

	/**
	 * Our state
	 */
	const [menuOpen, setMenuOpen] = useState(false);

	return (
		<Menu
			noOverlay
			pageWrapId={'page-wrap'}
			outerContainerId={'outer-container'}
			isOpen={menuOpen}
			onStateChange={(state) => setMenuOpen(state.isOpen)}
			width={'400px'}
			disableAutoFocus
		>
			<header className={s.logo} style={{ overflow: 'visible' }}>
				<a href="/">
					Ade<span className="fw-bold">nom</span>
				</a>
			</header>
			<nav ref={(nav) => {}}>
				<ul className={s.nav}>
					<LinksGroup
						onActiveSidebarItemChange={(activeItem) => dispatch(changeActiveSidebarItem(activeItem))}
						activeItem={navigation.activeItem}
						header="Dashboard"
						isOpen={true}
						isHeader
						iconName="flaticon-home"
						link="/app/main"
						index="main"
						childrenLinks={[
							{
								header: 'Home',
								link: '/',
							},
						]}
					/>
					<LinksGroup
						onActiveSidebarItemChange={(activeItem) => dispatch(changeActiveSidebarItem(activeItem))}
						activeItem={navigation.activeItem}
						header="Browse"
						isHeader
						iconName="flaticon-database-1"
						link="/browse"
						index="browse"
						label="Backtests"
						labelColor="success"
						exact={false}
						childrenLinks={[
							{
								header: 'Optimizations',
								link: '/browse/optimization',
							},
							{
								header: 'Backtests',
								link: '/browse/backtests',
							},
							{
								header: 'Transactions',
								link: '/browse/transactions',
							},
						]}
					/>
					<LinksGroup
						onActiveSidebarItemChange={(activeItem) => dispatch(changeActiveSidebarItem(activeItem))}
						activeItem={navigation.activeItem}
						header="Instruments"
						isHeader
						iconName="flaticon-home"
						link="/instruments"
						index="main"
					/>
					<LinksGroup
						onActiveSidebarItemChange={(activeItem) => dispatch(changeActiveSidebarItem(activeItem))}
						activeItem={navigation.activeItem}
						header="Notes"
						isHeader
						iconName="flaticon-home"
						link="/notes"
						index="main"
					/>
					<LinksGroup
						onActiveSidebarItemChange={(activeItem) => dispatch(changeActiveSidebarItem(activeItem))}
						activeItem={navigation.activeItem}
						header="News"
						isHeader
						iconName="flaticon-home"
						link="/news"
						index="main"
					/>
					<LinksGroup
						onActiveSidebarItemChange={(activeItem) => dispatch(changeActiveSidebarItem(activeItem))}
						activeItem={navigation.activeItem}
						header="Sentiment"
						isHeader
						iconName="flaticon-home"
						link="/sentiment"
						index="main"
					/>
				</ul>
				<HeartbeatSignals />
			</nav>
		</Menu>
	);
};

export default withRouter(Sidebar);
