// Oanda Action/Reducer Types
export const FETCH_PROFIT_DATA = 'FETCHING_PROFIT_DATA';
export const FETCH_PROFIT_DATA_TODAY = 'FETCH_PROFIT_DATA_TODAY';
export const FETCH_PROFIT_DATA_YESTERDAY = 'FETCH_PROFIT_DATA_YESTERDAY';
export const FETCH_PROFIT_DATA_D2 = 'FETCH_PROFIT_DATA_D2';
export const FETCH_PROFIT_DATA_D3 = 'FETCH_PROFIT_DATA_D3';
export const FETCH_PROFIT_DATA_D4 = 'FETCH_PROFIT_DATA_D4';
export const FETCH_PROFIT_DATA_D5 = 'FETCH_PROFIT_DATA_D5';
export const FETCH_PROFIT_DATA_D6 = 'FETCH_PROFIT_DATA_D6';
export const FETCH_PROFIT_DATA_D7 = 'FETCH_PROFIT_DATA_D7';
export const FETCH_PROFIT_DATA_D8 = 'FETCH_PROFIT_DATA_D8';
export const FETCH_PROFIT_DATA_D9 = 'FETCH_PROFIT_DATA_D9';
export const FETCH_PROFIT_DATA_D10 = 'FETCH_PROFIT_DATA_D10';
export const FETCH_PROFIT_DATA_WEEK = 'FETCH_PROFIT_DATA_WEEK';
export const FETCH_BALANCE_WEEK = 'FETCH_BALANCE_WEEK';
export const FETCH_BALANCE_MONTH = 'FETCH_BALANCE_MONTH';
export const FETCH_BALANCE_QUARTER = 'FETCH_BALANCE_QUARTER';
export const FETCH_BALANCE_YEAR = 'FETCH_BALANCE_YEAR';
export const FETCH_ACCOUNT_DETAILS = 'FETCH_ACCOUNT_DETAILS';
export const FETCH_ACCOUNT_LIST = 'FETCH_ACCOUNT_LIST';
export const FETCH_PROFIT_DATA_LAST_WEEK = 'FETCH_PROFIT_DATA_LAST_WEEK';
export const FETCH_PROFIT_DATA_MONTH = 'FETCH_PROFIT_DATA_MONTH';
export const FETCH_PROFIT_DATA_LAST_MONTH = 'FETCH_PROFIT_DATA_LAST_MONTH';
export const FETCH_PROFIT_DATA_YEAR = 'FETCH_PROFIT_DATA_YEAR';
export const FETCH_PROFIT_DATA_LAST_YEAR = 'FETCH_PROFIT_DATA_LAST_YEAR';
export const SET_ACCOUNT_ID = 'SET_ACCOUNT_ID';
export const FETCH_ACCOUNT_TYPE = 'FETCH_ACCOUNT_TYPE';
export const FETCH_INSTRUMENT_LIST = 'FETCH_INSTRUMENT_LIST';
export const SET_CHART_INSTRUMENT = 'SET_CHART_INSTRUMENT';
export const FETCH_CHART_CANDLES = 'FETCH_CHART_CANDLES';
export const UPDATE_CHART_CANDLES = 'UPDATE_CHART_CANDLES';
export const SET_CHART_GRANULARITY = 'SET_CHART_GRANULARITY';
export const FETCH_BALANCE_LASTMONTH = 'FETCH_BALANCE_LASTMONTH';
export const FETCH_BALANCE_LASTWEEK = 'FETCH_BALANCE_LASTWEEK';
export const FETCH_PROFIT_DATA_52_WEEK = 'FETCH_PROFIT_DATA_52_WEEK';
export const FETCH_POSITIONS = 'FETCH_POSITIONS';
export const FETCH_TRADES = 'FETCH_TRADES';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDER_BOOK = 'FETCH_ORDER_BOOK';
export const FETCH_COT_DATA = 'FETCH_COT_DATA';
export const FETCH_POSITION_BOOK = 'FETCH_POSITION_BOOK';
export const FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA';
export const FETCH_PROFIT_STATS = 'FETCH_PROFIT_STATS';
export const SET_INDICATOR_LIST = 'SET_INDICATOR_LIST';
export const ADD_NEW_INDICATOR_LIST = 'ADD_NEW_INDICATOR_LIST';
export const SET_INDIVIDUAL_POSITIONS = 'SET_INDIVIDUAL_POSITIONS';
export const SET_CHART_TYPE = 'SET_CHART_TYPE';
export const SET_CALENDAR = 'SET_CALENDAR';
export const FETCH_STUCK_TRADES = 'FETCH_STUCK_TRADES';
export const MORE_CHART_CANDLES = 'MORE_CHART_CANDLES';
export const SET_GENERIC_API_DATA = 'SET_GENERIC_API_DATA';
export const SET_NONMERGE_GENERIC_API_DATA = 'SET_NONMERGE_GENERIC_API_DATA';
export const SET_CANDLE_TYPE = 'SET_CANDLE_TYPE';
export const CANDLE_LOOP_DONE = 'CANDLE_LOOP_DONE';
export const SHOW_FOOTER_LOADING = 'SHOW_FOOTER_LOADING';
export const SET_DASHBOARD_SIZE = 'SET_DASHBOARD_SIZE';
export const FETCH_CONVERSION_RATES = 'FETCH_CONVERSION_RATES';
export const CANCEL_ORDER_BY_ID = 'CANCEL_ORDER_BY_ID';

// Backtest Types
export const FETCH_BACKTEST_DATA = 'FETCH_BACKTEST_DATA';
export const FETCH_BACKTEST_CANDLES = 'FETCH_BACKTEST_CANDLES';
export const FETCH_OPTIMIZATION_DATA = 'FETCH_OPTIMIZATION_DATA';
export const FETCH_OPTIMIZATION_BROWSE_DATA = 'FETCH_OPTIMIZATION_BROWSE_DATA';
export const REMOVE_OPTIMIZATION_BROWSE_DATA = 'REMOVE_OPTIMIZATION_BROWSE_DATA';
export const FETCH_OPTIMIZATION_BROWSE_COUNT = 'FETCH_OPTIMIZATION_BROWSE_COUNT';
export const SET_OPTIMIZATION_CONFIG = 'SET_OPTIMIZATION_CONFIG';
export const SET_OPTIMIZATION_SELECTS = 'SET_OPTIMIZATION_SELECTS';
export const FETCH_BACKTEST_BROWSE_DATA = 'FETCH_BACKTEST_BROWSE_DATA';
export const REMOVE_BACKTEST_BROWSE_DATA = 'REMOVE_BACKTEST_BROWSE_DATA';
export const FETCH_BACKTEST_BROWSE_COUNT = 'FETCH_BACKTEST_BROWSE_COUNT';
export const SET_BACKTEST_CONFIG = 'SET_BACKTEST_CONFIG';
export const FETCH_TRANSACTION_BROWSE_DATA = 'FETCH_TRANSACTION_BROWSE_DATA';
export const FETCH_TRANSACTION_BROWSE_COUNT = 'FETCH_TRANSACTION_BROWSE_COUNT';
export const SET_TRANSACTION_CONFIG = 'SET_TRANSACTION_CONFIG';
export const REMOVE_TRANSACTION_BROWSE_DATA = 'REMOVE_TRANSACTION_BROWSE_DATA';
export const FETCH_TRANSACTION_DATA = 'FETCH_TRANSACTION_DATA';

// Heartbeat
export const FETCH_HEARTBEAT_DATA = 'FETCH_HEARTBEAT_DATA';
export const SET_HISTORICAL_HB_DATA = 'SET_HISTORICAL_HB_DATA';

// User Types
export const FETCH_USER = 'FETCH_USER';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const PASSWORD_RESET_EMAIL_REQUEST = 'PASSWORD_RESET_EMAIL_REQUEST';
export const PASSWORD_RESET_EMAIL_SUCCESS = 'PASSWORD_RESET_EMAIL_SUCCESS';
export const AUTH_INIT_SUCCESS = 'AUTH_INIT_SUCCESS';
export const AUTH_INIT_ERROR = 'AUTH_INIT_ERROR';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

// Header
export const SET_HEADER_STATUS = 'SET_HEADER_STATUS';

// Console
export const SET_CONSOLE_DATA = 'SET_CONSOLE_DATA';

// Notes
export const ADD_NEW_NOTE = 'ADD_NEW_NOTE';
export const FETCH_NOTES = 'FETCH_NOTES';
export const FETCH_NOTES_BY_TYPE = 'FETCH_NOTES_BY_TYPE';

// Auth
export const FETCH_APP_VERSION = 'FETCH_APP_VERSION';

// Navigation
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const CHANGE_ACTIVE_SIDEBAR_ITEM = 'CHANGE_ACTIVE_SIDEBAR_ITEM';
export const CHANGE_SIDEBAR_POSITION = 'CHANGE_SIDEBAR_POSITION';
export const CHANGE_SIDEBAR_VISIBILITY = 'CHANGE_SIDEBAR_VISIBILITY';
export const CHANGE_MODAL_INSTRUMENT_OPEN = 'CHANGE_MODAL_INSTRUMENT_OPEN';
export const CHANGE_MODAL_CHARTTYPE_OPEN = 'CHANGE_MODAL_CHARTTYPE_OPEN';
export const CHANGE_MODAL_GRANULARITY_OPEN = 'CHANGE_MODAL_GRANULARITY_OPEN';
export const CHANGE_MODAL_INDICATOR_OPEN = 'CHANGE_MODAL_INDICATOR_OPEN';
export const CHANGE_MODAL_ACCOUNTSEARCH_OPEN = 'CHANGE_MODAL_ACCOUNTSEARCH_OPEN';
export const CHANGE_MODAL_ORDERS_OPEN = 'CHANGE_MODAL_ORDERS_OPEN';
export const CHANGE_MODAL_TPSL_OPEN = 'CHANGE_MODAL_TPSL_OPEN';
export const CHANGE_MODAL_LIMIT_OPEN = 'CHANGE_MODAL_LIMIT_OPEN';
export const CHANGE_MODAL_INDICATOR_EDIT = 'CHANGE_MODAL_INDICATOR_EDIT';
