import { SET_CONSOLE_DATA } from '../actions/types';

const defaultState = {
	data: [],
};

export default function (state = defaultState, action) {
	switch (action.type) {
		case SET_CONSOLE_DATA:
			return (
				{
					...state,
					data: [...state.data.slice(-49), action.payload],
				} || false
			);
		default:
			return state;
	}
}
