import { Table, Badge } from 'reactstrap';
import React from 'react';
import { floatToCurrencyFormat } from '../../../components/utils';

// To use redux hooks, you export a function component, not a react component.
export const Trades = (props) => {
	/**
	 * Get our data
	 */
	const backtest = props.backtestData || null;

	/**
	 * Do nothing if null
	 */
	if (backtest == null) return '';

	/**
	 * This loops through all of the signals/trades and
	 * creates an output for each one.
	 */
	const signalsLoop = () => {
		return backtest.signals.map((signal) => {
			let typeColumn;
			if (signal.exit.type === 'win') typeColumn = <Badge color="success">Win</Badge>;
			else if (signal.exit.type === 'loss') typeColumn = <Badge color="danger">Loss</Badge>;
			else if (signal.exit.type === 'flat') typeColumn = <Badge color="warning">Flat</Badge>;

			return (
				<tr key={signal.entry.time}>
					<td>
						{signal.entry.position === 'long' ? (
							<span className="glyphicon glyphicon-chevron-up text-success" />
						) : (
							<span className="glyphicon glyphicon-chevron-down text-danger" />
						)}
					</td>
					<td>{signal.entry.time}</td>
					<td>{signal.entry.price}</td>
					<td>{signal.exit.price}</td>
					<td>{signal.exit.reason}</td>
					<td>{signal.entry.quantity / 100000}</td>
					<td>{typeColumn}</td>
					<td>{signal.profit.pips}</td>
					<td>{signal.profit.amount !== null ? floatToCurrencyFormat(signal.profit.amount) : '0'}</td>
					<td>{signal.entry.bar._id}</td>
				</tr>
			);
		});
	};

	/**
	 * Return our trade/signal table.
	 */
	return (
		<Table className="mb-0 table-striped table-hover text-white" borderless responsive>
			<thead>
				<tr className="text-white">
					<th scope="col"></th>
					<th scope="col">Date</th>
					<th scope="col">Price</th>
					<th scope="col">Exit</th>
					<th scope="col">XReason</th>
					<th scope="col">Lots</th>
					<th scope="col">Result</th>
					<th scope="col">Pips</th>
					<th scope="col">$</th>
					<th>id</th>
				</tr>
			</thead>
			<tbody>{signalsLoop()}</tbody>
		</Table>
	);
};
