/**
 * This is a replacement to the d3 deviation algo which
 * uses the TradingView deviation algo. The TV version is
 * different than the standard.
 *
 * 2021-04-04 08:19:51 JD (easter)
 *
 * @param {obj} values - the chart/graph values and line items
 * 			the value in values is the length (period, windowsize, etc)
 * @param {func} accessor - a function to get the value we want the standard deviation of
 *
 * @return {float}
 */
export const tvDeviation = (values, accessor) => {
	if (!values || !accessor) return 0;

	/**
	 * Our vars
	 */
	let deviationSum = 0,
		mean = 0;

	// console.log(values[10]);
	// console.log(accessor(values[10]));

	/**
	 * Let's get the average
	 */
	for (let z = 0; z < values.length; z++) mean += accessor(values[z]);
	mean = mean / values.length;

	/**
	 * Loop through and calculate!
	 */
	for (let z = 0; z < values.length - 1; z++) {
		let sampleMinusMean = accessor(values[z]) - mean;
		deviationSum = deviationSum + sampleMinusMean * sampleMinusMean;
	}

	return Math.sqrt(deviationSum / (values.length - 1));
};

export const tvVWAPDeviation = (values) => {
	/**
	 * Data validation and... if we only have 1
	 * value in the array, return 0 since it will be
	 * zero anyway.
	 */
	if (!values) return 0;

	/**
	 * Our vars
	 */
	let deviationSum = 0,
		meanSum = 0,
		mean = 0;

	/**
	 * Let's get the average
	 */
	for (let z = 0; z < values.length; z++) meanSum += values[z].mean;

	/**
	 * Get the mean
	 */
	mean = meanSum / values.length;

	/**
	 * Loop through and calculate!
	 */
	for (let z = 0; z < values.length - 1; z++) {
		// if (values[z].datum.dateMS === 1617573600000) console.log(values[z].mean - mean, values.length);

		deviationSum = deviationSum + Math.pow(values[z].mean - mean, 2);
	}

	// if (values[values.length - 1].datum.dateMS === 1617573600000) {
	// 	console.log(deviationSum, meanSum, values[values.length - 1].mean, mean, values.length);
	// }

	return Math.sqrt(deviationSum / values.length);
};

/**
 * Special VWAP deviation from pinescript
 *
 * vwapStdev() =>
 *  var int vwapLen = na
	var float vwapSum = na
	var float vwapStdev = na
	var vwapArray = array.new_float(na, na)
	vwapDividend = 0.0

	if isNewPeriod
		vwapLen := 0
		vwapSum := 0
		vwapStdev := 0
		array.clear(vwapArray)

	vwapLen := vwapLen + 1
	vwapSum := vwapSum + myvwap
	vwapMean = vwapSum / vwapLen

	if showBands
		array.push(vwapArray, myvwap)
		for i=0 to vwapLen-1
			vwapDividend := vwapDividend + pow(array.get(vwapArray, i)-vwapMean, 2)
		vwapStdev := sqrt(vwapDividend/vwapLen)

 */

/**
 * This is the python version of it
 */
// ubstdev(Series, Period) => // Unbiased Estimation
//     mean = sum(Series, Period) / Period
//     summation = 0.0
//     for i=0 to Period-1
//         sampleMinusMean = nz(Series[i]) - mean
//         summation := summation + sampleMinusMean * sampleMinusMean
//     return = sqrt(summation / (Period - 1))
