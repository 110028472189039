import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { findAllInObjByInstrument, objectEqual, getFormat } from '../../utils';
import { chartColors, cancelOrderWithAlert } from '../config/genericConfig';
import { InteractiveYCoordinate } from '../../../libs/react-stockcharts/src/lib/interactive';
import { changeModalTPSLOpen } from '../../../actions/navigation';

// To use redux hooks, you export a function component, not a react component.
export const TradesPriceCoordinateStore = () => {
	/**
	 * Get our store and the data that we need.
	 */
	const chartInstrument = useSelector((state) => state.oanda.chartInstrument);
	const individualPositions = useSelector((state) => state.oanda.chartSettings.individualPositions);
	const trades = useSelector((state) => {
		if (state.oanda.accountID != null && chartInstrument != null && state.oanda.trades[state.oanda.accountID] != null) {
			return findAllInObjByInstrument(state.oanda.trades[state.oanda.accountID], chartInstrument);
			// let localTrades = findAllInObjByInstrument(state.oanda.trades[state.oanda.accountID], chartInstrument);

			// if (localTrades.length > 0) {
			// 	return localTrades.map((trade) => {
			// 		return {
			// 			currentUnits: trade.currentUnits,
			// 			unrealizedPL: trade.unrealizedPL,
			// 			price: trade.price,
			// 		};
			// 	});
			// } else return null;
		}
	}, objectEqual);
	const dispatch = useDispatch();

	/**
	 * This is the holder for interactive y
	 * cooridnates.
	 */
	const yCoordinatePositionList = useRef([]);

	/**
	 * Update when trades updates
	 */
	useEffect(() => {
		yCoordinatePositionList.current = [];
	}, [trades]);

	/**
	 * Make sure we have data or return false.
	 * We seperate the below for legability.
	 */
	if (!individualPositions) return '';
	if (individualPositions && (!trades || trades == null || trades.length <= 0)) return '';

	/**
	 * The context menu is the right click menu.
	 * In our case, we bring up the
	 */
	const handleContextMenu = (tradeID) => {
		if (parseInt(tradeID) > 0) dispatch(changeModalTPSLOpen(parseInt(tradeID)));
	};

	/**
	 * When the X is clicked, this is ho it is handled.
	 *
	 * @param {obj} yCoordinate
	 * @param {obj} moreProps
	 */
	const handleDelete = (deletedAlert, moreProps) => cancelOrderWithAlert(deletedAlert, moreProps, dispatch);

	/**
	 * Loop through all of the trades
	 */
	for (let i = 0; i < trades.length; i++) {
		/**
		 * This is the object that we are going to push into
		 * the array.
		 *
		 * 2021-04-23 10:24:45
		 */
		const coordinateObject = {
			...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate,
			stroke: parseInt(trades[i].currentUnits) > 0 ? chartColors.ownTradeLongLine : chartColors.ownTradeShortLine,
			textFill: '#fff',
			bgFill: chartColors.bgColorTradingView,
			text: `${trades[i].unrealizedPL}  ${trades[i].currentUnits}`,
			yValue: parseFloat(trades[i].price),
			id: trades[i].id, //+ '_' + Math.random().toString(36).substr(2, 7),
			draggable: false,
			type: 'ORDER',
			strokeWidth: 1,
			onClick: () => handleContextMenu(trades[i].id),
			edge: {
				...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate.edge,
				displayFormat: getFormat(chartInstrument),
				stroke: parseInt(trades[i].currentUnits) > 0 ? chartColors.ownTradeLongLine : chartColors.ownTradeShortLine,
				fill: chartColors.bgColorTradingView,
				strokeWidth: 1,
			},
		};

		/**
		 * Determine if we have the item in our array already
		 *
		 * 2021-04-23 10:22:17 - JD
		 */
		const dupIndex = yCoordinatePositionList.current.findIndex((elem) => elem.id === trades[i].id);

		/**
		 * If we have a duplicate, update it. Otherwise,
		 * push the object into the array.
		 */
		if (dupIndex !== -1) yCoordinatePositionList.current[dupIndex] = coordinateObject;
		else yCoordinatePositionList.current.push(coordinateObject);
	}

	return <InteractiveYCoordinate enabled={false} yCoordinateList={yCoordinatePositionList.current} onDelete={handleDelete} />;
};
