import React from 'react';
import { useSelector, useDispatch } from 'react-redux'; // useDispatch()
import CustomChart from './CustomChart';
import { CustomChartSidebar } from './CustomChartSidebar';
import { CustomChartFooter } from './CustomChartFooter';
import { getPipDifference, formatOandaData, addSignalsToData, addGenericToData, detrendBars, addGenericToDataByIndex } from '../utils';
import { chartColors } from './config/genericConfig';
import Loader from '../Loader/Loader';
import { CustomChartToolbarBacktest } from './CustomChartToolbarBacktest';
import useDimensions from '../useDimensions';
import { getMatchingIndicator } from './config/indicatorOptions';
import { setDashboardChartGranularity, setDashboardChartInstrument } from '../../actions/oanda';
import { changeModalIndicatorEditOpen } from '../../actions/navigation';

// To use redux hooks, you export a function component, not a react component.
export const BacktestStockChart = (props) => {
	/**
	 * Get the chart width and height
	 */
	const [chartDivRef, dimensions] = useDimensions({ noScroll: true });

	/**
	 * Get our state for the indicators.
	 *
	 * 2022-02-10 11:03:41 JD
	 */
	const chartSettings = useSelector((state) => state.oanda.chartSettings);
	const cotData = useSelector((state) => state.oanda.cotData);
	const nonMergeApiData = useSelector((state) => state.oanda.nonMergeApiData);
	const genericApiData = useSelector((state) => state.oanda.genericApiData);
	const dispatch = useDispatch();

	/**
	 * Do nothing if no data.
	 */
	if (props.backtestData == null) return;

	/**
	 * We use an arrow function whitch passes state to a
	 * callback function that returns the state we need.
	 *
	 */
	const chartInstrument = props.backtestData.symbol;
	const chartGranularity = props.backtestData.granularity;
	const chartCandles = props.backtestCandles.candles;
	const backtestData = props.backtestData;

	/**
	 * Set the chartInstrument and granularity
	 * in the state.
	 *
	 * 2022-02-10 12:57:14 JD
	 */
	if (chartInstrument != null) dispatch(setDashboardChartInstrument(chartInstrument));
	if (chartGranularity != null) dispatch(setDashboardChartGranularity(chartGranularity));

	/**
	 * Make sure the settings contain our own signals
	 * or this chart is pretty much useless.
	 *
	 * 2022-02-10 11:05:42 JD
	 */
	if (!chartSettings.indicators.some((e) => e.id === 'SignalLines')) {
		chartSettings.indicators.push({
			id: 'SignalLines',
			label: 'Signal Lines',
			value: 'SignalLines',
			type: 'SignalLines', // This is used in the chart indicators
			category: 'Own', // Used in indicator sorting
			isOutside: false,
			arguments: {},
		});
	}

	/**
	 * Make sure certain settings are valid
	 * for the backtest chart.
	 *
	 * 2022-02-10 11:08:18 JD
	 */
	chartSettings.individualPositions = true;
	chartSettings.chartType = 'Candlestick';
	chartSettings.candleType = props.backtestData.candleType;
	chartSettings.backtesting = true;
	chartSettings.candleLoopDone = true;

	/**
	 * The total height of any top and/or bottom
	 * toolbars.
	 */
	let toolbarHeight = 68;

	/**
	 * Get our height from the props or set one
	 */
	let height = 600;
	let loadingHeight = 550 - toolbarHeight;

	/**
	 * This variable creates an appropriate string
	 * for the HTML below.
	 */
	const heightHTML = height === '100vh' || !Number.isInteger(height) ? height : `${height}px`;

	/**
	 * User is clicking on an indicator to change the params.
	 *
	 * @param {str} indicatorID
	 */
	const handleIndicatorEditClick = (indicatorID) => {
		dispatch(changeModalIndicatorEditOpen(indicatorID));
	};

	/**
	 * This will return a spinner or the actual chart if we have
	 * all of the required data.
	 */
	const chartOrLoading = () => {
		// Return nothing if we have no data.
		if (!chartInstrument || !chartGranularity || !chartCandles || chartCandles.length <= 0) {
			return <Loader size={75} height={`${loadingHeight}px`} />;
		} else {
			// Format our Oanda Chart data for use with React-stockcharts
			let data = formatOandaData(chartCandles);
			data = addSignalsToData(data, backtestData.signals);

			/**
			 * COT Data
			 */
			if (cotData != null && cotData.length > 0) data = addGenericToData(data, cotData, 'cotData');

			/**
			 * Combine our generic data to the data
			 *
			 * This data should all have a standard format.
			 */
			if (genericApiData != null) {
				for (const item in genericApiData) {
					if (item.length > 0) {
						let newData = addGenericToData(data, genericApiData[item].data, item); // This could return null..
						data = newData || data;
					}
				}
			}

			/**
			 * Loop through all of the indicators the user has set.
			 */
			for (const indicator of chartSettings.indicators) {
				let mergedIndicator = getMatchingIndicator(indicator);

				/**
				 *  Execute the merged functions here
				 */
				if (mergedIndicator?.arguments?.mergeFunctions && mergedIndicator?.arguments?.mergeFunctions.length > 0) {
					for (const func of mergedIndicator.arguments.mergeFunctions) {
						if (typeof func.execute === 'function') {
							data = addGenericToDataByIndex(data, func.execute(data, mergedIndicator.arguments.mergeOptions), indicator.arguments.fetchName);
						}
					}
				}

				/**
				 * If we have detrend set, alter the data here
				 *
				 * 2021-05-27 14:01:52 - JD
				 */
				if (mergedIndicator.type === 'Detrend') data = detrendBars(data);

				/**
				 * If this is the volume bucket, we need to append the data back to it
				 * for entry/exit signals.
				 *
				 * 2022-02-01 16:13:48 JD
				 */
				if (mergedIndicator?.arguments?.needsOwnData) {
					if (nonMergeApiData?.[mergedIndicator.arguments.fetchName] != null) {
						mergedIndicator.arguments.data = nonMergeApiData?.[mergedIndicator.arguments.fetchName];
						indicator.arguments.data = nonMergeApiData?.[mergedIndicator.arguments.fetchName];
					}
				}
			}

			/**
			 * Calculate the spread in pips
			 *
			 * Start by grabing the last chart
			 */
			const lastCandle = chartCandles[chartCandles.length - 1];
			const pipSpread = getPipDifference(lastCandle.bid.c, lastCandle.ask.c, chartInstrument);

			/**
			 * Create the chart
			 */
			return (
				<CustomChart
					height={height}
					width={dimensions.width}
					data={data}
					spread={pipSpread}
					toolbarHeight={toolbarHeight}
					chartInstrument={chartInstrument}
					chartSettings={chartSettings}
					genericApiData={genericApiData}
					backtestData={backtestData}
					handleIndicatorEditClick={handleIndicatorEditClick}
				/>
			);
		}
	};

	return (
		<div
			className="trading-vue"
			style={{
				color: chartColors.whiteLabels,
				backgroundColor: props.backgroundColor ? props.backgroundColor : chartColors.bgColorTradingView,
			}}
		>
			<CustomChartSidebar height={height} backgroundColor={props.toolbarBackgroundColor} />
			<div className="trading-vue-chart" style={{ marginLeft: '57px', height: `${heightHTML}` }}>
				<CustomChartToolbarBacktest backtestData={backtestData} />
				<div ref={chartDivRef}>{chartOrLoading()}</div>
				<CustomChartFooter backgroundColor={props.toolbarBackgroundColor} chartSettings={chartSettings} />
			</div>
		</div>
	);
};
