import React from 'react';
import GenericChartComponent from '../GenericChartComponent';

import ToolTipText from './ToolTipText';
import ToolTipTSpanLabel from './ToolTipTSpanLabel';
import { functor } from '../utils';
import { atr } from '../calculator';
import { priceToPips } from '../../../../../components/utils';

export const ATRText = (props) => {
	/**
	 * Render the SVG
	 *
	 * @param {obj} moreProps
	 * @returns
	 */
	const renderSVG = (moreProps) => {
		const { onClick, fontFamily, fontSize, labelFill = '#4682B4', valueFill = '#000000', className, length = 14 } = props;
		const { yLabel, instrument } = props;

		const {
			chartConfig: { width, height },
			plotData,
		} = moreProps;

		const { origin: originProp } = props;
		const origin = functor(originProp);
		const [x, y] = origin(width, height);

		/**
		 * Calculate the ATR over the length of time
		 *
		 */
		const atrFunction = atr().options({ windowSize: length });
		const atrData = atrFunction(plotData);

		/**
		 * Our value is the last item, made to pips
		 */
		const value = priceToPips(atrData[atrData.length - 1], instrument);

		return (
			<g className={className} transform={`translate(${x}, ${y})`} onClick={onClick}>
				<ToolTipText x={0} y={0} fontFamily={fontFamily} fontSize={fontSize}>
					{yLabel ? <ToolTipTSpanLabel fill={labelFill}>{`${yLabel}: `}</ToolTipTSpanLabel> : null}
					<tspan fill={valueFill}>{value}</tspan>
				</ToolTipText>
			</g>
		);
	};

	return <GenericChartComponent clip={false} svgDraw={renderSVG} drawOn={['mousemove']} />;
};
