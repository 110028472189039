/**
 * this comes from https://www.tradingview.com/script/SnCTrJNq-LTB-Zigzag-Circles/
 */
import React from 'react';
import GenericChartComponent from './GenericChartComponent';
import { hexToRGBA } from './utils';
import { getAxisCanvas } from './GenericComponent';
import { chartColors } from '../../../../components/StockChart/config/genericConfig';
import TA from '../../../ta-math';
import { findDataIndexBetweenTime } from '../../../../components/utils';

export const Zigzag = ({
	longFill = hexToRGBA(chartColors.positiveText, 1), // 'rgba(66, 184, 131, 0.7)',
	longFill2 = hexToRGBA(chartColors.fadedBlue, 1), // 'rgba(66, 184, 131, 0.7)',
	shortFill = hexToRGBA(chartColors.shortBar, 1), //'rgba(239,83,80, 0.7)',
	shortFill2 = hexToRGBA(chartColors.redFadedTradingVue, 1), //'rgba(239,83,80, 0.7)',
	lineWidth = 2,
	lineWidth2 = 4,
	percentRetrace = 25,
	percentRetrace2 = 45,
	backstep = 2,
	backstep2 = 4,
	instrument = '',
	showEntrySignals = false,
	entryOnCircleBreak = false,
}) => {
	const drawOnCanvas = (ctx, moreProps) => {
		const {
			fullData,
			// mouseXY,
			chartConfig: { yScale },
			xScale,
			xAccessor,
		} = moreProps;

		/**
		 * These are the actual upper and lower yaxis
		 * values as shown.
		 *
		 * use realYDomain to get just the charbar price
		 * values.
		 *
		 * IE:
		 * const { chartConfig: { yScale, realYDomain } } = moreProps;
		 * realYDomain[0] == lower, realYDomain[1] = upper;
		 *
		 */
		// const [lowerYValue, upperYValue] = yScale.domain();

		/**
		 * The plotData is the bars being shown depending on
		 * zoom and pan.
		 */
		if (!fullData || fullData.length === 0 || !yScale) return null;

		/**
		 * Get our zigzag data
		 */
		const ta = new TA(fullData, instrument, TA.plotDataFormat);
		const zigZag = ta.zigzag(percentRetrace, backstep);

		/**
		 * Dual zigzags ?
		 */
		const zigZag2 = percentRetrace2 > 0 ? ta.zigzag(percentRetrace2, backstep2) : false;

		/**
		 * If we have nothing, return
		 */
		if (zigZag?.price?.length <= 0) return;

		/**
		 * Loop through all of our found zones and draw them out.
		 *
		 * @param {obj} zz - ZigZag params
		 * @param {int} lineWidth = lineWidth
		 */
		const drawZigZag = (zz, longFill, shortFill, lineWidth = 2) => {
			for (let i = 1; i < zz.price.length; i++) {
				const zigZagPrice = zz.price[i];
				const zigZagPricePrev = zz.price[i - 1];
				const zigZagData = zz.data[i];
				const zigZagDataPrev = zz.data[i - 1];

				/**
				 * Get our cooridnates. This is done using the xAccessor
				 * and passing in the entire bar data which contains the IDX
				 * (index) values.
				 */
				const y1Start = yScale(zigZagPricePrev);
				const y1End = yScale(zigZagPrice);

				/**
				 * Top line
				 */
				ctx.strokeStyle = y1Start > y1End ? longFill : shortFill;
				ctx.lineWidth = lineWidth;
				ctx.beginPath();
				ctx.setLineDash([]);
				ctx.moveTo(xScale(xAccessor(zigZagDataPrev)), y1Start);
				ctx.lineTo(xScale(xAccessor(zigZagData)), y1End);
				ctx.closePath();
				ctx.stroke();
			}
		};

		/**
		 * Run the draw function
		 */
		drawZigZag(zigZag, longFill, shortFill, lineWidth);
		if (zigZag2 !== false) drawZigZag(zigZag2, longFill2, shortFill2, lineWidth2);

		/**
		 * Signals! If we have two zigZags, we need to combine
		 * the signals - or rather remove the ones that are not
		 * valid.
		 */
		if (showEntrySignals) {
			let signals = [],
				exits = [];

			/**
			 * The zigzag circles turns out to be just
			 * the length of the previous zigzag. If the new
			 * zigzag passes the old zigzag in length, enter!
			 *
			 * 2021-06-01 16:55:45
			 */
			if (entryOnCircleBreak) {
				if (zigZag2 !== false) {
					for (let i = 0; i < zigZag.circleBreakAt.length; i++) {
						/**
						 * Find the matching higher time frame data
						 */
						const zz2DataIndex = findDataIndexBetweenTime(zigZag2.circleBreakAt, zigZag.circleBreakAt[i].dateMS);
						const zz2Data =
							zz2DataIndex === null && i > 0
								? zigZag2.circleBreakAt[zigZag2.circleBreakAt.length - 1]
								: zigZag2?.circleBreakAt?.[zz2DataIndex];

						/**
						 * Are they the same direction?
						 */
						if (zz2Data?.direction === zigZag?.circleBreakAt?.[i]?.direction) {
							signals.push(zigZag.circleBreakAt[i]);

							/**
							 * Find the exit that goes along with this
							 */
							for (let p = 0; p < zigZag.circleExits.length; p++) {
								if (
									zigZag.circleExits[p].dateMS >= zigZag.circleBreakAt[i].dateMS &&
									zigZag.circleBreakAt[i].direction !== zigZag.circleExits[p].direction
								) {
									exits.push(zigZag.circleExits[p]);
									break; // End the loop!
								}
							}
						}
					}

					/**
					 * Now the exits. We do the exits as the zigZag Entries.
					 * The extries would be when the lines switch which become
					 * our entries.
					 */
					// for (let i = 0; i < zigZag.circleExits.length; i++) {
					// 	/**
					// 	 * Find the matching higher time frame data
					// 	 */
					// 	const zz2DataIndex = findDataIndexBetweenTime(zigZag2.circleExits, zigZag.circleExits[i].dateMS);
					// 	const zz2Data =
					// 		zz2DataIndex === null && i > 0
					// 			? zigZag2.circleExits[zigZag2.circleExits.length - 1]
					// 			: zigZag2?.circleExits?.[zz2DataIndex];

					// 	/**
					// 	 * Are they the same direction?
					// 	 */
					// 	if (zz2Data?.direction !== zigZag?.circleExits?.[i]?.direction) exits.push(zigZag.circleExits[i]);
					// }
					// exits = zigZag.circleExits;
				} else {
					signals = zigZag.circleBreakAt;
					exits = zigZag.circleExits;
				}
			} else {
				if (zigZag2 !== false) {
					for (let i = 0; i < zigZag.entryAt.length; i++) {
						/**
						 * Find the matching higher time frame data
						 */
						const zz2DataIndex = findDataIndexBetweenTime(zigZag2.entryAt, zigZag.entryAt[i].dateMS);
						const zz2Data =
							zz2DataIndex === null && i > 0 ? zigZag2.entryAt[zigZag2.entryAt.length - 1] : zigZag2?.entryAt?.[zz2DataIndex];

						/**
						 * Are they the same direction?
						 */
						if (zz2Data?.direction === zigZag?.entryAt?.[i]?.direction) signals.push(zigZag.entryAt[i]);
					}

					/**
					 * Draw circles where the switch actually happened
					 */
					for (let i = 0; i < zigZag2.entryAt.length; i++) {
						ctx.fillStyle = zigZag2?.entryAt?.[i]?.direction === 1 ? longFill : shortFill;
						ctx.lineWidth = 2;
						ctx.strokeStyle = zigZag2?.entryAt?.[i]?.direction === 1 ? chartColors.circleStroke1 : chartColors.circleStroke1;
						ctx.beginPath();
						ctx.setLineDash([]);
						ctx.arc(
							xScale(xAccessor(zigZag2?.entryAt?.[i])),
							yScale(zigZag2.entryAt?.[i]?.direction === 1 ? zigZag2.entryAt[i].low : zigZag2.entryAt[i].high),
							6,
							0,
							2 * Math.PI,
							false
						);
						ctx.closePath();
						ctx.fill();
						ctx.stroke();
					}
				} else signals = zigZag.entryAt;
			}

			/**
			 * Now we loop through!
			 */
			for (let i = 0; i < signals.length; i++) {
				if (signals?.[i]?.close) {
					ctx.fillStyle = signals?.[i]?.direction === 1 ? longFill : shortFill;
					ctx.save();
					ctx.beginPath();
					ctx.translate(
						xScale(xAccessor(signals[i])),
						yScale(signals?.[i]?.direction === 1 ? signals[i].low : signals[i].high) + (signals?.[i]?.direction === -1 ? -15 : +15)
					);
					ctx.rotate(signals?.[i]?.direction === 1 ? 0 : Math.PI);
					ctx.moveTo(0, 0);
					ctx.lineTo(5, 20);
					ctx.lineTo(-5, 20);
					ctx.closePath();
					ctx.restore();
					ctx.fill();
				}
			}

			/**
			 * Do the same for exits
			 */
			for (let i = 0; i < exits.length; i++) {
				if (exits?.[i]?.close) {
					/**
					 * The below directions are inversed because we are
					 * exiting positions.
					 */
					let x1 = xScale(xAccessor(exits[i]));
					let y1 = yScale(exits?.[i]?.direction === 1 ? exits[i].low : exits[i].high) + (exits?.[i]?.direction === -1 ? -15 : +15);

					ctx.fillStyle = exits?.[i]?.direction === -1 ? longFill : shortFill;
					ctx.strokeStyle = exits?.[i]?.direction === -1 ? longFill : shortFill;
					ctx.lineWidth = 2;
					ctx.save();
					ctx.beginPath();
					ctx.moveTo(x1 - 10, y1 - 10);
					ctx.lineTo(x1 + 10, y1 + 10);
					ctx.moveTo(x1 + 10, y1 - 10);
					ctx.lineTo(x1 - 10, y1 + 10);
					ctx.stroke();
					ctx.fill();
					ctx.closePath();
				}
			}
		}
	};

	const renderSVG = (moreProps) => {};

	return (
		<GenericChartComponent
			canvasToDraw={getAxisCanvas}
			canvasDraw={drawOnCanvas}
			svgDraw={renderSVG}
			drawOn={['pan']}
			key={Math.random().toString(36).substr(2, 7)}
		/>
	);
};
