import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// To use redux hooks, you export a function component, not a react component.
export const Sentiment = (props) => {
	/**
	 * Call the state variables and dispatch into
	 * existance.
	 */
	// const backtest = useSelector((state) => state.backtest.data.backtest);
	const dispatch = useDispatch();

	/**
	 * Local state for the tabs
	 */
	// const [activeTab, setActiveTab] = useState('1');

	/**
	 * Similar to componentDidMount and componentDidUpdate:
	 * The optional second param ([]) in useEffect tells it to only
	 * run if whatever data in that array has changed. If left blank (just []),
	 * it will only run once.
	 */
	useEffect(() => {}, [dispatch, props.match.params.id]);

	return (
		<div>
			See: https://www.reddit.com/r/algotrading/comments/jdcy3p/best_way_to_analyse_financial_news/
			<br />
			http://www.alternative-analytics.eu/dashboard/sentiment.html
			<br />
			and the JS tool: https://www.npmjs.com/package/vader-sentiment
			<br />
			- connect to twitter and gather the sentiment of forex pairs. <br />
			- Connect to reddit maybe? <br />- And other news sources? <br />
			- Sample code in python - https://www.dropbox.com/s/7vf8abxum5kcsps/Screen%20Shot%202020-11-28%20at%201.46.07%20PM.png?dl=0
			<br />
			<br />
			Possible sources: <br />
			- https://www.marketwatch.com/ <br />
			- https://t.co/ie1694dEBp <br />
			- https://www.dailyfx.com/ <br />
			- What news source do you use? <br />
			----- Abnswer: Basically all large financial news websites, eg marketwatch, CNBC, wsj, zerohedge etc.
			<br />
			<br />
			Cool display of tweets (click the demo): https://github.com/Afischbacher/twitter-sentiment-visualizer
			<br />
			<br />
		</div>
	);
};
