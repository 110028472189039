import axios from 'axios';
import { push } from 'connected-react-router';
import { FETCH_USER, AUTH_FAILURE, LOGOUT_SUCCESS, FETCH_APP_VERSION } from './types';
import { fetchAllData } from './oanda';
import { setHeaderStatus } from './header';

/*
 * Refactor info
 * If you have a single function/expression, you can remove the {} and the return keyword
 *     --- Does not work if you have more than one expression
 * Function keyword removed, changed to fat arrow and taken dispatch to first line
 *
 * Rather than promises, used async. Find function of promise, add async defore it
 *  -- Find all promises, mark with await key
 *  -- Assign to const variable
 *  -- Remove entire .then statement
 *
 * Further Refactor - move the await axios into the payload
 */
export const fetchUser = () => async (dispatch) => {
	dispatch(setHeaderStatus({ text: 'Fetching user data...', type: 'loading' }));

	// Dispatch is the callback function being passed in!
	dispatch(fetchVersion());
	const res = await axios.get('/api/current_user');

	/**
	 * Send the user to the login page if they are not
	 * logged in
	 */
	if (!res.data) {
		dispatch({ type: LOGOUT_SUCCESS, payload: res.data });
		dispatch(push('/login')); // Removed as this causes constant reloading
	} else {
		dispatch({ type: FETCH_USER, payload: res.data });
		if (window.location.pathname === '/') dispatch(fetchAllData());
	}

	dispatch(setHeaderStatus({ text: 'All data fetched.', type: 'info' }));

	//dispatch({ type: FETCH_USER, payload: await axios.get('/api/current_user') });
};

export const logoutUser = () => async (dispatch) => {
	localStorage.removeItem('user');

	// Dispatch is the callback function being passed in!
	const res = await axios.get('/api/logout');
	dispatch({ type: LOGOUT_SUCCESS, payload: res.data });
	dispatch(push('/login'));
};

export function authError(payload) {
	return {
		type: AUTH_FAILURE,
		payload,
	};
}

/**
 * Get the current version of the server
 */
export const fetchVersion = () => async (dispatch) => {
	const res = await axios.get('/api/version');
	if (res.data != null) dispatch({ type: FETCH_APP_VERSION, payload: res.data });
};
