import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBacktestData } from '../../actions/backtest';
import Loader from '../../components/Loader/Loader';
import s from '../dashboard/Dashboard.module.scss';
import { Trades } from './components/Trades';
import { Summary } from './components/Summary';
import { EquityChart } from './components/EquityChart';
import { BacktestStockChart } from '../../components/StockChart/BacktestStockChart';

// To use redux hooks, you export a function component, not a react component.
export const Backtest = (props) => {
	/**
	 * Call the state variables and dispatch into
	 * existance. x
	 */
	const backtestData = useSelector((state) => state.backtest.data.backtest);
	const backtestCandles = useSelector((state) => state.backtest.candles);
	const dispatch = useDispatch();

	/**
	 * Local state for the tabs
	 */
	const [activeTab, setActiveTab] = useState('1');

	/**
	 * Similar to componentDidMount and componentDidUpdate:
	 * The optional second param ([]) in useEffect tells it to only
	 * run if whatever data in that array has changed. If left blank (just []),
	 * it will only run once.
	 */
	useEffect(() => {
		dispatch(fetchBacktestData(props.match.params.id));
	}, [dispatch, props.match.params.id]);

	/**
	 * Only render if we actually have data worth showing.
	 */
	if (backtestData && backtestData.summary) {
		return (
			<div className={s.root}>
				<Row>
					<Col xs="12" className="mb-5">
						<Nav tabs>
							<NavItem className={s.customNavItem}>
								<NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
									<span>Summary</span>
								</NavLink>
							</NavItem>
							<NavItem className={s.customNavItem}>
								<NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
									<span>Trades</span>
								</NavLink>
							</NavItem>
							<NavItem className={s.customNavItem}>
								<NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
									<span>Trade Chart</span>
								</NavLink>
							</NavItem>
							<NavItem className={s.customNavItem}>
								<NavLink className={activeTab === '4' ? 'active' : ''} onClick={() => setActiveTab('4')}>
									<span>Equity Chart</span>
								</NavLink>
							</NavItem>
							<NavItem className={s.customNavItem}>
								<NavLink className={activeTab === '5' ? 'active' : ''} onClick={() => setActiveTab('5')}>
									<span>Config & Input</span>
								</NavLink>
							</NavItem>
						</Nav>

						<TabContent className="mb-lg" activeTab={activeTab}>
							<TabPane tabId="1">
								<Summary backtestData={backtestData} {...props} />
							</TabPane>
							<TabPane tabId="2">
								<Trades backtestData={backtestData} />
							</TabPane>
							<TabPane tabId="3" className="p-0 m-0">
								{activeTab === '3' ? <BacktestStockChart backtestCandles={backtestCandles} backtestData={backtestData} /> : ''}
							</TabPane>
							<TabPane tabId="4">
								<EquityChart backtestData={backtestData} />
							</TabPane>
							<TabPane tabId="5">
								Input:
								<pre>{JSON.stringify(backtestData.input, null, 4).replace(/["[,\]]/g, '')}</pre>
								Config:
								<pre>{JSON.stringify(backtestData.config, null, 4).replace(/["[,\]]/g, '')}</pre>
								CLI Options
								<pre>{JSON.stringify(backtestData.cliOptions, null, 4).replace(/["[,\]]/g, '')}</pre>
							</TabPane>
						</TabContent>
					</Col>
				</Row>
			</div>
		);
	} else {
		return <Loader />;
	}
};
