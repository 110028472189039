import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import { floatToNumberFormat, customSortCaret, NoDataIndication, floatPrecisionFix } from '../../components/utils';
import { fetchAccountInstruments, fetchCurrencyConversion } from '../../actions/oanda';
import Widget from '../../components/Widget/Widget';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import axios from 'axios';

// To use redux hooks, you export a function component, not a react component.
export const Instruments = () => {
	/**
	 * Create some state!
	 */
	const [strategies, setStrategies] = useState(null);
	const [selectedStrat, setSelectedStrat] = useState(null);
	const [strategyConfig, setStrategyConfig] = useState(null);

	/**
	 * Call the state variables and dispatch into
	 * existance.
	 */
	const instruments = useSelector((state) => state.oanda.instruments);
	const conversionRates = useSelector((state) => state.oanda.conversionRates);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchAccountInstruments());
		dispatch(fetchCurrencyConversion());

		const fetchStrategies = async () => {
			/**
			 * Fetch all of our strategies
			 */
			const res = await axios.get('/api/getAllStrategies');
			if (res.data) setStrategies(res.data);
		};
		fetchStrategies();
	}, []);

	/**
	 * Set a default strat
	 */
	useEffect(() => {
		if (strategies != null && selectedStrat == null && strategies.length > 0) setSelectedStrat(strategies[0]);
	}, [strategies]);

	/**
	 * Load the details of the selected strat
	 */
	useEffect(() => {
		const fetchStrategyConfig = async () => {
			if (selectedStrat == null) return;

			/**
			 * Fetch all of our strategies
			 */
			const res = await axios.get(`/api/getStrategyConfig/${selectedStrat}`);
			if (res.data) setStrategyConfig(res.data);
		};
		fetchStrategyConfig();
	}, [selectedStrat]);

	/**
	 * Add some needed data to the instruments list
	 */
	// useEffect(() => {
	if (conversionRates && instruments) {
		for (const currentInstrument of instruments) {
			/**
			 * Get the instrument data from the
			 * instruments variable.
			 */
			currentInstrument.marginRatePercent =
				currentInstrument && currentInstrument.marginRate ? floatPrecisionFix(currentInstrument.marginRate * 100) : 0;
			currentInstrument.marginRatio =
				currentInstrument.marginRatePercent > 0 ? floatPrecisionFix((1 / currentInstrument.marginRatePercent) * 100, 0) : 0;

			/**
			 * We need the conversion Rates to find the true margin rate
			 * Rate (0.5 for example) * amount = Margin require in Base = Base * conversion to USD rate
			 *
			 * Start by getting the average rate
			 */
			const currencyAverage =
				conversionRates?.rates?.[currentInstrument?.name] != null
					? floatPrecisionFix(
							floatPrecisionFix(conversionRates.rates[currentInstrument.name].bid) +
								floatPrecisionFix(conversionRates.rates[currentInstrument.name].ask) / 2
					  )
					: 0;

			/**
			 * Use 1 for the amount.
			 * When 1 is used as the amount, the margin rate * 1 = the amount.
			 *
			 * For example: 0.05 * 1 = 0.05 (5%). By multiplying by the example we also get
			 * a percent: 0.05 * 1 * 1.4 = 0.07 (7%)
			 */
			currentInstrument.trueMarginRate = currentInstrument.name.startsWith('USD')
				? currentInstrument.marginRatePercent
				: floatPrecisionFix(parseFloat(parseFloat(currentInstrument.marginRatePercent) * 1 * currencyAverage), 4);

			currentInstrument.trueMarginRatio =
				currentInstrument.trueMarginRate > 0 ? floatPrecisionFix((1 / currentInstrument.trueMarginRate) * 100, 0) : 0;
		}
	}
	// }, [instruments, conversionRates]);

	/**
	 * These are the table headers which will be used by
	 * reactTable
	 */
	const columns = [
		{
			text: '',
			dataField: 'isDummyField',
			headerStyle: { outline: 0, width: '16px' },
			formatter: (cell, row, index) => {
				if (strategyConfig == null || strategyConfig?.symbols?.length <= 0) return '';
				if (strategyConfig.symbols.find((element) => element.symbol === row.name)) {
					if (strategyConfig.symbols.find((element) => element.symbol === row.name && element.paused === true))
						return <i className="fa fa-pause text-warning" />;
					else return <i className="fa fa-check text-success" />;
				}
			},
			formatExtraData: {
				strategyConfig,
			},
		},
		{
			text: 'Symbol',
			dataField: 'name',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			formatExtraData: {
				conversionRates,
			},
		},
		{
			text: 'Margin',
			dataField: 'marginRate',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			formatter: (cell, row, index) => {
				return (
					<>
						{floatToNumberFormat(cell)}{' '}
						<small>
							({row.marginRatePercent}% 1:{row.marginRatio})
						</small>
					</>
				);
			},
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				if (order === 'asc') return a - b;
				else return b - a; // desc
			},
		},
		{
			text: 'True Margin',
			dataField: 'trueMarginRate',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			formatter: (cell, row, index) => {
				return (
					<>
						{floatToNumberFormat(cell / 100)}{' '}
						<small>
							({row.trueMarginRate}% 1:{row.trueMarginRatio})
						</small>
					</>
				);
			},
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				if (order === 'asc') return a - b;
				else return b - a; // desc
			},
		},
		{
			text: 'Long Rate',
			dataField: 'financing.longRate',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: 'Short Rate',
			dataField: 'financing.shortRate',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: 'Pip Loc.',
			dataField: 'pipLocation',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: 'Display Prec.',
			dataField: 'displayPrecision',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
	];

	const defaultSorted = [
		{
			dataField: 'name',
			order: 'asc',
		},
	];

	/**
	 * Format all of our strats
	 *
	 * @returns jsx array
	 */
	const renderStrategies = () => {
		if (strategies == null || strategies?.length <= 0) return '';

		/**
		 * Loop through and return JSX
		 */
		return strategies.map((strat) => {
			return (
				<DropdownItem onClick={() => setSelectedStrat(strat)} key={Math.random().toString(36).substr(2, 7)}>
					{strat}
				</DropdownItem>
			);
		});
	};

	return (
		<Widget
			title={
				<div className="d-flex justify-content-between flex-wrap">
					<h4 className="d-flex align-items-center pb-1">
						Instru<span className="fw-normal ml-0">ments</span>
					</h4>
					<div>
						<UncontrolledButtonDropdown>
							<DropdownToggle caret color="subtle-blue" className="dropdown-toggle-split btn-sm" style={{ textTransform: 'capitalize' }}>
								{selectedStrat || ' '} &nbsp;
							</DropdownToggle>
							<DropdownMenu>{renderStrategies()}</DropdownMenu>
						</UncontrolledButtonDropdown>
					</div>
				</div>
			}
		>
			<BootstrapTable
				keyField="instrument"
				data={instruments}
				columns={columns}
				defaultSorted={defaultSorted}
				filter={filterFactory()}
				noDataIndication={() => <NoDataIndication />}
				classes="text-white tr-curosor"
				filterPosition="top"
			/>
		</Widget>
	);
};
