/**
 * this comes from https://github.com/rrag/react-stockcharts/issues/707
 */
import React from 'react';
import GenericChartComponent from './GenericChartComponent';
import { useSelector } from 'react-redux';
import { objectEqual } from '../../../../components/utils';
// import { chartColors } from '../../../../components/StockChart/config/genericConfig';

import { getAxisCanvas } from './GenericComponent';
import { chartColors } from '../../../../components/StockChart/config/genericConfig';
import { hexToRGBA } from './utils';

export const DrawSRLines = ({ stroke = 'rgba(255,255,255,0.05)', fill = hexToRGBA(chartColors.mehroon, 1), rectHeight = 1 }) => {
	/**
	 * Get our data needed for the lines which is
	 * stored in the state.
	 *
	 * 2022-01-20 14:24:53 JD
	 */
	const supportResistance = useSelector((state) => state.oanda.nonMergeApiData.supportResistance, objectEqual);

	/**
	 * Actually drawe the lines.
	 *
	 * @param {*} ctx
	 * @param {*} moreProps
	 */
	const drawOnCanvas = (ctx, moreProps) => {
		const {
			plotData,
			// mouseXY,
			chartConfig: { yScale },
			width,
		} = moreProps;

		/**
		 * Make sure we have data
		 */
		if (!supportResistance || !supportResistance?.support || supportResistance.support.length <= 0) return;

		/**
		 * The plotData is the bars being shown depending on
		 * zoom and pan.
		 */
		if (!plotData || plotData.length === 0 || !yScale) return null;

		/**
		 * Our width data
		 */
		// const begin = width / 2 - width / 4;

		/**
		 * Loop through the data and go!
		 */
		for (let i = 0; i < supportResistance.support.length; i++) {
			let supportLine = supportResistance.support[i];
			let resistanceLine = supportResistance.resistance[i];

			/**
			 * Get our cooridnates. This is done using the xAccessor
			 * and passing in the entire bar data which contains the IDX
			 * (index) values.
			 */
			const y1Support = yScale(supportLine);
			const y1Resistance = yScale(resistanceLine);

			/**
			 * Support
			 */
			ctx.fillStyle = fill;
			ctx.strokeStyle = stroke;
			ctx.beginPath();
			ctx.rect(0, y1Support, width, rectHeight);
			ctx.strokeRect(0, y1Support, width, rectHeight);
			ctx.closePath();
			ctx.fill();

			/**
			 * Resistance
			 */
			ctx.fillStyle = fill;
			ctx.strokeStyle = stroke;
			ctx.beginPath();
			ctx.rect(0, y1Resistance, width, rectHeight);
			ctx.strokeRect(0, y1Resistance, width, rectHeight);
			ctx.closePath();
			ctx.fill();
		}
	};

	const renderSVG = (moreProps) => {};

	return (
		<GenericChartComponent
			canvasToDraw={getAxisCanvas}
			canvasDraw={drawOnCanvas}
			svgDraw={renderSVG}
			drawOn={['pan']}
			key={Math.random().toString(36).substr(2, 7)}
		/>
	);
};
