import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeSidebar } from '../../actions/navigation';
import { NewsFeeds } from '../dashboard/components/tradingpanel/components/NewsFeeds';

// To use redux hooks, you export a function component, not a react component.
export const News = () => {
	/**
	 * State dispatch
	 */
	const dispatch = useDispatch();

	/**
	 * Run once on load to fetch our data
	 */
	useEffect(() => {
		// dispatch(fetchNotes());

		/**
		 * Keep the sidebar closed if we are on the graph chart.
		 */
		dispatch(closeSidebar());
	}, [dispatch]); //run once on initial render

	return (
		<div id="root">
			<div className="Layout_root__KuT-s sidebar-left sidebar-hide">
				<div className="Layout_wrap__3Y4cM" style={{ marginLeft: '50px', marginRight: '50px', marginTop: '20px' }}>
					<NewsFeeds />
				</div>
			</div>
		</div>
	);
};
