/* eslint-disable import/prefer-default-export */
import {
	OPEN_SIDEBAR,
	CLOSE_SIDEBAR,
	CHANGE_ACTIVE_SIDEBAR_ITEM,
	CHANGE_SIDEBAR_POSITION,
	CHANGE_SIDEBAR_VISIBILITY,
	CHANGE_MODAL_INSTRUMENT_OPEN,
	CHANGE_MODAL_CHARTTYPE_OPEN,
	CHANGE_MODAL_GRANULARITY_OPEN,
	CHANGE_MODAL_INDICATOR_OPEN,
	CHANGE_MODAL_ACCOUNTSEARCH_OPEN,
	CHANGE_MODAL_ORDERS_OPEN,
	CHANGE_MODAL_TPSL_OPEN,
	CHANGE_MODAL_LIMIT_OPEN,
	CHANGE_MODAL_INDICATOR_EDIT,
} from './types';

export function openSidebar() {
	return {
		type: OPEN_SIDEBAR,
	};
}

export function changeSidebarPosition(nextPosition) {
	return {
		type: CHANGE_SIDEBAR_POSITION,
		payload: nextPosition,
	};
}

export function closeSidebar() {
	return {
		type: CLOSE_SIDEBAR,
	};
}

export function changeActiveSidebarItem(activeItem) {
	return {
		type: CHANGE_ACTIVE_SIDEBAR_ITEM,
		activeItem,
	};
}

export function changeSidebarVisibility(nextVisibility) {
	return {
		type: CHANGE_SIDEBAR_VISIBILITY,
		payload: nextVisibility,
	};
}

export function changeModalInstrumentOpen(open) {
	return {
		type: CHANGE_MODAL_INSTRUMENT_OPEN,
		payload: open,
	};
}

export function changeModalChartTypeOpen(open) {
	return {
		type: CHANGE_MODAL_CHARTTYPE_OPEN,
		payload: open,
	};
}
export function changeModalOrdersOpen(open) {
	return {
		type: CHANGE_MODAL_ORDERS_OPEN,
		payload: open,
	};
}
export function changeModalTPSLOpen(open) {
	return {
		type: CHANGE_MODAL_TPSL_OPEN,
		payload: open,
	};
}
export function changeModalLimitOpen(open) {
	return {
		type: CHANGE_MODAL_LIMIT_OPEN,
		payload: open,
	};
}
export function changeModalGranularityOpen(open) {
	return {
		type: CHANGE_MODAL_GRANULARITY_OPEN,
		payload: open,
	};
}
export function changeModalIndicatorOpen(open) {
	return {
		type: CHANGE_MODAL_INDICATOR_OPEN,
		payload: open,
	};
}
export function changeModalIndicatorEditOpen(open) {
	return {
		type: CHANGE_MODAL_INDICATOR_EDIT,
		payload: open,
	};
}
export function changeModalAccountSearchOpen(open) {
	return {
		type: CHANGE_MODAL_ACCOUNTSEARCH_OPEN,
		payload: open,
	};
}
