/**
 * Returns a session start function.
 *
 * @param {str} sessionType - day, week, month, quarter, year
 */
export const getSessionStart = (sessionType) => {
	/**
	 * Default to weekly
	 */
	let sessionStart = ({ d, i, plotData }) => {
		if (d?.datum) d = d.datum;
		return i > 0 && plotData?.[i - 1]?.date && plotData[i - 1].date.getWeek() !== d.date.getWeek();
	};

	/**
	 * Get other sessions
	 */
	if (sessionType === 'day')
		sessionStart = ({ d, i, plotData }) => {
			if (d?.datum) d = d.datum;
			return i > 0 && plotData?.[i - 1]?.date && plotData[i - 1].date.getDay() !== d.date.getDay();
		};
	else if (sessionType === 'month')
		sessionStart = ({ d, i, plotData }) => {
			if (d?.datum) d = d.datum;
			return i > 0 && plotData?.[i - 1]?.date && plotData[i - 1].date.getMonth() !== d.date.getMonth();
		};
	else if (sessionType === 'quarter')
		sessionStart = ({ d, i, plotData }) => {
			if (d?.datum) d = d.datum;
			return (
				i > 0 && plotData?.[i - 1]?.date && Math.floor((plotData[i - 1].date.getMonth() + 3) / 3) !== Math.floor((d.date.getMonth() + 3) / 3)
			);
		};
	else if (sessionType === 'year')
		sessionStart = ({ d, i, plotData }) => {
			if (d?.datum) d = d.datum;
			return i > 0 && plotData?.[i - 1]?.date && plotData[i - 1].date.getYear() !== d.date.getYear();
		};

	return sessionStart;
};
