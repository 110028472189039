import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import List from '@editorjs/list';
import Warning from '@editorjs/warning';
import Code from '@editorjs/code';
// import LinkTool from '@editorjs/link';
import Image from '@editorjs/image';
import Raw from '@editorjs/raw';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';
import SimpleImage from '@editorjs/simple-image';
import Underline from '@editorjs/underline';

export const EDITOR_JS_TOOLS = {
	embed: Embed,
	underline: Underline,
	table: Table,
	marker: Marker,
	list: List,
	warning: Warning,
	code: Code,
	// linkTool: LinkTool,
	image: Image,
	raw: Raw,
	header: Header,
	quote: Quote,
	checklist: CheckList,
	delimiter: Delimiter,
	inlineCode: InlineCode,
	simpleImage: SimpleImage,
};

/**
 * This is copied from another user. I know, it looks like shit. I corrected
 * some of the code but ffs.
 *
 * @param {json} json
 */
export const editorJSToHTML = (json) => {
	if (!json) return null;

	let outputHTML = '';

	/**
	 * Loop through all of the block items
	 */
	for (let i = 0; i < json.blocks.length; i++) {
		let obj = json.blocks[i];

		switch (obj.type) {
			case 'delimiter':
				outputHTML += '<div class="ce-block"><div class="ce-block__content"><div class="ce-delimiter cdx-block"></div></div></div>\n';
				break;

			case 'checklist':
				outputHTML += `<div class="ce-block"><div class="ce-block__content"><div class="cdx-block cdx-checklist">`;
				for (let di = 0; di < obj.data.items.length; di++)
					outputHTML += `<div class="cdx-checklist__item cdx-checklist__item
${obj.data.items[di].checked ? '--checked' : ''}"><span class="cdx-checklist__item-checkbox"></span><div class="cdx-checklist__item-text">${
						obj.data.items[di].text
					}</div></div>`;

				outputHTML += `</div></div></div>\n`;
				break;

			case 'header':
				outputHTML += `<h${obj.data.level}>${obj.data.text}</h${obj.data.level}>`;
				break;

			case 'raw':
				outputHTML += obj.data.html;
				break;

			case 'paragraph':
				outputHTML += `<p>${obj.data.text}</p>\n`;
				break;

			case 'code':
				outputHTML += `<pre><code>${obj.data.code}</pre></code>\n`;
				break;

			case 'warning':
				outputHTML += `<div role="alert" class="alert alert-dismissible alert-danger"><span><span class="fw-semi-bold">${obj.data.title}:</span> ${obj.data.message} </span></div>\n`;
				break;

			case 'list':
				outputHTML += obj.data.style === 'ordered' ? '<ol>' : '<ul>';
				for (let di = 0; di < obj.data.items.length; di++) outputHTML += `<li>${obj.data.items[di]}</li>`;
				outputHTML += obj.data.style === 'ordered' ? '</ol>' : '</ul>';
				break;

			case 'quote':
				outputHTML += ` <div className="widget-padding-md w-100 h-100 text-left border rounded"><blockquote className="blockquote">${obj.data.text}</pre></code><footer className="blockquote-footer"><cite title="${obj.data.caption}">${obj.data.caption}</cite></footer></blockquote></div>\n`;
				break;

			case 'table':
				outputHTML += '<table class="tc-table"><tbody>';
				for (let c = 0; c < obj.data.content.length; c++) {
					outputHTML += '<tr>';
					for (let r = 0; r < obj.data.content[c].length; r++)
						outputHTML += `<td class="tc-table__cell"><div class="tc-table__area">${obj.data.content[c][r]}</div></td>\n`;
					outputHTML += '</tr>\n';
				}
				outputHTML += '</tbody></table></div></div></div></div>\n';
				break;

			default:
				console.log('Type not computed', obj);
				return '';
		}
	}

	return outputHTML;
};
