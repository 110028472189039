import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // useDispatch()
import { setDashboardChartInstrument, fetchStuckTrades, newStuckTrade, unstickStuckTrade } from '../../../../../actions/oanda';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import {
	floatToCurrencyFormat,
	floatToNumberFormat,
	objectEqual,
	customSortCaret,
	NoDataIndication,
	floatPrecisionFix,
} from '../../../../../components/utils';
import { chartColors } from '../../../../../components/StockChart/config/genericConfig';
// import Loader from '../../../../../components/Loader/Loader';

// To use redux hooks, you export a function component, not a react component.
export const OpenPositionsTable = () => {
	/**
	 * We use an arrow function whitch passes state to a
	 * callback function that returns the state we need.
	 */
	const chartInstrument = useSelector((state) => state.oanda.chartInstrument);
	const stuckTrades = useSelector((state) => state.oanda.stuckTrades);
	const accountID = useSelector((state) => state.oanda.accountID);
	const positions = useSelector((state) => {
		if (state.oanda.accountID != null && state.oanda.positions[state.oanda.accountID] != null) {
			/**
			 * Filter removed because it seems to cause the comparison to always
			 * be false, even when they are the same.
			 */
			return state.oanda.positions[state.oanda.accountID];
			//.filter((pos) => parseFloat(pos.long.units) > 0 || parseFloat(pos.short.units) < 0);
		}
	}, objectEqual);
	const instruments = useSelector((state) => state.oanda.instruments, objectEqual);
	const conversionRates = useSelector((state) => state.oanda.conversionRates);
	const trades = useSelector((state) => {
		if (state.oanda.accountID != null && state.oanda.trades[state.oanda.accountID] != null) return state.oanda.trades[state.oanda.accountID];
	}, objectEqual);
	const dispatch = useDispatch();

	/**
	 * Create an array of stats for our action buttons
	 */
	const [actionLoading, setActionLoading] = useState([]);

	/**
	 * Fetch stuck trade information only once, upon
	 * load.
	 */
	useEffect(() => {
		dispatch(fetchStuckTrades());
	}, [dispatch]);

	/**
	 * Run this everytime we have an update
	 * on stuck trades
	 */
	useEffect(() => {
		setActionLoading([]);
		// console.log(stuckTrades);
	}, [stuckTrades]);

	/**
	 * Show a loading screen until we have the data.
	 */
	if (!positions || positions === undefined) return ''; //<Loader size={14} />;

	/**
	 * Filter out any positions that don't have units and then
	 * sort by symbol name
	 */
	const activePositions = positions.filter((pos) => parseFloat(pos.long.units) > 0 || parseFloat(pos.short.units) < 0);

	/**
	 * Filter out our stuck trades
	 */
	const activeStuckTrades = stuckTrades.filter((trade) => trade.accountID === accountID && trade.status === 'active');

	/**
	 * Add additional fields to the data so we can parse it
	 * properly in Bootstrap table.
	 */
	for (let i = 0; i < activePositions.length; i++) {
		// This is var just to simplify things
		let currentPos = activePositions[i];

		// Units (long or short)
		activePositions[i].units = parseFloat(currentPos.long.units) > 0 ? currentPos.long.units : currentPos.short.units;

		// Average price
		activePositions[i].averagePrice = parseFloat(currentPos.long.units) > 0 ? currentPos.long.averagePrice : currentPos.short.averagePrice;

		// Get the number of trades each has.
		activePositions[i].tradeCount = parseFloat(currentPos.long.units) > 0 ? currentPos.long.tradeIDs.length : currentPos.short.tradeIDs.length;

		// Set a flag if this one is selected
		activePositions[i].selected = currentPos.instrument === chartInstrument ? true : false;

		/**
		 * Get our YTD Net
		 */
		activePositions[i].ytdNet = currentPos.pl - currentPos.commission;

		/**
		 * Mark if it is stuck or not
		 */
		activePositions[i].stuck = activeStuckTrades.some((trade) => trade.instrument === currentPos.instrument)
			? activeStuckTrades.find((trade) => trade.instrument === currentPos.instrument)
			: false;

		/**
		 * Match the trade details to the active positions
		 */
		const firstTradeID = parseFloat(currentPos.long.units) > 0 ? currentPos.long.tradeIDs[0] : currentPos.short.tradeIDs[0];

		/**
		 * Set a default value
		 */
		activePositions[i].daysOpen = 0;

		/**
		 * Find the first trade
		 */
		if (trades && firstTradeID) {
			const firstTrade = trades.find((trade) => trade.id === firstTradeID);

			/**
			 * Make sure we found something
			 */
			if (firstTrade && firstTrade.openTime) {
				const msDiffTime = new Date().getTime() - new Date(firstTrade.openTime).getTime();
				activePositions[i].daysOpen = parseFloat(msDiffTime / (1000 * 3600 * 24)).toFixed(0);
			}
		}
	}

	/**
	 * These are the table headers which will be used by
	 * reactTable
	 */
	const columns = [
		{
			text: 'Symbol',
			dataField: 'instrument',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			formatter: (cell, row, index, extraData) => {
				return row.instrument === extraData.chartInstrument ? <span className="text-warning">{cell}</span> : cell;
			},
			formatExtraData: {
				chartInstrument,
			},
		},
		{
			text: 'Side',
			dataField: 'long.units',
			formatter: (cell, row, index, extraData) => {
				return parseFloat(cell) > 0 ? (
					<span style={{ color: extraData.chartColors.positiveText }}>long</span>
				) : (
					<span style={{ color: extraData.chartColors.negativeText }}>short</span>
				);
			},
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			formatExtraData: {
				chartColors,
			},
		},
		{
			text: 'Units',
			dataField: 'units',
			formatter: (cell) => {
				return parseFloat(cell) > 0 ? floatToNumberFormat(cell) : floatToNumberFormat(cell);
			},
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				if (order === 'asc') return Math.abs(a) - Math.abs(b);
				else return Math.abs(b) - Math.abs(a); // desc
			},
		},
		{
			text: 'Avg Price',
			dataField: 'averagePrice',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: 'Open P/L',
			dataField: 'unrealizedPL',
			formatter: (cell, row, index, extraData) => {
				return parseFloat(cell) > 0 ? (
					<span style={{ color: extraData.chartColors.positiveText }}>{floatToCurrencyFormat(cell)}</span>
				) : (
					<span style={{ color: extraData.chartColors.negativeText }}>{floatToCurrencyFormat(cell)}</span>
				);
			},
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				if (order === 'asc') return a - b;
				else return b - a; // desc
			},
			formatExtraData: {
				chartColors,
			},
		},
		{
			text: 'Margin',
			dataField: 'marginUsed',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			formatter: (cell, row, index, extraData) => {
				/**
				 * Get the instrument data from the
				 * instruments variable.
				 */
				const currentInstrument = extraData.instruments.find((inst) => inst.name === row.instrument);
				const marginRate = currentInstrument && currentInstrument.marginRate ? floatPrecisionFix(currentInstrument.marginRate * 100) : 0;
				const marginRatio = marginRate > 0 ? floatPrecisionFix((1 / marginRate) * 100, 0) : 0;

				/**
				 * We need the conversion Rates to find the true margin rate
				 * Rate (0.5 for example) * amount = Margin require in Base = Base * conversion to USD rate
				 *
				 * Start by getting the average rate
				 */
				const currencyAverage =
					extraData?.conversionRates?.rates?.[row?.instrument] != null
						? floatPrecisionFix(
								floatPrecisionFix(extraData.conversionRates.rates[row.instrument].bid) +
									floatPrecisionFix(extraData.conversionRates.rates[row.instrument].ask) / 2
						  )
						: 0;

				/**
				 * Use 1 for the amount.
				 * When 1 is used as the amount, the margin rate * 1 = the amount.
				 *
				 * For example: 0.05 * 1 = 0.05 (5%). By multiplying by the example we also get
				 * a percent: 0.05 * 1 * 1.4 = 0.07 (7%)
				 */
				const trueMarginRate = row.instrument.startsWith('USD')
					? marginRate
					: floatPrecisionFix(parseFloat(parseFloat(marginRate) * 1 * currencyAverage), 4);
				const trueMarginRatio = trueMarginRate > 0 ? floatPrecisionFix((1 / trueMarginRate) * 100, 0) : 0;

				return (
					<>
						{floatToNumberFormat(cell)}{' '}
						<small>
							({marginRate}% 1:{marginRatio}, True: {trueMarginRate}% 1:{trueMarginRatio})
						</small>
					</>
				);
			},
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				if (order === 'asc') return a - b;
				else return b - a; // desc
			},
			formatExtraData: {
				instruments,
				conversionRates,
			},
		},
		{
			text: 'Trade #',
			dataField: 'tradeCount',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			formatter: (cell) => {
				if (parseInt(cell) > 1) return <strong>{cell}</strong>;
				return cell;
			},
		},
		{
			text: 'Days',
			dataField: 'daysOpen',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				if (order === 'asc') return a - b;
				else return b - a; // desc
			},
			formatter: (cell) => {
				if (parseInt(cell) > 10) return <strong>{cell}</strong>;
				return cell;
			},
		},
		{
			text: 'YTD Earnings',
			dataField: 'pl',
			formatter: (cell) => floatToCurrencyFormat(cell),
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: 'YTD Com.',
			dataField: 'commission',
			formatter: (cell) => floatToCurrencyFormat(-cell),
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: 'YTD Net',
			dataField: 'ytdNet',
			formatter: (cell, row, index, extraData) => {
				return parseFloat(cell) > 0 ? (
					<span style={{ color: extraData.chartColors.positiveText }}>{floatToCurrencyFormat(cell)}</span>
				) : (
					<span style={{ color: extraData.chartColors.negativeText }}>{floatToCurrencyFormat(cell)}</span>
				);
			},
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			formatExtraData: {
				chartColors,
			},
		},
		{
			text: '',
			dataField: 'stuck',
			classes: 'action-icons',
			formatter: (cell, row, index, extraData) => {
				/**
				 * Determine if we are loading or not
				 */
				if (extraData.actionLoading.filter((al) => al === row.instrument).length !== 0) return <i className="la la-spinner la-spin" />;

				/**
				 * The ternary expressions were getting too complex
				 * so I extracted them into an if statement here.
				 */
				let icons = [];
				if (!row.stuck)
					icons.push(
						<i
							className="la la-thumb-tack"
							key={Math.random().toString(36).substr(2, 7)}
							style={{ cursor: 'pointer' }}
							onClick={(e) => handleStuckClick(row)}
						/>
					);
				else
					icons.push(
						<i
							className="las la-poo text-warning"
							key={Math.random().toString(36).substr(2, 7)}
							style={{ cursor: 'pointer' }}
							onClick={(e) => handleUnStickClick(row)}
						/>
					);

				// icons.push(<i className="las la-pause-circle pl-2" key={Math.random().toString(36).substr(2, 7)} style={{ cursor: 'pointer' }} />);

				return icons;
			},
			headerStyle: { outline: 0 },
			formatExtraData: {
				actionLoading,
			},
		},
	];

	const defaultSorted = [
		{
			dataField: 'instrument',
			order: 'asc',
		},
	];

	const selectRow = {
		mode: 'radio',
		selected: [chartInstrument],
		clickToSelect: true,
		hideSelectColumn: true,
		bgColor: chartColors.dark,
	};

	/**
	 * When something happens on a row in
	 * bootstrap table, we call this function
	 */
	const rowEvents = {
		onClick: (e, row, rowIndex) => {
			dispatch(setDashboardChartInstrument(row.instrument));
		},
	};

	/**
	 * A trade is now stuck. Send it to the server for
	 * logging which will allow the Chipper to take over.
	 *
	 * @param {obj} row
	 */
	const handleStuckClick = (row) => {
		if (!row) return null;

		setActionLoading([...actionLoading, row.instrument]);

		/**
		 * Send it to the actions
		 */
		dispatch(newStuckTrade(getStuckTradeDetails(row)));
	};

	/**
	 * Same as above but unsticking
	 *
	 * @param {str} id - mongo id
	 */
	const handleUnStickClick = (row) => {
		if (!row) return null;
		setActionLoading([...actionLoading, row.instrument]);
		dispatch(unstickStuckTrade(row.stuck._id));
	};

	/**
	 * This just returns an object used to stick and
	 * unstick trades.
	 *
	 * @param {obj} row
	 */
	const getStuckTradeDetails = (row) => {
		if (!row) return null;

		/**
		 * Create an object with all of the details
		 * we need for the chipper.
		 */
		return {
			accountID,
			instrument: row.instrument,
			tradeIDs: parseFloat(row.long.units) > 0 ? row.long.tradeIDs : row.short.tradeIDs,
		};
	};

	/**
	 * This is the actual display that we are returning.
	 */
	return (
		<BootstrapTable
			keyField="instrument"
			data={activePositions}
			columns={columns}
			defaultSorted={defaultSorted}
			filter={filterFactory()}
			rowEvents={rowEvents}
			noDataIndication={() => <NoDataIndication />}
			classes="text-white tr-curosor"
			filterPosition="top"
			selectRow={selectRow}
		/>
	);
};
