import { hexToRGBA, rgbToHex } from '../../../libs/react-stockcharts/src/lib/utils';
import { confirmAlert } from 'react-confirm-alert'; // Import
import variables from './CSS.module.scss';
import { cancelOandaOrder, closeOandaTrade } from '../../../actions/oanda';

/**
 * Our pre-defined chart colors
 */
export const chartColors = {
	...variables,

	// blue: '#1870DC',
	lightBlue: '#4682B4',
	fadedBlue: '#2D3945',
	skyBlue: '#4286f4',
	skyBlue1: '#6fa4fc',
	skyBlue2: '#b5d0ff',
	grayBlueFadedTradingVue: 'rgba(45, 57, 69, .5)',
	grayBlueFadedTradingVueOpac: 'rgba(45, 57, 69, .2)',
	tradingViewBlue: '#1976d2',
	tradingVueBlue: 'rgb(86, 145, 206);',
	genericBlueGray: '#00897b',

	// green: '#58D777',
	greenMint: '#40944e',
	greenNeon: '#25bb2e',
	defaultGreen: 'green',
	green1: '#A2F5BF',
	green2: '#00421B',
	greenTradingVue: 'rgb(35, 167, 118)', // Used on the candles
	greenTradingVue2: 'rgb(66, 184, 131)',

	// orange: '#f0af03',
	orangeRed: 'rgb(244, 87, 34)',

	// red: '#F45722',
	redChilli: '#d62323',
	redHot: '#FF0000',
	redDesire: '#d62323',
	defaultRed: 'red', // Colors.RED2,
	redFadedTradingVue: 'rgba(75, 38, 48, .5)',
	redTradingView: '#ef5350',
	redTradingVue: 'rgb(229, 66, 80)',

	// purple: '#474D84',
	purpleStroke: '#05233B',
	moodyPurple: 'rgba(194, 143, 229, 1)',

	dark: '#040620',
	darkOutline: '#05233b',
	teal: '#14d3d3',
	pink: '#e671b8',

	gray: '#d6dee5',
	lightGray: '#999999',

	white: '#FFF',
	whiteHalfOp: 'RGBA(255,255,255,0.5)',
	whiteLabels: 'rgb(222, 221, 221)',

	default: '#595d78',
	textColor: '#e0e0e1',
	gridLineColor: '#040620',
	// faded: 'rgba(255, 255, 255, .1)',

	bgColorTradingView: 'rgb(15, 22, 40)',
	bgColorBlackDark: 'RGBA(0,0,0,.1)',
	bgColorTradingVue: 'rgb(18, 24, 38)',

	mehroon2: '#F9ACAA',
	mehroon1: '#6A3126',
	mehroon: '#6A1B19',

	primary: '#4286f4', //Colors.PRIMARY,
	edges: '#f1f1f1', //Colors.GRAY5,
	axis: '#f1f1f1', // Colors.GRAY4,
	black: '#000', //Colors.BLACK,

	upColorSilver: 'rgba(199, 208, 255, .9)',
	downColorDark: '#5D5F6A',

	// This is in the SCSS now
	// toolbarBorder: 'rgba(100, 100, 100, 0.29)',
	// toolbarBackground: 'rgb(18, 24, 38)',

	blackTrans: (trans = 1) => `rgba(0, 0, 0, ${trans})`, // black
	widgetBlueBg: (trans = 1) => `rgba(39, 41, 61, ${trans})`, // Blue
	widgetDarkBlueBg: (trans = 1) => `rgba(30, 30, 47, ${trans})`, // Darker Blue
	widgetProBlue: (trans = 1) => `rgba(31, 34, 81, ${trans})`, // Darker Blue
	grayBlueFaded: (trans = 1) => `rgba(45, 57, 69, ${trans})`,

	genericTransFunc: (color, trans = 1) => `rgba(${color},  ${trans})`,
	hexToRGBA,
	rgbToHex,

	htmlWidgetBlueBg: '#27293d', // Blue
};

export const upColor = chartColors.longBar;
export const downColor = chartColors.shortBar;
export const longColor = upColor;
export const shortColor = downColor;
export const backgroundColor = chartColors.bgColorBlackDark;

/**
 * Notes
 *
 * dx: 30,
 * dy: 30 - These functions appear to move the size price bar off of the edge
 * ONLY dx is in the verison we use....
 *
 *
 *
 */
export const mouseEdgeAppearanceY = {
	textFill: '#fff',
	stroke: chartColors.neutralOrderLine,
	strokeOpacity: 0.3,
	strokeWidth: 1,
	// arrowWidth: 0,
	fill: chartColors.bgColorTradingView,
};

export const mouseEdgeAppearanceX = {
	textFill: '#fff',
	stroke: chartColors.neutralOrderLine,
	strokeOpacity: 0,
	strokeWidth: 0,
	arrowWidth: 0,
	fill: chartColors.bgColorTradingView,
	rectWidth: 110,
};

export const candlesAppearance = {
	wickStroke: function fill(d) {
		return d.close > d.open ? upColor : downColor;
	},
	fill: function fill(d) {
		return d.close > d.open ? upColor : downColor;
	},
	stroke: function fill(d) {
		return d.close > d.open ? chartColors.longBarStroke : chartColors.shortBarStroke;
	},
	candleStrokeWidth: 1,
	widthRatio: 0.7,
	opacity: 1,
};

export const yAxisAppearance = {
	showTicks: true,
	showTickLabel: true,
	showDomain: true,
	className: 'react-stockcharts-x-axis',
	ticks: 10,
	outerTickSize: 0,
	fill: 'none',
	stroke: '#ffffff', // x axis stroke color
	strokeWidth: 0.4,
	opacity: 0.2, // x axis opacity
	domainClassName: 'react-stockcharts-axis-domain',
	innerTickSize: 5,
	tickPadding: 6,
	tickStroke: '#ffffff', // tick/grid stroke
	tickStrokeOpacity: 1,
	fontFamily:
		'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif', //'Helvetica Neue, Helvetica, Arial, sans-serif',
	fontSize: 12,
	fontWeight: 400,
	xZoomHeight: 25,
	zoomEnabled: true,
};

export const xAxisAppearance = {
	showTicks: true,
	showTickLabel: true,
	showDomain: true,
	className: 'react-stockcharts-x-axis',
	ticks: 5,
	outerTickSize: 1,
	fill: '#ffffff',
	stroke: '#ffffff', // x axis stroke color
	strokeWidth: 0.1,
	opacity: 1, // x axis opacity
	domainClassName: 'react-stockcharts-axis-domain',
	// innerTickSize: 1,
	tickPadding: 6,
	tickStroke: '#ffffff', // tick/grid stroke - this is also the font color :|
	tickStrokeOpacity: 1,
	fontFamily:
		'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif', //'Helvetica Neue, Helvetica, Arial, sans-serif',
	fontSize: 12,
	fontWeight: 400,
	xZoomHeight: 25,
	zoomEnabled: true,
};

export const CursorAppearance = {
	stroke: '#ffffff',
	opacity: 0.4,
	strokeDasharray: 'ShortDash',
	snapX: true,
};

export const OHLCAppearance = {
	stroke: (d) => {
		return d.close > d.open ? longColor : shortColor;
	},
	textFill: chartColors.white,
	labelFill: chartColors.downColorDark,
	// onClick = props.onClick,
	fontFamily:
		'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
	fontSize: 11,
};
export const KagiAppearance = {
	stroke: {
		yang: longColor,
		yin: shortColor,
	},
	fill: {
		yang: longColor,
		yin: shortColor,
	},
	currentValueStroke: longColor,
};

export const SingleValueToolTipAppearance = {
	// className = props.className,
	textFill: chartColors.lightGray,
	// labelFill = props.labelFill,
	// onClick = props.onClick,
	fontFamily:
		'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
	// fontSize: 28,
};

export const StaticValueToolTipAppearance = {
	// className = props.className,
	valueFill: chartColors.white,
	labelFill: chartColors.downColorDark,
	fontFamily:
		'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
	// fontSize: 28,
};

export const movingAverageToolTipAppearance = {
	fontFamily:
		'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
	textFill: chartColors.lightGray,
};

export const defaultAnnotationAppearance = {
	fontFamily: 'FontAwesome',
	fontSize: 20,
	opacity: 0.8,
	onClick: console.log.bind(console),
};

export const longAnnotation = {
	...defaultAnnotationAppearance,
	fill: chartColors.greenTradingVue,
	text: '\uf0d8',
	y: ({ yScale, datum }) => yScale(datum.low) + 20,
	tooltip: 'Long',
};

export const shortAnnotation = {
	...defaultAnnotationAppearance,
	fill: chartColors.redTradingVue,
	text: '\uf0d7',
	y: ({ yScale, datum }) => yScale(datum.high) - 20,
	tooltip: 'Short',
};

export const takeProfitFromShortAnnotation = {
	...defaultAnnotationAppearance,
	fill: chartColors.tradingViewBlue,
	text: '\uf0d8',
	y: ({ yScale, datum }) => yScale(datum.low) + 30,
	tooltip: 'TP',
};
export const takeProfitFromLongAnnotation = {
	...defaultAnnotationAppearance,
	fill: chartColors.tradingViewBlue,
	text: '\uf0d7',
	y: ({ yScale, datum }) => yScale(datum.high) - 30,
	tooltip: 'TP',
};

export const stopLossAnnotation = {
	...defaultAnnotationAppearance,
	fill: '#E20000',
	text: '↓',
	y: ({ yScale, datum }) => yScale(datum.high),
	tooltip: 'Short',
};

/**
 * When the X is clicked, this is ho it is handled.
 *
 * @param {obj} yCoordinate
 * @param {obj} moreProps
 */
export const cancelOrderWithAlert = (deletedAlert, moreProps, dispatch) => {
	const { id, type } = deletedAlert;

	/**
	 * Only move forward if this is a type that we
	 * support draging
	 */
	// if (type === 'TAKE_PROFIT' || type === 'STOP_LOSS' || type === 'STOP_LOSS' || type === 'STOP_LOSS') {
	confirmAlert({
		title: 'Cancel Order',
		message: `Are you sure you want to cancel ${type} ID ${id}?`,
		closeOnEscape: true,
		closeOnClickOutside: true,
		overlayClassName: 'react-confirm-alert-overlay-top mt-9rem',
		buttons: [
			{
				label: 'Yes',
				onClick: () => (type === 'ORDER' ? dispatch(closeOandaTrade(id)) : dispatch(cancelOandaOrder(id))), // Dispatch to cancel
				className: 'ml-1 btn btn-success',
			},
			{
				label: 'No',
				onClick: () => {},
				className: 'ml-1 btn btn-danger',
			},
		],
	});
	// }
};
