import React, { useState, createRef, useRef } from 'react';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BaseModal } from './BaseModal';
import { changeModalIndicatorOpen } from '../../actions/navigation';
import { indicatorOptionsFunction } from '../StockChart/config/indicatorOptions';
import { moveFocus, searchKeyDown } from '../utils';

/**
 * Call the function
 *
 * 2021-04-01 10:19:21 JD
 */
const indicatorOptions = indicatorOptionsFunction();

// To use redux hooks, you export a function component, not a react component.
export const IndicatorSearch = (props) => {
	/**
	 * Use props to pass some items so we can
	 * have versatile results
	 */
	const { indicators, indicatorChange, handleIndicatorRemoval } = props;

	/**
	 * Fetch our redux states
	 */
	const isOpen = useSelector((state) => state.navigation.modals.indicatorSearchOpen);
	const dispatch = useDispatch();

	/**IndicatorOptions
	 * Our state
	 */
	const [searchTerm, setSearchTerm] = useState(null);
	const [searchCategory, setSearchCategory] = useState(null);
	const refSearch = useRef(null);
	let focusRefs = []; // For refs

	/**
	 * Filter each account by the search term.
	 */
	let filteredIndicatorOptions =
		searchTerm === null
			? indicatorOptions
			: indicatorOptions.filter(
					(indicator) =>
						indicator.label.search(new RegExp(searchTerm, 'i')) !== -1 ||
						indicator.type.search(new RegExp(searchTerm, 'i')) !== -1 ||
						indicator.category.search(new RegExp(searchTerm, 'i')) !== -1
			  );

	/**
	 * Filter by category if it is set
	 */
	if (searchCategory != null) filteredIndicatorOptions = filteredIndicatorOptions.filter((indicator) => indicator.category === searchCategory);

	/**
	 * Build a list of all of the indicator
	 * categories.
	 */
	let indicatorCategories = ['All', ...new Set(indicatorOptions.map((item) => item.category))];
	indicatorCategories.sort((a, b) => {
		if (a < b) {
			return -1;
		}
		if (a > b) {
			return 1;
		}
		return 0;
	});
	// indicatorOptions.map((item) => {
	// 	if (!indicatorCategories.includes(item.category)) indicatorCategories.push(item.category);
	// });

	/**
	 * The onClose function
	 */
	const onClose = () => {
		setSearchTerm(null);
		dispatch(changeModalIndicatorOpen(false));
	};

	/**
	 * Handle when the user clicks on a new accountID.
	 *
	 * @param {str} account
	 */
	const handleIndicatorClick = (indicator) => {
		if (!indicator || indicator.length <= 0) return;
		indicatorChange(indicator);
		// onClose();
	};

	/**
	 * The user is changing category items.
	 *
	 * @param {obj} category
	 */
	const handleCategoryChange = (category) => {
		if (category === 'All') setSearchCategory(null);
		else setSearchCategory(category);
	};

	/**
	 * This will loop through all of the categories and
	 * render them for us.
	 */
	const renderIndicatorCategories = () => {
		if (!indicatorCategories || indicatorCategories.length <= 0) return '';

		return indicatorCategories.map((category) => {
			return (
				<div className={`tab ${category === searchCategory ? 'active' : ''}`} key={category} onClick={() => handleCategoryChange(category)}>
					<span className="icon"></span>
					<span className="title">{category}</span>
				</div>
			);
		});
	};

	/**
	 * Cycle through all of the indicators selected and
	 * make a selection box.
	 */
	const renderSelectedIndicators = () => {
		if (!indicators || !Array.isArray(indicators) || indicators.length <= 0) return '';

		return indicators.map((indicator) => {
			return (
				<span
					key={`toolbarList_${indicator.id}`}
					role="listitem"
					direction="ltr"
					className="selectedIndiBadge"
					color="#0074D9"
					onClick={() => handleIndicatorRemoval(indicator)}
				>
					<span className="selectedIndiLabel">{indicator.label}</span>
					<span className="selectedIndiRemove">×</span>
				</span>
			);
		});
	};

	/**
	 * Cycle through the instrument options and map them
	 * to a new array as an option.
	 */
	const renderIndicatorOptions = (indicatorList) => {
		if (!isOpen || !indicatorList || !Array.isArray(indicatorList)) return '';

		return indicatorList
			.sort((a, b) => (a.label > b.label ? 1 : -1))
			.map((indicator) => {
				const newRef = createRef();
				focusRefs.push(newRef);

				return (
					<div
						className="listContainer"
						key={indicator.id}
						onClick={() => handleIndicatorClick(indicator)}
						ref={newRef}
						tabIndex={-1}
						onKeyDown={(e) => moveFocus(e, refSearch)}
					>
						<div className="itemRow">
							<div className="itemInfoCell cell">
								<div className="expandHandleWrap">
									{indicators.some((indi) => indi.id === indicator.id) ? (
										<span className="expandHandle">
											<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
												<path stroke="currentColor" d="M8 5l3.5 3.5L8 12"></path>
											</svg>
										</span>
									) : (
										''
									)}
								</div>
								<div className="description">
									<div className="symbolTitleNoFlow">
										<span>{indicator.label}</span>
									</div>
								</div>
							</div>
							<div className="symbolDescription cell">{indicator.id}</div>
							<div className="exchangeCell cell">
								<div className="exchangeDescription">
									<div className="marketType">{indicator.category}</div>
									<div className="exchangeName"></div>
								</div>
								<div className="flag"></div>
							</div>
						</div>
					</div>
				);
			});
	};

	return (
		<BaseModal isOpen={isOpen} onClose={onClose} title="Indicator Search" mainClass="largeSelectModal">
			<InputGroup className="modalSearchInputGroup">
				<InputGroupAddon addonType="prepend">
					<span className="input-group-text transparentBG">
						<i className="fa fa-search" />
					</span>
				</InputGroupAddon>
				<Input
					type="text"
					className="modalSearch"
					placeholder="Search..."
					autoFocus={true}
					onChange={(e) => setSearchTerm(e.target.value)}
					innerRef={refSearch} // This is a prop of the input which converts into ref
					onKeyDown={(e) => searchKeyDown(e, focusRefs)}
				/>
			</InputGroup>
			{indicators && indicators.length > 0 ? <div className="selectedBadges">{renderSelectedIndicators()}</div> : ''}
			<div className="modalSearchWrapper">
				<div className="modalContainer">{renderIndicatorCategories()}</div>
				<div className="modalContainerResults">
					<div className="modalSearchResultsList">{renderIndicatorOptions(filteredIndicatorOptions)}</div>
				</div>
			</div>
		</BaseModal>
	);
};
