import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './Loader.module.scss';

class Loader extends React.Component {
	static propTypes = {
		size: PropTypes.number.isRequired,
	};

	static defaultProps = {
		size: 75,
		height: 'auto',
	};

	render() {
		return (
			<div
				align="center"
				className={`{container d-flex mb-5 ${cx(s.root, this.props.className)}`}
				style={{ marginTop: 'auto', height: this.props.height }}
			>
				<i
					className="la la-spinner la-spin row justify-content-center align-self-center"
					style={{ fontSize: this.props.size, marginRight: 'auto', marginLeft: 'auto' }}
				/>
			</div>
		);
	}
}

export default Loader;
