import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { customSortCaret, NoDataIndication } from '../../../../../components/utils';
import CheckMobileScreen from '../../../../../libs/checkMobileScreen';
import filterFactory, { textFilter, customFilter } from '../../../../../libs/react-bootstrap-table2/packages/react-bootstrap-table2-filter';

// To use redux hooks, you export a function component, not a react component.
export const NotesTable = (props) => {
	/**
	 * Get the data we need
	 */
	let { data, type, noteStatus, setNoteStatus } = props;
	const isMobile = CheckMobileScreen();

	/**
	 * Check it
	 */
	if (!data || data.length <= 0) return '';

	/**
	 * Create a new data list which has the
	 */
	let archivedArray = data.filter((d) => d.status === 'archived').sort((a, b) => new Date(b.time) - new Date(a.time));
	let otherArray = data.filter((d) => d.status !== 'archived').sort((a, b) => new Date(b.time) - new Date(a.time));
	const sortedData = [...otherArray, ...archivedArray];

	/**
	 * Format our HTML for display
	 *
	 * @param {str} cell
	 */
	const htmlFormatter = (cell, row) => {
		/**
		 * Add target blank to all links in the
		 * html string.
		 */
		cell = cell.split('<a').join('<a target="_blank"');

		/**
		 * If it is archived/completed, add a line
		 * through it.
		 */
		if (row.status === 'archived') return <div style={{ textDecoration: 'line-through' }} dangerouslySetInnerHTML={{ __html: `${cell}` }} />;
		return <div dangerouslySetInnerHTML={{ __html: `${cell}` }} />;
	};

	/**
	 * Build our table colums
	 */
	const columns = [
		{
			text: '',
			dataField: 'htmlData',
			formatter: (cell, row) => htmlFormatter(cell, row),
			filter:
				type !== 'records'
					? textFilter({
							id: `text-filter-column-${type}-${Math.random().toString(36).substr(2, 7)}`,
					  })
					: null,
			sort: false,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: '',
			dataField: 'status',
			classes: 'action-icons',
			filter: customFilter(),
			filterRenderer: () => {
				return (
					<UncontrolledButtonDropdown>
						<DropdownToggle caret color="subtle-blue" className="dropdown-toggle-split btn-sm notes-header">
							{noteStatus}&nbsp;&nbsp;
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem onClick={() => setNoteStatus('split')}>Split</DropdownItem>
							<DropdownItem onClick={() => setNoteStatus('active')}>Active</DropdownItem>
							<DropdownItem onClick={() => setNoteStatus('archived')}>Completed</DropdownItem>
							<DropdownItem onClick={() => setNoteStatus('deleted')}>Deleted</DropdownItem>
						</DropdownMenu>
					</UncontrolledButtonDropdown>
				);
			},
			formatter: (cell, row) => {
				/**
				 * The ternary expressions were getting too complex
				 * so I extracted them into an if statement here.
				 */
				let icons = [];
				if (row.status === 'active')
					icons.push(
						<i
							className="fa fa-check"
							key={Math.random().toString(36).substr(2, 7)}
							style={{ cursor: 'pointer' }}
							onClick={props.handleArchived.bind(this, row._id)}
						/>,
						<i
							className="fa fa-trash"
							key={Math.random().toString(36).substr(2, 7)}
							style={{ cursor: 'pointer' }}
							onClick={props.handleDelete.bind(this, row._id)}
						/>
					);
				else if (row.status === 'deleted')
					icons.push(
						<i
							className="fa fa-undo"
							key={Math.random().toString(36).substr(2, 7)}
							style={{ cursor: 'pointer' }}
							onClick={props.handleUndelete.bind(this, row._id)}
						/>
					);
				else if (row.status === 'archived')
					icons.push(
						<i
							className="fa fa-repeat"
							key={Math.random().toString(36).substr(2, 7)}
							style={{ cursor: 'pointer' }}
							onClick={props.handleUndelete.bind(this, row._id)}
						/>,
						<i
							className="fa fa-trash"
							key={Math.random().toString(36).substr(2, 7)}
							style={{ cursor: 'pointer' }}
							onClick={props.handleDelete.bind(this, row._id)}
						/>
					);

				return icons;
			},
			headerStyle: { outline: 0 },
		},
	];

	/**
	 * Selected Row data
	 */
	const selectRowProp = {
		mode: 'checkbox',
		hideSelectAll: true,
		hideSelectColumn: isMobile,
	};

	return (
		<BootstrapTable
			bordered={false}
			keyField="_id"
			data={sortedData}
			columns={columns}
			// defaultSorted={defaultSorted}
			selectRow={selectRowProp}
			filter={filterFactory()}
			pagination={type !== 'records' && sortedData.lenght > 100 ? paginationFactory({ sizePerPage: 100 }) : null}
			noDataIndication={() => <NoDataIndication />}
			classes="text-white mb-4 notes-lists"
			filterPosition="top"
		/>
	);
};
