import React from 'react';
import { chartColors } from './config/genericConfig';

export const CustomChartSidebar = (props) => {
	/**
	 * Get our height from the props or set one
	 */
	let height = props.height ? props.height : '100%';
	let backgroundColor = props.backgroundColor ? props.backgroundColor : chartColors.toolbarBackground;
	let borderRightStyle = props.borderRightStyle ? props.borderRightStyle : `1px solid ${chartColors.toolbarBorderMain}`;

	return (
		<div className="trading-vue-toolbar" style={{ backgroundColor: backgroundColor, borderRight: borderRightStyle, width: '56px', height: height }}>
			<div className="trading-vue-tbitem selected-item" style={{ width: '37px', height: '37px', margin: '8px 10px 0px', borderRadius: '3px' }}>
				<div
					className="trading-vue-tbicon pixelated"
					style={{
						backgroundImage:
							'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZAgMAAAC5h23wAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAxQTFRFAAAATU1NTU1NTU1NwlMHHwAAAAR0Uk5TAOvhxbpPrUkAAAAkSURBVHicY2BgYHBggAByabxg1WoGBq2pRCk9AKUbcND43AEAufYHlSuusE4AAAAASUVORK5CYII=")',
						width: '25px',
						height: '25px',
						margin: '6px',
						filter: 'brightness(3)',
					}}
				></div>
			</div>
			<div className="trading-vue-tbitem" style={{ width: '37px', height: '37px', margin: '8px 10px 0px', borderRadius: '3px' }}>
				<div
					className="trading-vue-tbicon pixelated"
					style={{
						backgroundImage:
							'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZAgMAAAC5h23wAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAlQTFRFAAAATU1NJCQkCxcHIQAAAAN0Uk5TAP8SmutI5AAAACxJREFUeJxjYMACGAMgNAsLdpoVKi8AVe8A1QblQlWRKt0AoULw2w1zGxoAABdiAviQhF/mAAAAAElFTkSuQmCC)',
						width: '25px',
						height: '25px',
						margin: '6px',
						filter: 'brightness(3)',
					}}
				></div>
			</div>

			<div className="trading-vue-tbitem" style={{ width: '37px', height: '37px', margin: '8px 10px 0px', borderRadius: '3px' }}>
				<div
					className="trading-vue-tbicon pixelated"
					style={{
						backgroundImage:
							'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZAQMAAAD+JxcgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAATU1NkJ+rOQAAAAJ0Uk5TAP9bkSK1AAAANElEQVR4nGNggABGEMEEIlhABAeI+AASF0AlHmAqA4kzKAAx8wGQuAMKwd6AoYzBAWonAwAcLwTgNfJ3RQAAAABJRU5ErkJggg==)',
						width: '25px',
						height: '25px',
						margin: '6px',
						filter: 'brightness(3)',
					}}
				></div>
			</div>

			<div className="trading-vue-tbitem" style={{ width: '37px', height: '37px', margin: '8px 10px 0px', borderRadius: '3px' }}>
				<div
					className="trading-vue-tbicon pixelated"
					style={{
						backgroundImage:
							'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZAQMAAAD+JxcgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAATU1NkJ+rOQAAAAJ0Uk5TAP9bkSK1AAAAIUlEQVR4nGNggAPm/w9gTA4QIQMitECEJ1yMEgLNDiAAADfgBMRu78GgAAAAAElFTkSuQmCC)',
						width: '25px',
						height: '25px',
						margin: '6px',
						filter: 'brightness(3)',
					}}
				></div>
			</div>
		</div>
	);
};
