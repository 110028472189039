import React from 'react';
// import { useSelector, useDispatch } from 'react-redux'; // useDispatch()
import { Switch, Route, withRouter } from 'react-router';
import { Dashboard } from '../../pages/dashboard';
import { Backtest } from '../../pages/backtest/Backtest';
import { Optimization } from '../../pages/optimization/Optimization';
import { Transaction } from '../../pages/transactions/Transaction';
import { BrowseOptimization } from '../../pages/optimization/Browse';
import { BrowseBacktests } from '../../pages/backtest/Browse';
import { BrowseTransactions } from '../../pages/transactions/Browse';
import { Chart } from '../../pages/chart/Chart';
import { Sentiment } from '../../pages/sentiment/Sentiment';
import Header from '../Header';
import Sidebar from '../Sidebar';
import BreadcrumbHistory from '../BreadcrumbHistory';
// import { changeSidebarVisibility } from '../../actions/navigation';
import s from './Layout.module.scss';
import { Notes } from '../../pages/notes/Notes';
import { Instruments } from '../../pages/instruments/Instruments';

const Layout = (props) => {
	return (
		<div className={[s.root, ''].join(' ')}>
			<div className={s.wrap} id="outer-container">
				<Header />
				<Sidebar />
				<main className={s.content} id="page-wrap">
					<BreadcrumbHistory url={props.location.pathname} />
					<Switch>
						<Route path={['/app', '/']} exact component={Dashboard} />
						<Route path={['/browse/optimization', '/browse/optimization/']} exact component={BrowseOptimization} />
						<Route path={'/browse/optimization/:id'} exact component={Optimization} />
						<Route path={['/browse/backtests', '/browse/backtests/']} exact component={BrowseBacktests} />
						<Route path={'/browse/backtests/:id'} exact component={Backtest} />
						<Route path={'/browse/transactions'} exact component={BrowseTransactions} />
						<Route path={'/browse/transactions/:id'} exact component={Transaction} />
						<Route path={['/graph', '/chart']} exact component={Chart} />
						<Route path={'/sentiment'} exact component={Sentiment} />
						<Route path={'/Instruments'} exact component={Instruments} />
						<Route path={'/notes'} exact component={Notes} />
					</Switch>
					<footer className={s.contentFooter}>
						Light Blue React Dashboard - React admin template made by <a href="https://flatlogic.com">Flatlogic</a>
					</footer>
				</main>
			</div>
		</div>
	);
};

export default withRouter(Layout);
