import React from 'react';
import { useSelector } from 'react-redux'; // useDispatch()
import Widget from '../../../../components/Widget';
import { Progress } from 'reactstrap';
import { floatToCurrencyFormat } from '../../../../components/utils';
import Loader from '../../../../components/Loader/Loader';

// To use redux hooks, you export a function component, not a react component.
export const MarginUsedWidget = () => {
	/**
	 * We use an arrow function whitch passes state to a
	 * callback function that returns the state we need.
	 */
	const accountDetails = useSelector((state) => state.oanda.accountDetails);
	const accountID = useSelector((state) => state.oanda.accountID);

	//const dispatch = useDispatch();

	if (!accountDetails || !accountID || accountDetails[accountID] === undefined)
		return (
			<Widget
				title={
					<h4 className="d-flex align-items-center bigStatTitle">
						<span className={`circle bg-success mr-sm`} style={{ fontSize: '6px' }} />
						Margin <span className="fw-normal ml-xs">Usage</span>
					</h4>
				}
			>
				<Loader />
			</Widget>
		);

	return (
		<Widget
			title={
				<h4 className="d-flex align-items-center bigStatTitle">
					<span
						className={`circle bg-${accountDetails[accountID].marginCallPercent > 0.5 ? 'danger' : 'success'} mr-sm`}
						style={{ fontSize: '6px' }}
					/>
					Margin <span className="fw-normal ml-xs">Usage</span>
				</h4>
			}
		>
			<div className="stats-row" style={{ overflow: 'hidden' }}>
				<div className="stat-item">
					<h6 className="name">Available</h6>
					<p className="value">{floatToCurrencyFormat(accountDetails[accountID].marginAvailable)}</p>
				</div>
				<div className="stat-item">
					<h6 className="name">Used</h6>
					<p className="value">{floatToCurrencyFormat(accountDetails[accountID].marginUsed)}</p>
				</div>
				<div className="stat-item">
					<h6 className="name">Margin Call</h6>
					<p className="value">{(parseFloat(accountDetails[accountID].marginCallPercent) * 100).toFixed(2)}%</p>
				</div>
			</div>
			<Progress
				color={accountDetails[accountID].marginCallPercent > 0.5 ? 'danger' : 'success'}
				value={parseFloat(accountDetails[accountID].marginCallPercent) * 100}
				className="bg-custom-dark progress-xs"
			/>
			<p>
				<small>
					<span className="circle bg-default text-white">
						<i className="fa fa-chevron-down" />
					</span>
				</small>
				<span className="fw-semi-bold">&nbsp;{floatToCurrencyFormat(accountDetails[accountID].marginCloseoutUnrealizedPL)}</span>
				&nbsp;closeout amount
			</p>
		</Widget>
	);
};

/* Account Details relating to Margin

	marginAvailable: "34926.6045"
	marginCallMarginUsed: "54997.8003"
	marginCallPercent: "0.60771"
	marginCloseoutMarginUsed: "54997.8003"
	marginCloseoutNAV: "90499.7895"
	marginCloseoutPercent: "0.30386"
	marginCloseoutPositionValue: "1224829.0282"
	marginCloseoutUnrealizedPL: "-12938.2456"
	marginRate: "0.02"
	marginUsed: "54997.8003"

*/
