import { combineReducers } from 'redux';
import auth from './auth';
import navigation from './navigation';
import alerts from './alerts';
import users from './usersReducers';
import oanda from './oanda';
import header from './header';
import backtest from './backtest';
import heartbeat from './heartbeat';
import console from './console';
import notes from './notes';
import { connectRouter } from 'connected-react-router';

export default (history) =>
	combineReducers({
		router: connectRouter(history),
		alerts,
		auth,
		oanda,
		navigation,
		users,
		header,
		backtest,
		heartbeat,
		console,
		notes,
	});
