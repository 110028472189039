import axios from 'axios';
// import { store } from '../index';
// import { setHeaderStatus } from './header';
import {
	FETCH_BACKTEST_DATA,
	FETCH_BACKTEST_CANDLES,
	FETCH_OPTIMIZATION_DATA,
	FETCH_OPTIMIZATION_BROWSE_DATA,
	FETCH_OPTIMIZATION_BROWSE_COUNT,
	SET_OPTIMIZATION_CONFIG,
	SET_OPTIMIZATION_SELECTS,
	FETCH_BACKTEST_BROWSE_DATA,
	FETCH_BACKTEST_BROWSE_COUNT,
	SET_BACKTEST_CONFIG,
	REMOVE_OPTIMIZATION_BROWSE_DATA,
	REMOVE_BACKTEST_BROWSE_DATA,
	FETCH_TRANSACTION_BROWSE_DATA,
	FETCH_TRANSACTION_BROWSE_COUNT,
	SET_TRANSACTION_CONFIG,
	REMOVE_TRANSACTION_BROWSE_DATA,
	FETCH_TRANSACTION_DATA,
} from './types';

/**
 * This will fetch all of the data from a specific
 * backtest.
 *
 * @param {str} id Mongo Object ID
 */
export const fetchBacktestData = (id) => async (dispatch) => {
	let res = await axios.get(`/api/backtest/${id}`);
	dispatch({ type: FETCH_BACKTEST_DATA, payload: res.data });

	if (res.data.length <= 0) return;

	dispatch(
		fetchBacktestCandles({
			instrument: res.data.symbol,
			granularity: res.data.granularity,
			from: res.data.cliOptions.inputBeginDate,
			to: res.data.cliOptions.inputEndDate,
		})
	);
};

/**
 * This will fetch all of the data from a specific
 * optimization result.
 *
 * @param {str} id Mongo Object ID
 */
export const fetchOptimizationData = (id) => async (dispatch) => {
	let res = await axios.get(`/api/optimization/${id}`);
	dispatch({ type: FETCH_OPTIMIZATION_DATA, payload: res.data });
};

/**
 * This will fetch all of the data from a specific
 * transaction result.
 *
 * @param {str} id Mongo Object ID
 */
export const fetchTransactionData = (id) => async (dispatch) => {
	let res = await axios.get(`/api/transaction/${id}`);
	dispatch({ type: FETCH_TRANSACTION_DATA, payload: res.data });
};

/**
 * This will fetch the candles needed for the backtest portion of
 * the site.
 *
 * @param {obj} params
 * 		- instrument
 *  	- granularity
 * 		- from
 * 		- to
 *
 */
export const fetchBacktestCandles = (params) => async (dispatch) => {
	if (!params.instrument || !params.granularity) throw new Error('bad data in fetchBacktestCandles');

	const res = await axios.get(`/api/getCandles/${params.instrument}`, { params: params });
	dispatch({ type: FETCH_BACKTEST_CANDLES, payload: res.data });
};

/**
 * This will return an array of possible field values for a select
 * drop down.
 *
 * @param {obj} params
 * 		- collection (db collection)
 * 		- field (IE: strategy)
 *
 */
export const fetchOptimizationDistinctFields = () => async (dispatch) => {
	const strategies = await axios.get('/api/getDistinctField', {
		params: {
			collection: 'Backtests',
			field: 'strategy',
		},
	});
	const symbols = await axios.get('/api/getDistinctField', {
		params: {
			collection: 'Backtests',
			field: 'symbol',
		},
	});

	const res = {
		strategies: strategies.data,
		symbols: symbols.data,
	};

	dispatch({ type: SET_OPTIMIZATION_SELECTS, payload: res });
};

/**
 * This will fetch the optimization reports
 *
 * @param {obj} params
 * 		- limit
 * 		- next : mongoID of the last received, used for pagination
 *
 */
export const fetchOptimizationBrowseData = (params) => async (dispatch) => {
	const res = await axios.get(`/api/optimizations`, { params: params });
	dispatch({ type: FETCH_OPTIMIZATION_BROWSE_DATA, payload: res.data });
};

export const fetchOptimizationBrowseCount = (params) => async (dispatch) => {
	const res = await axios.get(`/api/countOptimizations`, { params: params });
	dispatch({ type: FETCH_OPTIMIZATION_BROWSE_COUNT, payload: res.data });
};

export const changeOptimizationConfig = (config) => async (dispatch) => {
	dispatch({ type: SET_OPTIMIZATION_CONFIG, payload: config });
};

/**
 * This will fetch the Backtest reports
 *
 * @param {obj} params
 * 		- limit
 * 		- next : mongoID of the last received, used for pagination
 *
 */
export const fetchBacktestBrowseData = (params) => async (dispatch) => {
	const res = await axios.get(`/api/backtests`, { params: params });
	dispatch({ type: FETCH_BACKTEST_BROWSE_DATA, payload: res.data });
};

export const fetchBacktestBrowseCount = (params) => async (dispatch) => {
	const res = await axios.get(`/api/countBacktests`, { params: params });
	dispatch({ type: FETCH_BACKTEST_BROWSE_COUNT, payload: res.data });
};

export const changeBacktestConfig = (config) => async (dispatch) => {
	dispatch({ type: SET_BACKTEST_CONFIG, payload: config });
};

export const removeOptimizationBrowseData = (config) => async (dispatch) => {
	dispatch({ type: REMOVE_OPTIMIZATION_BROWSE_DATA, payload: [] });
};
export const removeBacktestBrowseData = (config) => async (dispatch) => {
	dispatch({ type: REMOVE_BACKTEST_BROWSE_DATA, payload: [] });
};

/**
 * This will fetch the Backtest reports
 *
 * @param {obj} params
 * 		- limit
 * 		- next : mongoID of the last received, used for pagination
 *
 */
export const fetchTransactionBrowseData = (params) => async (dispatch) => {
	const res = await axios.get(`/api/transactions`, { params: params });
	dispatch({ type: FETCH_TRANSACTION_BROWSE_DATA, payload: res.data });
};

export const fetchRecentTransactionBrowseData = (accountID) => async (dispatch) => {
	dispatch(
		fetchTransactionBrowseData({
			filters: {
				accountID: { filterVal: accountID },
				type: {
					filterVal: {
						$nin: ['ORDER_CANCEL', 'ORDER_CLIENT_EXTENSIONS_MODIFY', 'ORDER_CANCEL_REJECT', 'LIMIT_ORDER', 'TRADE_CLIENT_EXTENSIONS_MODIFY'],
					},
				},
			},
		})
	);
};

export const fetchTransactionBrowseCount = (params) => async (dispatch) => {
	const res = await axios.get(`/api/countTransactions`, { params: params });
	dispatch({ type: FETCH_TRANSACTION_BROWSE_COUNT, payload: res.data });
};

export const changeTransactionConfig = (config) => async (dispatch) => {
	dispatch({ type: SET_TRANSACTION_CONFIG, payload: config });
};

export const removeTransactionBrowseData = (config) => async (dispatch) => {
	dispatch({ type: REMOVE_TRANSACTION_BROWSE_DATA, payload: [] });
};
