import React, { useState, createRef, useRef } from 'react';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BaseModal } from './BaseModal';
import { changeModalGranularityOpen } from '../../actions/navigation';
import { oandaData } from '../../pages/dashboard/oandaData';
import { moveFocus, searchKeyDown } from '../utils';

// To use redux hooks, you export a function component, not a react component.
export const GranularitySearch = (props) => {
	/**
	 * Use props to pass some items so we can
	 * have versatile results
	 */
	const { chartGranularity, granularityChange } = props;

	/**
	 * Fetch our redux states
	 */
	const isOpen = useSelector((state) => state.navigation.modals.granularitySearchOpen);
	const dispatch = useDispatch();

	/**
	 * Our state
	 */
	const [searchTerm, setSearchTerm] = useState(null);
	const refSearch = useRef(null);
	let focusRefs = []; // For refs

	/**
	 * This is just a search result holder
	 */
	let filteredOptions =
		searchTerm === null
			? oandaData.candlestickGranularity
			: oandaData.candlestickGranularity.filter((gran) => gran.search(new RegExp(searchTerm, 'i')) !== -1);

	/**
	 * The onClose function
	 */
	const onClose = () => {
		setSearchTerm(null);
		dispatch(changeModalGranularityOpen(false));
	};

	/**
	 * Handle when the user clicks on a new granularity.
	 *
	 * @param {str} granularity
	 */
	const handleSearchClick = (granularity) => {
		granularityChange(granularity);
		onClose();
	};

	const renderFavorite = (option) => {
		return (
			<div className={`tab ${option === chartGranularity ? 'active' : ''}`} key={`fav_${option}`} onClick={() => handleSearchClick(option)}>
				<span className="icon"></span>
				<span className="title">{option}</span>
			</div>
		);
	};

	/**
	 * Cycle through the chartType options and map them
	 * to a new array as an option.
	 */
	const renderOptions = () => {
		if (!filteredOptions || !Array.isArray(filteredOptions)) return '';

		/**
		 * Map the results out.
		 */
		return filteredOptions.map((option) => {
			const newRef = createRef();
			focusRefs.push(newRef);

			return (
				<div
					className="listContainer"
					key={option}
					onClick={() => handleSearchClick(option)}
					ref={newRef}
					tabIndex={-1}
					onKeyDown={(e) => moveFocus(e, refSearch)}
				>
					<div className="itemRow">
						<div className="itemInfoCell cell">
							<div className="expandHandleWrap">
								{chartGranularity === option ? (
									<span className="expandHandle">
										<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
											<path stroke="currentColor" d="M8 5l3.5 3.5L8 12"></path>
										</svg>
									</span>
								) : (
									''
								)}
							</div>
							<div className="description">
								<div className="symbolTitleNoFlow">
									<span>{option}</span>
								</div>
							</div>
						</div>
						<div className="exchangeCell cell">
							<div className="exchangeDescription">
								<div className="marketType"></div>
								<div className="exchangeName"></div>
							</div>
							<div className="flag"></div>
						</div>
					</div>
				</div>
			);
		});
	};

	return (
		<BaseModal isOpen={isOpen} onClose={onClose} title="Granularity" mainClass="smallModal">
			<InputGroup className="modalSearchInputGroup">
				<InputGroupAddon addonType="prepend">
					<span className="input-group-text transparentBG">
						<i className="fa fa-search" />
					</span>
				</InputGroupAddon>
				<Input
					type="text"
					className="modalSearch"
					placeholder="Search..."
					onChange={(e) => setSearchTerm(e.target.value)}
					autoFocus={true}
					innerRef={refSearch} // This is a prop of the input which converts into ref
					onKeyDown={(e) => searchKeyDown(e, focusRefs)}
				/>
			</InputGroup>
			<div className="modalSearchWrapper">
				<div className="modalContainer">
					{renderFavorite('M1')}
					{renderFavorite('H1')}
					{renderFavorite('H4')}
					{renderFavorite('D')}
					{renderFavorite('W')}
				</div>
				<div className="modalContainerResults">
					<div className="modalSearchResultsList">{renderOptions()}</div>
				</div>
			</div>
		</BaseModal>
	);
};
