import { FETCH_USER, LOGOUT_SUCCESS, FETCH_APP_VERSION } from '../actions/types';

const defaultState = {
	currentUser: null,
	version: 0,
};

const auth = (state = defaultState, action) => {
	switch (action.type) {
		case FETCH_USER:
			return { ...state, currentUser: action.payload } || false;
		case LOGOUT_SUCCESS:
			return {};
		case FETCH_APP_VERSION:
			return (
				{
					...state,
					version: action.payload.version,
				} || false
			);
		default:
			return state;
	}
};

export default auth;
