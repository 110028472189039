import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAccountID, setChartType, setDashboardChartGranularity, setDashboardChartInstrument, setIndicatorList } from '../../actions/oanda';
import { SocketContext } from '../../context/socket';
// import { objectEqual } from '../utils';
import { AccountSearch } from './AccountSearch';
import { CandleTypeSearch } from './CandleTypeSearch';
import { GranularitySearch } from './GranularitySearch';
import { IndicatorSearch } from './IndicatorSearch';
import { InstrumentSearch } from './InstrumentSearch';
import { OrdersBuySell } from './OrdersBuySell';
import { IndicatorEdit } from './IndicatorEdit';

/**
 * This is just a wrapper to encase the different modals and
 * pass props.
 *
 */
export const DashboardModalWrapper = () => {
	/**
	 * Get our instruments
	 */
	const instruments = useSelector((state) => state.oanda.instruments);
	const chartInstrument = useSelector((state) => state.oanda.chartInstrument);
	const chartType = useSelector((state) => state.oanda.chartSettings.chartType);
	const chartGranularity = useSelector((state) => state.oanda.chartGranularity);
	const accountID = useSelector((state) => state.oanda.accountID);
	const accountList = useSelector((state) => state.oanda.accountList);
	const indicators = useSelector((state) => state.oanda.chartSettings.indicators);
	const modals = useSelector((state) => state.navigation.modals);
	const dispatch = useDispatch();

	/**
	 * Get our socket context so we can update
	 * the socket.
	 */
	const socket = useContext(SocketContext);

	/**
	 * When the user clicks on a different chart instrument.
	 *
	 * @param {str} instrument
	 */
	const instrumentChange = (instrument) => {
		if (instrument != null) dispatch(setDashboardChartInstrument(instrument));
	};

	/**
	 * When the user clicks on a different chart type.
	 *
	 * @param {str} chartTypeChange
	 */
	const chartTypeChange = (chartType) => dispatch(setChartType(chartType));

	/**
	 * When the user clicks on a different granularity type.
	 *
	 * @param {str} granularity
	 */
	const granularityChange = (granularity) => dispatch(setDashboardChartGranularity(granularity));

	/**
	 * When the user clicks on a different account
	 *
	 * @param {str} account - the entire account object
	 */
	const accountChange = (account) => {
		/**
		 * Tell our socket about it
		 */
		socket.emit('changeAccountId', account);

		/**
		 * Send it out
		 */
		dispatch(setAccountID(account));
	};

	/**
	 * When the dropdown select is changed, this will be
	 * fired.
	 *
	 * @param {obj} values
	 */
	const indicatorChange = (values) => dispatch(setIndicatorList([...indicators, values]));
	const handleIndicatorRemoval = (indicator) => dispatch(setIndicatorList(indicators.filter((indi) => indi.id !== indicator.id)));

	/**
	 * Return our modals.
	 */
	return (
		<>
			{modals.chartTypeSearchOpen ? <CandleTypeSearch chartType={chartType} chartTypeChange={chartTypeChange} /> : ''}
			{modals.instrumentSearchOpen ? (
				<InstrumentSearch chartInstrument={chartInstrument} instruments={instruments} instrumentChange={instrumentChange} />
			) : (
				''
			)}
			{modals.granularitySearchOpen ? <GranularitySearch chartGranularity={chartGranularity} granularityChange={granularityChange} /> : ''}
			{modals.accountSearchOpen ? <AccountSearch accountID={accountID} accountList={accountList} accountChange={accountChange} /> : ''}
			{modals.indicatorSearchOpen ? (
				<IndicatorSearch indicators={indicators} indicatorChange={indicatorChange} handleIndicatorRemoval={handleIndicatorRemoval} />
			) : (
				''
			)}
			{modals.indicatorEditOpen !== false ? (
				<IndicatorEdit indicators={indicators} indicator={indicators.filter((indi) => indi.id === modals.indicatorEditOpen)} />
			) : (
				''
			)}
			{modals.ordersOpen || modals.tpslOpen > 0 || modals.limitOpen > 0 ? (
				<OrdersBuySell chartInstrument={chartInstrument} chartGranularity={chartGranularity} modals={modals} />
			) : (
				''
			)}
		</>
	);
};
