import React, { useEffect, useState } from 'react';
import s from '../dashboard/Dashboard.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	fetchOptimizationBrowseData,
	fetchOptimizationBrowseCount,
	changeOptimizationConfig,
	removeOptimizationBrowseData,
} from '../../actions/backtest';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { NoDataIndication } from '../../components/utils';
import Moment from 'react-moment';

// To use redux hooks, you export a function component, not a react component.
export const BrowseOptimization = (props) => {
	/**
	 * Call the state variables and dispatch into
	 * existance.
	 */
	const { pageIndex, pageSize, sortBy, filters, total, lastID } = useSelector((state) => state.backtest.browse.optimizationConfig);
	const optimizationData = useSelector((state) => state.backtest.browse.optimizationData);
	const dispatch = useDispatch();

	/**
	 * Local state for the tabs
	 */
	const [activeTab, setActiveTab] = useState('1');

	/**
	 * Similar to componentDidMount and componentDidUpdate:
	 * The optional second param ([]) in useEffect tells it to only
	 * run if whatever data in that array has changed. If left blank (just []),
	 * it will only run once.
	 */
	useEffect(() => {
		/**
		 * Dispatch it!
		 */
		dispatch(fetchOptimizationBrowseCount({ filters }));
		dispatch(fetchOptimizationBrowseData({ pageIndex, pageSize, sortBy, filters, lastID }));
	}, [pageIndex, pageSize, sortBy, filters, lastID, dispatch]);

	/**
	 * These are the table headers which will be used by
	 * reactTable
	 */
	const columns = [
		{
			text: 'ID',
			dataField: '_id',
			formatter: (cell) => {
				return (
					<Link to={`/browse/optimization/${cell}`} className="text-white">
						{cell}
					</Link>
				);
			},
		},
		{
			text: 'Strategy',
			dataField: 'strategy',
			filter: textFilter({ delay: 2000, defaultValue: filters && filters.strategy ? filters.strategy.filterVal : '' }),
		},
		{
			text: 'Symbol',
			dataField: 'symbol',
			filter: textFilter({ delay: 2000, defaultValue: filters && filters.symbol ? filters.symbol.filterVal : '' }),
		},
		{
			text: 'TF',
			dataField: 'granularity',
			filter: textFilter({
				delay: 2000,
				defaultValue: filters && filters.granularity ? filters.granularity.filterVal : '',
			}),
		},
		{
			text: 'Version',
			dataField: 'version',
		},
		{
			text: 'Type',
			dataField: 'cliOptions.inputGenetic',
			formatter: (cell) => {
				return cell ? 'Genetic' : 'Exhaustive';
			},
		},
		{
			text: 'Executed',
			dataField: 'date',
			formatter: (cell) => {
				return <Moment format="YYYY-MM-DD HH:MM:SS">{cell}</Moment>;
			},
		},
		{
			text: 'Start',
			dataField: 'cliOptions.inputBeginDate',
			formatter: (cell) => {
				return <Moment format="YYYY-MM-DD HH:MM:SS">{cell}</Moment>;
			},
		},
		{
			text: 'End',
			dataField: 'cliOptions.inputEndDate',
			formatter: (cell) => {
				return <Moment format="YYYY-MM-DD HH:MM:SS">{cell}</Moment>;
			},
		},
	];

	const defaultSorted = [
		{
			dataField: '_id',
			order: 'desc',
		},
	];

	/**
	 * The function that calls the server data
	 *
	 * @param {} param0
	 */
	const RemoteAll = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
		<div>
			<BootstrapTable
				remote
				keyField="_id"
				data={data}
				columns={columns}
				defaultSorted={defaultSorted}
				filter={filterFactory()}
				pagination={paginationFactory({ page, sizePerPage, totalSize })}
				onTableChange={onTableChange}
				noDataIndication={() => <NoDataIndication />}
				striped
				borderless
				classes="text-white table-sm"
				filterPosition="top"
			/>
		</div>
	);

	const handleTableChange = (type, options) => {
		// Nothing to do here.
		if (type === 'filter' && JSON.stringify(filters) === JSON.stringify(options.filters)) return;

		/**
		 * Change the options
		 */
		const newOptimizationConfig = {
			pageIndex: options.page,
			pageSize: options.sizePerPage,
			sortBy: options.sortField,
			filters: options.filters,
			lastID: null, // We don't use this.
			// lastID: page > pageIndex ? data[data.length - 1]['_id'] : null,
		};

		/**
		 * This should trigger an update via redux.
		 */
		dispatch(removeOptimizationBrowseData());
		dispatch(changeOptimizationConfig(newOptimizationConfig));
	};

	/**
	 * Only render if we actually have data worth showing.
	 */
	return (
		<div className={s.root}>
			<Row>
				<Col xs="12" className="mb-5">
					<Nav tabs>
						<NavItem className={s.customNavItem}>
							<NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
								<span>Optimization Results</span>
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent className="mb-lg" activeTab={activeTab}>
						<TabPane tabId="1">
							<RemoteAll data={optimizationData} page={pageIndex} sizePerPage={pageSize} totalSize={total} onTableChange={handleTableChange} />
						</TabPane>
					</TabContent>
				</Col>
			</Row>
		</div>
	);
};
