import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // ()
import axios from 'axios';
import CustomChart from './CustomChart';
import { CustomChartToolbar } from './CustomChartToolbar';
import { CustomChartSidebar } from './CustomChartSidebar';
import { CustomChartFooter } from './CustomChartFooter';
import {
	formatOandaData,
	objectEqual,
	addSignalsToData,
	addGenericToData,
	getDefaultTimePeriod,
	addGenericToDataByIndex,
	getFormat,
	detrendBars,
} from '../utils';
import { chartColors } from './config/genericConfig';
import Loader from '../../components/Loader/Loader';
import { candleTypeCycle } from '../../actions/oanda';
import { changeModalIndicatorEditOpen, changeModalLimitOpen } from '../../actions/navigation';
import { SHOW_FOOTER_LOADING, MORE_CHART_CANDLES } from '../../actions/types';
import useDimensions from '../useDimensions';
import { getMatchingIndicator } from './config/indicatorOptions';

/**
 * Only for dev
 */
// import useWhyDidYouUpdate from '../useWhyDidYouUpdate';

// To use redux hooks, you export a function component, not a react component.
export const ChartComponent = (props) => {
	/**
	 * We use an arrow function whitch passes state to a
	 * callback function that returns the state we need.
	 */
	const chartInstrument = useSelector((state) => state.oanda.chartInstrument, objectEqual);
	const chartGranularity = useSelector((state) => state.oanda.chartGranularity, objectEqual);
	const chartCandles = useSelector((state) => state.oanda.chartCandles, objectEqual);
	const chartSettings = useSelector((state) => state.oanda.chartSettings);
	const chartSignals = useSelector((state) => {
		if (
			state.oanda.accountID != null &&
			state.oanda.profit[state.oanda.accountID] != null &&
			state.oanda.chartInstrument != null &&
			state.oanda.profit[state.oanda.accountID].signals != null
		)
			return state.oanda.profit[state.oanda.accountID].signals[state.oanda.chartInstrument];
	}, objectEqual);
	const cotData = useSelector((state) => state.oanda.cotData, objectEqual);
	const nonMergeApiData = useSelector((state) => state.oanda.nonMergeApiData);
	const genericApiData = useSelector((state) => state.oanda.genericApiData);

	/**
	 * This allows me to see what caused this component to update.
	 *
	 * This is an example on how to use hooks and useSelector
	 * and determine what changed.
	 */
	// useWhyDidYouUpdate('Default ChartComponent', {
	// 	chartCandles,
	// 	chartInstrument,
	// 	chartGranularity,
	// 	chartSettings,
	// 	chartSignals,
	// 	cotData,
	// 	genericApiData,
	// });

	/**
	 * What do we actually use.
	 * Position - long/short, unrealizedPL, units, averageprice
	 * trades - long/short, unrelizedPL, currentUnits (of each), price (of each trade), id(of each)
	 * orders - price, units, id,
	 */
	const dispatch = useDispatch();

	/**
	 * Our state
	 */
	const [suffix, setSuffix] = useState(1);
	const timePeriod = useRef(getDefaultTimePeriod(chartGranularity));
	const candleLoop = useRef(0);
	const downloadingData = useRef(false);
	const chartData = useRef([]);
	const alreadyDownloaded = useRef([]); // Holds timestamps of already downloaded items

	/**
	 * Get the chart width and height
	 */
	const [chartDivRef, dimensions] = useDimensions({ noScroll: true });

	/**
	 * We use the useEffect to clear our cache
	 * when things need to be recaclulated.
	 */
	useEffect(() => {
		candleLoop.current = 0;
		chartData.current = [];
		alreadyDownloaded.current = [];
		downloadingData.current = false;
	}, [chartInstrument, chartGranularity]);

	useEffect(() => {
		if (chartCandles?.[chartInstrument]?.[chartGranularity]?.length > 0) {
			/**
			 * Determine if this is the first load or not
			 */
			// const isFirstLoad = !chartData?.current || chartData.current.length <= 0 ? true : false;

			/**
			 * Format our data.
			 */
			chartData.current = formatCandleData(chartCandles[chartInstrument][chartGranularity]);

			/**
			 * Force a reload
			 */
			// if (isFirstLoad) setSuffix((s) => s + 1);
			setSuffix((s) => s + 1);
		}
	}, [chartCandles, chartGranularity, chartInstrument, chartSettings, genericApiData, nonMergeApiData]);

	/**
	 * The total height of any top and/or bottom
	 * toolbars.
	 */
	const toolbarHeight = 68;

	/**
	 * Get our height from the props or set one
	 */
	const height = props.height ? props.height : dimensions.height; //'100vh'; // Was props.height
	const loadingHeight = 620 - toolbarHeight; // If you remove the odd 560, the bottom toolbar is outside of the area intended.

	/**
	 * This variable creates an appropriate string
	 * for the HTML below.
	 */
	const heightHTML = height === '100vh' || !Number.isInteger(height) ? height : `${height}px`;

	/**
	 * If the user clicks the time period in the footer,
	 * change the state here.
	 *
	 * @param {int} period
	 */
	const handleTimePeriodClick = (period) => {
		if (period == null || (period !== 'All' && period <= 0)) return;

		/**
		 * For sanity, lets get the current chart candles
		 */
		let currentChartCandles = chartData.current;

		/**
		 * If the period is All, use the
		 * date of the first candle.
		 */
		if (period === 'All') period = currentChartCandles[0].timeMS ? currentChartCandles[0].timeMS : Date.parse(currentChartCandles[0].time);

		/**
		 * Set the period here.
		 */
		timePeriod.current = period;

		/**
		 * Force a reload
		 */
		setSuffix(suffix + 1);
	};

	/**
	 * This will reset the time period so it doesn't
	 * continue to render it.
	 */
	const handleTimePeriodReset = () => (timePeriod.current = null);

	/**
	 * Force a reload
	 *
	 * 2021-12-09 14:08:06 JD
	 */
	const handleReloadClick = () => {
		/**
		 * Format our data.
		 */
		chartData.current = formatCandleData(chartCandles[chartInstrument][chartGranularity]);

		setSuffix(suffix + 1);
	};

	/**
	 * This will change the chart type from
	 * mid, ask and bid. It will just cycle through
	 * them.
	 */
	const handleCandleTypeClick = () => {
		dispatch(candleTypeCycle());
	};

	/**
	 * User is clicking on an indicator to change the params.
	 *
	 * @param {str} indicatorID
	 */
	const handleIndicatorEditClick = (indicatorID) => {
		dispatch(changeModalIndicatorEditOpen(indicatorID));
	};

	/**
	 * This will change the chart type from
	 * mid, ask and bid. It will just cycle through
	 * them.
	 */
	const handleContextMenu = React.useMemo(
		() => (moreProps, e) => {
			const {
				mouseXY: [, mouseY],
				chartConfig: { yScale },
			} = moreProps;

			/**
			 * Get our formatting function
			 */
			const priceFormat = getFormat(chartInstrument);

			/**
			 * Get the yValue (price) based on the mouse Y
			 */
			const yValue = yScale.invert(mouseY);

			/**
			 * Launch the limit order
			 */
			dispatch(changeModalLimitOpen(priceFormat(yValue)));
		},
		[chartInstrument, dispatch]
	);

	const formatCandleData = (candleData) => {
		// Format our Oanda Chart data for use with React-stockcharts
		let data = formatOandaData(candleData, chartSettings.candleType);

		/**
		 * Only waste time doing the below if the candle loop is done.
		 * If it is not done, it is still loading candles..
		 */
		if (chartSettings.candleLoopDone) {
			/**
			 * Send our cache data back instead of redoing all of the
			 * calculations
			 */
			if (chartSignals != null && chartSignals.length > 0) data = addSignalsToData(data, chartSignals);
			if (cotData != null && cotData.length > 0) data = addGenericToData(data, cotData, 'cotData');

			/**
			 * Combine our generic data to the data
			 *
			 * This data should all have a standard format.
			 */
			if (genericApiData != null) {
				for (const item in genericApiData) {
					if (item.length > 0) {
						let newData = addGenericToData(data, genericApiData[item].data, item); // This could return null..
						data = newData || data;
					}
				}
			}

			/**
			 * Loop through all of the indicators the user has set.
			 */
			for (const indicator of chartSettings.indicators) {
				let mergedIndicator = getMatchingIndicator(indicator);

				/**
				 *  Execute the merged functions here
				 */
				if (mergedIndicator?.arguments?.mergeFunctions && mergedIndicator?.arguments?.mergeFunctions.length > 0) {
					for (const func of mergedIndicator.arguments.mergeFunctions) {
						if (typeof func.execute === 'function') {
							data = addGenericToDataByIndex(data, func.execute(data, mergedIndicator.arguments.mergeOptions), indicator.arguments.fetchName);
						}
					}
				}

				/**
				 * If we have detrend set, alter the data here
				 *
				 * 2021-05-27 14:01:52 - JD
				 */
				if (mergedIndicator.type === 'Detrend') data = detrendBars(data);

				/**
				 * If this is the volume bucket, we need to append the data back to it
				 * for entry/exit signals.
				 *
				 * 2022-02-01 16:13:48 JD
				 */
				if (mergedIndicator?.arguments?.needsOwnData) {
					if (nonMergeApiData?.[mergedIndicator.arguments.fetchName] != null) {
						mergedIndicator.arguments.data = nonMergeApiData?.[mergedIndicator.arguments.fetchName];
						indicator.arguments.data = nonMergeApiData?.[mergedIndicator.arguments.fetchName];
					}
				}
			}
		}

		return data;
	};
	//,
	// 	[chartSettings.candleType, chartSettings.candleLoopDone, cotData, chartSettings.indicators, chartSignals, genericApiData]
	// );

	/**
	 * When the user is requesting more data by
	 * edging to either side.
	 *
	 * @param {*} start
	 * @param {*} end
	 */
	const handleLoadMore = async (start, end, prevChartData) => {
		if (Math.ceil(start) === end) return;
		if (!chartCandles || !chartInstrument || !chartGranularity || downloadingData.current === true) return;

		/**
		 * If we have already downloaded it, get out
		 */
		if (alreadyDownloaded.current.indexOf(prevChartData[0].dateMS) !== -1) return;

		/**
		 * Set the amount of rows to be the same as what
		 * we have now.
		 */
		const rowsToDownload = 1000; // chartData.current.length; // end - Math.ceil(start);

		/**
		 * Prevent multiple dls
		 */
		downloadingData.current = true;

		/**
		 * Setup the download params
		 */
		const params = {
			params: {
				count: rowsToDownload,
				cursor: true,
				beforeDate: prevChartData[0].time,
			},
		};

		/**
		 * Add this to the array
		 */
		alreadyDownloaded.current.push(prevChartData[0].dateMS);

		/**
		 * Tell Redux that we are loading more candles
		 *
		 * Why do we have SHOW_FOOTER_LOADING if we have CANDLE_LOOP_DONE ??
		 * Because, CANDLE_LOOP_DONE is in the chartsettings which causes double
		 * reloads when issued here - the double being here and in the customchart.js.
		 * This value is outside of the chartsettings and does not cause double reloads.
		 */
		dispatch({ type: SHOW_FOOTER_LOADING, payload: true });

		/**
		 * Go get more candles.
		 */
		const candleResults = await axios.get(`/api/getCandles`, params);

		/**
		 * No data :(
		 */
		if (!candleResults?.data?.candles || candleResults?.data?.candles?.length <= 0) return;

		/**
		 * Let redux know about it
		 *
		 * This was causing re-render issues in the actual chart. :|
		 */
		await dispatch({ type: MORE_CHART_CANDLES, payload: candleResults.data }); // gets the .candles in the reducer

		/**
		 * Tell Redux that we are done loading more candles
		 */
		dispatch({ type: SHOW_FOOTER_LOADING, payload: false });

		/**
		 * Format our results
		 */
		// candleResults = formatCandleData(candleResults?.data?.candles);

		/**
		 * Append it to our global
		 */
		// chartData.current = chartData.current.concat(candleResults, chartData.current);

		/**
		 * End the download
		 */
		downloadingData.current = false;

		/**
		 * return it
		 */
		return true;
		// return candleResults;
	};

	/**
	 * This will return a spinner or the actual chart if we have
	 * all of the required data.
	 */
	const chartOrLoading = (props) => {
		// Return nothing if we have no data.
		if (!chartData?.current || chartData.current.length <= 0) {
			return <Loader size={75} height={`${loadingHeight}px`} />;
		} else {
			/**
			 * Ok, so to prevent a shit-ton of re-renders I store
			 * the chart-data in chartData.current, which is a ref.
			 *
			 * It is in a useEffect block which is updated on new candles. If we
			 * just loaded more, it ignores the updating call.
			 *
			 * Format it
			 */
			// chartData.current = formatCandleData(chartCandles[chartInstrument][chartGranularity]);

			/**
			 * Start the second download of data
			 *
			 * 2021-04-16 05:36:52 - JD Disabled
			 * This caused the "unable to update a component from another component"
			 * error.
			 *
			 */
			// if (candleLoop.current === 0) {
			// 	handleLoadMore(0, 1, chartData.current);
			// 	candleLoop.current = 1;
			// }

			/**
			 * Create the chart
			 */
			return (
				<CustomChart
					height={height}
					width={dimensions.width}
					data={chartData.current}
					toolbarHeight={toolbarHeight}
					chartInstrument={chartInstrument}
					chartSettings={chartSettings}
					genericApiData={genericApiData}
					timePeriod={timePeriod.current}
					handleTimePeriodReset={handleTimePeriodReset}
					handleContextMenu={handleContextMenu}
					handleLoadMore={handleLoadMore}
					handleIndicatorEditClick={handleIndicatorEditClick}
				/>
			);
		}
	};

	/**
	 * Height issues????
	 *
	 * Another solution here: https://github.com/rrag/react-stockcharts/issues/442
	 */
	return (
		<div
			className="trading-vue"
			style={{
				color: chartColors.whiteLabels,
				backgroundColor: props.backgroundColor ? props.backgroundColor : chartColors.bgColorTradingView,
			}}
		>
			<CustomChartSidebar height={height} backgroundColor={props.toolbarBackgroundColor} />
			<div className="trading-vue-chart" style={{ marginLeft: '57px', height: `${heightHTML}` }}>
				<CustomChartToolbar backgroundColor={props.toolbarBackgroundColor} />
				<div ref={chartDivRef}>{chartOrLoading()}</div>
				<CustomChartFooter
					backgroundColor={props.toolbarBackgroundColor}
					handleTimePeriodClick={handleTimePeriodClick}
					handleCandleTypeClick={handleCandleTypeClick}
					handleReloadClick={handleReloadClick}
					chartSettings={chartSettings}
				/>
			</div>
		</div>
	);
};
