import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { findObjByInstrument, objectEqual, getFormat, formatPositionData } from '../../utils';
import { chartColors } from '../config/genericConfig';
import { InteractiveYCoordinate } from '../../../libs/react-stockcharts/src/lib/interactive';

// To use redux hooks, you export a function component, not a react component.
export const PositionPriceCoordinateStore = () => {
	/**
	 * Get our store and the data that we need.
	 */
	const chartInstrument = useSelector((state) => state.oanda.chartInstrument);
	const individualPositions = useSelector((state) => state.oanda.chartSettings.individualPositions);
	const chartInstrumentPosition = useSelector((state) => {
		if (state.oanda.accountID != null && chartInstrument != null && state.oanda.positions[state.oanda.accountID] != null) {
			// if (!state.oanda.chartSettings.individualPositions) return '';
			return findObjByInstrument(state.oanda.positions[state.oanda.accountID], chartInstrument);
		}
	}, objectEqual);

	/**
	 * This is the holder for interactive y
	 * cooridnates.
	 */
	const yCoordinatePositionList = useRef([]);

	/**
	 * Update when trades updates
	 */
	useEffect(() => {
		yCoordinatePositionList.current = [];
		// setShouldUpdate(shouldUpdate + 1);
	}, [chartInstrumentPosition]);

	/**
	 * Make sure we have data or return false.
	 * We seperate the below for legability.
	 */
	if (individualPositions) return ''; // This means we are basing off of trades
	if (!individualPositions && (!chartInstrumentPosition || chartInstrumentPosition == null || chartInstrumentPosition === {})) return '';
	if (!individualPositions && (chartInstrumentPosition.long === undefined || chartInstrumentPosition.short === undefined)) return '';

	/**
	 * Format the position
	 */
	let formatedPosition = formatPositionData(chartInstrumentPosition);

	/**
	 * Determine if this is a buy or sell
	 */
	let type = parseInt(formatedPosition.units) > 0 ? 'Long' : 'Short';

	/**
	 * Push each trade into an array
	 */
	yCoordinatePositionList.current.push({
		...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate,
		stroke: type === 'Long' ? chartColors.ownTradeLongLine : chartColors.ownTradeShortLine,
		textFill: '#fff',
		bgFill: chartColors.bgColorTradingView,
		text: `${formatedPosition.unrealizedPL}  ${formatedPosition.units}`,
		yValue: parseFloat(formatedPosition.averagePrice),
		id: Math.random().toString(36).substr(2, 7),
		draggable: false,
		edge: {
			...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate.edge,
			displayFormat: getFormat(chartInstrument),
			stroke: type === 'Long' ? chartColors.ownTradeLongLine : chartColors.ownTradeShortLine,
			fill: chartColors.bgColorTradingView,
		},
	});

	return <InteractiveYCoordinate enabled={true} yCoordinateList={yCoordinatePositionList.current} />;
};
