export const oandaData = {
	candlestickGranularity: [
		'S5',
		'S10',
		'S15',
		'S30',
		'M1',
		'M2',
		'M4',
		'M5',
		'M10',
		'M15',
		'M30',
		'H1',
		'H2',
		'H3',
		'H4',
		'H6',
		'H8',
		'H12',
		'D',
		'W',
		'M',
	],
};

/**
 * An Array map for the time frame
 */
export const timeFrameMap = {
	today: 'Today',
	yesterday: 'Yesterday',
	d2: 'D2',
	d3: 'D3',
	d4: 'D4',
	d5: 'D5',
	d6: 'D6',
	d7: 'D7',
	d8: 'D8',
	d9: 'D9',
	d10: 'D10',
	week: 'Week',
	w1: 'Last Week',
	month: 'Month',
	m1: 'Last Month',
	m2: 'M2',
	year: 'Year',
};
