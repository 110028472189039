import React, { useEffect } from 'react';
import Loader from '../../../../../components/Loader/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchRecentTransactionBrowseData } from '../../../../../actions/backtest';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import { floatToCurrencyFormat, customSortCaret, NoDataIndication, objectEqual } from '../../../../../components/utils';
import Moment from 'react-moment';
import { setDashboardChartInstrument } from '../../../../../actions/oanda';

// To use redux hooks, you export a function component, not a react component.
export const RecentTransactions = () => {
	const transactionsData = useSelector((state) => {
		return state.backtest.browse.transactionsData;
	}, objectEqual);
	const accountID = useSelector((state) => state.oanda.accountID);
	const dispatch = useDispatch();

	/**
	 * Similar to componentDidMount and componentDidUpdate:
	 * The optional second param ([]) in useEffect tells it to only
	 * run if whatever data in that array has changed. If left blank (just []),
	 * it will only run once.
	 */
	useEffect(() => {
		if (accountID != null && accountID.length > 0) dispatch(fetchRecentTransactionBrowseData(accountID));
	}, [dispatch, transactionsData, accountID]);

	/**
	 * Return nothing if no NewsFeeds data
	 */
	if (transactionsData == null || transactionsData.length <= 0) return <Loader />;

	/**
	 * These are the table headers which will be used by
	 * reactTable
	 */
	const columns = [
		{
			text: 'ID',
			dataField: '_id',
			formatter: (cell) => {
				return (
					<Link to={`/browse/transactions/${cell}`} className="text-white">
						{cell}
					</Link>
				);
			},
		},
		{
			text: 'Account ID',
			dataField: 'accountID',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: 'Order ID',
			dataField: 'orderID',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: 'Symbol',
			dataField: 'instrument',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: 'Type',
			dataField: 'type',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			formatter: (cell, row) => {
				const colorClass = cell.indexOf('REJECT') !== -1 ? 'text-danger' : '';
				if (cell) {
					return row?.rejectReason ? (
						<>
							<p className={`m-0 ${colorClass}`}>{cell.replaceAll('_', ' ')}</p>
							<p className={`m-0 ${colorClass}`}>{row?.rejectReason.replaceAll('_', ' ')}</p>
						</>
					) : (
						<p className={`m-0 ${colorClass}`}>{cell}</p>
					);
				} else return '';
			},
		},
		{
			text: 'Reason',
			dataField: 'reason',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: 'Profit',
			dataField: 'pl',
			formatter: (cell) => {
				const colorClass = parseFloat(cell) >= 0 ? 'text-success' : 'text-danger';
				if (cell) return <p className={`m-0 fw-semi-bold ${colorClass}`}>{floatToCurrencyFormat(cell)}</p>;
				else return '';
			},
		},
		{
			text: 'Financing',
			dataField: 'financing',
			formatter: (cell) => {
				if (cell) return <p className="m-0 fw-semi-bold text-danger">{floatToCurrencyFormat(cell)}</p>;
				else return '';
			},
		},
		{
			text: 'Units',
			dataField: 'units',
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				if (order === 'asc') return Math.abs(a) - Math.abs(b);
				else return Math.abs(b) - Math.abs(a); // desc
			},
		},
		{
			text: 'Price',
			dataField: 'price',
		},
		{
			text: 'time',
			dataField: 'time',
			formatter: (cell) => {
				return <Moment format="YYYY-MM-DD HH:MM:SS">{cell}</Moment>;
			},
		},
	];

	const defaultSorted = [
		{
			dataField: '_id',
			order: 'desc',
		},
	];

	/**
	 * When something happens on a row in
	 * bootstrap table, we call this function
	 */
	const rowEvents = {
		onClick: (e, row, rowIndex) => {
			dispatch(setDashboardChartInstrument(row.instrument));
		},
	};

	return (
		<BootstrapTable
			keyField="_id"
			data={transactionsData}
			columns={columns}
			defaultSorted={defaultSorted}
			filter={filterFactory()}
			noDataIndication={() => <NoDataIndication />}
			classes="text-white tr-curosor"
			filterPosition="top"
			rowEvents={rowEvents}
		/>
	);
};
