import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { ActiveOrdersTable } from './components/ActiveOrdersTable';
import { Console } from './components/Console';
import { OpenPositionsTable } from './components/OpenPositionsTable';
import { RecentTransactions } from './components/RecentTransactions';
import { Calendar } from './components/Calendar';
import { NewsFeeds } from './components/NewsFeeds';
// import { NotesAndLists } from './components/NotesAndLists';
// import { fetchNotes } from '../../../../actions/notes';
import { Notes } from '../../../notes/Notes';
import { ActiveTradesTable } from './components/ActiveTradesTable';

// To use redux hooks, you export a function component, not a react component.
export const TradingPanelContainer = () => {
	/**
	 * Local state for the tabs
	 */
	const [activeTab, setActiveTab] = useState('1');

	/**
	 * This is the actual display that we are returning.
	 */
	return (
		<div>
			<Nav tabs>
				<NavItem>
					<NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
						<span>Open Positions</span>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={activeTab === '8' ? 'active' : ''} onClick={() => setActiveTab('8')}>
						<span>Trades</span>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
						<span>Orders</span>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
						<span>Recent Transactions</span>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={activeTab === '4' ? 'active' : ''} onClick={() => setActiveTab('4')}>
						<span>Console</span>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={activeTab === '5' ? 'active' : ''} onClick={() => setActiveTab('5')}>
						<span>Calendar</span>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={activeTab === '6' ? 'active' : ''} onClick={() => setActiveTab('6')}>
						<span>News</span>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={activeTab === '7' ? 'active' : ''} onClick={() => setActiveTab('7')}>
						<span>Notes & Todos</span>
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent className="mb-5" activeTab={activeTab}>
				<TabPane tabId="1">{activeTab === '1' ? <OpenPositionsTable /> : ''}</TabPane>
				<TabPane tabId="2">{activeTab === '2' ? <ActiveOrdersTable /> : ''}</TabPane>
				<TabPane tabId="3">{activeTab === '3' ? <RecentTransactions /> : ''}</TabPane>
				<TabPane tabId="4" style={{ padding: 0, margin: 0 }}>
					{activeTab === '4' ? <Console /> : ''}
				</TabPane>
				<TabPane tabId="5">{activeTab === '5' ? <Calendar /> : ''}</TabPane>
				<TabPane tabId="6">{activeTab === '6' ? <NewsFeeds /> : ''}</TabPane>
				<TabPane tabId="7">{activeTab === '7' ? <Notes /> : ''}</TabPane>
				<TabPane tabId="8">{activeTab === '8' ? <ActiveTradesTable /> : ''}</TabPane>
			</TabContent>
		</div>
	);
};
