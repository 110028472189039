import React, { useEffect, useState } from 'react';
import s from '../dashboard/Dashboard.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTransactionData } from '../../actions/backtest';
// import { floatToCurrencyFormat, floatToNumberFormat } from '../../components/utils';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import Loader from '../../components/Loader/Loader';

// To use redux hooks, you export a function component, not a react component.
export const Transaction = (props) => {
	/**
	 * Call the state variables and dispatch into
	 * existance.
	 */
	const transaction = useSelector((state) => state.backtest.data.transaction);
	const dispatch = useDispatch();

	/**
	 * Local state for the tabs
	 */
	const [activeTab, setActiveTab] = useState('1');

	/**
	 * Similar to componentDidMount and componentDidUpdate:
	 * The optional second param ([]) in useEffect tells it to only
	 * run if whatever data in that array has changed. If left blank (just []),
	 * it will only run once.
	 */
	useEffect(() => {
		dispatch(fetchTransactionData(props.match.params.id));
	}, [dispatch, props.match.params.id, transaction]);

	/**
	 * Only render if we actually have data worth showing.
	 */
	if (transaction) {
		return (
			<div className={s.root}>
				<Row>
					<Col xs="12" className="mb-5">
						<Nav tabs>
							<NavItem className={s.customNavItem}>
								<NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
									<span>Transaction</span>
								</NavLink>
							</NavItem>
						</Nav>

						<TabContent className="mb-lg" activeTab={activeTab}>
							<TabPane tabId="1">
								<pre>{JSON.stringify(transaction, null, 4).replace(/["[,\]]/g, '')}</pre>
							</TabPane>
						</TabContent>
					</Col>
				</Row>
			</div>
		);
	} else {
		return <Loader />;
	}
};
