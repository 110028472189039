import React from 'react';
// import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { BaseModal } from './BaseModal';
import { changeModalIndicatorEditOpen } from '../../actions/navigation';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { updateIndicatorList } from '../../actions/oanda';

// To use redux hooks, you export a function component, not a react component.
export const IndicatorEdit = (props) => {
	/**
	 * Use props to pass some items so we can
	 * have versatile results
	 */
	const { indicator, indicators } = props;

	/**
	 * Fetch our redux states
	 */
	const isOpen = indicator ? true : false;
	const dispatch = useDispatch();

	/**
	 * The onClose function
	 */
	const onClose = () => dispatch(changeModalIndicatorEditOpen(false));

	/**
	 * The user has changed something
	 *
	 * Originally I was going to just do one indicator per
	 * change/click but then I passed in all indicators on the
	 * editor and new options became available such as "duplicating".
	 * This would make it pretty easy to add multiple of one with different
	 * params.
	 *
	 * 2021-04-02 12:25:20 JD
	 */
	const handleChange = (json) => {
		if (json) dispatch(updateIndicatorList(json));
	};

	/**
	 * Change the name of the individual nodes.
	 *
	 * 2022-02-09 09:45:27
	 *
	 * @param {*} json
	 * 			{
	 * 				path: string[],
	 *				type: 'object' | 'array',
	 *				size: number,
	 *				value: object
	 *			}
	 *
	 * @return {string}
	 */
	const onNodeName = (json) => {
		console.log(indicators?.[json.path]);
		if (json) return indicators?.[json.path]?.label;
	};

	return (
		<BaseModal isOpen={isOpen} onClose={onClose} title="Indicator Edit" mainClass="largeSelectModal">
			<Editor value={indicators} onChange={handleChange} history allowedModes={Editor.modes.allValues} name="Indicators" onNodeName={onNodeName} />
		</BaseModal>
	);
};
