import React from 'react';

/**
 * Create our different chart types
 */
export const chartTypeOptions = [
	// {
	// 	value: 'Area',
	// 	label: 'Area',
	// },
	// {
	// 	value: 'AlternatingArea',
	// 	label: 'Alternating Area',
	// },
	{
		value: 'Candlestick',
		label: 'Candlestick',
	},
	// {
	// 	value: 'GroupedBars',
	// 	label: 'Grouped Bars',
	// },
	// {
	// 	value: 'HeikinAshi',
	// 	label: 'Heikin Ashi',
	// },
	// {
	// 	value: 'HollowCandle',
	// 	label: 'Hollow Candle',
	// },
	// {
	// 	value: 'Line',
	// 	label: 'Line',
	// },
	{
		value: 'Kagi',
		label: 'Kagi',
	},
	{
		value: 'OHLC',
		label: 'OHLC',
	},
	{
		value: 'Renko',
		label: 'Renko',
	},
	// {
	// 	value: 'Scatter',
	// 	label: 'Scatter',
	// },
	// {
	// 	value: 'StackedBars',
	// 	label: 'Stacked Bars',
	// },
	// {
	// 	value: 'PointFigure',
	// 	label: 'Point & Figure',
	// },
];

export const chartTypeSVGSelect = (type) => {
	switch (type) {
		case 'Area':
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor">
					<path d="M12.5 17.207L18.707 11h2l3.647-3.646-.708-.708L20.293 10h-2L12.5 15.793l-3-3-4.854 4.853.708.708L9.5 14.207z"></path>
					<path d="M9 16h1v1H9zm1 1h1v1h-1zm-1 1h1v1H9zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1H9zm2 0h1v1h-1zm-3-3h1v1H8zm-1 1h1v1H7zm-1 1h1v1H6zm2 0h1v1H8zm-1 1h1v1H7zm-2 0h1v1H5zm17-9h1v1h-1zm1-1h1v1h-1zm0 2h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-5-7h1v1h-1zm2 0h1v1h-1zm1-1h1v1h-1zm-2 2h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-2-6h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-3-3h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1z"></path>
				</svg>
			);
		case 'Bars':
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
					<g fill="none" stroke="currentColor" strokeLinecap="square">
						<path d="M10.5 7.5v15M7.5 20.5H10M13.5 11.5H11M19.5 6.5v15M16.5 9.5H19M22.5 16.5H20"></path>
					</g>
				</svg>
			);
		case 'Candlestick':
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor">
					<path d="M17 11v6h3v-6h-3zm-.5-1h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5z"></path>
					<path d="M18 7h1v3.5h-1zm0 10.5h1V21h-1z"></path>
					<path d="M9 8v12h3V8H9zm-.5-1h4a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .5-.5z"></path>
					<path d="M10 4h1v3.5h-1zm0 16.5h1V24h-1z"></path>
				</svg>
			);
		case 'HeikinAshi':
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor">
					<path d="M9 8v12h3V8H9zm-1-.502C8 7.223 8.215 7 8.498 7h4.004c.275 0 .498.22.498.498v13.004a.493.493 0 0 1-.498.498H8.498A.496.496 0 0 1 8 20.502V7.498z"></path>
					<path d="M10 4h1v3.5h-1z"></path>
					<path d="M17 6v6h3V6h-3zm-1-.5c0-.276.215-.5.498-.5h4.004c.275 0 .498.23.498.5v7c0 .276-.215.5-.498.5h-4.004a.503.503 0 0 1-.498-.5v-7z"></path>
					<path d="M18 2h1v3.5h-1z"></path>
				</svg>
			);
		case 'HollowCandle':
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor">
					<path d="M17 11v6h3v-6h-3zm-.5-1h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5z"></path>
					<path d="M18 7h1v3.5h-1zm0 10.5h1V21h-1z"></path>
					<path d="M9 8v11h3V8H9zm-.5-1h4a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-12a.5.5 0 0 1 .5-.5z"></path>
					<path d="M10 4h1v5h-1zm0 14h1v5h-1zM8.5 9H10v1H8.5zM11 9h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11z"></path>
				</svg>
			);
		case 'OHLC':
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
					<path
						fill="currentColor"
						d="M11.982 16.689L17.192 12h3.033l4.149-4.668-.748-.664L19.776 11h-2.968l-4.79 4.311L9 12.293l-4.354 4.353.708.708L9 13.707z"
					></path>
				</svg>
			);
		case 'Kagi':
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
					<path fill="currentColor" d="M11 5h3v12h5V8h3v13h1V7h-5v9h-3V4h-5v18H7v-5H6v6h5z"></path>
				</svg>
			);
		case 'Renko':
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
					<path fill="currentColor" d="M18 5v5h3V5h-3zm-1-1h5v7h-5V4zm-4 13h3v-5h-3v5zm-1-6h5v7h-5v-7zM8 24h3v-5H8v5zm-1-6h5v7H7v-7z"></path>
				</svg>
			);
		case 'PointFigure':
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor">
					<path d="M14.5 16a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0-1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 7a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0-1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm3.293-15.5l4.707 4.707.707-.707L18.5 4.793z"></path>
					<path d="M18.5 10.207L23.207 5.5l-.707-.707L17.793 9.5zm-.707 1.293l4.707 4.707.707-.707-4.707-4.707z"></path>
					<path d="M18.5 16.207l4.707-4.707-.707-.707-4.707 4.707zM5.793 17.5l4.707 4.707.707-.707L6.5 16.793z"></path>
					<path d="M6.5 22.207l4.707-4.707-.707-.707L5.793 21.5zM5.793 5.5l4.707 4.707.707-.707L6.5 4.793z"></path>
					<path d="M6.5 10.207L11.207 5.5l-.707-.707L5.793 9.5zM5.793 11.5l4.707 4.707.707-.707L6.5 10.793z"></path>
					<path d="M6.5 16.207l4.707-4.707-.707-.707L5.793 15.5z"></path>
				</svg>
			);

		default:
			break;
	}
};
