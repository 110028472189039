import React, { useState } from 'react';
import { Button } from 'reactstrap';
import EditorJs from 'react-editor-js';
import { EDITOR_JS_TOOLS, editorJSToHTML } from './EditorJsTools';

// To use redux hooks, you export a function component, not a react component.
export const WysiwygEditor = (props) => {
	/**
	 * Get our handle functions from the props
	 */
	const { handleSendData, type } = props;

	/**
	 * This will hold our editor instance
	 */
	const [editorInstance, setEditorInstance] = useState();

	/**
	 * Create a unique ID for the holder element
	 */
	const holderID = `wysiwyg_${type}`; //${Math.random().toString(36).substr(2, 7)}`;

	/**
	 * Reset the form
	 */
	const handleReset = async (e) => {
		await editorInstance.blocks.clear();
	};

	/**
	 * This will send the data to a parent and
	 * reset the local state.
	 */
	const handleSubmit = async (e) => {
		e.preventDefault();

		/**
		 * Make sure the state isn't null
		 */
		if (!editorInstance || !editorInstance.blocks || editorInstance.blocks.getBlocksCount() <= 0) return;

		/**
		 * This will convert the state/obj into a json
		 * object for storage.
		 */
		const savedData = await editorInstance.save();
		const htmlData = editorJSToHTML(savedData);
		/**
		 * This works below, it returns all of the html content, including
		 * the divs and classes used to create it  but we have no control over the classes
		 */
		// let htmlData = '';
		// for (let i = 0; i < editorInstance.blocks.getBlocksCount(); i++)
		// 	htmlData += editorInstance.blocks.getBlockByIndex(i).holder.innerHTML;

		/**
		 * Don't send empty data.
		 */
		if (htmlData === '') return;

		/**
		 * Clear the editor
		 */
		await editorInstance.blocks.clear();

		/**
		 * Sent it to our parent item
		 *
		 * -> From props!
		 */
		handleSendData(htmlData);
	};

	/**
	 * Render the editor and buttons
	 */
	return (
		<>
			<EditorJs tools={EDITOR_JS_TOOLS} holder={holderID} instanceRef={(instance) => setEditorInstance(instance)} minHeight={20}>
				<div id={holderID} />
			</EditorJs>
			<div className="text-md-right mt-3">
				<Button color="gray" onClick={handleReset}>
					Discard
				</Button>
				<Button color="danger" className="ml-2" onClick={handleSubmit}>
					Send
				</Button>
			</div>
		</>
	);
};
