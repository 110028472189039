import React, { useState } from 'react';
import { useSelector } from 'react-redux'; // useDispatch()
import Widget from '../../../../components/Widget/Widget';
import { Progress, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import _ from 'lodash';
import { floatToCurrencyFormat, getPercentageChange } from '../../../../components/utils';
import Loader from '../../../../components/Loader/Loader';

// To use redux hooks, you export a function component, not a react component.
export const WeeklyFaceOffWidget = () => {
	/**
	 * We use an arrow function which passes state to a
	 * callback function that returns the state we need.
	 */
	const profit = useSelector((state) => state.oanda.profit);
	const accountID = useSelector((state) => state.oanda.accountID);

	/**
	 * Local state for changing what we show
	 */
	const [activeWeekComparison, setActiveWeekComparison] = useState(2);

	/**
	 * Do nothing if the data isn't loaded
	 */
	if (!profit || !accountID || profit[accountID] === undefined || profit[accountID].w52 === undefined)
		return (
			<Widget
				title={
					<h4 className="d-flex align-items-center bigStatTitle">
						<span className={`circle bg-success mr-sm`} style={{ fontSize: '6px' }} />
						Last Week <span className="fw-normal ml-xs">Vs</span>
					</h4>
				}
			>
				<Loader />
			</Widget>
		);

	/**
	 * Get our percentage change
	 */
	const percentChange = getPercentageChange(profit[accountID].w52[1].gross, profit[accountID].w52[activeWeekComparison].gross).toFixed(2);

	/**
	 * Calculate the weekly average
	 * - Use the lodash meanBy to cycle through the object and return the average.
	 * -- The filter removes all objects that have a gross of 0
	 */
	let averageWeeklyEarnings = _.meanBy(
		_.filter(profit[accountID].w52, (w) => {
			return w.gross > 0;
		}),
		'gross'
	);

	let averageWeeklyNet = _.meanBy(
		_.filter(profit[accountID].w52, (w) => {
			return w.net > 0;
		}),
		'net'
	);
	let averageWeeklyComissions = _.meanBy(
		_.filter(profit[accountID].w52, (w) => {
			return w.comissions > 0;
		}),
		'comissions'
	);

	/**
	 * Loop through our array above and return a clickable dropdown
	 */
	const renderDropDownClick = () => {
		let dropDownItems = [];
		for (let i = 2; i < 52; i++)
			dropDownItems.push(
				<DropdownItem key={i} onClick={() => setActiveWeekComparison(i)}>
					vs {i}W. Ago
				</DropdownItem>
			);

		return dropDownItems;
	};

	return (
		<Widget
			title={
				<div className="d-flex justify-content-between">
					<h4 className="d-flex align-items-center">
						<span className={`circle bg-${percentChange < 0 ? 'danger' : 'success'} mr-sm`} style={{ fontSize: '6px' }} />
						Last Week
					</h4>
					<h4>
						<span className="fw-normal ml-xs">vs</span>
					</h4>
					<UncontrolledButtonDropdown>
						<DropdownToggle caret color="subtle-blue" className="dropdown-toggle-split btn-sm">
							{activeWeekComparison}W. Ago&nbsp;&nbsp;
						</DropdownToggle>
						<DropdownMenu>{renderDropDownClick()}</DropdownMenu>
					</UncontrolledButtonDropdown>
				</div>
			}
		>
			<div className="stats-row mb-3" style={{ overflow: 'hidden' }}>
				<div className="stat-item">
					<h6 className="name">1 Week</h6>
					<p className="value">{floatToCurrencyFormat(profit[accountID].w52[1].gross)}</p>
				</div>
				<div className="stat-item">
					<h6 className="name">{activeWeekComparison} Week</h6>
					<p className="value">{floatToCurrencyFormat(profit[accountID].w52[activeWeekComparison].gross)}</p>
				</div>
				<div className="stat-item">
					<h6 className="name">Avg Gross</h6>
					<p className="value">{floatToCurrencyFormat(averageWeeklyEarnings)}</p>
				</div>
				<div className="stat-item">
					<h6 className="name">Avg Net</h6>
					<p className="value">{floatToCurrencyFormat(averageWeeklyNet)}</p>
				</div>
			</div>
			<Progress
				color={percentChange < 0 ? 'danger' : 'success'}
				value={percentChange < 0 ? percentChange * -1 : percentChange}
				className="bg-custom-dark progress-xs"
			/>
			<p>
				<small>
					<span className="circle bg-default text-white">
						<i className="fa fa-chevron-down" />
					</span>
				</small>
				<span className="fw-semi-bold">&nbsp;{floatToCurrencyFormat(averageWeeklyComissions)}</span>
				&nbsp;avg weekly comissions paid
			</p>
		</Widget>
	);
};
