import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const TooltipItem = (props) => {
	const { placement = 'top', target, children } = props;
	const [tooltipOpen, setTooltipOpen] = useState(false);

	const toggle = () => setTooltipOpen(!tooltipOpen);

	return (
		<Tooltip placement={placement} isOpen={tooltipOpen} target={target} toggle={toggle}>
			{children}
		</Tooltip>
	);
};

export default TooltipItem;
