import React, { useEffect, useState } from 'react';
import s from '../dashboard/Dashboard.module.scss';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'; // useDispatch()
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { numberFilter } from '../../libs/react-bootstrap-table2/packages/react-bootstrap-table2-filter';
import { fetchOptimizationData } from '../../actions/backtest';
import { floatToCurrencyFormat, floatToNumberFormat, customSortCaret, NoDataIndication } from '../../components/utils';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import Moment from 'react-moment';

// To use redux hooks, you export a function component, not a react component.
export const Optimization = (props) => {
	/**
	 * Call the state variables and dispatch into
	 * existance.
	 */
	const optimization = useSelector((state) => state.backtest.data.optimization);
	const dispatch = useDispatch();

	/**
	 * Local state for the tabs
	 */
	const [activeTab, setActiveTab] = useState('1');

	/**
	 * Similar to componentDidMount and componentDidUpdate:
	 * The optional second param ([]) in useEffect tells it to only
	 * run if whatever data in that array has changed. If left blank (just []),
	 * it will only run once.
	 */
	useEffect(() => {
		dispatch(fetchOptimizationData(props.match.params.id));
	}, [dispatch, props.match.params.id]);

	/**
	 * These are the table headers which will be used by
	 * reactTable
	 */
	const columns = [
		{
			text: 'Total Trades',
			dataField: 'totalTrades',
			formatter: (cell) => floatToNumberFormat(cell),
			sort: true,
			headerStyle: { outline: 0 },
			sortCaret: customSortCaret,
		},
		{
			text: 'Net Profit',
			dataField: 'totalProfit',
			formatter: (cell) => {
				return <p className={cell > 0 ? 'm-0 fw-semi-bold text-success' : 'm-0 fw-semi-bold text-danger'}>{floatToCurrencyFormat(cell)}</p>;
			},
			sort: true,
			sortCaret: customSortCaret,
			headerStyle: { outline: 0 },
			filter: numberFilter(),
		},
		{
			text: 'Gross Profit',
			dataField: 'grossProfit',
			formatter: (cell) => {
				return <p className={cell > 0 ? 'm-0 fw-semi-bold text-success' : 'm-0 fw-semi-bold text-danger'}>{floatToCurrencyFormat(cell)}</p>;
			},
			sort: true,
			sortCaret: customSortCaret,
			headerStyle: { outline: 0 },
			filter: numberFilter(),
		},
		{
			text: 'Gross Loss',
			dataField: 'grossLoss',
			formatter: (cell) => {
				return <p className="m-0 fw-semi-bold text-danger">{floatToCurrencyFormat(cell)}</p>;
			},
			sort: true,
			sortCaret: customSortCaret,
			headerStyle: { outline: 0 },
			filter: numberFilter(),
		},
		{
			text: 'Win/Loss',
			dataField: 'winCount',
			formatter: (cell, row) => {
				return (
					<small>
						<span className="m-0 text-secondary">{row.winCount}</span> / <span className="m-0 text-secondary">{row.lossCount}</span> ({row.winPercent}
						)%
					</small>
				);
			},
			sort: true,
			sortCaret: customSortCaret,
			headerStyle: { outline: 0 },
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				if (order === 'asc') {
					return rowA.winPercent >= rowB.winPercent ? 1 : -1;
				} else {
					return rowA.winPercent >= rowB.winPercent ? -1 : 1;
				}
			},
		},
		{
			text: 'Max DD',
			dataField: 'maxOpenDrawdown',
			formatter: (cell) => {
				return <p className="m-0 fw-semi-bold text-danger">-{floatToCurrencyFormat(cell)}</p>;
			},
			sort: true,
			sortCaret: customSortCaret,
			headerStyle: { outline: 0 },
			filter: numberFilter(),
		},
		{
			text: 'Comm.',
			dataField: 'totalComission',
			formatter: (cell) => {
				return <p className="m-0 fw-semi-bold text-danger">-{floatToCurrencyFormat(cell)}</p>;
			},
			sort: true,
			sortCaret: customSortCaret,
			headerStyle: { outline: 0 },
		},
		{
			text: 'Profit Factor',
			dataField: 'profitFactor',
			formatter: (cell) => floatToNumberFormat(cell),
			sort: true,
			sortCaret: customSortCaret,
			headerStyle: { outline: 0 },
			filter: numberFilter(),
		},
		{
			text: 'Max Units',
			dataField: 'maxUnits',
			formatter: (cell) => floatToNumberFormat(cell),
			sort: true,
			sortCaret: customSortCaret,
			headerStyle: { outline: 0 },
			filter: numberFilter(),
		},
		{
			text: 'ID',
			dataField: 'insertID',
			formatter: (cell) => {
				return (
					<small>
						<Link to={`/browse/backtests/${cell}`}>{cell.substring(0, 7) + '...'}</Link>
					</small>
				);
			},
		},
	];

	/**
	 * Our expanded row
	 */
	const expandRow = {
		renderer: (row) => {
			return (
				<table>
					<tbody>
						{Object.keys(row.input).map((key) => {
							return (
								<tr key={key}>
									<td>{key}</td>
									<td>{row.input[key]}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			);
		},
		onlyOneExpanding: false,
		showExpandColumn: true,
	};

	/**
	 * Default sorted
	 */
	const defaultSorted = [
		{
			dataField: 'totalProfit',
			order: 'desc',
		},
	];

	/**
	 * This is the general data tab which will
	 * collect certain stat data for us and display it.
	 */
	const collectGeneralData = () => {
		if (optimization == null || optimization.backtests == null) return '';

		/**
		 * Get our data.
		 */
		let backtests = optimization.backtests.slice(); // Make a clone

		/**
		 * Get the top 100 results based on net profit which is
		 * totalProfit in the database, go figure...
		 */
		backtests.sort((a, b) => (a.totalProfit > b.totalProfit ? -1 : 1));

		/**
		 * Get the top 50 results
		 */
		backtests = backtests.slice(0, 50);

		/**
		 * Now we are looking for inputs.
		 */
		let keyResults = [];
		for (let i = 0; i < backtests.length; i++) {
			Object.keys(backtests[i].input).map((key) => {
				if (keyResults[key] == null) keyResults[key] = [];

				let inputIndex = keyResults[key].findIndex((x) => x.key === backtests[i].input[key]);
				if (inputIndex > -1) keyResults[key][inputIndex].count++;
				else keyResults[key].push({ count: 1, key: backtests[i].input[key] });

				return ''; // Without this return and a value, it throws an error in react
			});
		}

		return Object.keys(keyResults).map((key) => {
			let sortedKey = keyResults[key].sort((a, b) => (a.count > b.count ? -1 : 1));
			let valueTable = Object.keys(sortedKey).map((values) => {
				return (
					<Row key={`generalData_${key}${Math.random().toString(36).substr(2, 7)}`}>
						<Col xs={2}>{keyResults[key][values].key}</Col>
						<Col xs={2}>{keyResults[key][values].count}</Col>
					</Row>
				);
			});

			return (
				<Row style={{ margin: '5px' }} key={`generalDataRow_${key}${Math.random().toString(36).substr(2, 7)}`}>
					<Col xs={2} key={key}>
						{key}
					</Col>
					<Col xs={4}>
						<Row>
							<Col xs={4}>
								<hr />
							</Col>
						</Row>
						{valueTable}
					</Col>
				</Row>
			);
		});
	};

	/**
	 * Create the table
	 *
	 * @param {*} param0
	 */
	const RTable = ({ data, page, sizePerPage, onTableChange }) => (
		<div>
			<BootstrapTable
				keyField="insertID"
				data={data}
				columns={columns}
				defaultSorted={defaultSorted}
				filter={filterFactory()}
				pagination={paginationFactory({ sizePerPage: 100 })}
				noDataIndication={() => <NoDataIndication />}
				striped
				borderless
				classes="text-white table-sm"
				filterPosition="top"
				expandRow={expandRow}
			/>
		</div>
	);

	/**
	 * Get a tab header item
	 */
	const tabHeaderText = () => {
		if (optimization) {
			return (
				<div style={{ paddingBottom: '15px' }}>
					<div>
						<span className="m-0 fw-semi-bold">{optimization.backtests[0].strategy}</span> optimization on {optimization.backtests[0].symbol} (
						{optimization.backtests[0].granularity}) from <Moment format="YYYY-MM-DD">{optimization.cliOptions.inputBeginDate}</Moment> to{' '}
						<Moment format="YYYY-MM-DD">{optimization.cliOptions.inputEndDate}</Moment>
					</div>
					<div>Type: {optimization.cliOptions.inputGenetic ? 'Genetic' : 'Exhaustive'}</div>
				</div>
			);
		} else {
			return '';
		}
	};

	/**
	 * Only render if we actually have data worth showing.
	 */
	return (
		<div className={s.root}>
			<Row>
				<Col xs="12" className="mb-5">
					<Nav tabs>
						<NavItem className={s.customNavItem}>
							<NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
								<span>Tests</span>
							</NavLink>
						</NavItem>
						<NavItem className={s.customNavItem}>
							<NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
								<span>Inputs</span>
							</NavLink>
						</NavItem>
					</Nav>

					<TabContent className="mb-lg" activeTab={activeTab}>
						<TabPane tabId="1">
							<Row>
								<Col sm={6}>
									<div style={{ paddingBottom: '15px' }}>{tabHeaderText()}</div>
								</Col>
							</Row>
							<RTable columns={columns} data={optimization != null ? optimization.backtests : []} />
						</TabPane>
						<TabPane tabId="2">{collectGeneralData()}</TabPane>
					</TabContent>
				</Col>
			</Row>
		</div>
	);
};
