import { Table, Badge, Row, Col } from 'reactstrap';
import React from 'react';
import { floatToCurrencyFormat, floatToNumberFormat } from '../../../components/utils';

// To use redux hooks, you export a function component, not a react component.
export const Summary = (props) => {
	/**
	 * Call the state variables and dispatch into
	 * existance.
	 */
	const backtest = props.backtestData || null;

	/**
	 * Do nothing if null
	 */
	if (backtest == null) return '';

	return (
		<>
			<Row>
				<Col sm={6}>
					<legend style={{ borderBottom: '1px solid #040620', paddingBottom: '15px' }}>
						{`${backtest.strategy} backtest of ${backtest.symbol} : ${backtest.granularity}`}
					</legend>
				</Col>
			</Row>
			<Table className="mb-0 table-striped table-hover text-white" borderless responsive>
				<thead>
					<tr className="text-white">
						<th scope="col"></th>
						<th scope="col">All Trades</th>
						<th scope="col">Long Trades</th>
						<th scope="col">Short Trades</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>ID</td>
						<td>{props.match.params.id}</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Start to End Date</td>
						<td>{backtest.cliOptions.inputBeginDate}</td>
						<td>{backtest.cliOptions.inputEndDate}</td>
						<td></td>
					</tr>
					<tr>
						<td>Start to End Capital</td>
						<td>
							<p className="h6 m-0 fw-semi-bold text-secondary">{floatToCurrencyFormat(backtest.cliOptions.inputCapital)}</p>
						</td>
						<td>
							<p
								className={
									backtest.capital > backtest.cliOptions.inputCapital ? 'h6 m-0 fw-semi-bold text-success' : 'h6 m-0 fw-semi-bold text-danger'
								}
							>
								{floatToCurrencyFormat(backtest.capital)}
							</p>
						</td>
						<td></td>
					</tr>
					<tr>
						<td>Net Profit</td>
						<td>
							<p className={backtest.summary.totalProfit > 0 ? 'm-0 fw-semi-bold text-success' : 'm-0 fw-semi-bold text-danger'}>
								{floatToCurrencyFormat(backtest.summary.totalProfit)}
							</p>
						</td>
						<td>
							<p className={backtest.summary.netLongProfit > 0 ? 'm-0 fw-semi-bold text-success' : 'm-0 fw-semi-bold text-danger'}>
								{floatToCurrencyFormat(backtest.summary.netLongProfit)}
							</p>
						</td>
						<td>
							<p className={backtest.summary.netShortProfit > 0 ? 'm-0 fw-semi-bold text-success' : 'm-0 fw-semi-bold text-danger'}>
								{floatToCurrencyFormat(backtest.summary.netShortProfit)}
							</p>
						</td>
					</tr>
					<tr>
						<td>Gross Profit</td>
						<td>
							<p className={backtest.summary.grossProfit > 0 ? 'm-0 fw-semi-bold text-success' : 'm-0 fw-semi-bold text-danger'}>
								{floatToCurrencyFormat(backtest.summary.grossProfit)}
							</p>
						</td>
						<td>
							<p className={backtest.summary.grossLongProfit > 0 ? 'h6 m-0 fw-semi-bold text-success' : 'h6 m-0 fw-semi-bold text-danger'}>
								{floatToCurrencyFormat(backtest.summary.grossLongProfit)}
							</p>
						</td>
						<td>
							<p className={backtest.summary.grossShortProfit > 0 ? 'h6 m-0 fw-semi-bold text-success' : 'h6 m-0 fw-semi-bold text-danger'}>
								{floatToCurrencyFormat(backtest.summary.grossShortProfit)}
							</p>
						</td>
					</tr>
					<tr>
						<td>Gross Loss</td>
						<td>
							<p className="h6 m-0 fw-semi-bold text-danger">{floatToCurrencyFormat(backtest.summary.grossLoss)}</p>
						</td>
						<td>
							<p className="h6 m-0 fw-semi-bold text-danger">{floatToCurrencyFormat(backtest.summary.grossLongLoss)}</p>
						</td>
						<td>
							<p className="h6 m-0 fw-semi-bold text-danger">{floatToCurrencyFormat(backtest.summary.grossShortLoss)}</p>
						</td>
					</tr>
					<tr>
						<td>Total Trades</td>
						<td>{floatToNumberFormat(backtest.summary.totalTrades)}</td>
						<td>{floatToNumberFormat(backtest.summary.totalLongTrades)}</td>
						<td>{floatToNumberFormat(backtest.summary.totalShortTrades)}</td>
					</tr>
					<tr>
						<td>Total Pips</td>
						<td>{floatToNumberFormat(backtest.summary.totalPips)}</td>
						<td>{floatToNumberFormat(backtest.summary.totalPipsLong)}</td>
						<td>{floatToNumberFormat(backtest.summary.totalPipsShort)}</td>
					</tr>
					<tr>
						<td>Win Count</td>
						<td>{floatToNumberFormat(backtest.summary.winCount)}</td>
						<td>{floatToNumberFormat(backtest.summary.winCountLong)}</td>
						<td>{floatToNumberFormat(backtest.summary.winCountShort)}</td>
					</tr>
					<tr>
						<td>Loss Count</td>
						<td>{floatToNumberFormat(backtest.summary.lossCount)}</td>
						<td>{floatToNumberFormat(backtest.summary.lossCountLong)}</td>
						<td>{floatToNumberFormat(backtest.summary.lossCountShort)}</td>
					</tr>
					<tr>
						<td>Win %</td>
						<td>{floatToNumberFormat(backtest.summary.winPercent)}%</td>
						<td>{floatToNumberFormat(backtest.summary.winPercentLong)}%</td>
						<td>{floatToNumberFormat(backtest.summary.winPercentShort)}%</td>
					</tr>
					<tr>
						<td>Comission & Slippage</td>
						<td>
							<p className="m-0 fw-semi-bold text-danger">-{floatToCurrencyFormat(backtest.summary.totalComission)}</p>
						</td>
						<td>
							<p className="m-0 fw-semi-bold text-danger">-{floatToCurrencyFormat(backtest.summary.totalComissionLong)}</p>
						</td>
						<td>
							<p className="m-0 fw-semi-bold text-danger">-{floatToCurrencyFormat(backtest.summary.totalComissionShort)}</p>
						</td>
					</tr>
					<tr>
						<td>Max Open Drawdown</td>
						<td>
							<p className="m-0 fw-semi-bold text-danger">-{floatToCurrencyFormat(backtest.summary.maxOpenDrawdown)}</p>
						</td>
						<td>
							<p className="m-0 fw-semi-bold text-danger">-{floatToCurrencyFormat(backtest.summary.maxOpenDrawdownLong)}</p>
						</td>
						<td>
							<p className="m-0 fw-semi-bold text-danger">-{floatToCurrencyFormat(backtest.summary.maxOpenDrawdownShort)}</p>
						</td>
					</tr>
					<tr>
						<td>Profit Factor</td>
						<td>
							<Badge color={backtest.summary.profitFactor >= 1 ? 'primary' : 'warning'} className="p-2">
								{floatToNumberFormat(backtest.summary.profitFactor)}
							</Badge>
						</td>
						<td>
							<Badge color={backtest.summary.profitFactor >= 1 ? 'primary' : 'warning'} className="p-2">
								{floatToNumberFormat(backtest.summary.profitFactorLong)}
							</Badge>
						</td>
						<td>
							<Badge color={backtest.summary.profitFactor >= 1 ? 'primary' : 'warning'} className="p-2">
								{floatToNumberFormat(backtest.summary.profitFactorShort)}
							</Badge>
						</td>
					</tr>
					<tr>
						<td>Return on Initial Capital / on Account</td>
						<td>
							<p className="m-0 fw-semi-bold">{backtest.summary.returnOnInitialCapital}%</p>
						</td>
						<td>
							<p className="m-0 fw-semi-bold">{backtest.summary.returnOnAccount}%</p>
						</td>
						<td></td>
					</tr>
					<tr>
						<td>Account Size Required / Max Units</td>
						<td>
							<p className="m-0 fw-semi-bold">{floatToCurrencyFormat(backtest.summary.accountSizeRequired)}</p>
						</td>
						<td>
							<p className="m-0 fw-semi-bold">{floatToNumberFormat(backtest.summary.maxUnits)}</p>
						</td>
						<td></td>
					</tr>
				</tbody>
			</Table>
		</>
	);
};
