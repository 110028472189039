// import './wdyr'; // <--- first import

import React from 'react';
import ReactDOM from 'react-dom';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';

import App from './components/App';
import config from './config';
import createRootReducer from './reducers';
//import { doInit } from './actions/auth';
import { createBrowserHistory } from 'history';
//import { persistStore } from 'redux-persist'; // persistReducer
// import { composeWithDevTools } from 'remote-redux-devtools';

// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

/**
 * This is removed because it was deleting the default
 * state in the reducers. Specifically, the history section
 * of the hearbeat.js reducer.
 */
// import { PersistGate } from 'redux-persist/integration/react';

// const persistConfig = {
// 	key: 'root',
// 	storage,
// 	blacklist: ['oanda', 'backtest'],
// };

// This is NOT hooked up...
// const oandaPersistConfig = {
// 	key: 'oanda',
// 	storage: storage,
// 	blacklist: ['chartCandles'],
// };

export const history = createBrowserHistory({ forceRefresh: true });

export function getHistory() {
	return history;
}

axios.defaults.baseURL = config.baseURLApi;
axios.defaults.withCredentials = true;
// axios.defaults.headers.common['Content-Type'] = 'application/json';
// const token = localStorage.getItem('token');
// if (token) {
// 	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
// }

// const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

/**
 * This works with Chrome and the plugins.
 */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * This one is for the remote dev.
 *
 * Uncomment composeWithDevTools above
 */
// const composeEnhancers =
// 	composeWithDevTools({
// 		realtime: true,
// 		name: 'Adenom',
// 		hostname: 'localhost',
// 		port: 8000, // the port your remotedev server is running at
// 	}) || compose;

export const store = createStore(createRootReducer(history), composeEnhancers(applyMiddleware(routerMiddleware(history), ReduxThunk)));
// export const store = createStore(persistedReducer, compose(applyMiddleware(routerMiddleware(history), ReduxThunk)));
// export const persistor = persistStore(store);

/**
 * This opens the store so we can now do things like
 * store.getState in the console. Use the tampermonkey script
 * that you wrote for this.
 *
 */
if (process.env.NODE_ENV !== 'production') window.store = store;

ReactDOM.render(
	<Provider store={store}>
		{/* <PersistGate loading={null} persistor={persistor}> */}
		<App />
		{/* </PersistGate> */}
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
