import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ApexChart from 'react-apexcharts';
import Widget from '../../../../components/Widget/Widget';
import { rawFloatToCurrencyFormat, objectEqual } from '../../../../components/utils';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { chartColors } from '../../../../components/StockChart/config/genericConfig';
import Loader from '../../../../components/Loader/Loader';
import { timeFrameMap } from '../../oandaData';

// To use redux hooks, you export a function component, not a react component.
export const InstrumentBreakDown = () => {
	/**
	 * We use an arrow function whitch passes state to a
	 * callback function that returns the state we need.
	 */
	const profit = useSelector((state) => {
		if (state.oanda.accountID != null && state.oanda.profit[state.oanda.accountID] != null) return state.oanda.profit[state.oanda.accountID];
	}, objectEqual);

	/**
	 * Local state for changing what we show
	 */
	const [activeTimePeriod, setActiveTimePeriod] = useState('month');

	/**
	 * If undefined, return nothing.
	 */
	if (profit == null || profit[activeTimePeriod] == null || profit[activeTimePeriod].instruments == null)
		return (
			<Widget title={<h6> Instrument Numbers </h6>}>
				<Loader />
			</Widget>
		);

	/**
	 * Loop through our array above and return a clickable dropdown
	 */
	const renderDropDownClick = () => {
		let dropDownItems = [];
		for (const period in timeFrameMap)
			dropDownItems.push(
				<DropdownItem key={period} onClick={() => setActiveTimePeriod(period)}>
					{timeFrameMap[period]}
				</DropdownItem>
			);

		return dropDownItems;
	};

	/**
	 * Category holder
	 */
	let categories = [];

	/**
	 * Create our object
	 */
	let seriesData = [
		{
			name: 'Closed Trades',
			type: 'column',
			data: [],
		},
		{
			name: 'Open Trades',
			type: 'column',
			data: [],
		},
		{
			name: 'Net Profit',
			type: 'line',
			data: [],
		},
		{
			name: 'Gross Profit',
			type: 'line',
			data: [],
		},
	];

	/**
	 * Build our categories which is our instrument
	 * list/name. We use the profit instruments here instead of
	 * the full instrument list because some accounts/bots only
	 * trade some instruments instead of all.
	 */
	if (Object.keys(profit[activeTimePeriod].instruments).length > 0) {
		/**
		 * Get a sorted list
		 */
		const sortedInstruments = {};
		Object.keys(profit[activeTimePeriod].instruments)
			.sort()
			.forEach(function (key) {
				sortedInstruments[key] = profit[activeTimePeriod].instruments[key];
			});

		/**
		 * Loop through it
		 */
		for (const [instrumentKey, currentInstrument] of Object.entries(sortedInstruments)) {
			/**
			 * Push the key as the category which is
			 * the instrument.
			 */
			categories.push(instrumentKey);

			/**
			 * Push the other data points while we are
			 * here.
			 */
			seriesData[0].data.push(currentInstrument.closedTradeCount);
			seriesData[1].data.push(currentInstrument.tradeCount);
			seriesData[2].data.push(parseFloat(currentInstrument.net.toFixed(2)));
			seriesData[3].data.push(parseFloat(currentInstrument.gross.toFixed(2)));
		}
	} else categories.push('NA');

	/**
	 * This is our options data for the multi chart
	 */
	let chartOptions = {
		dataLabels: {
			enabled: false,
		},
		colors: [chartColors.blue, chartColors.green, chartColors.red, chartColors.faded],
		stroke: {
			curve: 'smooth',
			width: [1, 1, 4],
		},
		chart: {
			animations: {
				enabled: false,
			},
		},
		// markers: {
		// 	size: 0,
		// 	strokeColor: '#fff',
		// 	strokeWidth: 3,
		// 	strokeOpacity: 1,
		// 	fillOpacity: 1,
		// 	hover: {
		// 		size: 6,
		// 	},
		// },
		title: {
			text: '',
			align: 'left',
			offsetX: 110,
			style: {
				color: chartColors.textColor,
			},
		},
		xaxis: {
			categories: categories,
			labels: {
				style: {
					colors: chartColors.textColor,
				},
			},
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
		},
		yaxis: [
			{
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: chartColors.blue,
				},
				labels: {
					style: {
						color: chartColors.blue,
					},
				},
				title: {
					text: 'Closed Trades',
					style: {
						color: chartColors.blue,
					},
				},
				tooltip: {
					enabled: true,
				},
			},
			{
				seriesName: 'Open Trades',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: chartColors.green,
				},
				labels: {
					style: {
						color: chartColors.green,
					},
				},
				title: {
					text: 'Open Trades',
					style: {
						color: chartColors.green,
					},
				},
			},
			{
				seriesName: 'Net Profit',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: chartColors.orange,
				},
				labels: {
					style: {
						color: chartColors.orange,
					},
					formatter: (value) => rawFloatToCurrencyFormat(value),
				},
				title: {
					text: 'Net Profit',
					style: {
						color: chartColors.orange,
					},
				},
			},
			{
				seriesName: 'Gross Profit',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: chartColors.faded,
				},
				labels: {
					style: {
						color: chartColors.faded,
					},
					formatter: (value) => rawFloatToCurrencyFormat(value),
				},
				title: {
					text: 'Gross Profit',
					style: {
						color: chartColors.faded,
					},
				},
			},
		],
		tooltip: {
			theme: 'dark',
			followCursor: true,
			fixed: {
				enabled: false,
				position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
				offsetY: 30,
				offsetX: 60,
			},
		},
		legend: {
			horizontalAlign: 'left',
			offsetX: 40,
			labels: {
				colors: [chartColors.textColor],
			},
		},
		grid: {
			borderColor: chartColors.gridLineColor,
		},
	};

	return (
		<Widget
			title={
				<div className="d-flex justify-content-between flex-wrap">
					<h4 className="d-flex align-items-center pb-1 bigStatTitle">
						Instrument <span className="fw-normal ml-xs">Numbers</span>
					</h4>
					<UncontrolledButtonDropdown>
						<DropdownToggle caret color="subtle-blue" className="dropdown-toggle-split btn-sm">
							{timeFrameMap[activeTimePeriod]}&nbsp;&nbsp;
						</DropdownToggle>
						<DropdownMenu>{renderDropDownClick()}</DropdownMenu>
					</UncontrolledButtonDropdown>
				</div>
			}
		>
			<ApexChart className="sparkline-chart" type={'line'} height={350} series={seriesData} options={chartOptions} />
		</Widget>
	);
};
