import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // useDispatch()
import { Progress, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Moment from 'react-moment';
import Loader from '../../../../components/Loader';
import CurrencyFormat from 'react-currency-format';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { objectEqual } from '../../../../components/utils';
import { changeModalAccountSearchOpen } from '../../../../actions/navigation';
import { timeFrameMap } from '../../oandaData';

export const DailyEarnings = (props) => {
	/**
	 * Call the state variables and dispatch into
	 * existance.
	 */
	const accountID = useSelector((state) => state.oanda.accountID);
	const accountList = useSelector((state) => state.oanda.accountList);
	const accountType = useSelector((state) => state.oanda.accountType);
	const balance = useSelector((state) => state.oanda.balance);
	const profit = useSelector((state) => {
		if (state.oanda.accountID != null && state.oanda.profit != null) return state.oanda.profit;
	}, objectEqual);

	// const profit = useSelector((state) => {
	// 	if (state.oanda.accountID != null && state.oanda.profit[state.oanda.accountID] != null) return state.oanda.profit[state.oanda.accountID];
	// }, objectEqual);
	// const liveProfit = useSelector((state) => {
	// 	if (state.oanda.profit['live'] != null) return state.oanda.profit['live'];
	// }, objectEqual);
	// const demoProfit = useSelector((state) => {
	// 	if (state.oanda.profit['demo'] != null) return state.oanda.profit['demo'];
	// }, objectEqual);

	const actBalance = useSelector((state) => {
		if (state.oanda.accountID != null && state.oanda.accountDetails[state.oanda.accountID] != null)
			return state.oanda.accountDetails[state.oanda.accountID].balance;
	}, objectEqual);
	const actUnrealizedPL = useSelector((state) => {
		if (state.oanda.accountID != null && state.oanda.accountDetails[state.oanda.accountID] != null)
			return state.oanda.accountDetails[state.oanda.accountID].unrealizedPL;
	}, objectEqual);

	/**
	 * The Alias is now part of the profit stats data.
	 */
	// const actAlias = useSelector((state) => {
	// 	if (state.oanda.accountID != null && state.oanda.accountDetails[state.oanda.accountID] != null)
	// 		return state.oanda.accountDetails[state.oanda.accountID].alias;
	// }, objectEqual);
	const actOpenPositionCount = useSelector((state) => {
		if (state.oanda.accountID != null && state.oanda.accountDetails[state.oanda.accountID] != null)
			return state.oanda.accountDetails[state.oanda.accountID].openPositionCount;
	}, objectEqual);

	const dispatch = useDispatch();

	/**
	 * Get our specific profits
	 */
	const currentProfit = profit?.[accountID];
	const liveProfit = profit?.['live'];
	const demoProfit = profit?.['demo'];

	/**
	 * Set the current account alias
	 */
	const actAlias = profit?.[accountID]?.alias;

	/**
	 * Local state for the tabs
	 */
	const [activeTab, setActiveTab] = useState('1');
	const [useNet, setUseNet] = useState(false);
	const [activeTimePeriod, setActiveTimePeriod] = useState('today');

	/**
	 * This will render the profit progress bar.
	 *
	 * @param {*} profitData
	 * @param {*} balance
	 * @param {str} label
	 * @param {bool} noZero - set to true hids zero profit data.
	 */
	const renderProgressStat = (profitData, balance, label = '', noZero = true) => {
		if (!profitData || profitData == null) return;

		/**
		 * Set our goal per day
		 */
		const goal = 150;

		// If profit data is blank, render a blank stat line
		if (!profitData.toDate) return renderBlankProgressStat(label);

		/**
		 * Get the value the user has selected
		 * (either net or gross)
		 */
		const profitValue = useNet ? profitData.net : profitData.gross;

		/**
		 * Make sure we have a value
		 */
		if (profitValue === 0 && label !== 'Today' && noZero === true) return;

		// Use the weekly balance first, but if it is zero then use the monthly.
		const startBalance = getStartingBalance(balance, accountID);

		// Do some basic calcs and updates
		if (!label) label = <Moment format="YYYY.MM.DD">{profitData.toDate}</Moment>;
		let percent = profitValue === 0 ? 0 : parseFloat((profitValue / startBalance) * 100).toFixed(2);
		let percentToGoal = profitValue === 0 ? 0 : parseFloat((profitValue / goal) * 100);

		// Get rid of NAN
		if (isNaN(percent)) percent = 0.0;
		if (isNaN(percentToGoal)) percentToGoal = 0.0;

		// For the week, last week and higher time frames, we need to reduce the %
		// let progressPercentValue = percent * 100;
		let progressPercentValue = percentToGoal;

		if (label === 'Week' || label === 'Last Week') progressPercentValue /= 7;
		else if (label === 'Month' || label === 'Last Month' || label === 'Last Mon.') progressPercentValue /= 31;
		else if (label === 'Year') progressPercentValue /= 250;

		return (
			<div className="row progress-stats" key={Math.random().toString(36).substr(2, 7)}>
				<div className="col-md-3 col-12">
					<p className="description deemphasize mb-xs text-white">{label}</p>
				</div>
				<div className="col-md-6 col-12">
					<p className="description deemphasize mb-xs text-white">
						<CurrencyFormat value={profitValue} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} />
					</p>
					{/* color={percent > 0.7 ? 'success' : percent > 0.3 ? 'primary' : percent < 0 ? 'danger' : 'info'} */}
					<Progress
						color={progressPercentValue > 99 ? 'success' : progressPercentValue > 30 ? 'info' : progressPercentValue < 0 ? 'danger' : 'info'}
						value={progressPercentValue < 0 ? progressPercentValue * -1 : progressPercentValue}
						className={progressPercentValue < 0 ? 'bg-custom-dark progress-xs justify-content-end' : 'bg-custom-dark progress-xs'}
					/>
				</div>
				<div className="col-md-3 col-12 text-center">
					<span className="status">
						<small>{percent}% </small>
					</span>
				</div>
			</div>
		);
	};

	/**
	 * Loop through our array above and return a clickable dropdown
	 */
	const renderDropDownClick = () => {
		let dropDownItems = [];
		for (const period in timeFrameMap)
			dropDownItems.push(
				<DropdownItem key={period} onClick={() => setActiveTimePeriod(period)}>
					{timeFrameMap[period]}
				</DropdownItem>
			);

		return dropDownItems;
	};

	const getStartingBalance = () => {
		if (balance === undefined || balance[accountID] === undefined) return 10000;
		if (balance[accountID].weekStart !== undefined && balance[accountID].weekStart > 0) return balance[accountID].weekStart;
		if (balance[accountID].lastWeekStart !== undefined && balance[accountID].lastWeekStart > 0) return balance[accountID].lastWeekStart;
		if (balance[accountID].monthStart !== undefined && balance[accountID].monthStart > 0) return balance[accountID].monthStart;
		if (balance[accountID].lastMonthStart !== undefined && balance[accountID].lastMonthStart > 0) return balance[accountID].lastMonthStart;
		if (balance[accountID].quarterStart !== undefined && balance[accountID].quarterStart > 0) return balance[accountID].quarterStart;
		if (balance[accountID].yearStart !== undefined && balance[accountID].yearStart > 0) return balance[accountID].monthStart;
		return 10000;
	};

	const renderBlankProgressStat = (label = '') => {
		return (
			<div className="row progress-stats">
				<div className="col-md-3 col-12">
					<p className="description deemphasize mb-xs text-white">{label}</p>
				</div>
				<div className="col-md-6 col-12">
					<span className="description deemphasize mb-xs text-white">
						<Loader className="widgetLoader" size={10} />
					</span>
					<Progress color="primary" value="30" className="bg-custom-dark progress-xs" />
				</div>
				<div className="col-md-3 col-12 text-center">
					<span className="status">
						<Loader className="widgetLoader" size={10} />
					</span>
				</div>
			</div>
		);
	};

	const renderCurrentAccountDetails = () => {
		if (!accountID || !actUnrealizedPL || !profit || profit == null || profit['live'] == null) return <Loader size={20} />;

		// Use the weekly balance first, but if it is zero then use the monthly.
		const startBalance = getStartingBalance(balance, accountID);

		// Get the percentage value
		let percent = actUnrealizedPL === 0 ? 0 : parseFloat((actUnrealizedPL / startBalance) * 100).toFixed(2);
		const classColor = actUnrealizedPL < 0 ? 'text-danger' : 'text-primary';
		// const classColor = 'heavy';

		// Get rid of NAN
		if (isNaN(percent)) percent = 0.0;

		return (
			<div className="daily-stats">
				<div className="row mt-3">
					<div className="col-md-9 col-12">
						<p className="account-earnings">
							{actAlias}:{' '}
							<span className={classColor}>
								<CurrencyFormat value={actUnrealizedPL} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} />
							</span>{' '}
							<small className={classColor}>({percent}%)</small> in {actOpenPositionCount} positions
						</p>
					</div>
					<div className="col-md-3 col-12 text-center">
						<button
							type="button"
							className="btn ml-2 btn-outline-inverse text-white"
							onClick={() => setUseNet(!useNet)}
							style={{ paddingTop: '1px', paddingBottom: '1px', marginRight: '4px' }}
						>
							{useNet ? 'N' : 'G'}
						</button>
					</div>
				</div>
			</div>
		);
	};

	/**
	 * This will go through all of the accounts and render the
	 * profit for all accounts for "today"
	 */
	const renderTodayProfits = () => {
		if (!profit || profit == null || profit['live'] == null) return <Loader />;

		/**
		 * JSX Holder
		 */
		let todayArray = [];

		/**
		 * Push the header first
		 */
		todayArray.push(
			<div className="row mb-3" key={Math.random().toString(36).substr(2, 7)}>
				<div className="col-md-2 col-12">
					<button
						type="button"
						className="btn ml-2 btn-outline-inverse text-white"
						onClick={() => setUseNet(!useNet)}
						style={{ paddingTop: '1px', paddingBottom: '1px', marginRight: '4px' }}
					>
						{useNet ? 'N' : 'G'}
					</button>
				</div>
				<div className="col-md-6 col-12">
					<h5 key={Math.random().toString(36).substr(2, 7)} className="dailyEarningsToday">
						Live
					</h5>
				</div>
				<div className="col-md-4 col-12 text-center" key={Math.random().toString(36).substr(2, 7)}>
					<UncontrolledButtonDropdown key={Math.random().toString(36).substr(2, 7)}>
						<DropdownToggle caret color="btn-outline-inverse" className="dropdown-toggle-split btn-sm text-white">
							{timeFrameMap[activeTimePeriod]}&nbsp;&nbsp;
						</DropdownToggle>
						<DropdownMenu>{renderDropDownClick()}</DropdownMenu>
					</UncontrolledButtonDropdown>
				</div>
			</div>
		);

		/**
		 * Lets divide this up into sections
		 *
		 * Custom map on an object!
		 */
		const live = Object.keys(profit)
			.filter((key) => profit[key].demo === false)
			.sort()
			.map((key) => profit[key]);
		const demo = Object.keys(profit)
			.filter((key) => profit[key].demo === true)
			.sort()
			.map((key) => profit[key]);

		/**
		 * Loop through each item
		 */
		for (const item of live) todayArray.push(renderProgressStat(item[activeTimePeriod], balance, item.alias, false));

		/**
		 * Push another header
		 */
		todayArray.push(
			<h5 key={Math.random().toString(36).substr(2, 7)} className="dailyEarningsToday">
				Demo
			</h5>
		);

		/**
		 * Loop through each item
		 */
		for (const item of demo) todayArray.push(renderProgressStat(item[activeTimePeriod], balance, item.alias, false));

		/**
		 * Push another header
		 */
		todayArray.push(
			<h5 key={Math.random().toString(36).substr(2, 7)} className="dailyEarningsToday">
				Totals
			</h5>
		);
		todayArray.push(renderProgressStat(profit['live'][activeTimePeriod], balance, 'Live', false));
		todayArray.push(renderProgressStat(profit['demo'][activeTimePeriod], balance, 'Demo', false));

		/**
		 * Return it.
		 */
		return todayArray;
	};

	/**
	 * This will render the list of profits. Made into a function
	 * so we can call it multiple times.
	 *
	 */
	const renderProfits = (profitField) => {
		/**
		 * Return it
		 */
		return (
			<React.Fragment>
				{profitField && profitField.today ? renderProgressStat(profitField.today, balance, 'Today') : ''}
				{profitField && profitField.yesterday ? renderProgressStat(profitField.yesterday, balance, 'Yesterday') : ''}
				{profitField && profitField.d2 ? renderProgressStat(profitField.d2, balance, '') : ''}
				{profitField && profitField.d3 ? renderProgressStat(profitField.d3, balance, '') : ''}
				{profitField && profitField.d4 ? renderProgressStat(profitField.d4, balance, '') : ''}
				{profitField && profitField.d5 ? renderProgressStat(profitField.d5, balance, '') : ''}
				{profitField && profitField.d6 ? renderProgressStat(profitField.d6, balance, '') : ''}
				{profitField && profitField.d7 ? renderProgressStat(profitField.d7, balance, '') : ''}
				{profitField && profitField.d8 ? renderProgressStat(profitField.d8, balance, '') : ''}
				{profitField && profitField.week ? renderProgressStat(profitField.week, balance, 'Week') : ''}
				{profitField && profitField.w1 ? renderProgressStat(profitField.w1, balance, 'Last Week') : ''}
				{profitField && profitField.month ? renderProgressStat(profitField.month, balance, 'Month') : ''}
				{profitField && profitField.m1 ? renderProgressStat(profitField.m1, balance, 'Last Mon') : ''}
				{profitField && profitField.year ? renderProgressStat(profitField.year, balance, 'Year') : ''}
			</React.Fragment>
		);
	};

	/**
	 * When the user clicks the account type, handle it.
	 */
	const handleAccountSearchClick = () => dispatch(changeModalAccountSearchOpen(true));

	/**
	 * Do nothing if we are loading.
	 */
	if ((accountID == null) | !accountID || accountList.length <= 0) return '';

	/**
	 * Get the currently selected instrument
	 */
	let selectedAccount = accountList.find((account) => account.id === accountID);
	let demoIndicator = selectedAccount.demo ? '(demo)' : '';

	return (
		<div className="daily-stats">
			<Nav tabs>
				<NavItem>
					<NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
						<span>Current</span>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={activeTab === '4' ? 'active' : ''} onClick={() => setActiveTab('4')}>
						<span>Today</span>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
						<span>Live</span>
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
						<span>Demo</span>
					</NavLink>
				</NavItem>
			</Nav>

			<TabContent className="mb-0" activeTab={activeTab}>
				<TabPane tabId="1">
					{activeTab === '1' ? (
						<div>
							{renderCurrentAccountDetails()}
							{renderProfits(currentProfit)}
							<div className="row mt-3">
								<div className="col-md-8 col-12">
									<strong className="primary">{accountType}</strong>
								</div>
								<div className="col-md-4 col-12">
									<CurrencyFormat value={actBalance} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} />
								</div>
							</div>
							<div className="input-group mt justify-content-center" onClick={handleAccountSearchClick}>
								{selectedAccount.alias} ({selectedAccount.id}) {demoIndicator}
							</div>
						</div>
					) : (
						''
					)}
				</TabPane>
				<TabPane tabId="2">{activeTab === '2' ? <div>{renderProfits(liveProfit)}</div> : ''}</TabPane>
				<TabPane tabId="3">{activeTab === '3' ? <div>{renderProfits(demoProfit)}</div> : ''}</TabPane>
				<TabPane tabId="4" style={{ maxHeight: '620px', overflow: 'scroll' }}>
					{activeTab === '4' ? <div>{renderTodayProfits()}</div> : ''}
				</TabPane>
			</TabContent>
		</div>
	);
};
