import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ApexChart from 'react-apexcharts';
import Widget from '../../../../components/Widget/Widget';
import { rawFloatToCurrencyFormat } from '../../../../components/utils';
import { chartColors } from '../../../../components/StockChart/config/genericConfig';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment/moment';

// To use redux hooks, you export a function component, not a react component.
export const BalanceOverTime = (props) => {
	/**
	 * Local state for changing what we show
	 */
	const [activeTimePeriod, setActiveTimePeriod] = useState('week');
	const [chartType, setChartType] = useState('Regular');
	const [accountSelector, setAccountSelector] = useState('current');

	/**
	 * This is our max used later when we loop through the profit data.
	 * A hard coded value is used here becuase week goes up to 52 which is
	 * way more than we want displayed.
	 */
	const maxIterations = activeTimePeriod === 'week' ? 30 : 11;

	/**
	 * Call the state variables and dispatch into
	 * existance.
	 */
	const accountID = useSelector((state) => state.oanda.accountID);
	const profitData = useSelector((state) => state.oanda.profit);

	// {
	// 	if (activeTimePeriod === 'week' && state.oanda.accountID != null && state.oanda.profit[state.oanda.accountID] != null)
	// 		return state.oanda.profit[state.oanda.accountID].w52;
	// 	if (activeTimePeriod === 'month' && state.oanda.accountID != null && state.oanda.profit[state.oanda.accountID] != null)
	// 		return state.oanda.profit[state.oanda.accountID].m12;
	// }, objectEqual);

	/**
	 * Create a null placeholder
	 */
	let selectedPeriod = null,
		periodData = null;

	/**
	 * Let's set our data based on what the user has selected.
	 */
	if (accountSelector === 'current') selectedPeriod = profitData?.[accountID];
	else selectedPeriod = profitData?.[accountSelector];

	/**
	 * Now select the weekly or monthly
	 */
	if (activeTimePeriod === 'week') periodData = selectedPeriod?.w52;
	else if (activeTimePeriod === 'month') periodData = selectedPeriod?.m12;

	/**
	 * This returns the categories for the graph (the X axis)
	 */
	const getCategories = () => {
		let categoryArray = [];

		/**
		 * The format must be different based on the
		 * active period.
		 */
		let dateFormat = activeTimePeriod === 'week' ? 'WW' : 'MM';

		/**
		 * Cycle through the previous months/weeks and add it to the
		 * array.
		 */
		for (let i = maxIterations; i >= 0; i--) categoryArray.push(moment().subtract(i, activeTimePeriod).format(dateFormat));

		/**
		 * Send it back!
		 */
		return categoryArray;
	};

	/**
	 * This is our options data for the multi chart
	 */
	let chartOptions = {
		dataLabels: {
			enabled: false,
		},
		colors: [chartColors.hexToRGBA(chartColors.blue, 1), chartColors.hexToRGBA(chartColors.green, 0.9), chartColors.red, chartColors.faded],
		stroke: {
			curve: 'smooth',
			width: [2, 2, 4],
		},
		chart: {
			animations: {
				enabled: false,
			},
			// 	stacked: false,
			// 	dropShadow: {
			// 		enabled: true,
			// 		enabledSeries: [0, 1, 2],
			// 		top: -2,
			// 		left: 2,
			// 		blur: 5,
			// 		opacity: 0.6,
			// 	},
		},
		// markers: {
		// 	size: 0,
		// 	strokeColor: '#fff',
		// 	strokeWidth: 3,
		// 	strokeOpacity: 1,
		// 	fillOpacity: 1,
		// 	hover: {
		// 		size: 6,
		// 	},
		// },
		// title: {
		// 	text: activeTimePeriod === 'week' ? 'Weekly Account Balance Over Time' : 'Monthly Account Balance Over Time',
		// 	align: 'left',
		// 	offsetX: 110,
		// 	style: {
		// 		color: chartColors.textColor,
		// 	},
		// },
		xaxis: {
			categories: getCategories(),
			labels: {
				style: {
					colors: chartColors.textColor,
				},
			},
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
		},
		yaxis: [
			{
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: chartColors.blue,
				},
				labels: {
					style: {
						color: chartColors.blue,
					},
					formatter: (value) => rawFloatToCurrencyFormat(value),
				},
				title: {
					text: 'Starting Balance',
					style: {
						color: chartColors.blue,
					},
				},
				tooltip: {
					enabled: true,
				},
			},
			{
				seriesName: 'Ending Balance',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: chartColors.green,
				},
				labels: {
					style: {
						color: chartColors.green,
					},
					formatter: (value) => rawFloatToCurrencyFormat(value),
				},
				title: {
					text: 'Ending Balance',
					style: {
						color: chartColors.green,
					},
				},
			},
			{
				seriesName: 'Net Profit',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: chartColors.orange,
				},
				labels: {
					style: {
						color: chartColors.orange,
					},
					formatter: (value) => rawFloatToCurrencyFormat(value),
				},
				title: {
					text: 'Net Profit',
					style: {
						color: chartColors.orange,
					},
				},
			},
			{
				seriesName: 'Gross Profit',
				opposite: true,
				axisTicks: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: chartColors.faded,
				},
				labels: {
					style: {
						color: chartColors.faded,
					},
					formatter: (value) => rawFloatToCurrencyFormat(value),
				},
				title: {
					text: 'Gross Profit',
					style: {
						color: chartColors.faded,
					},
				},
			},
		],
		tooltip: {
			theme: 'dark',
			followCursor: true,
			fixed: {
				enabled: false,
				position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
				offsetY: 30,
				offsetX: 60,
			},
		},
		legend: {
			horizontalAlign: 'left',
			offsetX: 40,
			labels: {
				colors: [chartColors.textColor],
			},
		},
		grid: {
			borderColor: chartColors.gridLineColor,
		},
		fill: {
			type: 'solid',
			fillOpacity: 0.7,
		},
	};

	/**
	 * Create our object
	 */
	let seriesData = [
		{
			name: 'Starting Balance',
			type: 'column',
			data: [],
		},
		{
			name: 'Ending Balance',
			type: 'column',
			data: [],
		},
		{
			name: 'Profit',
			type: 'line',
			data: [],
		},
		{
			name: 'Gross Profit',
			type: 'line',
			data: [],
		},
	];

	/**
	 * This will get the 52 week balance
	 * series data from redux.
	 */
	const getBalanceSeriesData = () => {
		if (periodData == null || accountID == null) return [];

		/**
		 * Map the profit data array to a new array
		 * that just has the dates.
		 */
		for (let i = maxIterations; i >= 0; i--) {
			seriesData[0].data.push(periodData[i].firstBalance.toFixed(2));
			seriesData[1].data.push(periodData[i].lastBalance.toFixed(2));
			seriesData[2].data.push(parseFloat(periodData[i].net.toFixed(2)));
			seriesData[3].data.push(parseFloat(periodData[i].gross.toFixed(2)));
		}

		/**
		 * Return the array
		 */
		if (chartType === 'Line' || chartType === 'Area') {
			seriesData[1].type = chartType === 'Line' ? 'line' : 'area';
			return [seriesData[1]];
		}
		return seriesData;
	};

	/**
	 * If we are on a line or area chart, delete and change some of the
	 * items in the series.
	 */
	if (chartType === 'Line' || chartType === 'Area') {
		chartOptions.yaxis.splice(0, 1);
		chartOptions.yaxis.splice(1, 2);
		chartOptions.stroke.width[0] = 4;
		chartOptions.colors[0] = chartColors.green;

		/**
		 * Add a gradient for the area chart.
		 */
		if (chartType === 'Area') {
			chartOptions.fill = {
				type: 'gradient',
				gradient: {
					enabled: true,
					opacityFrom: 0.85,
					opacityTo: 0.1,
				},
			};
		} else chartOptions.fill = { type: 'solid' };
	} else chartOptions.fill = { type: 'solid', opacity: 1 };

	/**
	 * Return the widget
	 */
	return (
		<Widget
			title={
				<div className="d-flex justify-content-between flex-wrap">
					<h4 className="d-flex align-items-center pb-1">
						{activeTimePeriod === 'week' ? 'Weekly' : 'Monthly'} Balance <span className="fw-normal ml-xs">Over Time</span>
					</h4>
					<div>
						<UncontrolledButtonDropdown>
							<DropdownToggle caret color="subtle-blue" className="dropdown-toggle-split btn-sm" style={{ textTransform: 'capitalize' }}>
								{accountSelector}&nbsp;&nbsp;
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem onClick={() => setAccountSelector('current')}>Current</DropdownItem>
								<DropdownItem onClick={() => setAccountSelector('live')}>Live</DropdownItem>
								<DropdownItem onClick={() => setAccountSelector('demo')}>Demo</DropdownItem>
							</DropdownMenu>
						</UncontrolledButtonDropdown>
						<UncontrolledButtonDropdown>
							<DropdownToggle caret color="subtle-blue" className="dropdown-toggle-split btn-sm">
								{chartType}&nbsp;&nbsp;
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem onClick={() => setChartType('Regular')}>Regular</DropdownItem>
								<DropdownItem onClick={() => setChartType('Line')}>Line</DropdownItem>
								<DropdownItem onClick={() => setChartType('Area')}>Area</DropdownItem>
							</DropdownMenu>
						</UncontrolledButtonDropdown>
						<UncontrolledButtonDropdown>
							<DropdownToggle caret color="subtle-blue" className="dropdown-toggle-split btn-sm">
								{activeTimePeriod === 'week' ? 'Weekly' : 'Monthly'}&nbsp;&nbsp;
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem onClick={() => setActiveTimePeriod('week')}>Weekly</DropdownItem>
								<DropdownItem onClick={() => setActiveTimePeriod('month')}>Monthly</DropdownItem>
							</DropdownMenu>
						</UncontrolledButtonDropdown>
					</div>
				</div>
			}
		>
			<ApexChart className="sparkline-chart" type={'line'} height={350} series={getBalanceSeriesData()} options={chartOptions} />
		</Widget>
	);
};
