import { connect } from 'react-redux';
import React from 'react';
import _ from 'lodash';
//import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
	Navbar,
	Nav,
	NavItem,
	NavLink,
	UncontrolledAlert,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Badge,
	ButtonGroup,
	Button,
} from 'reactstrap';
import Notifications from '../Notifications';
import { logoutUser } from '../../actions/auth';
import { setAccountID } from '../../actions/oanda';
import { openSidebar, closeSidebar, changeSidebarPosition, changeSidebarVisibility } from '../../actions/navigation';

import sender1 from '../../images/1.png';
import sender2 from '../../images/2.png';
import sender3 from '../../images/3.png';

import adminDefault from '../../images/chat/chat2.png';

import s from './Header.module.scss';

class Header extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		sidebarPosition: PropTypes.string.isRequired,
	};

	constructor(props) {
		super(props);

		this.doLogout = this.doLogout.bind(this);
		this.onDismiss = this.onDismiss.bind(this);
		this.toggleMessagesDropdown = this.toggleMessagesDropdown.bind(this);
		this.toggleSupportDropdown = this.toggleSupportDropdown.bind(this);
		this.toggleSettingsDropdown = this.toggleSettingsDropdown.bind(this);
		this.toggleAccountDropdown = this.toggleAccountDropdown.bind(this);
		this.toggleSidebar = this.toggleSidebar.bind(this);
		this.toggleSearchOpen = this.toggleSearchOpen.bind(this);
		this.accountLinkClick = this.accountLinkClick.bind(this);

		this.state = {
			visible: true,
			messagesOpen: false,
			supportOpen: false,
			settingsOpen: false,
			searchFocused: false,
			searchOpen: false,
			notificationsOpen: false,
		};
	}

	toggleNotifications = () => {
		this.setState({
			notificationsOpen: !this.state.notificationsOpen,
		});
	};

	onDismiss() {
		this.setState({ visible: false });
	}

	doLogout() {
		this.props.dispatch(logoutUser());
		//window.location.href = '/login';
	}

	toggleMessagesDropdown() {
		this.setState({
			messagesOpen: !this.state.messagesOpen,
		});
	}

	toggleSupportDropdown() {
		this.setState({
			supportOpen: !this.state.supportOpen,
		});
	}

	toggleSettingsDropdown() {
		this.setState({
			settingsOpen: !this.state.settingsOpen,
		});
	}

	toggleAccountDropdown() {
		this.setState({
			accountOpen: !this.state.accountOpen,
		});
	}

	toggleSearchOpen() {
		this.setState({
			searchOpen: !this.state.searchOpen,
		});
	}

	toggleSidebar() {
		this.props.isSidebarOpened ? this.props.dispatch(closeSidebar()) : this.props.dispatch(openSidebar());
	}

	moveSidebar(position) {
		this.props.dispatch(changeSidebarPosition(position));
	}

	toggleVisibilitySidebar(visibility) {
		this.props.dispatch(changeSidebarVisibility(visibility));
	}

	accountLinkClick(account) {
		this.props.dispatch(setAccountID(account));
	}

	renderHeaderAlertStatus() {
		let statusIcon = <i className="fa fa-info-circle mr-1" />;
		if (this.props.headerData.status.type === 'loading') statusIcon = <i className="la la-spinner la-spin" style={{ fontSize: 10 }} />;

		return (
			<span>
				{statusIcon} {this.props.headerData.status.text}
			</span>
		);
	}

	renderAccountChangeLink() {
		return _.map(this.props.accountList, (account) => {
			let accountIcon = (
				<Badge color="warning">
					<i className="fa fa-free-code-camp" />
				</Badge>
			);
			if (account.demo)
				accountIcon = (
					<Badge color="danger">
						<i className="fa fa-codepen" />
					</Badge>
				);

			return (
				<DropdownItem onClick={() => this.accountLinkClick(account)} className={s.details} key={account.id}>
					{accountIcon}
					<div className={s.details}>
						{account.alias} <small>({account.id})</small>
					</div>
				</DropdownItem>
			);
		});
	}

	render() {
		const user = this.props.currentUser;
		const avatar = user && user.google.picture;
		const firstUserLetter = user && (user.name || user.email)[0].toUpperCase();

		return (
			<>
				<Navbar className={`d-print-none ${s.root}`}>
					<UncontrolledAlert className={`${s.alert} mr-3 d-lg-down-none animate__animated animate__backInLeft`}>
						{this.renderHeaderAlertStatus()}
					</UncontrolledAlert>

					<Nav className="ml-md-0">
						<Dropdown
							nav
							isOpen={this.state.notificationsOpen}
							toggle={this.toggleNotifications}
							id="basic-nav-dropdown"
							className={`${s.notificationsMenu}`}
						>
							<DropdownToggle nav caret style={{ color: '#f4f4f5', padding: 0 }}>
								<span className={`${s.avatar} rounded-circle float-left mr-2`}>
									{avatar ? (
										<img
											src={avatar}
											onError={(e) => (e.target.src = adminDefault)}
											alt="..."
											title={user && (user.name.split(' ')[0] || user.email)}
										/>
									) : user && user.role === 'admin' ? (
										<img
											src={adminDefault}
											onError={(e) => (e.target.src = adminDefault)}
											alt="..."
											title={user && (user.name.split(' ')[0] || user.email)}
										/>
									) : (
										<span title={user && (user.name.split(' ')[0] || user.email)}>{firstUserLetter}</span>
									)}
								</span>
								<span className={`small d-sm-down-none ${this.props.sidebarStatic ? s.adminEmail : ''}`}>
									{user ? user.name.split(' ')[0] || user.email : 'N/A'}
								</span>
								<Badge className={s.badge} color="primary">
									13
								</Badge>
							</DropdownToggle>
							<DropdownMenu right className={`${s.notificationsWrapper} py-0 animate__animated animate__faster animate__fadeInUp`}>
								<Notifications />
							</DropdownMenu>
						</Dropdown>
						<NavItem className="d-lg-none d-md-block">
							<NavLink onClick={this.toggleSearchOpen} className={s.navItem} href="#">
								<i className="glyphicon glyphicon-search text-white" />
							</NavLink>
						</NavItem>
						<Dropdown nav isOpen={this.state.messagesOpen} toggle={this.toggleMessagesDropdown}>
							<DropdownToggle nav className={`${s.navItem} text-white`}>
								<i className="glyphicon glyphicon-comments" />
							</DropdownToggle>
							<DropdownMenu className={`${s.dropdownMenu} ${s.messages}`}>
								<DropdownItem>
									<img className={s.image} src={sender1} alt="" />
									<div className={s.details}>
										<div>Jane Hew</div>
										<div className={s.text}>Hey, John! How is it going? ...</div>
									</div>
								</DropdownItem>
								<DropdownItem>
									<img className={s.image} src={sender2} alt="" />
									<div className={s.details}>
										<div>Alies Rumiancaŭ</div>
										<div className={s.text}>I will definitely buy this template</div>
									</div>
								</DropdownItem>
								<DropdownItem>
									<img className={s.image} src={sender3} alt="" />
									<div className={s.details}>
										<div>Michał Rumiancaŭ</div>
										<div className={s.text}>Is it really Lore ipsum? Lore ...</div>
									</div>
								</DropdownItem>
								<DropdownItem>
									{/* eslint-disable-next-line */}
									<a href="#" className="text-white">
										See all messages <i className="fa fa-arrow-right" />
									</a>
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
						<NavItem className={`${s.divider} text-white`} />
						<Dropdown nav isOpen={this.state.settingsOpen} toggle={this.toggleSettingsDropdown}>
							<DropdownToggle nav className={`${s.navItem} text-white`}>
								<i className="glyphicon glyphicon-cog" />
							</DropdownToggle>
							<DropdownMenu className={`${s.dropdownMenu} ${s.settings}`}>
								<h6>Sidebar on the</h6>
								<ButtonGroup size="sm">
									<Button color="dark" onClick={() => this.moveSidebar('left')} className={this.props.sidebarPosition === 'left' ? 'active' : ''}>
										Left
									</Button>
									<Button color="dark" onClick={() => this.moveSidebar('right')} className={this.props.sidebarPosition === 'right' ? 'active' : ''}>
										Right
									</Button>
								</ButtonGroup>
								<h6 className="mt-2">Sidebar</h6>
								<ButtonGroup size="sm">
									<Button
										color="dark"
										onClick={() => this.toggleVisibilitySidebar('show')}
										className={this.props.sidebarVisibility === 'show' ? 'active' : ''}
									>
										Show
									</Button>
									<Button
										color="dark"
										onClick={() => this.toggleVisibilitySidebar('hide')}
										className={this.props.sidebarVisibility === 'hide' ? 'active' : ''}
									>
										Hide
									</Button>
								</ButtonGroup>
							</DropdownMenu>
						</Dropdown>
						<Dropdown nav isOpen={this.state.supportOpen} toggle={this.toggleSupportDropdown}>
							<DropdownToggle nav className={`${s.navItem} text-white`}>
								<i className="glyphicon glyphicon-globe" />
								<span className={s.count}>8</span>
							</DropdownToggle>
							<DropdownMenu right className={`${s.dropdownMenu} ${s.support}`}>
								{this.renderAccountChangeLink()}

								{/* <DropdownItem> */}
								{/* eslint-disable-next-line */}
								{/* <a href="/#/app" className="text-white">
									Place Holder <i className="fa fa-arrow-right" />
								</a>
							</DropdownItem> */}
							</DropdownMenu>
						</Dropdown>
						<NavItem>
							<NavLink onClick={this.doLogout} className={`${s.navItem} text-white`} href="#">
								<i className="glyphicon glyphicon-off" />
							</NavLink>
						</NavItem>
						<NavItem className="d-md-none">
							<NavLink onClick={this.toggleSidebar} className={`${s.navItem} text-white`} href="#">
								<i className="fa fa-bars" />
							</NavLink>
						</NavItem>
					</Nav>
				</Navbar>
			</>
		);
	}
}

function mapStateToProps(store) {
	return {
		isSidebarOpened: store.navigation.sidebarOpened,
		sidebarVisibility: store.navigation.sidebarVisibility,
		sidebarPosition: store.navigation.sidebarPosition,
		currentUser: store.auth.currentUser,
		accountList: store.oanda.accountList,
		headerData: store.header,
	};
}

export default withRouter(connect(mapStateToProps)(Header));
