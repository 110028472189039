import React from 'react';
import { findAllInObjByInstrument, getFormat, getPipDifference, getPipValue, floatPrecisionFix } from '../../utils';
import { chartColors, cancelOrderWithAlert } from '../config/genericConfig';
import { InteractiveYCoordinate } from '../../../libs/react-stockcharts/src/lib/interactive';
import { replaceOandaOrder } from '../../../actions/oanda';
import { useSelector, useDispatch } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// To use redux hooks, you export a function component, not a react component.
export const OrdersPriceCoordinateStore = () => {
	/**
	 * This is the holder for interactive y
	 * cooridnates.
	 */
	let yCoordinateOrderList = [];

	/**
	 * Get our store and the data that we need.
	 */
	const chartInstrument = useSelector((state) => state.oanda.chartInstrument);
	// const accountID = useSelector((state) => state.oanda.accountID);
	const orders = useSelector((state) => {
		if (state?.oanda?.accountID != null && state?.oanda?.orders[state?.oanda?.accountID] != null) {
			return state.oanda.orders[state.oanda.accountID];
		}
	});
	const trades = useSelector((state) => {
		if (state?.oanda?.accountID != null && chartInstrument != null && state?.oanda?.trades[state?.oanda?.accountID] != null) {
			return findAllInObjByInstrument(state.oanda.trades[state.oanda.accountID], chartInstrument);
		}
	});
	const conversionRates = useSelector((state) => state.oanda.conversionRates);
	const chartCandles = useSelector((state) => {
		if (
			state?.oanda?.chartCandles != null &&
			state?.oanda?.chartInstrument != null &&
			state?.oanda?.chartGranularity != null &&
			state?.oanda?.chartCandles?.[chartInstrument]?.[state?.oanda?.chartGranularity] != null
		) {
			return state.oanda.chartCandles[chartInstrument][state.oanda.chartGranularity];
		}
	});

	const dispatch = useDispatch();

	/**
	 * Make sure we have data or return false.
	 */
	if (!orders || orders === {}) return '';

	/**
	 * When a TP or SL is dragged, this will
	 * handle the completed movement of it.
	 */
	const handleDragComplete = (yCoordinateList, moreProps, draggedAlert) => {
		const { id, yValue, type, instrument, timeInForce, tradeID, units, clientExtensions, tradeClientExtensions } = draggedAlert;

		/**
		 * Get our format function
		 */
		const formatter = getFormat(instrument);

		/**
		 * Only move forward if this is a type that we
		 * support draging
		 */
		const orderObj = {
			type,
			instrument,
			timeInForce,
			tradeID,
			units,
			price: formatter(yValue),
			clientExtensions: { ...clientExtensions },
			tradeClientExtensions: { ...tradeClientExtensions },
		};

		/**
		 * If this is a trailing stop loss, we have
		 * to include the following distance rather than
		 * the price.
		 */
		if (type === 'TRAILING_STOP_LOSS') {
			/**
			 * To get the distance, just take the current
			 * price and find the difference from where it
			 * is now to where the current.
			 *
			 *
			 * We use the mid here because if you use ask or bid, you get a distance
			 * + spread and need to subtract spread... which is just the mid..
			 */
			const lastPrice = chartCandles[chartCandles.length - 1].mid.c;
			const newDistance = formatter(Math.abs(parseFloat(yValue) - parseFloat(lastPrice)));

			/**
			 * If the user moves the distance to an invalid distance,
			 * do nothing.
			 */
			if (newDistance <= 0) return;

			/**
			 * Set it
			 */
			orderObj.distance = `${newDistance}`; // Needs to be a string...
		}

		/**
		 * Send it to the API
		 */
		dispatch(replaceOandaOrder(id, orderObj));
	};

	/**
	 * When the X is clicked, this is ho it is handled.
	 *
	 * @param {obj} yCoordinate
	 * @param {obj} moreProps
	 */
	const handleDelete = (deletedAlert, moreProps) => cancelOrderWithAlert(deletedAlert, moreProps, dispatch);

	/**
	 * Find all of our stop loss or take
	 * profit orders which are in the orders but the
	 * order TP/SL does not have a chart instrument. So we
	 * find the TP/SL number from the trades and match it to
	 * the orders.
	 */
	let tpSLArray = [];
	if (trades) {
		for (const trade of trades) {
			if (trade?.stopLossOrderID) tpSLArray.push({ id: trade.stopLossOrderID, units: trade.currentUnits, instrument: trade.instrument });
			if (trade?.trailingStopLossOrderID)
				tpSLArray.push({ id: trade.trailingStopLossOrderID, units: trade.currentUnits, instrument: trade.instrument });
			if (trade?.takeProfitOrderID) tpSLArray.push({ id: trade.takeProfitOrderID, units: trade.currentUnits, instrument: trade.instrument });
		}
	}

	/**
	 * Get only the orders we care about
	 */
	const filteredOrders = findAllInObjByInstrument(orders, chartInstrument, tpSLArray);

	// Determine if long or short
	if (filteredOrders.length > 0) {
		yCoordinateOrderList = []; // Clear it

		for (let i = 0; i < filteredOrders.length; i++) {
			/**
			 * Only allow those with a price
			 */
			if (parseFloat(filteredOrders[i].price) > 0) {
				// && filteredOrders[i].state !== 'CANCELLED'
				/**
				 * Create a holder object
				 */
				let yCoordHolderObj = null;

				/**
				 * Additional text to be added to the order,
				 * used for the Trailing Stop profit ATM
				 */
				let additionalText = '';

				/**
				 * If it is a trailing stop, figure out the
				 * current profit and add it to the text.
				 */
				if (filteredOrders[i].type === 'TRAILING_STOP_LOSS') {
					/**
					 * Get the price per pip
					 */
					const pricePerPip = getPipValue(
						chartCandles?.[chartCandles.length - 1]?.mid?.c,
						Math.abs(filteredOrders[i].units),
						chartInstrument,
						conversionRates
					);

					/**
					 * Find the trade that matches this order!
					 */
					const matchingTrade = trades.filter((elem) => elem.id === filteredOrders[i].tradeID)[0];

					/**
					 * Get the distance between the two prices
					 */
					const tslPips = getPipDifference(parseFloat(filteredOrders[i].price), parseFloat(matchingTrade.price), chartInstrument);

					/**
					 * Get the profit by multiplying
					 */
					const tslProfit = floatPrecisionFix(pricePerPip * tslPips, 2);

					/**
					 * Add the price to the additional text
					 */
					additionalText = `$${tslProfit}`;
				}

				if (filteredOrders[i].type === 'TAKE_PROFIT' || filteredOrders[i].type === 'STOP_LOSS' || filteredOrders[i].type === 'TRAILING_STOP_LOSS') {
					yCoordHolderObj = {
						...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate,
						...filteredOrders[i],
						stroke: chartColors.neutralOrderLine,
						textFill: filteredOrders[i].type === 'TAKE_PROFIT' ? chartColors.positiveText : chartColors.negativeText,
						bgFill: chartColors.bgColorTradingView, // This needs to be in HEX chartColors.rgbToHex()
						bgOpacity: 1,
						text:
							filteredOrders[i].type === 'TAKE_PROFIT'
								? `Take Profit ${filteredOrders[i].units}`
								: filteredOrders[i].type === 'TRAILING_STOP_LOSS'
								? `TSL ${additionalText} ${filteredOrders[i].units}`
								: `Stop Loss ${filteredOrders[i].units}`,
						yValue: parseFloat(filteredOrders[i].price),
						id: filteredOrders[i].id,
						draggable: true,
						enabled: true,
						edge: {
							...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate.edge,
							displayFormat: getFormat(chartInstrument),
							stroke: chartColors.neutralOrderLine,
							fill: chartColors.bgColorTradingView,
						},
					};
				} else if (filteredOrders[i].type === 'LIMIT' || filteredOrders[i].type === 'STOP' || filteredOrders[i].type === 'MARKET_IF_TOUCHED') {
					/**
					 * Determine if this is a buy or sell
					 */
					const type = parseInt(filteredOrders[i].units) > 0 ? 'Buy' : 'Sell';

					/**
					 * Find the text type
					 */
					let typeText = 'Limit';
					if (filteredOrders[i].type === 'MARKET_IF_TOUCHED') typeText = 'MiT';
					else if (filteredOrders[i].type === 'STOP') typeText = 'Stop';

					/**
					 * Stroke Color
					 */
					let strokeColor = type === 'Buy' ? chartColors.grayBlueFadedTradingVue : chartColors.grayBlueFadedTradingVue;
					let strokeOpacity = 1;

					/**
					 * Make our wobble orders a bit different
					 */
					if (filteredOrders?.[i]?.clientExtensions?.tag === 'wobble' || filteredOrders?.[i]?.clientExtensions?.comment?.startsWith('WOBBLE')) {
						typeText = `${typeText} WOB`;
						strokeColor = chartColors.rgbToHex(chartColors.moodyPurple);
						strokeOpacity = 0.4;
					}

					/**
					 * TPs
					 */
					if (filteredOrders?.[i]?.clientExtensions?.comment?.startsWith('tp:')) {
						typeText = `${typeText} TP`;
						strokeColor = type === 'Buy' ? chartColors.positiveText : chartColors.negativeText;
						strokeOpacity = 1;
					}

					yCoordHolderObj = {
						...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate,
						...filteredOrders[i],
						stroke: strokeColor,
						strokeOpacity: strokeOpacity,
						textFill: '#fff',
						bgFill: chartColors.bgColorTradingView,
						text: `${type} ${typeText} ${filteredOrders[i].units}`,
						yValue: parseFloat(filteredOrders[i].price),
						id: filteredOrders[i].id,
						draggable: true,
						enabled: true,
						type: filteredOrders[i].type,
						edge: {
							...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate.edge,
							displayFormat: getFormat(chartInstrument),
							stroke: strokeColor,
							strokeOpacity: strokeOpacity,
							fill: chartColors.bgColorTradingView,
						},
					};
				}

				/**
				 * Push each order into an array
				 */
				if (yCoordHolderObj != null) yCoordinateOrderList.push(yCoordHolderObj);
			}
		}
		return (
			<InteractiveYCoordinate enabled={true} onDragComplete={handleDragComplete} onDelete={handleDelete} yCoordinateList={yCoordinateOrderList} />
		);
	} else return '';
};
