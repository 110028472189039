import { FETCH_HEARTBEAT_DATA, SET_HISTORICAL_HB_DATA } from '../actions/types';

const defaultState = {
	history: {},
	data: [],
};

export default function (state = defaultState, action) {
	switch (action.type) {
		case FETCH_HEARTBEAT_DATA:
			return { ...state, data: action.payload } || false;
		case SET_HISTORICAL_HB_DATA:
			let machineName = action.payload.machine;

			/**
			 * Create the default state if it is not
			 * present.
			 */
			if (state.history[machineName] == null) state.history[machineName] = [];

			return {
				...state,
				history:
					{
						...state.history,
						[machineName]: [...state.history[machineName].slice(-8), action.payload.hostStats],
					} || false,
			};
		default:
			return state;
	}
}
