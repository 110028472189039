import { SET_HEADER_STATUS } from '../actions/types';

const defaultState = {
	status: {
		text: '',
		type: '',
	},
};

export default function (state = defaultState, action) {
	switch (action.type) {
		case SET_HEADER_STATUS:
			return { ...state, status: action.payload } || false;
		default:
			return state;
	}
}
