import React from 'react';
import ApexChart from 'react-apexcharts';
import { chartColors } from '../../../components/StockChart/config/genericConfig';
import { floatPrecisionFix, rawFloatToCurrencyFormat } from '../../../components/utils';
import moment from 'moment-timezone';

// To use redux hooks, you export a function component, not a react component.
export const EquityChart = (props) => {
	/**
	 * Call the state variables and dispatch into
	 * existance.
	 */
	const backtest = props.backtestData || null;

	/**
	 * Do nothing if null
	 */
	if (backtest == null) return '';

	/**
	 * Create our chart data
	 */
	const chartOptions = {
		chart: {
			zoom: {
				enabled: true,
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: 'straight',
			width: 2,
		},
		xaxis: {
			categories: [],
			labels: {
				style: {
					colors: chartColors.textColor,
				},
			},
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
		},
		yaxis: {
			labels: {
				formatter: function (value) {
					return rawFloatToCurrencyFormat(value, 2);
				},
				style: {
					color: chartColors.textColor,
				},
			},
			axisTicks: {
				show: false,
			},
			axisBorder: {
				show: false,
			},
		},
		tooltip: {
			theme: 'dark',
		},
		grid: {
			borderColor: chartColors.gridLineColor,
		},
		colors: [chartColors.greenTradingVue2],
	};

	/**
	 * Create our series data by looping through the
	 * backtest data
	 */
	const filteredSignals = backtest.signals.filter((signal) => signal.profit.capital != null);
	const equity = filteredSignals.map((signal) => floatPrecisionFix(signal.profit.capital, 2));
	chartOptions.xaxis.categories = filteredSignals.map((signal) => moment(new Date(signal.exit.time)).format('YYYY/MM/DD hh:mm:ss'));

	/**
	 * Build our series object
	 */
	const equitySeries = [
		{
			name: 'Equity',
			data: [floatPrecisionFix(backtest.cliOptions.inputCapital, 2), ...equity],
		},
	];

	return <ApexChart className="sparkline-chart" type={'line'} series={equitySeries} options={chartOptions} />;
};
