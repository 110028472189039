import { ADD_NEW_NOTE, FETCH_NOTES, FETCH_NOTES_BY_TYPE } from './types';
import axios from 'axios';

/**
 * This will send any notes/todos to the server
 *
 *  @param {obj} data
 */
export const addNewNote = (htmlData, type) => async (dispatch) => {
	if (!htmlData || htmlData == null || !type) return;

	/**
	 * Data
	 */
	const postData = { htmlData: htmlData, type: type };

	/**
	 * Send the data to the api
	 */
	const res = await axios.post(`/api/new/note`, postData);
	dispatch({ type: ADD_NEW_NOTE, payload: res.data });
};

/**
 * Fetch a set of notes by type.
 *
 *
 * 	Currently Unused.
 *
 *  @param {obj} data
 */
export const fetchNotesByType = (type) => async (dispatch) => {
	if (type == null) return;
	const res = await axios.get(`/api/notes/${type}`);

	dispatch({ type: FETCH_NOTES_BY_TYPE, payload: res.data });
};

/**
 * Fetch all notes
 */
export const fetchNotes = () => async (dispatch) => {
	const res = await axios.get(`/api/notes`);

	dispatch({ type: FETCH_NOTES, payload: res.data });
};

/**
 * Fetch all notes
 *
 *  @param {str} id - mongodb id
 *  @param {str} action - deleted, archived, active
 */
export const updateNoteStatus = (id, action) => async (dispatch) => {
	if (!id || !action) return;

	const res = await axios.get(`/api/notes/${action}/${id}`);
	dispatch({ type: FETCH_NOTES, payload: res.data });
};
