import React from 'react';
import { chartColors } from '../../../components/StockChart/config/genericConfig';
import { useSelector } from 'react-redux';
// import { formatBytes } from '../../utils';
import ApexChart from 'react-apexcharts';
// import Sparklines from '../../Sparklines/Sparklines';
import s from '../Sidebar.module.scss';
// import { Alert } from 'reactstrap';
import { rawFloatFormat } from '../../utils';

/**
 * This is a replacement function to get a shorter version of the machine
 * name.
 *
 * @param {str} machine
 */
const getMachineName = (machine) => {
	/**
	 * Change the name if the name is long
	 *
	 * This was a ternary expression but had too many legs so is
	 * now an if.
	 */
	let machineName = machine;
	if (machine === 'ionicware-H370AORUSGAMING3WIFI') machineName = 'Staging';
	else if (machine === 'mongodb-s-1vcpu-1gb-nyc1-01') machineName = 'DO Mongo';
	else if (machine === 'Jordans2020MBP.broadbandsupport.net') machineName = 'Jordans2020MBP';
	else if (machine === 'stash-collection') machineName = 'Stash';
	else if (machine === 'WIN-6NEDUP9ESLR') machineName = 'WPNap';
	else if (machine === 'DESKTOP-TL1CJAG') machineName = 'JHome';
	else if (machine === 'Treadmill-MBP') machineName = 'Treadmill';

	return machineName;
};

/**
 * Ignore certain machines from appearing such as test
 * machines (IE: My laptop)
 *
 * @param {str} machine
 */
const ignoreMachine = (machine) => {
	/**
	 * We don't care about some machines
	 */
	if (machine === 'Jordans2020MBP.broadbandsupport.net' || machine === 'Jordans-TrashCan') return true;
	return false;
};

// To use redux hooks, you export a function component, not a react component.
export const HeartbeatSignals = () => {
	/**
	 * Get our data out of the store
	 */
	const heartbeat = useSelector((state) => state.heartbeat);

	/**
	 * Ensure we have data
	 */
	if (heartbeat == null) return '';

	/**
	 * Render the signals that are currently active
	 * on the sidebar.
	 */
	const renderSignals = () => {
		if (heartbeat.data == null || heartbeat.data <= 0) return '';

		/**
		 * Loop through the signals
		 */
		return heartbeat.data.map((obj) => {
			let nameArray = obj.name.split(':');

			/**
			 * We don't care about some machines
			 */
			if (ignoreMachine(obj.machine)) return false;

			/**
			 * Get only those that are named 'signal'
			 */
			if (nameArray[0] === 'signal') {
				/**
				 * Let's fetch the timestamp and
				 * see what color the button should be.
				 */
				let textClass =
					new Date().getTime() - new Date(obj.time).getTime() < 5000
						? 'fa fa-circle mr-2 text-success'
						: new Date().getTime() - new Date(obj.time).getTime() < 10000
						? 'fa fa-circle mr-2 text-warning'
						: 'fa fa-circle mr-2 text-danger';

				/**
				 * return the JSX
				 */
				return (
					<li key={`${obj.name}${Math.random().toString(36).substr(2, 7)}`}>
						<a href="/">
							<i className={textClass} />
							<span className={s.labelName}>{nameArray[1]}</span>
						</a>
					</li>
				);
			} else return false;
		});
	};

	/**
	 * This is hopefully a solution to the
	 * renderHeartbeats() function below which uses too
	 * much memory and crashes chrome.
	 */
	const renderRadialBarServers = () => {
		if (heartbeat.data == null || heartbeat.data.length <= 0) return '';

		/**
		 * Create a unique list of machines
		 */
		const uniqueMachines = heartbeat.data.filter(function ({ machine }) {
			return !this[machine] && (this[machine] = machine);
		}, {});

		/**
		 * The is the amount that will make the
		 * machine shown and red
		 */
		const redAmount = 80;

		/**
		 * Loop through the signals
		 */
		let returnJSX = [];
		for (let i = 0; i < uniqueMachines.length; i++) {
			/**
			 * Change the name if the name is long
			 */
			let machineName = getMachineName(uniqueMachines[i].machine);

			/**
			 * We don't care about some machines
			 */
			if (ignoreMachine(uniqueMachines[i].machine)) continue;

			/**
			 * Get the first item which is the most
			 * recent.
			 */
			let obj = uniqueMachines[i].hostStats;

			/**
			 * Move past any odd entires without
			 * spae data.
			 */
			if (obj.space == null) continue;

			/**
			 * os.loadavg is:
			 * an array containing fractional number of size three signifies
			 * 1, 5 and 15 minutes load average calculated by the operating system.
			 *
			 * We use the 1 minute one here
			 */
			let loadAverage = parseFloat(rawFloatFormat(Math.min(Math.floor((obj.loadavg[0] * 100) / obj.cores), 100), 0, 3, ',', '.'));
			let memUsePercent = parseFloat(rawFloatFormat((obj.freemem / obj.totalmem) * 100, 0, 3, ',', '.'));

			/**
			 * Space
			 */
			let usedSpace = obj.space.size - obj.space.free;
			let spaceUsage = parseFloat(rawFloatFormat((parseInt(usedSpace) / parseInt(obj.space.size)) * 100, 0, 3, ',', '.'));

			/**
			 * Only display these if there is a load average that
			 * we need to be aware of, or if it is the last one
			 * and none are showing.
			 */
			if (loadAverage >= redAmount || (returnJSX.length <= 0 && i === uniqueMachines.length - 1)) {
				/**
				 * These are our options
				 */
				const radialOptions = {
					series: [],
					options: {
						labels: [`CPU`, `Memory`, `Space`],
						// title: {
						// 	text: machineName,
						// 	style: {
						// 		color: chartColors.textColor,
						// 	},
						// },
						chart: {
							sparkline: {
								enabled: true,
							},
						},
						responsive: [
							{
								breakpoint: 480,
								options: {
									chart: {
										width: 50,
									},
									legend: {
										position: 'bottom',
									},
								},
							},
						],
						colors: [
							loadAverage > redAmount ? chartColors.redTradingVue : chartColors.tradingViewBlue,
							memUsePercent > redAmount ? chartColors.redTradingVue : chartColors.greenTradingVue2,
							spaceUsage > redAmount ? chartColors.redTradingVue : chartColors.purple,
						],
						plotOptions: {
							radialBar: {
								offsetY: 0,
								startAngle: 0,
								endAngle: 270,
								hollow: {
									margin: 5,
									size: '5%',
									background: 'transparent',
								},
								dataLabels: {
									value: {
										color: chartColors.textColor,
									},
								},
								track: {
									background: '#000000',
									opacity: 0.24,
								},
							},
						},
					},
				};

				/**
				 * Push our data into the series
				 */
				radialOptions.series.push(loadAverage, memUsePercent, spaceUsage);

				/**
				 * Put it in our JSX array for later
				 * return.
				 */
				returnJSX.push(
					<div key={`space_${machineName}`} className="serverRadials">
						<div style={{ float: 'left', width: '130px' }}>
							<ApexChart className="sparkline-chart" type={'radialBar'} series={radialOptions.series} options={radialOptions.options} />
						</div>
						<div className="serverName">{machineName}</div>
					</div>
				);
			} // End if clase (only showing red)
		} // End foreach

		/**
		 * Return our results
		 */
		return returnJSX;
	};

	/**
	 * Render the signals that are currently active
	 * on the sidebar.
	 */
	// const renderHeartbeats = () => {
	// 	if (heartbeat.history == null || heartbeat.history.length <= 0) return '';

	// 	/**
	// 	 * This is our sparkline options
	// 	 */
	// 	const sparklineOptions = {
	// 		colors: [chartColors.blue, chartColors.green],
	// 		plotOptions: {
	// 			area: {
	// 				columnWidth: '50%',
	// 			},
	// 		},
	// 		stroke: {
	// 			width: 1,
	// 			curve: 'smooth',
	// 			lineCap: 'square',
	// 		},
	// 		yaxis: [
	// 			{
	// 				seriesName: 'Memory Usage',
	// 				axisTicks: {
	// 					show: false,
	// 				},
	// 				axisBorder: {
	// 					show: false,
	// 					color: '#1870DC',
	// 				},
	// 				labels: {
	// 					style: {
	// 						color: '#1870DC',
	// 					},
	// 					formatter: (value) => `${value}%`,
	// 				},
	// 				title: {
	// 					text: 'Memory Usage',
	// 					style: {
	// 						color: '#1870DC',
	// 					},
	// 				},
	// 				tooltip: {
	// 					enabled: true,
	// 				},
	// 			},
	// 			{
	// 				seriesName: 'CPU Usage',

	// 				opposite: true,
	// 				axisTicks: {
	// 					show: false,
	// 				},
	// 				axisBorder: {
	// 					show: false,
	// 				},
	// 				labels: {
	// 					formatter: (value) => `${value}% (1m)`,
	// 				},
	// 				title: {
	// 					text: 'CPU',
	// 					style: {},
	// 				},
	// 			},
	// 		],
	// 	};

	// 	/**
	// 	 * Loop through the signals
	// 	 */
	// 	let returnJSX = [];
	// 	for (const [machine, objArray] of Object.entries(heartbeat.history)) {
	// 		let seriesData = [
	// 			{
	// 				name: 'Memory Usage',
	// 				type: 'bar',
	// 				data: [],
	// 			},
	// 			{
	// 				name: 'CPU Usage',
	// 				type: 'bar',
	// 				data: [],
	// 			},
	// 		];

	// 		/**
	// 		 * Loop through each sub item and extra the series
	// 		 * data that we need to create a sparkline.
	// 		 */
	// 		for (let i = 0; i < objArray.length; i++) {
	// 			let obj = objArray[i];

	// 			/**
	// 			 * os.loadavg is:
	// 			 * an array containing fractional number of size three signifies
	// 			 * 1, 5 and 15 minutes load average calculated by the operating system.
	// 			 *
	// 			 * We use the 1 minute one here
	// 			 */
	// 			let loadAverage = obj.loadavg[0] * 100;

	// 			/**
	// 			 * The first one gets the amount free percentage,
	// 			 * the second one gets the amount used percentage.
	// 			 */
	// 			// let memUsePercent = ((obj.totalmem - obj.freemem) / obj.totalmem) * 100;
	// 			let memUsePercent = (obj.freemem / obj.totalmem) * 100;
	// 			// let hbTimeMS = new Date().getTime() - new Date(obj.time).getTime();
	// 			// let hbPercent = (hbTimeMS / 1000) * 20;

	// 			// if (machine == 'E3-2288G') console.log(loadAverage.toFixed(0), memUsePercent);

	// 			/**
	// 			 * Set the mem color
	// 			 */
	// 			// let memUseColor = memUsePercent >= 50 ? 'danger' : 'success';
	// 			// let loadAvgColor = loadAverage >= 50 ? 'danger' : 'primary';
	// 			// let hbColor = hbPercent < 30 ? 'success' : hbPercent < 50 ? 'primary' : 'danger';

	// 			// Push it to our series
	// 			seriesData[0].data.push(memUsePercent.toFixed(2));
	// 			seriesData[1].data.push(loadAverage.toFixed(0));
	// 		}

	// 		/**
	// 		 * Change the name if the name is long
	// 		 */
	// 		let machineName = getMachineName(machine);

	// 		/**
	// 		 * return the JSX
	// 		 */
	// 		returnJSX.push(
	// 			<Alert
	// 				key={`${machine}${Math.random().toString(36).substr(2, 7)}`}
	// 				className={s.sidebarAlert}
	// 				color="transparent"
	// 				isOpen={true} // eslint-disable-line
	// 			>
	// 				<div className="stat-item stat-item-mini-chart">
	// 					<Sparklines options={sparklineOptions} width={150} height={25} data={seriesData} />

	// 					<small className="pl-3">{machineName}</small>
	// 				</div>
	// 				{/* <Progress className={`bg-custom-dark progress-xs mt-1`} color={loadAvgColor} value={loadAverage} /> */}
	// 				{/* <Progress className={`bg-custom-dark progress-xs mt-1`} color={loadAvgColor} value={loadAverage} /> */}
	// 				{/* <small>
	// 					{floatToNumberFormat(memUsePercent)}% mem, {loadAverage} load
	// 				</small> */}
	// 			</Alert>
	// 		);
	// 	}

	// 	return returnJSX;
	// };

	// /**
	//  * Render the signals that are currently active
	//  * on the sidebar.
	//  */
	// const renderServerSpace = () => {
	// 	if (heartbeat.history.length <= 0) return '';

	// 	/**
	// 	 * Loop through the signals
	// 	 */
	// 	let returnJSX = [];
	// 	for (const [machine, objArray] of Object.entries(heartbeat.history)) {
	// 		/**
	// 		 * Change the name if the name is long
	// 		 */
	// 		let machineName = machine === 'ionicware-H370AORUSGAMING3WIFI' ? 'Staging' : machine === 'mongodb-s-1vcpu-1gb-nyc1-01' ? 'DO Mongo' : machine;

	// 		/**
	// 		 * Our donut chart options
	// 		 */
	// 		const donutOptions = {
	// 			series: [],
	// 			options: {
	// 				chart: {
	// 					sparkline: {
	// 						enabled: true,
	// 					},
	// 				},
	// 				labels: [`${machineName} Used`, `${machineName} Free`],
	// 				tooltip: {
	// 					theme: 'dark',
	// 					y: {
	// 						formatter: function (value) {
	// 							return formatBytes(value);
	// 						},
	// 					},
	// 				},
	// 				legend: {
	// 					labels: {
	// 						colors: [chartColors.textColor],
	// 					},
	// 				},
	// 				responsive: [
	// 					{
	// 						breakpoint: 480,
	// 						options: {
	// 							chart: {
	// 								width: 50,
	// 							},
	// 							legend: {
	// 								position: 'bottom',
	// 							},
	// 						},
	// 					},
	// 				],
	// 				stroke: {
	// 					show: false,
	// 					width: 0,
	// 				},
	// 				colors: [chartColors.red, chartColors.purple],
	// 			},
	// 		};

	// 		/**
	// 		 * Get the first item which is the most
	// 		 * recent.
	// 		 */
	// 		let obj = objArray[0];

	// 		/**
	// 		 * Move past any odd entires without
	// 		 * spae data.
	// 		 */
	// 		if (obj.space == null) continue;

	// 		/**
	// 		 * Push our space data
	// 		 */
	// 		donutOptions.series.push(obj.space.size - obj.space.free);
	// 		donutOptions.series.push(obj.space.free);

	// 		/**
	// 		 * return the JSX
	// 		 */
	// 		returnJSX.push(
	// 			<div key={`space_${machineName}`} className="p-2 stat-item stat-item-mini-chart">
	// 				<ApexChart className="sparkline-chart" type={'donut'} height={50} width={50} series={donutOptions.series} options={donutOptions.options} />
	// 			</div>
	// 		);
	// 	}

	// 	return returnJSX;
	// };

	return (
		<>
			<h5 className={s.navTitle}>SIGNALS</h5>
			{/* eslint-disable */}
			<ul className={s.sidebarLabels}>{renderSignals()}</ul>
			{/* eslint-enable */}
			{/* <h5 className={`pb-3 ' ${s.navTitle}`}>SERVERS</h5>
			<div className={s.sidebarAlerts}>{renderHeartbeats()}</div> */}

			<h5 className={`${s.navTitle} pb-3`}>SERVERS</h5>
			<div className={`${s.sidebarAlerts} d-flex flex-wrap`}>{renderRadialBarServers()}</div>

			<div style={{ height: '100px' }}> </div>
		</>
	);
};
