import React from 'react';
import { useSelector } from 'react-redux';
import { floatToCurrencyFormat, objectEqual, findObjByInstrument } from '../utils';
import { chartColors } from './config/genericConfig';

// To use redux hooks, you export a function component, not a react component.
export const CustomChartFooter = (props) => {
	/**
	 *  Get the data we need for the toolbar.
	 */
	const { candleType, candleLoopDone } = props.chartSettings;
	const showFooterLoading = useSelector((state) => state.oanda.showFooterLoading);
	const chartInstrumentPosition = useSelector((state) => {
		if (state.oanda.accountID != null && state.oanda.chartInstrument != null && state.oanda.positions[state.oanda.accountID] != null) {
			// if (!state.oanda.chartSettings.individualPositions) return '';
			return findObjByInstrument(state.oanda.positions[state.oanda.accountID], state.oanda.chartInstrument);
		}
	}, objectEqual);

	/**
	 * Get our props
	 */
	let backgroundColor = props.backgroundColor ? props.backgroundColor : chartColors.toolbarBackground;
	let borderTopStyle = props.borderTopStyle ? props.borderTopStyle : `1px solid ${chartColors.toolbarBorderMain}`;
	let bottomToolbarHeight = props.bottomToolbarHeight ? `${props.bottomToolbarHeight}px` : '25px';

	return (
		<div
			className="trading-vue-toolbar-bottom"
			style={{ backgroundColor: backgroundColor, borderTop: borderTopStyle, width: '100%', height: bottomToolbarHeight }}
		>
			<div className="btn-toolbar justify-content-between">
				<div className="d-inline-flex">
					<div className="trading-vue-toolbar-bottom-item" onClick={() => props.handleTimePeriodClick(86400000)}>
						1D
					</div>
					<div className="trading-vue-toolbar-bottom-item" onClick={() => props.handleTimePeriodClick(172800000)}>
						2D
					</div>
					<div className="trading-vue-toolbar-bottom-item" onClick={() => props.handleTimePeriodClick(259200000)}>
						3D
					</div>
					<div className="trading-vue-toolbar-bottom-item" onClick={() => props.handleTimePeriodClick(604800000)}>
						1W
					</div>
					<div className="trading-vue-toolbar-bottom-item" onClick={() => props.handleTimePeriodClick(1209600000)}>
						2W
					</div>
					<div className="trading-vue-toolbar-bottom-item" onClick={() => props.handleTimePeriodClick(2629800000)}>
						1M
					</div>
					<div className="trading-vue-toolbar-bottom-item" onClick={() => props.handleTimePeriodClick(7889400000)}>
						3M
					</div>
					<div className="trading-vue-toolbar-bottom-item" onClick={() => props.handleTimePeriodClick(15778800000)}>
						6M
					</div>
					<div className="trading-vue-toolbar-bottom-item" onClick={() => props.handleTimePeriodClick(31557600000)}>
						1Y
					</div>
					<div className="trading-vue-toolbar-bottom-item" onClick={() => props.handleTimePeriodClick('All')}>
						ALL
					</div>

					<div className="toolbar-bottom-separator"> </div>

					<div onClick={() => props.handleReloadClick()} className="trading-vue-toolbar-bottom-time trading-vue-toolbar-bottom-item">
						<i className="la la-redo-alt" style={{ fontSize: 10, marginLeft: '3px', marginRight: '3px' }} />
					</div>

					<div className="toolbar-bottom-separator"> </div>

					<div
						className="trading-vue-toolbar-bottom-time trading-vue-toolbar-bottom-item"
						onClick={() => props.handleCandleTypeClick()}
						style={{ textTransform: 'capitalize' }}
					>
						{candleType}
					</div>

					{!candleLoopDone || showFooterLoading ? (
						<>
							<div className="toolbar-bottom-separator"> </div>
							<div>
								<i className="la la-spinner la-spin" style={{ fontSize: 10, marginTop: '4px', marginLeft: '3px' }} />
							</div>
						</>
					) : (
						''
					)}
				</div>
				<div className="d-inline-flex trading-vue-toolbar-bottom-normal" style={{ marginRight: '10px' }}>
					{chartInstrumentPosition && chartInstrumentPosition.unrealizedPL != null ? floatToCurrencyFormat(chartInstrumentPosition.unrealizedPL) : ''}
				</div>
			</div>
		</div>
	);
};
