import React, { useState, createRef, useRef } from 'react';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BaseModal } from './BaseModal';
import { changeModalChartTypeOpen } from '../../actions/navigation';
import { chartTypeOptions, chartTypeSVGSelect } from '../StockChart/config/chartTypeOptions';
import { moveFocus, searchKeyDown } from '../utils';

// To use redux hooks, you export a function component, not a react component.
export const CandleTypeSearch = (props) => {
	/**
	 * Use props to pass some items so we can
	 * have versatile results
	 */
	const { chartType, chartTypeChange } = props;

	/**
	 * Fetch our redux states
	 */
	const isOpen = useSelector((state) => state.navigation.modals.chartTypeSearchOpen);
	const dispatch = useDispatch();

	/**
	 * Our state
	 */
	const [searchTerm, setSearchTerm] = useState(null);
	const refSearch = useRef(null);
	let focusRefs = []; // For refs

	/**
	 * This is just a search result holder
	 */
	let filteredChartTypes =
		searchTerm === null
			? chartTypeOptions
			: chartTypeOptions.filter(
					(cType) => cType.value.search(new RegExp(searchTerm, 'i')) !== -1 || cType.label.search(new RegExp(searchTerm, 'i')) !== -1
			  );

	/**
	 * The onClose function
	 */
	const onClose = () => {
		setSearchTerm(null);
		dispatch(changeModalChartTypeOpen(false));
	};

	/**
	 * Handle when the user clicks on a new chartType.
	 *
	 * @param {str} chartType
	 */
	const handleSearchClick = (chartType) => {
		chartTypeChange(chartType);
		onClose();
	};

	/**
	 * Cycle through the chartType options and map them
	 * to a new array as an option.
	 */
	const renderOptions = () => {
		if (!filteredChartTypes || !Array.isArray(filteredChartTypes)) return '';

		return filteredChartTypes
			.sort((a, b) => (a.label > b.label ? 1 : -1))
			.map((option) => {
				const newRef = createRef();
				focusRefs.push(newRef);

				return (
					<div
						className="listContainer"
						key={option.value}
						onClick={() => handleSearchClick(option.value)}
						ref={newRef}
						tabIndex={-1}
						onKeyDown={(e) => moveFocus(e, refSearch)}
					>
						<div className="itemRow">
							<div className="itemInfoCell cell">
								<div className="expandHandleWrap">
									{chartType === option.value ? (
										<span className="expandHandle">
											<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
												<path stroke="currentColor" d="M8 5l3.5 3.5L8 12"></path>
											</svg>
										</span>
									) : (
										''
									)}
								</div>
								<div className="description">
									<div className="symbolTitleNoFlow">
										<span>{option.label}</span>
									</div>
								</div>
							</div>
							<div className="exchangeCell cell">
								<div className="exchangeDescription">
									<div className="marketType"></div>
									<div className="exchangeName"></div>
								</div>
								<div className="flag">{chartTypeSVGSelect(option.value)}</div>
							</div>
						</div>
					</div>
				);
			});
	};

	return (
		<BaseModal isOpen={isOpen} onClose={onClose} title="Chart Type" mainClass="smallModal">
			<InputGroup className="modalSearchInputGroup">
				<InputGroupAddon addonType="prepend">
					<span className="input-group-text transparentBG">
						<i className="fa fa-search" />
					</span>
				</InputGroupAddon>
				<Input
					type="text"
					className="modalSearch"
					placeholder="Search..."
					autoFocus={true}
					onChange={(e) => setSearchTerm(e.target.value)}
					innerRef={refSearch} // This is a prop of the input which converts into ref
					onKeyDown={(e) => searchKeyDown(e, focusRefs)}
				/>
			</InputGroup>
			<div className="modalSearchResultsList">{renderOptions()}</div>
		</BaseModal>
	);
};
