/**
 * Supertrends!
 *
 * https://earnfo.com/supertrend-indicator/
 *
 * https://www.quora.com/How-is-a-Supertrend-calculated
 *
 */
import React from 'react';
import GenericChartComponent from './GenericChartComponent';
import { hexToRGBA } from './utils';
import { getAxisCanvas } from './GenericComponent';
import { chartColors } from '../../../../components/StockChart/config/genericConfig';
import { atr, rsi } from './calculator';

export const SuperTrend = ({
	trends = {
		factor: 2,
		atrLength: 10,
		lineWidth: 2,
		longFill: hexToRGBA(chartColors.positiveText, 1),
		shortFill: hexToRGBA(chartColors.shortBar, 1),
	},
	rsiLen = 7,
	rsiOverSold = 30,
	rsiOverBought = 70,
	showEntrySignals = false,
	showAlternateSignals = false,
	signalLong = hexToRGBA(chartColors.positiveText, 1),
	signalShort = hexToRGBA(chartColors.shortBar, 1),
}) => {
	const drawOnCanvas = (ctx, moreProps) => {
		const {
			fullData,
			// mouseXY,
			chartConfig: { yScale },
			xScale,
			xAccessor,
		} = moreProps;

		/**
		 * The plotData is the bars being shown depending on
		 * zoom and pan.
		 */
		if (!fullData || fullData.length === 0 || !yScale) return null;

		/**
		 * Create a function for calculating and drawing each
		 * ST line that we have.
		 *
		 * @param {obj} options - the props/options needed
		 */
		const calcDrawSuperTrend = (options) => {
			/**
			 * Get our local options out of the param
			 * options.
			 */
			const { atrLength, fullData, factor, longFill, shortFill, lineWidth } = options;

			/**
			 * Calculate the ATR over the length of time
			 *
			 */
			const atrFunction = atr().options({ windowSize: atrLength });
			const atrData = atrFunction(fullData);

			// console.log(atrLength, atrData);

			/**
			 * Our vars
			 */
			let trendUp = [0];
			let trendDown = [0];
			let trend = [0];

			/**
			 * Let's get the SuperTrends lines
			 */
			for (let z = 0; z < fullData.length; z++) {
				/**
				 * EVerything is zero at the start
				 */
				if (z === 0) continue;

				/**
				 * Calculate the upper and lower lines of the
				 * super trend.
				 */
				const basicUpper = (fullData[z].high + fullData[z].low) / 2 - factor * atrData[z];
				const basicLower = (fullData[z].high + fullData[z].low) / 2 + factor * atrData[z];
				trendUp.push(fullData[z - 1].close > trendUp[trendUp.length - 1] ? Math.max(basicUpper, trendUp[trendUp.length - 1]) : basicUpper);
				trendDown.push(
					fullData[z - 1].close < trendDown[trendDown.length - 1] ? Math.min(basicLower, trendDown[trendDown.length - 1]) : basicLower
				);
				trend.push(
					fullData[z].close > trendDown[trendDown.length - 2]
						? 1
						: fullData[z].close < trendUp[trendUp.length - 2]
						? -1
						: trend[trend.length - 1]
				);

				/**
				 * Get our cooridnates. This is done using the xAccessor
				 * and passing in the entire bar data which contains the IDX
				 * (index) values.
				 */
				const y1Start = yScale(trend[trend.length - 2] === 1 ? trendUp[trendUp.length - 2] : trendDown[trendDown.length - 2]);
				const y1End = yScale(trend[trend.length - 1] === 1 ? trendUp[trendUp.length - 1] : trendDown[trendDown.length - 1]);

				/**
				 * Set our stroke info
				 */
				ctx.strokeStyle = trend[trend.length - 1] === 1 ? longFill : shortFill;
				ctx.lineWidth = lineWidth;

				/**
				 * This is where we actually draw the trend
				 */
				ctx.beginPath();
				ctx.setLineDash([]);
				ctx.moveTo(
					xScale(xAccessor(trend[trend.length - 2] === trend[trend.length - 1] ? fullData[z - 1] : fullData[z])),
					trend[trend.length - 2] === trend[trend.length - 1] ? y1Start : y1End
				);
				ctx.lineTo(xScale(xAccessor(fullData[z])), y1End);
				ctx.closePath();
				ctx.stroke();
			}

			/**
			 * Everything is complete, return our object
			 */
			return {
				trendUp,
				trendDown,
				trend,
			};
		};

		/**
		 * This is a hold for all of our trends
		 * so that we can create the buy/sell signals
		 * later.
		 *
		 * 2021-08-09 15:26:36 JD
		 */
		let trendValues = [];

		/**
		 * Loop through all of our arguments and draw them
		 */
		for (const trend of trends) {
			trendValues.push(calcDrawSuperTrend({ fullData, ...trend }));
		}

		/**
		 * If we have entry signals shown, we need to
		 * calculate the RSI and show those signals.
		 * This is specific to the STx3 strategy.
		 *
		 * 2021-08-11 10:34:48 JD
		 */
		if (showEntrySignals) {
			let signals = [],
				exits = [];

			/**
			 * Fetch our RSI Length
			 */
			const rsiFunction = rsi().options({ windowSize: rsiLen });
			const rsiData = rsiFunction(fullData);

			/**
			 * Loop through all of our arguments and see
			 * if we have any signals or not.
			 */
			for (let i = 0; i < trendValues[0].trend.length; i++) {
				/**
				 * Just so it is easier to code below
				 */
				const fastTrend = trendValues[0];
				const mediumTrend = trendValues[1];
				const slowTrend = trendValues[2];

				/**
				 * Long entry is when all are up except the
				 * fast line and RSI is crossing over.
				 */
				if (slowTrend.trend[i] === 1 && mediumTrend.trend[i] === 1) {
					/**
					 * Primary long strategy
					 */
					if (fastTrend.trend[i] !== 1) {
						/**
						 * Determine if RSI is crossing over or not
						 * Long
						 */
						if (rsiData[i] > rsiOverSold && rsiData[i - 1] <= rsiOverSold) signals.push({ direction: 1, ...fullData[i] });
					}

					/**
					 * Alternate long where all are positive but it dipped below
					 * the halfway line.
					 */
					if (showAlternateSignals && fastTrend.trend[i] === 1) {
						if (rsiData[i] > 50 && rsiData[i - 1] <= 50 && rsiData[i - 2] <= 50) signals.push({ direction: 1, ...fullData[i] });
					}
				}

				/**
				 * Short entry is when all are down except the
				 * fast line and RSI is crossing over.
				 */
				if (slowTrend.trend[i] === -1 && mediumTrend.trend[i] === -1) {
					/**
					 * Primary strat
					 */
					if (fastTrend.trend[i] !== -1) {
						/**
						 * Determine if RSI is crossing over or not
						 * Long
						 */
						if (rsiData[i] < rsiOverBought && rsiData[i - 1] >= rsiOverBought) signals.push({ direction: -1, ...fullData[i] });
					}

					/**
					 * Alternate (trend follow)
					 */
					if (showAlternateSignals && fastTrend.trend[i] === -1) {
						/**
						 * Determine if RSI is crossing over or not
						 * Long
						 */
						if (rsiData[i] < 50 && rsiData[i - 1] >= 50 && rsiData[i - 2] >= 50) signals.push({ direction: -1, ...fullData[i] });
					}
				}
			}

			/**
			 * Now we loop through!
			 */
			for (let i = 0; i < signals.length; i++) {
				if (signals?.[i]?.close) {
					ctx.fillStyle = signals?.[i]?.direction === 1 ? signalLong : signalShort;
					ctx.save();
					ctx.beginPath();
					ctx.translate(
						xScale(xAccessor(signals[i])),
						yScale(signals?.[i]?.direction === 1 ? signals[i].low : signals[i].high) + (signals?.[i]?.direction === -1 ? -15 : +15)
					);
					ctx.rotate(signals?.[i]?.direction === 1 ? 0 : Math.PI);
					ctx.moveTo(0, 0);
					ctx.lineTo(5, 20);
					ctx.lineTo(-5, 20);
					ctx.closePath();
					ctx.restore();
					ctx.fill();
				}
			}
		}
	};

	const renderSVG = (moreProps) => {};

	return (
		<GenericChartComponent
			canvasToDraw={getAxisCanvas}
			canvasDraw={drawOnCanvas}
			svgDraw={renderSVG}
			drawOn={['pan']}
			key={Math.random().toString(36).substr(2, 7)}
		/>
	);
};
