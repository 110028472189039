import React, { useState, createRef, useRef } from 'react';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BaseModal } from './BaseModal';
import { changeModalAccountSearchOpen } from '../../actions/navigation';
import { moveFocus, searchKeyDown } from '../utils';

// To use redux hooks, you export a function component, not a react component.
export const AccountSearch = (props) => {
	/**
	 * Use props to pass some items so we can
	 * have versatile results
	 */
	const { accountID, accountList, accountChange } = props;

	/**
	 * Fetch our redux states
	 */
	const isOpen = useSelector((state) => state.navigation.modals.accountSearchOpen);
	const dispatch = useDispatch();

	/**
	 * Our state
	 */
	const [searchTerm, setSearchTerm] = useState(null);
	const refSearch = useRef(null);
	let focusRefs = []; // For refs

	/**
	 * Let's seperate our account list into
	 * live and demo accounts.
	 */
	const liveAccounts = accountList.filter((account) => !account.demo);
	const demoAccounts = accountList.filter((account) => account.demo);

	/**
	 * Filter each account by the search term.
	 */
	const liveFilteredAccounts =
		searchTerm === null
			? liveAccounts
			: liveAccounts.filter(
					(account) => account.alias.search(new RegExp(searchTerm, 'i')) !== -1 || account.id.search(new RegExp(searchTerm, 'i')) !== -1
			  );
	const demoFilteredAccounts =
		searchTerm === null
			? demoAccounts
			: demoAccounts.filter(
					(account) => account.alias.search(new RegExp(searchTerm, 'i')) !== -1 || account.id.search(new RegExp(searchTerm, 'i')) !== -1
			  );

	/**
	 * The onClose function
	 */
	const onClose = () => {
		setSearchTerm(null);
		dispatch(changeModalAccountSearchOpen(false));
	};

	/**
	 * Handle when the user clicks on a new accountID.
	 *
	 * @param {str} account
	 */
	const handleAccountClick = (account) => {
		accountChange(account);
		onClose();
	};

	/**
	 * Cycle through the instrument options and map them
	 * to a new array as an option.
	 */
	const renderAccountOptions = (acctList) => {
		if (!acctList || !Array.isArray(acctList)) return '';

		return acctList
			.sort((a, b) => (a.id > b.id ? 1 : -1))
			.map((account) => {
				const newRef = createRef();
				focusRefs.push(newRef);

				return (
					<div
						className="listContainer"
						key={account.id}
						onClick={() => handleAccountClick(account)}
						ref={newRef}
						tabIndex={-1}
						onKeyDown={(e) => moveFocus(e, refSearch)}
					>
						<div className="itemRow">
							<div className="itemInfoCell cell">
								<div className="expandHandleWrap">
									{accountID === account.id ? (
										<span className="expandHandle">
											<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
												<path stroke="currentColor" d="M8 5l3.5 3.5L8 12"></path>
											</svg>
										</span>
									) : (
										''
									)}
								</div>
								<div className="description">
									<div className="symbolTitleNoFlow">
										<span>{account.alias}</span>
									</div>
								</div>
							</div>
							<div className="symbolDescription cell">{account.id}</div>
							<div className="exchangeCell cell">
								<div className="exchangeDescription">
									<div className="marketType">{account.demo ? 'demo' : 'live'}</div>
									<div className="exchangeName"></div>
								</div>
								<div className="flag">
									{account.demo ? (
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none">
											<circle stroke="currentColor" strokeWidth="1.5" cx="14" cy="14" r="10.25"></circle>
											<path
												fill="currentColor"
												d="M7.97 14.97a.75.75 0 1 0 1.06 1.06l-1.06-1.06zM12 12l.53-.53a.75.75 0 0 0-1.06 0L12 12zm3.5 3.5l-.53.53c.3.3.77.3 1.06 0l-.53-.53zm4.03-2.97a.75.75 0 1 0-1.06-1.06l1.06 1.06zm-10.5 3.5l3.5-3.5-1.06-1.06-3.5 3.5 1.06 1.06zm2.44-3.5l3.5 3.5 1.06-1.06-3.5-3.5-1.06 1.06zm4.56 3.5l3.5-3.5-1.06-1.06-3.5 3.5 1.06 1.06z"
											></path>
											<path
												stroke="currentColor"
												strokeLinecap="round"
												strokeWidth="1.5"
												d="M18.9 9a7 7 0 0 0-11.83 4M9 18.9A7 7 0 0 0 20.93 15"
											></path>
										</svg>
									) : (
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" fill="none">
											<path
												stroke="currentColor"
												strokeWidth="2"
												d="M9 28H3C2.44772 28 2 27.5523 2 27V23C2 21.8954 2.89543 21 4 21H9M9 28H16M9 28V21M16 28H23M16 28V15M23 28H29C29.5523 28 30 27.5523 30 27V6C30 4.89543 29.1046 4 28 4H25C23.8954 4 23 4.89543 23 6V15M23 28V15M16 15V11C16 9.89543 15.1046 9 14 9H11C9.89543 9 9 9.89543 9 11V21M16 15H23"
											></path>
										</svg>
									)}
								</div>
							</div>
						</div>
					</div>
				);
			});
	};

	return (
		<BaseModal isOpen={isOpen} onClose={onClose} title="Account Search">
			<InputGroup className="modalSearchInputGroup">
				<InputGroupAddon addonType="prepend">
					<span className="input-group-text transparentBG">
						<i className="fa fa-search" />
					</span>
				</InputGroupAddon>
				<Input
					type="text"
					className="modalSearch"
					placeholder="Search..."
					autoFocus={true}
					onChange={(e) => setSearchTerm(e.target.value)}
					innerRef={refSearch} // This is a prop of the input which converts into ref
					onKeyDown={(e) => searchKeyDown(e, focusRefs)}
				/>
			</InputGroup>
			<div className="ModalSearchResultsSeperator">Live</div>
			<div className="modalSearchResultsList">{renderAccountOptions(liveFilteredAccounts)}</div>
			<div className="ModalSearchResultsSeperator">Demo</div>
			<div className="modalSearchResultsList">{renderAccountOptions(demoFilteredAccounts)}</div>
		</BaseModal>
	);
};
