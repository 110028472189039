import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCalendar } from '../../../../../actions/oanda';
// import { floatToCurrencyFormat, customSortCaret, NoDataIndication } from '../../../../../components/utils';
import Moment from 'react-moment';
import { Table, Badge } from 'reactstrap';

// To use redux hooks, you export a function component, not a react component.
export const Calendar = () => {
	const calendarData = useSelector((state) => state.oanda.calendar);
	const dispatch = useDispatch();

	/**
	 * Similar to componentDidMount and componentDidUpdate:
	 * The optional second param ([]) in useEffect tells it to only
	 * run if whatever data in that array has changed. If left blank (just []),
	 * it will only run once.
	 */
	useEffect(() => {
		dispatch(getCalendar());
	}, [dispatch]);

	/**
	 * Loop through and render the timeline
	 */
	const renderTimeLine = () => {
		return calendarData.map((line, index) => {
			let formatedDate = <Moment unix>{line.timestamp}</Moment>;
			let impaceBadgeColor = 'success';
			let forcastData = '';

			/**
			 * Change the badge color based on the impact
			 */
			if (line.impact >= 3) impaceBadgeColor = 'danger';
			else if (line.impact === 2) impaceBadgeColor = 'warning';

			/**
			 * This will format the forcast/actual/etc event
			 */
			if (line.unit) {
				forcastData = (
					<div className="d-flex flex-wrap justify-content-between">
						<div>
							<h6>{line.actual ? `${line.actual}${line.unit}` : '-'}</h6>
							<p className="text-muted mb-0 mr">
								<small>Actual</small>
							</p>
						</div>
						<div>
							<h6>{line.forecast ? `${line.forecast}${line.unit}` : '-'}</h6>
							<p className="text-muted mb-0">
								<small>Forecast</small>
							</p>
						</div>
						<div>
							<h6>{line.market ? `${line.market}${line.unit}` : '-'}</h6>
							<p className="text-muted mb-0">
								<small>Market</small>
							</p>
						</div>
						<div>
							<h6>{line.previous ? `${line.previous}${line.unit}` : '-'}</h6>
							<p className="text-muted mb-0 mr">
								<small>Previous</small>
							</p>
						</div>
					</div>
				);
			}

			/**
			 * Create the badge
			 */
			let impactBadge = (
				<Badge color={impaceBadgeColor} className="text-gray-dark" style={{ width: '50px' }}>
					{line.impact}
				</Badge>
			);

			return (
				<tr key={`calendarNews_${index}`}>
					<td className="align-middle">{line.currency}</td>
					<td className="align-middle">{line.title}</td>
					<td className="align-middle">{line.region}</td>
					<td className="align-middle">{impactBadge}</td>
					<td className="align-middle">{forcastData}</td>
					<td className="align-middle">{formatedDate}</td>
				</tr>
			);
		});
	};

	return (
		<Table className="table-striped text-white">
			<thead>
				<tr>
					<th>Currency</th>
					<th>Title</th>
					<th>Region</th>
					<th>Impact</th>
					<th>Data</th>
					<th>Date</th>
				</tr>
			</thead>
			<tbody>{renderTimeLine()}</tbody>
		</Table>
	);
};
