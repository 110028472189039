import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { store } from '../index';
import { oandaActions, authActions, backtest } from '../actions';
import {
	FETCH_ACCOUNT_DETAILS,
	FETCH_PROFIT_DATA_TODAY,
	UPDATE_CHART_CANDLES,
	FETCH_HEARTBEAT_DATA,
	FETCH_PROFIT_STATS,
	FETCH_POSITIONS,
	FETCH_ORDERS,
	FETCH_TRADES,
	SET_CONSOLE_DATA,
	FETCH_ORDER_BOOK,
	FETCH_POSITION_BOOK,
	FETCH_DASHBOARD_DATA,
	FETCH_CONVERSION_RATES,
} from '../actions/types';
import Login from '../pages/auth/login';
import { Chart } from '../pages/chart/Chart';
import { getHistory } from '../index';
import { objectEqual } from './utils';
import '../styles/theme.scss';
import LayoutComponent from '../components/Layout';
import successSound from '../sounds/success.wav';
import failureSound from '../sounds/failure.mp3';
import message1 from '../sounds/message1.mp3';
import beepbeep from '../sounds/beepbeep.mp3';
import bsPing from '../sounds/BigSur/Ping.mp3';
import { DashboardModalWrapper } from './Modals/DashboardModalWrapper';
import { push } from 'connected-react-router';
import { SocketContext, socket } from '../context/socket';

/* eslint-disable */
import { fetchNotes } from '../actions/notes';
import { Notes } from '../pages/notes/Notes';
import { News } from '../pages/news/News';
import { dashboardDataToBalanceFromStats, fetchProfitStats } from '../actions/oanda';
//import ErrorPage from '../pages/error';
/* eslint-enable */

/**
 * create our close button for toast
 */
const CloseButton = ({ closeToast }) => <i onClick={closeToast} className="la la-close notifications-close" />;

/**
 * Define our sounds
 */
const successAudio = new Audio(successSound);
// const successAudio2 = new Audio('../sounds/success1.mp3');
// const successAudio3 = new Audio('../sounds/success2.mp3');
const failureAudio = new Audio(failureSound);
const message1Audio = new Audio(message1);
const beepbeepAudio = new Audio(beepbeep);
const bsPingAudio = new Audio(bsPing);

/**
 * This is a global holder of our toastIDs so that
 * we can remove the first one when we get past a
 * certain amount/limit.
 */
let toastIDHolder = {
	bottomLeft: [],
	topRight: [],
};

class App extends React.PureComponent {
	// The instant this component is rendered on the string
	// componentDidMount is the preferred return for calls of this type
	componentDidMount() {
		if (window.location.pathname !== '/login') this.props.fetchUser(); // From the actions/index.js file
		// if (window.location.pathname == '/chart') this.props.fetchAllData();
	}

	componentDidUpdate() {
		if (this.props.oandaAccountID && this.props.oandaAccountList.length > 0) {
			// Use an arrow function in the find to find the account with id matching the event.
			let accountInfo = this.props.oandaAccountList.find((acct) => acct.id === this.props.oandaAccountID);
			// socket.emit('changeAccountId', { accountInfo });
		}
	}

	render() {
		// if (!this.props.currentUser) return <div></div>;

		return (
			<SocketContext.Provider value={socket}>
				<ToastContainer closeButton={<CloseButton />} limit={6} />
				<ConnectedRouter history={getHistory()}>
					<BrowserRouter>
						<Switch>
							<Route path="/login" exact component={Login} />
							<Route path="/chart" exact component={Chart} />
							<Route path="/notes" exact component={Notes} />
							<Route path="/news" exact component={News} />
							<Route path="/" dispatch={this.props.dispatch} component={LayoutComponent} />
							<Route path="/app" exact render={() => <Redirect to="/app/main" />} />
						</Switch>
					</BrowserRouter>
					<DashboardModalWrapper />
				</ConnectedRouter>
			</SocketContext.Provider>
		);
	}
}

// Socket stuff
socket.on('transactions', (data) => {
	if (!data) return;

	const oandaAccountID = store.getState().oanda.accountID;

	/**
	 * The transaction data has the accountID on actual transactions
	 * but does not have it on HEARTBEATS.
	 *
	 * In Node, in the socket area, I appended a .id to all transactions which
	 * has the actual account ID.
	 */
	if (data.id === oandaAccountID) {
		if (data['type'] !== 'HEARTBEAT') {
			store.dispatch(oandaActions.fetchPositions());

			/**
			 * If we are on the homepage, we should update
			 * the recent transaction list.
			 */
			if (window.location.pathname === '/') store.dispatch(backtest.fetchRecentTransactionBrowseData(oandaAccountID));
		}
		//if (data['type'] !== 'HEARTBEAT') toast.success(data.type, { position: 'bottom-right' });
	}
});

socket.on('accountDetails', (data) => {
	const oandaAccountID = store.getState().oanda.accountID;
	if (data.account.id === oandaAccountID && !objectEqual(data.account, store.getState().oanda.accountDetails[oandaAccountID])) {
		store.dispatch({ type: FETCH_ACCOUNT_DETAILS, payload: data.account });
		store.dispatch({ type: FETCH_POSITIONS, payload: data.account });
		store.dispatch({ type: FETCH_ORDERS, payload: data.account });
		store.dispatch({ type: FETCH_TRADES, payload: data.account });
	}
});

socket.on('profitTodayUpdate', (data) => {
	const oandaAccountID = store.getState().oanda.accountID;
	if (data.accountID === oandaAccountID) store.dispatch({ type: FETCH_PROFIT_DATA_TODAY, payload: data });
});

socket.on('currencyRates', (data) => {
	if (data) store.dispatch({ type: FETCH_CONVERSION_RATES, payload: data.rates }); // Apply .rates so we don't have .rates.rates
});

socket.on('pricing', (data) => {
	//console.log(data);
});

socket.on('NewBook', (data) => {
	if (data && data.instrument === store.getState().oanda.chartInstrument) {
		if (data.type === 'order') store.dispatch({ type: FETCH_ORDER_BOOK, payload: data.message });
		if (data.type === 'position') store.dispatch({ type: FETCH_POSITION_BOOK, payload: data.message });
	}
});

/**
 * This is typically that we've made money, but it could
 * be negative.
 */
socket.on('TransactionClosed', (data) => {
	// console.log('Received TransactionClosed', data);

	/**
	 * We want the alias for the account.
	 */
	const alias = store.getState().oanda.accountList.find((obj) => obj.id === data.transaction.accountID);

	/**
	 * If null or demo, do nothing!
	 */
	if (alias == null || alias.demo === true) return;

	/**
	 * Create our config for toast notifications
	 */
	const toastConfig = {
		position: 'bottom-left',
		autoClose: 30000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		toastId: data.transaction.id, // This will prevent duplicates
	};

	/**
	 * Get the profit or loss amount
	 */
	const plAmount = parseFloat(data.transaction.pl).toFixed(2);

	/**
	 * If it is positive or negative, change the
	 * color.
	 */
	try {
		if (parseFloat(data.transaction.pl) > 0) {
			successAudio.play();
			toastIDHolder.bottomLeft.push(toast.dark(`$${plAmount} earned on ${alias.alias} (${data.transaction.instrument})`, toastConfig));
		} else if (parseFloat(data.transaction.pl) < 0) {
			failureAudio.play();
			toastIDHolder.bottomLeft.push(toast.error(`$${plAmount} lost on ${alias.alias} (${data.transaction.instrument})`, toastConfig));
		}

		/**
		 * Check the toast counts and remove any
		 * excessive amounts.
		 */
		checkToastCount();
	} catch (error) {
		console.log(error);
	}
});

/**
 * A new notification has appeared
 */
socket.on('Notification', (data) => {
	/**
	 * Create our config for toast notifications
	 */
	const toastConfig = {
		position: 'top-right',
		autoClose: data.type === 'error' || data.type === 'warning' ? false : 50000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		toastId: Math.random().toString(36).substr(2, 7), // This will prevent duplicates
	};

	/**
	 * If it is positive or negative, change the
	 * color.
	 */
	try {
		if (data.type === 'error') {
			failureAudio.play();

			/**
			 * We don't push errors or warnings into the toast array
			 * because we don't want them removed.
			 */
			toast.error(data.message, toastConfig);
		} else if (data.type === 'info') {
			bsPingAudio.play();
			toastIDHolder.topRight.push(toast.info(data.message, toastConfig));
		} else if (data.type === 'warning') {
			beepbeepAudio.play();
			toast.warning(data.message, toastConfig);
		} else {
			message1Audio.play();
			toastIDHolder.topRight.push(toast.dark(data.message, toastConfig));
		}

		/**
		 * Check the toast counts and remove any
		 * excessive amounts.
		 */
		checkToastCount();
	} catch (error) {
		console.log(error);
	}
});

socket.on('Heartbeat', (data) => {
	if (data == null) return;
	// if (objectEqual(data, store.getState().heartbeat.data)) return;

	store.dispatch({ type: FETCH_HEARTBEAT_DATA, payload: data });

	/**
	 * We need to create a custom data structure for the
	 * past historical heartbeat data.
	 *
	 * Disabled, this caused a re-render of however many
	 * machines there was. This functionally is no longer used
	 * anyway (the history).
	 */
	// store.dispatch(heartbeat.setHistoricalData(data));
});

socket.on('Console', (data) => {
	if (data == null) return;
	store.dispatch({ type: SET_CONSOLE_DATA, payload: data });
});

/**
 * We received updates on various things such as
 * notes being updated.
 */
socket.on('Update', (data) => {
	if (data == null) return;

	if (data.type === 'Notes') store.dispatch(fetchNotes());
});

/**
 * This was for ALL of the profit stats
 */
socket.on('ProfitStats', (data) => {
	if (data != null) {
		store.dispatch({ type: FETCH_PROFIT_STATS, payload: data.data });
		store.dispatch(dashboardDataToBalanceFromStats(data));
	}
});

/**
 * This was for ALL of the profit stats
 */
socket.on('ProfitStatsUpdate', (data) => {
	store.dispatch(fetchProfitStats());
});

/**
 * This is for the profit stats of the currently selected
 * ID.
 */
socket.on('ProfitStatsByID', (data) => {
	const oandaAccountID = store.getState().oanda.accountID;

	/**
	 * Removed && data.accountID !== 'live' && data.accountID !== 'demo'
	 * I'm not sure why it was blocked or if it is even coming through?
	 *
	 * 2021-09-24 07:58:12 JD
	 */
	if (data == null && data.accountID !== oandaAccountID) return;
	store.dispatch({ type: FETCH_DASHBOARD_DATA, payload: data });
});

/**
 * Send the user to the login page.
 */
socket.on('login', (data) => {
	if (data != null) store.dispatch(push('/login'));
});

/**
 * Update the candles on the chart
 */
socket.on('candleUpdate', (data) => {
	if (!data) return;

	const chartInstrument = store.getState().oanda.chartInstrument;
	const chartGranularity = store.getState().oanda.chartGranularity;

	if (chartInstrument && chartGranularity && data.instrument === chartInstrument && data.granularity === chartGranularity) {
		store.dispatch({ type: UPDATE_CHART_CANDLES, payload: data });
	}
});

/**
 * The account ID on the socket server is invalid
 * on the socket server.
 */
socket.on('ExpiredAccountID', () => {
	let accountInfo = store.getState().oanda.accountList.find((acct) => acct.id === store.getState().oanda.accountID);
	socket.emit('changeAccountId', { accountInfo });
});

/**
 * This updates the version from the server
 */
socket.on('version', (data) => {
	if (store.getState().auth.version !== data.version) window.location.reload();
});

/**
 * Remove the first object which will be the 0th object.
 * Dismis the toast.
 */
const checkToastCount = () => {
	if (toastIDHolder.topRight.length >= 4) toast.dismiss(toastIDHolder.topRight.shift());
	if (toastIDHolder.bottomLeft.length >= 4) toast.dismiss(toastIDHolder.bottomLeft.shift());
};

const mapStateToProps = (store) => ({
	oandaAccountID: store.oanda.accountID,
	currentUser: store.auth.currentUser,
	oandaAccountList: store.oanda.accountList,

	// loadingInit: store.auth.loadingInit,
});
// Once we pass in all the different actions, they are assigned to the
// app component as props.
export default connect(mapStateToProps, { fetchUser: authActions.fetchUser, fetchAllData: oandaActions.fetchAllData })(App);
