/**
 * this comes from https://stackoverflow.com/questions/21703318/is-it-possible-to-enhance-the-default-appearance-of-svg-title-tooltip
 */
import React from 'react';

export const BasicToolTip = ({ x, y, width = 100, height = 25, rx = 2, opacity = 0.9, className = 'react-svg-tooltip', content = '' }) => {
	width = Math.ceil(content.length * 7.3);

	return (
		<g className={className} transform={`translate(${x}, ${y})`} opacity={opacity}>
			<rect rx={rx} width={width} height={height}></rect>
			<text x="15" y="16">
				{content}
			</text>
		</g>
	);
};
