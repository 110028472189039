import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { closeSidebar } from '../../actions/navigation';
import { fetchNotes } from '../../actions/notes';
import { NotesAndLists } from '../dashboard/components/tradingpanel/components/NotesAndLists';

// To use redux hooks, you export a function component, not a react component.
export const Notes = () => {
	/**
	 * State dispatch
	 */
	const dispatch = useDispatch();

	/**
	 * Run once on load to fetch our data
	 */
	useEffect(() => {
		dispatch(fetchNotes());

		/**
		 * Keep the sidebar closed if we are on the graph chart.
		 */
		dispatch(closeSidebar());
	}, [dispatch]); //run once on initial render

	return (
		<div id="root">
			<div className="Layout_root__KuT-s sidebar-left sidebar-hide">
				<div className="Layout_wrap__3Y4cM" style={{ marginLeft: '50px', marginRight: '50px', marginTop: '20px' }}>
					<Row>
						<Col xl={6} sm={12} className="notes-left-col">
							<NotesAndLists type="todo" />
						</Col>
						<Col xl={6} sm={12} className="notes-right-col">
							<NotesAndLists type="notes" />
							<hr className="notes-hr" />
							<NotesAndLists type="records" />
							<hr className="notes-hr" />
							<NotesAndLists type="links" />
							<hr className="notes-hr" />
							<NotesAndLists type="quotes" />
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};
