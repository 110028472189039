/**
 * this comes from https://github.com/rrag/react-stockcharts/issues/707
 */
import React from 'react';
import GenericChartComponent from './GenericChartComponent';
// import { floatPrecisionFix } from '../../../../components/utils';
// import { chartColors } from '../../../../components/StockChart/config/genericConfig';

import { getAxisCanvas } from './GenericComponent';
import { chartColors } from '../../../../components/StockChart/config/genericConfig';
// import { hexToRGBA } from './utils';

export const ConsolidationZones = ({
	fillTop = chartColors.tradingViewBlue, // 'rgba(66, 184, 131, 0.7)',
	fillBottom = chartColors.pink, // 'rgba(66, 184, 131, 0.7)',
	breakOutBars = 2,
	lookbackPeriod = 20, // Loopback Period (bars back)
	consolidationLen = 5, // Consolidation Length/bars
	atrLength = 25,
}) => {
	const drawOnCanvas = (ctx, moreProps) => {
		const {
			plotData,
			// mouseXY,
			chartConfig: { yScale },
			xScale,
			xAccessor,
		} = moreProps;

		/**
		 * These are the actual upper and lower yaxis
		 * values as shown.
		 *
		 * use realYDomain to get just the charbar price
		 * values.
		 *
		 * IE:
		 * const { chartConfig: { yScale, realYDomain } } = moreProps;
		 * realYDomain[0] == lower, realYDomain[1] = upper;
		 *
		 */
		const [lowerYValue, upperYValue] = yScale.domain();

		/**
		 * The plotData is the bars being shown depending on
		 * zoom and pan.
		 */
		if (!plotData || plotData.length === 0 || !yScale) return null;

		/**
		 * These are ported directly from the
		 * C# indicator that you made.
		 *
		 * 2021-03-29 11:23:41 JD
		 */
		const consolidationZones = [];

		/**
		 * Loop through all of our plotable data now.
		 *
		 * The data is 0 = oldest to newest
		 */
		for (let i = 0; i < plotData.length; i++) {
			let counter = 0,
				startIndex = i,
				endIndex = i,
				breakOutHigh = 0,
				breakOutLow = 0;

			/**
			 * Go forward
			 */
			if (i < plotData.length - consolidationLen) {
				for (let x = i; x <= i + lookbackPeriod; x++) {
					/**
					 * Determine if the bars are within the start bar
					 */
					if (plotData?.[x]?.close < plotData?.[i]?.high && plotData?.[x]?.close > plotData?.[i]?.low) {
						counter++;
						endIndex = x;

						/**
						 * Find our lows and highs
						 */
						if (breakOutLow === 0 || plotData[x].close < breakOutLow) breakOutLow = plotData[x].close;
						if (breakOutHigh === 0 || plotData[x].close > breakOutHigh) breakOutHigh = plotData[x].close;
					} else break;
				}
			}
			/**
			 * Go back over our lookback period.
			 */
			if (i > lookbackPeriod) {
				for (let x = i; x >= i - lookbackPeriod; x--) {
					/**
					 * Determine if the bars are within the start bar
					 */
					if (plotData[x].close < plotData[i].high && plotData[x].close > plotData[i].low) {
						counter++;
						startIndex = x;

						/**
						 * Find our lows and highs
						 */
						if (breakOutLow === 0 || plotData[x].close < breakOutLow) breakOutLow = plotData[x].close;
						if (breakOutHigh === 0 || plotData[x].close > breakOutHigh) breakOutHigh = plotData[x].close;
					} else break;
				}
			}

			/**
			 * If we found one, push it into our array
			 */
			if (counter > consolidationLen) {
				// for (let c = 0; c < consolidationZones.length; c++) {
				// 	if (consolidationZones[c].endIndex)
				// }
				consolidationZones.push({
					start: plotData[startIndex],
					startIndex: startIndex,
					end: plotData[endIndex],
					endIndex: endIndex,
					priceHigh: breakOutHigh,
					priceLow: breakOutLow,
					priceMiddle: (breakOutLow + breakOutHigh) / 2,
				});

				/**
				 * set our i to the end.
				 */
				// i = endIndex + 1;
			}
		}

		/**
		 * Loop through all of our found zones and draw them out.
		 */
		for (let i = 0; i < consolidationZones.length; i++) {
			const zone = consolidationZones[i];

			/**
			 * Get our cooridnates. This is done using the xAccessor
			 * and passing in the entire bar data which contains the IDX
			 * (index) values.
			 */
			const y1Top = yScale(zone.priceHigh);
			const y1Bottom = yScale(zone.priceLow);

			// ctx.fillStyle = fill;
			// ctx.strokeStyle = stroke;
			// ctx.beginPath();
			// ctx.rect(zone.start, y1, zone.end, rectHeight);
			// ctx.strokeRect(zone.start, y1, zone.end, rectHeight);
			// ctx.closePath();
			// ctx.fill();

			/**
			 * Top line
			 */
			ctx.strokeStyle = fillTop;
			ctx.lineWidth = 1;
			ctx.beginPath();
			ctx.setLineDash([]);
			ctx.moveTo(xScale(xAccessor(zone.start)), y1Top);
			ctx.lineTo(xScale(xAccessor(zone.end)), y1Top);
			ctx.closePath();
			ctx.stroke();

			/**
			 * Bottom Line
			 */
			ctx.strokeStyle = fillBottom;
			ctx.lineWidth = 1;
			ctx.beginPath();
			ctx.setLineDash([]);
			ctx.moveTo(xScale(xAccessor(zone.start)), y1Bottom);
			ctx.lineTo(xScale(xAccessor(zone.end)), y1Bottom);
			ctx.closePath();
			ctx.stroke();
		}
	};

	const renderSVG = (moreProps) => {};

	return (
		<GenericChartComponent
			canvasToDraw={getAxisCanvas}
			canvasDraw={drawOnCanvas}
			svgDraw={renderSVG}
			drawOn={['pan']}
			key={Math.random().toString(36).substr(2, 7)}
		/>
	);
};
